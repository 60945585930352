import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import InshaPage from "views/InshaBanglesPage/InshaPage.js";
import InshaSizeMMList from "views/InshaBanglesPage/Section/InshaSizeMMList.js";
import MakingCharge from "views/InshaBanglesPage/Section/MakingCharge";
import InshaContacts from "views/InshaBanglesPage/Section/InshaContacts";
import CareerField from "views/InshaBanglesPage/Section/CareerField";
import PrivacyPolicy from "views/InshaBanglesPage/Section/PrivacyPolicy";
import ReturnPolicy from "views/InshaBanglesPage/Section/ReturnPolicy";
import RefundPolicy from "views/InshaBanglesPage/Section/RefundPolicy";
import TermCondition from "views/InshaBanglesPage/Section/Term&Condition";
import TopBangles from "views/InshaBanglesPage/Section/TopBangles";
import License from "views/InshaBanglesPage/Section/License";
import ImageGallery from "views/InshaBanglesPage/Section/ImageGallery.js";
import ErrPage from "views/InshaBanglesPage/Section/ErrPage";
import InshaBanglesSizeGuide from "views/InshaBanglesPage/Section/InshaBanglesSizeGuide";
// import Payment from "views/InshaBanglesPage/Cart/Payment";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/error-page" component={ErrPage} />
      <Route path="/insha-bangles-size-guide" component={InshaBanglesSizeGuide} />
      <Route path="/size-mm-list" component={InshaSizeMMList} /> 
      <Route path="/making-charge" component={MakingCharge} />
      <Route path="/contact-us" component={InshaContacts} />
      <Route path="/careers" component={CareerField} />
      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/return-policy" component={ReturnPolicy} />
      <Route path="/refund-policy" component={RefundPolicy} />
      <Route path="/terms-and-conditions" component={TermCondition} />
      <Route path="/top-bangles-manufacturers-in-india" component={TopBangles} />
      <Route path="/license" component={License} />
      <Route path="/image-gallery" component={ImageGallery} />
      <Route path="/" component={InshaPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
