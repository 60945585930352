/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { headApi } from "utils/headApi";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import InshaSection from "views/InshaBanglesPage/Section/InshaSectionPro.js";
import InshaContact from "views/InshaBanglesPage/Section/InshaContact.js";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { FiPhoneCall } from "react-icons/fi";
import Close from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import InshaTeam from "views/InshaBanglesPage/Section/InshaTeam";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";


const useStyles = makeStyles(presentationStyle);
const useStyle = makeStyles(javascriptStyles);
const useStyl = makeStyles(productStyle);

// function initializeAnalytics(){
//   ReactGA.initialize("G-N88RPJG9X5")
//   ReactGA.pageview("/home")
// }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function InshaPage() {
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [otpNumber, setOtpNumber] = React.useState("");
  const [otpNumberErr, setOtpNumberErr] = React.useState("");
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [loginModal, setLoginModal] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const classPop = useStyle();
  const classe = useStyles();
  const history = useHistory();

  const activeSession = sessionStorage.getItem("sessionData");

  // useEffect(() => {
  //   ReactGA.event({
  //     category:'Home Page',
  //     action: 'Load on the landing page',
  //   })
  // }, [])

  // initializeAnalytics()

  return (
    <div>
      <Helmet>
        <title>Insha Bangles</title>
        <meta name="description" content="Best Manufacture Comapnay" />
        <meta
          name="keywords"
          content="We are one of the top manufacturers and retailers in Maharastra & Karnataka"
        />
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" textAlign="center" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "dark",
        }}
      />
      <Parallax
        image={require("assets/img/bg4.png").default}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1>
                  INSHA BANGLES
                  {/* <span className={classes.proBadge}>PRO</span> */}
                </h1>
                <h3 className={classes.title}>
                  We have everthing in Fashion and Style, Ready for every occasion and our designs never go out of Fashion.
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <InshaSection />
        {/* <InshaTeam /> */}
      </div>
      <InshaContact />
      <Footer />
    </div>
  );
}