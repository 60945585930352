/*eslint-disable*/
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Apps from "@material-ui/icons/Apps";
import { IoFingerPrintSharp } from "react-icons/io5"; //IoBagAddOutline
import { IoMdLogOut } from "react-icons/io";
// import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import parentApi from "offlineApi/parentApi";

import styles from "assets/jss/material-kit-pro-react/components/inshaheaderLinkStyle.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

import { cardTitle } from "assets/jss/material-kit-pro-react.js";

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
const useStyle = makeStyles(javascriptStyles);
const useStyl = makeStyles(headersStyle);
const useSty = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function InshaHeaderLink(props) {
  const [bangle, setbangle] = React.useState([]);
  const [earing, setEaring] = React.useState([]);
  const [ring, setRing] = React.useState([]);
  const [watch, setWatch] = React.useState([]);
  const [kada, setKada] = React.useState([]);

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const classHeader = useStyl();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  useEffect(() => {
    setbangle(parentApi.filter((b) => b.adminId == "2" && b.category_id == "11"));
    setEaring(parentApi.filter((b) => b.adminId == "2" && b.category_id == "12"));
    setRing(parentApi.filter((b) => b.adminId == "2" && b.category_id == "13"));
    setWatch(parentApi.filter((b) => b.adminId == "2" && b.category_id == "14"));
    setKada(parentApi.filter((b) => b.adminId == "2" && b.category_id == "15"));
  },[])

  
  return (
    <div className={classHeader.collapse}>
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Bangles"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              bangle &&
              bangle.map((x) => (
                <Link
                  to={`/size-mm-list?catdesc=BANGLES&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}>
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Earrings"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList=
            {earing && earing.map((x) => (
              <Link to={`/size-mm-list?catdesc=EARRINGS&cat1desc=${x.title}&Plating=Gold%20Plating`} className={classes.dropdownLink} key={x.id}>
                {x.title}
              </Link>
            ))}
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Finger Ring"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList=
            {ring && ring.map((x) => (
              <Link to={`/size-mm-list?catdesc=FINGER RINGS&cat1desc=${x.title}&Plating=Gold%20Plating`} className={classes.dropdownLink} key={x.id}>
                {x.title}
              </Link>
            ))}
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Watch"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList=
            {watch && watch.map((x) => (
              <Link to={`/size-mm-list?catdesc=WATCH&cat1desc=${x.title}&Plating=Gold%20Plating`} className={classes.dropdownLink} key={x.id}>
                {x.title}
              </Link>
            ))}
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Punjabi kada"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList=
            {kada && kada.map((x) => (
              <Link to={`/size-mm-list?catdesc=PUNJABI KADA&cat1desc=${x.title}&Plating=Gold%20Plating`} className={classes.dropdownLink} key={x.id}>
                {x.title}
              </Link>
            ))}
          />
        </ListItem>
      </List>
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          {!activeSession ? (
            <Button
              color="transparent"
              simple
              size="lg"
              // onClick={() => setLoginModal(true)}
              // href="https://client.inshabangles.com/"
              className={classes.navLink + " " + classes.navLinkJustIcon}
            >
              <IoFingerPrintSharp className={classes.dropdownIcons} /> Log In
            </Button>
          ) : (
            <Button
              color="transparent"
              simple
              size="lg"
              // onClick={handleLogOut}
              className={classes.navLink + " " + classes.navLinkJustIcon}
            >
              <IoMdLogOut className={classes.dropdownIcons} /> Log Out
            </Button>
          )}
        </ListItem>
      </List>
    </div>
  );
}

InshaHeaderLink.defaultProps = {
  hoverColor: "primary",
};

InshaHeaderLink.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};