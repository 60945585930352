/*eslint-disable*/
import React, { useEffect } from "react";
import classNames from "classnames";
import Selects from "react-select";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
// import { saveAs } from 'file-saver'
import Axios from "axios";
import fileDownload from "js-file-download";
import Button from "components/CustomButtons/Button.js";

import Parallax from "components/Parallax/Parallax.js";
import InshaFooter from "./InshaFooter";
import InshaHeaderLink from "./InshaHeaderLink";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(styles);

import blog1 from "assets/img/examples/blog1.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import marc from "assets/img/faces/marc.jpg";

export default function ImageGallery() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const [prodList, setProdList] = React.useState([]);
  const [itemProd, setItemProd] = React.useState([]);
  const [simpleProductSelect, setSimpleProductSelect] = React.useState("");
  const [prodId, setProdId] = React.useState("");
  const [prodDesc, setProdDesc] = React.useState("");
  const [prodItem1, setProdItem1] = React.useState("");
  const [prodItem2, setProdItem2] = React.useState("");
  const [prodItem3, setProdItem3] = React.useState("");
  const [prodItem4, setProdItem4] = React.useState("");
  const [prodItem5, setProdItem5] = React.useState("");
  const [prodItem6, setProdItem6] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();

  const activeSession = sessionStorage.getItem("sessionData");

  useEffect(() => {
    fetch(headApi + `/products?userid=1&active=1`)
      .then((res) => res.json())
      .then((data) => {
        setProdList(data.ProductsData);
        setItemProd(data.ProductsData);
      });
  }, []);

  const handleProductSimple = (simpleProductSelect) => {
    setSimpleProductSelect(simpleProductSelect);
    if (simpleProductSelect.value) {
      // let newP = simpleProductSelect.value.split("-");
      const itemz = itemProd.find(
        (itemz) => itemz.id == simpleProductSelect.value
      );
      setProdId(itemz.id);
      setProdDesc(itemz.description)
      let tempImg = [];
      let val = 1;
      Object.keys(itemz).map((i) => {
        if (i.includes("design")) {
          tempImg.push({
            crop_img: imgApi + "" + itemz[i][`image${val}`],
            orgImg: imgApi + "" + itemz[i][`originalimg${val}`],
          });
          val += 1;
        }
      });
      setProdItem1(tempImg[0]);
      setProdItem2(tempImg[1]);
      setProdItem3(tempImg[2]);
      setProdItem4(tempImg[3]);
      setProdItem5(tempImg[4]);
      setProdItem6(tempImg[5]);
    }
  };

  // const download1 = (e, prod) => {
  //   Axios({
  //     url: imgApi + "" + prod.design1.originalimg1,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then((res) => {
  //     const split = prod.design1.originalimg1.split("/");
  //     fileDownload(res.data, split[2]);
  //   });
  // };

  // const download2 = (e, prod) => {
  //   Axios({
  //     url: imgApi + "" + prod.design2.originalimg2,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then((res) => {
  //     const split = prod.design2.originalimg2.split("/");
  //     fileDownload(res.data, split[2]);
  //   });
  // };

  // const download3 = (e, prod) => {
  //   Axios({
  //     url: imgApi + "" + prod.design3.originalimg3,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then((res) => {
  //     const split = prod.design3.originalimg3.split("/");
  //     fileDownload(res.data, split[2]);
  //   });
  // };

  // const download4 = (e, prod) => {
  //   Axios({
  //     url: imgApi + "" + prod.design4.originalimg4,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then((res) => {
  //     const split = prod.design4.originalimg4.split("/");
  //     fileDownload(res.data, split[2]);
  //   });
  // };

  // const download5 = (e, prod) => {
  //   Axios({
  //     url: imgApi + "" + prod.design5.originalimg5,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then((res) => {
  //     const split = prod.design5.originalimg5.split("/");
  //     fileDownload(res.data, split[2]);
  //   });
  // };

  // const download6 = (e, prod) => {
  //   Axios({
  //     url: imgApi + "" + prod.design6.originalimg6,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then((res) => {
  //     const split = prod.design6.originalimg6.split("/");
  //     fileDownload(res.data, split[2]);
  //   });
  // };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <Helmet>
        <title>Image Gallery</title>
        <meta
          name="description"
          content="Omega Bangles Image Gallery Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can't offer you a refund or exchange "
        />
        <meta name="keywords" content="Omega Bangles Image Gallery" />
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "dark",
        }}
      />
      <Parallax
        image={require("assets/img/bg2.png").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={10}
              sm={10}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1
                style={{
                  color: "#fff",
                  fontFamily: "Tahoma",
                  fontWeight: "bold",
                }}
              >
                Image Gallery
              </h1>
              <h4 style={{ color: "#fff" }}>Gallery</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        {/* <div> */}
        <div className={classe.container}>
          {/* <div className={classe.title}>
                            <h3>Plain Card</h3>
                        </div> */}
          <GridContainer justify="center" alignItems="center">
            <GridItem
              xs={12}
              sm={12}
              md={6}
              style={{ marginTop: "5%", marginBottom: "2%" }}
            >
              <Selects
                styles={customStyles}
                style={select}
                placeholder="Search Product Id..."
                value={simpleProductSelect}
                onChange={handleProductSimple}
                options={
                  itemProd &&
                  itemProd.map((p) => {
                    return {
                      label: p.id,
                      value: p.id,
                    };
                  })
                }
              />
            </GridItem>
          </GridContainer>
          {simpleProductSelect ? (
            <GridContainer>
              {prodItem1.crop_img !== imgApi + "" + "/crop_img/null" ? (
                <GridItem xs={12} sm={6} md={6} lg={2}>
                  <Card blog plain>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={prodItem1.crop_img} alt="..." />
                      </a>
                      <div
                        className={classe.coloredShadow}
                        style={{
                          backgroundImage: `url(${prodItem1.crop_img})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    {/* href={`https://api.whatsapp.com/send/?phone=${prod.user.whatsapp
                              }&text=https://inshabangles.com/insha-product?id=${prod.id
                              }%20${prod.banglessizemm.description
                              }%20${prod.plating.description.slice(
                                8
                              )}&app_absent=0`} */}
                    <CardBody plain>
                      <a href={prodItem1.orgImg} target="_blank">
                        Download Org
                      </a>
                      <br />
                      <a href={`https://api.whatsapp.com/send/?phone=+919967457786&text=${prodItem1.crop_img}&app_absent=0`} target="_blank">
                        Download Crop
                      </a>
                      <div className={classe.cardDescription}>{prodId + "/1 - " + prodDesc}</div>
                    </CardBody>
                  </Card>
                </GridItem>
              ) : (
                ""
              )}
              {prodItem2.crop_img !== imgApi + "" + "/crop_img/null" ? (
                <GridItem xs={12} sm={6} md={6} lg={2}>
                  <Card blog plain>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={prodItem2.crop_img} alt="..." />
                      </a>
                      <div
                        className={classe.coloredShadow}
                        style={{
                          backgroundImage: `url(${prodItem2.crop_img})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    <CardBody plain>
                      <a href={prodItem2.orgImg} target="_blank">
                        Download Org
                      </a>
                      <br />
                      <a href={`https://api.whatsapp.com/send/?phone=+919967457786&text=${prodItem2.crop_img}`} target="_blank">
                        Download Crop
                      </a>
                      <div className={classe.cardDescription}>{prodId + "/2 - " + prodDesc}</div>
                    </CardBody>
                  </Card>
                </GridItem>
              ) : (
                ""
              )}
              {prodItem3.crop_img !== imgApi + "" + "/crop_img/null" ? (
                <GridItem xs={12} sm={6} md={6} lg={2}>
                  <Card blog plain>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={prodItem3.crop_img} alt="..." />
                      </a>
                      <div
                        className={classe.coloredShadow}
                        style={{
                          backgroundImage: `url(${prodItem3.crop_img})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    <CardBody plain>
                      <a href={prodItem3.orgImg} target="_blank">
                        Download Org
                      </a>
                      <br />
                      <a href={`https://api.whatsapp.com/send/?phone=+919967457786&text=${prodItem3.crop_img}`} target="_blank">
                        Download Crop
                      </a>
                      <div className={classe.cardDescription}>{prodId + "/3 - " + prodDesc}</div>
                    </CardBody>
                  </Card>
                </GridItem>
              ) : (
                ""
              )}
              {prodItem4.crop_img !== imgApi + "" + "/crop_img/null" ? (
                <GridItem xs={12} sm={6} md={6} lg={2}>
                  <Card blog plain>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={prodItem4.crop_img} alt="..." />
                      </a>
                      <div
                        className={classe.coloredShadow}
                        style={{
                          backgroundImage: `url(${prodItem4.crop_img})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    <CardBody plain>
                      <a href={prodItem4.orgImg} target="_blank">
                        Download Org
                      </a>
                      <br />
                      <a href={`https://api.whatsapp.com/send/?phone=+919967457786&text=${prodItem4.crop_img}`} target="_blank">
                        Download Crop
                      </a>
                      <div className={classe.cardDescription}>{prodId + "/4 - " + prodDesc}</div>
                    </CardBody>
                  </Card>
                </GridItem>
              ) : (
                ""
              )}
              {prodItem5.crop_img !== imgApi + "" + "/crop_img/null" ? (
                <GridItem xs={12} sm={6} md={6} lg={2}>
                  <Card blog plain>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={prodItem5.crop_img} alt="..." />
                      </a>
                      <div
                        className={classe.coloredShadow}
                        style={{
                          backgroundImage: `url(${prodItem5.crop_img})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    <CardBody plain>
                      <a href={prodItem5.orgImg} target="_blank">
                        Download Org
                      </a>
                      <br />
                      <a href={`https://api.whatsapp.com/send/?phone=+919967457786&text=${prodItem5.crop_img}`} target="_blank">
                        Download Crop
                      </a>
                      <div className={classe.cardDescription}>{prodId + "/5 - " + prodDesc}</div>
                    </CardBody>
                  </Card>
                </GridItem>
              ) : (
                ""
              )}
              {prodItem6.crop_img !== imgApi + "" + "/crop_img/null" ? (
                <GridItem xs={12} sm={6} md={6} lg={2}>
                  <Card blog plain>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={prodItem6.crop_img} alt="..." />
                      </a>
                      <div
                        className={classe.coloredShadow}
                        style={{
                          backgroundImage: `url(${prodItem6.crop_img})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    <CardBody plain>
                      <a href={prodItem6.orgImg} target="_blank">
                        Download Org
                      </a>
                      <br />
                      <a href={`https://api.whatsapp.com/send/?phone=+919967457786&text=${prodItem6.crop_img}`} target="_blank">
                        Download Crop
                      </a>
                      <div className={classe.cardDescription}>{prodId + "/6 - " + prodDesc}</div>
                    </CardBody>
                  </Card>
                </GridItem>
              ) : (
                ""
              )}
            </GridContainer>
          ) : (
            ""
          )}
          {/* {!simpleProductSelect ? (
            <GridContainer>
              {prodList.map((prod) => (
                <>
                  {imgApi + "" + prod.design1.image1 !==
                  imgApi + "" + "/crop_img/null" ? (
                    <GridItem xs={12} sm={6} md={6} lg={2} key={prod.id}>
                      <Card blog plain>
                        <CardHeader image plain>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              src={imgApi + "" + prod.design1.image1}
                              alt="..."
                            />
                          </a>
                          <div
                            className={classe.coloredShadow}
                            style={{
                              backgroundImage: `url(${
                                imgApi + "" + prod.design1.image1
                              })`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody plain>
                          {imgApi + "" + prod.design1.originalimg1 !==
                          imgApi + "" + "/original_img/null" ? (
                            <a href="#" onClick={(e) => download1(e, prod)}>
                              Download Org
                            </a>
                          ) : (
                            ""
                          )}
                          <br />
                          {imgApi + "" + prod.design1.image1 !==
                          imgApi + "" + "/crop_img/null" ? (
                            <a
                              href={imgApi + "" + prod.design1.image1}
                              target="_blank"
                            >
                              Download Crop
                            </a>
                          ) : (
                            ""
                          )}
                          <div className={classe.cardDescription}>
                            {prod.id + " - " + prod.description}
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {imgApi + "" + prod.design2.image2 !==
                  imgApi + "" + "/crop_img/null" ? (
                    <GridItem xs={12} sm={6} md={6} lg={2} key={prod.id}>
                      <Card blog plain>
                        <CardHeader image plain>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              src={imgApi + "" + prod.design2.image2}
                              alt="..."
                            />
                          </a>
                          <div
                            className={classe.coloredShadow}
                            style={{
                              backgroundImage: `url(${
                                imgApi + "" + prod.design2.image2
                              })`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody plain>
                          {imgApi + "" + prod.design2.originalimg2 !==
                          imgApi + "" + "/original_img/null" ? (
                            <a href="#" onClick={(e) => download2(e, prod)}>
                              Download Org
                            </a>
                          ) : (
                            ""
                          )}
                          <br />
                          {imgApi + "" + prod.design2.image2 !==
                          imgApi + "" + "/crop_img/null" ? (
                            <a
                              href={imgApi + "" + prod.design2.image2}
                              target="_blank"
                            >
                              Download Crop
                            </a>
                          ) : (
                            ""
                          )}
                          <div className={classe.cardDescription}>
                            {prod.id + " - " + prod.description}
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {imgApi + "" + prod.design3.image3 !==
                  imgApi + "" + "/crop_img/null" ? (
                    <GridItem xs={12} sm={6} md={6} lg={2} key={prod.id}>
                      <Card blog plain>
                        <CardHeader image plain>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              src={imgApi + "" + prod.design3.image3}
                              alt="..."
                            />
                          </a>
                          <div
                            className={classe.coloredShadow}
                            style={{
                              backgroundImage: `url(${
                                imgApi + "" + prod.design3.image3
                              })`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody plain>
                          {imgApi + "" + prod.design3.originalimg3 !==
                          imgApi + "" + "/original_img/null" ? (
                            <a href="#" onClick={(e) => download3(e, prod)}>
                              Download Org
                            </a>
                          ) : (
                            ""
                          )}
                          <br />
                          {imgApi + "" + prod.design3.image3 !==
                          imgApi + "" + "/crop_img/null" ? (
                            <a
                              href={imgApi + "" + prod.design3.image3}
                              target="_blank"
                            >
                              Download Crop
                            </a>
                          ) : (
                            ""
                          )}
                          <div className={classe.cardDescription}>
                            {prod.id + " - " + prod.description}
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {imgApi + "" + prod.design4.image4 !==
                  imgApi + "" + "/crop_img/null" ? (
                    <GridItem xs={12} sm={6} md={6} lg={2} key={prod.id}>
                      <Card blog plain>
                        <CardHeader image plain>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              src={imgApi + "" + prod.design4.image4}
                              alt="..."
                            />
                          </a>
                          <div
                            className={classe.coloredShadow}
                            style={{
                              backgroundImage: `url(${
                                imgApi + "" + prod.design4.image4
                              })`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody plain>
                          {imgApi + "" + prod.design4.originalimg4 !==
                          imgApi + "" + "/original_img/null" ? (
                            <a href="#" onClick={(e) => download4(e, prod)}>
                              Download Org
                            </a>
                          ) : (
                            ""
                          )}
                          <br />
                          {imgApi + "" + prod.design4.image4 !==
                          imgApi + "" + "/crop_img/null" ? (
                            <a
                              href={imgApi + "" + prod.design4.image4}
                              target="_blank"
                            >
                              Download Crop
                            </a>
                          ) : (
                            ""
                          )}
                          <div className={classe.cardDescription}>
                            {prod.id + " - " + prod.description}
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {imgApi + "" + prod.design5.image5 !==
                  imgApi + "" + "/crop_img/null" ? (
                    <GridItem xs={12} sm={6} md={6} lg={2} key={prod.id}>
                      <Card blog plain>
                        <CardHeader image plain>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              src={imgApi + "" + prod.design5.image5}
                              alt="..."
                            />
                          </a>
                          <div
                            className={classe.coloredShadow}
                            style={{
                              backgroundImage: `url(${
                                imgApi + "" + prod.design5.image5
                              })`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody plain>
                          {imgApi + "" + prod.design5.originalimg5 !==
                          imgApi + "" + "/original_img/null" ? (
                            <a href="#" onClick={(e) => download5(e, prod)}>
                              Download Org
                            </a>
                          ) : (
                            ""
                          )}
                          <br />
                          {imgApi + "" + prod.design5.image5 !==
                          imgApi + "" + "/crop_img/null" ? (
                            <a
                              href={imgApi + "" + prod.design5.image5}
                              target="_blank"
                            >
                              Download Crop
                            </a>
                          ) : (
                            ""
                          )}
                          <div className={classe.cardDescription}>
                            {prod.id + " - " + prod.description}
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {imgApi + "" + prod.design6.image6 !==
                  imgApi + "" + "/crop_img/null" ? (
                    <GridItem xs={12} sm={6} md={6} lg={2} key={prod.id}>
                      <Card blog plain>
                        <CardHeader image plain>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              src={imgApi + "" + prod.design6.image6}
                              alt="..."
                            />
                          </a>
                          <div
                            className={classe.coloredShadow}
                            style={{
                              backgroundImage: `url(${
                                imgApi + "" + prod.design6.image6
                              })`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                        <CardBody plain>
                          {imgApi + "" + prod.design6.originalimg6 !==
                          imgApi + "" + "/original_img/null" ? (
                            <a href="#" onClick={(e) => download6(e, prod)}>
                              Download Org
                            </a>
                          ) : (
                            ""
                          )}
                          <br />
                          {imgApi + "" + prod.design6.image6 !==
                          imgApi + "" + "/crop_img/null" ? (
                            <a
                              href={imgApi + "" + prod.design6.image6}
                              target="_blank"
                            >
                              Download Crop
                            </a>
                          ) : (
                            ""
                          )}
                          <div className={classe.cardDescription}>
                            {prod.id + " - " + prod.description}
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </GridContainer>
          ) : (
            ""
          )} */}
        </div>
        {/* </div> */}
      </div>
      <br />
      <br />
      <InshaFooter />
    </div>
  );
}
