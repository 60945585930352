// http://localhost:8085/api/parent/downloadList/byAdminId/2

const parentApi = [
  { id: 101, title: "Golwire small", adminId: 2, category_id: 11 },
  { id: 102, title: "Golwire Medium", adminId: 2, category_id: 11 },
  { id: 103, title: "1MM", adminId: 2, category_id: 11 },
  { id: 104, title: "1.5MM", adminId: 2, category_id: 11 },
  { id: 105, title: "2MM", adminId: 2, category_id: 11 },
  { id: 106, title: "2.5MM", adminId: 2, category_id: 11 },
  { id: 107, title: "3MM", adminId: 2, category_id: 11 },
  { id: 108, title: "3.5MM", adminId: 2, category_id: 11 },
  { id: 109, title: "4MM", adminId: 2, category_id: 11 },
  { id: 110, title: "4.5MM", adminId: 2, category_id: 11 },
  { id: 111, title: "5MM", adminId: 2, category_id: 11 },
  { id: 112, title: "5.2MM", adminId: 2, category_id: 11 },
  { id: 113, title: "5/3MM", adminId: 2, category_id: 11 },
  { id: 114, title: "5.5MM", adminId: 2, category_id: 11 },
  { id: 115, title: "6MM", adminId: 2, category_id: 11 },
  { id: 116, title: "6.2MM", adminId: 2, category_id: 11 },
  { id: 117, title: "6/3MM", adminId: 2, category_id: 11 },
  { id: 118, title: "6.5MM", adminId: 2, category_id: 11 },
  { id: 119, title: "7MM", adminId: 2, category_id: 11 },
  { id: 120, title: "7.2MM", adminId: 2, category_id: 11 },
  { id: 121, title: "7/3MM", adminId: 2, category_id: 11 },
  { id: 122, title: "7.8MM", adminId: 2, category_id: 11 },
  { id: 123, title: "8MM", adminId: 2, category_id: 11 },
  { id: 124, title: "8/3MM", adminId: 2, category_id: 11 },
  { id: 125, title: "8.5MM", adminId: 2, category_id: 11 },
  { id: 126, title: "9MM", adminId: 2, category_id: 11 },
  { id: 127, title: "9.2MM", adminId: 2, category_id: 11 },
  { id: 128, title: "9/3MM", adminId: 2, category_id: 11 },
  { id: 129, title: "10MM", adminId: 2, category_id: 11 },
  { id: 130, title: "10/3MM", adminId: 2, category_id: 11 },
  { id: 131, title: "11MM", adminId: 2, category_id: 11 },
  { id: 132, title: "11/3MM", adminId: 2, category_id: 11 },
  { id: 133, title: "11/4MM", adminId: 2, category_id: 11 },
  { id: 134, title: "11/5MM", adminId: 2, category_id: 11 },
  { id: 135, title: "12MM", adminId: 2, category_id: 11 },
  { id: 136, title: "12/3MM", adminId: 2, category_id: 11 },
  { id: 137, title: "13MM", adminId: 2, category_id: 11 },
  { id: 138, title: "13/3MM", adminId: 2, category_id: 11 },
  { id: 139, title: "14MM", adminId: 2, category_id: 11 },
  { id: 140, title: "14/3MM", adminId: 2, category_id: 11 },
  { id: 141, title: "15MM", adminId: 2, category_id: 11 },
  { id: 142, title: "15/3MM", adminId: 2, category_id: 11 },
  { id: 143, title: "16MM", adminId: 2, category_id: 11 },
  { id: 144, title: "16/3MM", adminId: 2, category_id: 11 },
  { id: 145, title: "17MM", adminId: 2, category_id: 11 },
  { id: 146, title: "17/3MM", adminId: 2, category_id: 11 },
  { id: 147, title: "18MM", adminId: 2, category_id: 11 },
  { id: 148, title: "18/3MM", adminId: 2, category_id: 11 },
  { id: 149, title: "19MM", adminId: 2, category_id: 11 },
  { id: 150, title: "19/3MM", adminId: 2, category_id: 11 },
  { id: 151, title: "20MM", adminId: 2, category_id: 11 },
  { id: 152, title: "20/3MM", adminId: 2, category_id: 11 },
  { id: 153, title: "21MM", adminId: 2, category_id: 11 },
  { id: 154, title: "21/3MM", adminId: 2, category_id: 11 },
  { id: 155, title: "22MM", adminId: 2, category_id: 11 },
  { id: 156, title: "22/3MM", adminId: 2, category_id: 11 },
  { id: 157, title: "23MM", adminId: 2, category_id: 11 },
  { id: 158, title: "23/3MM", adminId: 2, category_id: 11 },
  { id: 159, title: "24MM", adminId: 2, category_id: 11 },
  { id: 160, title: "24/3MM", adminId: 2, category_id: 11 },
  { id: 161, title: "25MM", adminId: 2, category_id: 11 },
  { id: 162, title: "25/3MM", adminId: 2, category_id: 11 },
  { id: 163, title: "Golwire Small", adminId: 2, category_id: 12 },
  { id: 164, title: "Golwire Medium", adminId: 2, category_id: 12 },
  { id: 165, title: "1MM", adminId: 2, category_id: 12 },
  { id: 166, title: "2MM", adminId: 2, category_id: 12 },
  { id: 167, title: "3MM", adminId: 2, category_id: 12 },
  { id: 168, title: "4MM", adminId: 2, category_id: 12 },
  { id: 169, title: "5MM", adminId: 2, category_id: 12 },
  { id: 170, title: "6MM", adminId: 2, category_id: 12 },
  { id: 171, title: "7MM", adminId: 2, category_id: 12 },
  { id: 172, title: "8MM", adminId: 2, category_id: 12 },
  { id: 173, title: "9MM", adminId: 2, category_id: 12 },
  { id: 174, title: "10MM", adminId: 2, category_id: 12 },
  { id: 175, title: "11MM", adminId: 2, category_id: 12 },
  { id: 176, title: "12MM", adminId: 2, category_id: 12 },
  { id: 177, title: "13MM", adminId: 2, category_id: 12 },
  { id: 178, title: "14MM", adminId: 2, category_id: 12 },
  { id: 179, title: "15MM", adminId: 2, category_id: 12 },
  { id: 180, title: "16MM", adminId: 2, category_id: 12 },
  { id: 181, title: "17MM", adminId: 2, category_id: 12 },
  { id: 182, title: "18MM", adminId: 2, category_id: 12 },
  { id: 183, title: "19MM", adminId: 2, category_id: 12 },
  { id: 184, title: "20MM", adminId: 2, category_id: 12 },
  { id: 185, title: "21MM", adminId: 2, category_id: 12 },
  { id: 186, title: "22MM", adminId: 2, category_id: 12 },
  { id: 187, title: "23MM", adminId: 2, category_id: 12 },
  { id: 188, title: "24MM", adminId: 2, category_id: 12 },
  { id: 189, title: "25MM", adminId: 2, category_id: 12 },
  { id: 190, title: "Golwire Small", adminId: 2, category_id: 13 },
  { id: 191, title: "Golwire Medium", adminId: 2, category_id: 13 },
  { id: 192, title: "1MM", adminId: 2, category_id: 13 },
  { id: 193, title: "2MM", adminId: 2, category_id: 13 },
  { id: 194, title: "3MM", adminId: 2, category_id: 13 },
  { id: 195, title: "4MM", adminId: 2, category_id: 13 },
  { id: 196, title: "5MM", adminId: 2, category_id: 13 },
  { id: 197, title: "6MM", adminId: 2, category_id: 13 },
  { id: 198, title: "7MM", adminId: 2, category_id: 13 },
  { id: 199, title: "8MM", adminId: 2, category_id: 13 },
  { id: 200, title: "9MM", adminId: 2, category_id: 13 },
  { id: 201, title: "10MM", adminId: 2, category_id: 13 },
  { id: 202, title: "11MM", adminId: 2, category_id: 13 },
  { id: 203, title: "12MM", adminId: 2, category_id: 13 },
  { id: 204, title: "13MM", adminId: 2, category_id: 13 },
  { id: 205, title: "14MM", adminId: 2, category_id: 13 },
  { id: 206, title: "15MM", adminId: 2, category_id: 13 },
  { id: 207, title: "16MM", adminId: 2, category_id: 13 },
  { id: 208, title: "17MM", adminId: 2, category_id: 13 },
  { id: 209, title: "18MM", adminId: 2, category_id: 13 },
  { id: 210, title: "19MM", adminId: 2, category_id: 13 },
  { id: 211, title: "20MM", adminId: 2, category_id: 13 },
  { id: 212, title: "21MM", adminId: 2, category_id: 13 },
  { id: 213, title: "22MM", adminId: 2, category_id: 13 },
  { id: 214, title: "23MM", adminId: 2, category_id: 13 },
  { id: 215, title: "24MM", adminId: 2, category_id: 13 },
  { id: 216, title: "25MM", adminId: 2, category_id: 13 },
  { id: 217, title: "4MM", adminId: 2, category_id: 14 },
  { id: 218, title: "5MM", adminId: 2, category_id: 14 },
  { id: 219, title: "6MM", adminId: 2, category_id: 14 },
  { id: 220, title: "7MM", adminId: 2, category_id: 14 },
  { id: 221, title: "8MM", adminId: 2, category_id: 14 },
  { id: 222, title: "9MM", adminId: 2, category_id: 14 },
  { id: 223, title: "10MM", adminId: 2, category_id: 14 },
  { id: 224, title: "11MM", adminId: 2, category_id: 14 },
  { id: 225, title: "12MM", adminId: 2, category_id: 14 },
  { id: 226, title: "13MM", adminId: 2, category_id: 14 },
  { id: 227, title: "14MM", adminId: 2, category_id: 14 },
  { id: 228, title: "15MM", adminId: 2, category_id: 14 },
  { id: 229, title: "16MM", adminId: 2, category_id: 14 },
  { id: 230, title: "17MM", adminId: 2, category_id: 14 },
  { id: 231, title: "18MM", adminId: 2, category_id: 14 },
  { id: 232, title: "19MM", adminId: 2, category_id: 14 },
  { id: 233, title: "20MM", adminId: 2, category_id: 14 },
  { id: 234, title: "21MM", adminId: 2, category_id: 14 },
  { id: 235, title: "22MM", adminId: 2, category_id: 14 },
  { id: 236, title: "23MM", adminId: 2, category_id: 14 },
  { id: 237, title: "24MM", adminId: 2, category_id: 14 },
  { id: 238, title: "25MM", adminId: 2, category_id: 14 },
  { id: 239, title: "3MM", adminId: 2, category_id: 15 },
  { id: 240, title: "4MM", adminId: 2, category_id: 15 },
  { id: 241, title: "5MM", adminId: 2, category_id: 15 },
  { id: 242, title: "6MM", adminId: 2, category_id: 15 },
  { id: 243, title: "7MM", adminId: 2, category_id: 15 },
  { id: 244, title: "8MM", adminId: 2, category_id: 15 },
  { id: 245, title: "9MM", adminId: 2, category_id: 15 },
  { id: 246, title: "10MM", adminId: 2, category_id: 15 },
  { id: 247, title: "11MM", adminId: 2, category_id: 15 },
  { id: 248, title: "12MM", adminId: 2, category_id: 15 },
  { id: 249, title: "13MM", adminId: 2, category_id: 15 },
  { id: 250, title: "14MM", adminId: 2, category_id: 15 },
  { id: 251, title: "15MM", adminId: 2, category_id: 15 },
  { id: 252, title: "16MM", adminId: 2, category_id: 15 },
  { id: 253, title: "17MM", adminId: 2, category_id: 15 },
  { id: 254, title: "18MM", adminId: 2, category_id: 15 },
  { id: 255, title: "19MM", adminId: 2, category_id: 15 },
  { id: 256, title: "20MM", adminId: 2, category_id: 15 },
  { id: 257, title: "21MM", adminId: 2, category_id: 15 },
  { id: 258, title: "22MM", adminId: 2, category_id: 15 },
  { id: 259, title: "23MM", adminId: 2, category_id: 15 },
  { id: 260, title: "24MM", adminId: 2, category_id: 15 },
  { id: 261, title: "25MM", adminId: 2, category_id: 15 },
  { id: 262, title: "3MM", adminId: 2, category_id: 16 },
  { id: 263, title: "4MM", adminId: 2, category_id: 16 },
  { id: 264, title: "5MM", adminId: 2, category_id: 16 },
  { id: 265, title: "6MM", adminId: 2, category_id: 16 },
  { id: 266, title: "7MM", adminId: 2, category_id: 16 },
  { id: 267, title: "8MM", adminId: 2, category_id: 16 },
  { id: 268, title: "9MM", adminId: 2, category_id: 16 },
  { id: 269, title: "10MM", adminId: 2, category_id: 16 },
  { id: 270, title: "11MM", adminId: 2, category_id: 16 },
  { id: 271, title: "12MM", adminId: 2, category_id: 16 },
  { id: 272, title: "13MM", adminId: 2, category_id: 16 },
  { id: 273, title: "14MM", adminId: 2, category_id: 16 },
  { id: 274, title: "15MM", adminId: 2, category_id: 16 },
  { id: 275, title: "16MM", adminId: 2, category_id: 16 },
  { id: 276, title: "17MM", adminId: 2, category_id: 16 },
  { id: 277, title: "18MM", adminId: 2, category_id: 16 },
  { id: 278, title: "19MM", adminId: 2, category_id: 16 },
  { id: 279, title: "20MM", adminId: 2, category_id: 16 },
  { id: 280, title: "21MM", adminId: 2, category_id: 16 },
  { id: 281, title: "22MM", adminId: 2, category_id: 16 },
  { id: 282, title: "23MM", adminId: 2, category_id: 16 },
  { id: 283, title: "24MM", adminId: 2, category_id: 16 },
  { id: 284, title: "25MM", adminId: 2, category_id: 16 },
];

export default parentApi;
