import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function ReturnDescription() {
    const classes = useStyles();
    return (
        <div className={classNames(classes.aboutDescription)}>
            <GridContainer>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>Return Policy</h3>
                    <h4 style={{textTransform: "uppercase"}}>HASSLE-FREE RETURNS AT INSHA</h4>
                    <h5 className={classes.description}>
                    Returns at Insha are easy. We understand problems occur and don{"'"}t hesitate in accepting you return for liable reasons. If you would like to initiate a return you can do so by clicking <a href="#">here</a>.
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>WHAT ITEMS ARE RETURNABLE?</h3>
                    <ul>
                        <li className={classes.description}>All items within 7 days of shipment received</li>
                        <li className={classes.description}>In original condition</li>
                        <li className={classes.description}>Unworn and unused</li>
                        <li className={classes.description}>In the original packaging</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>HOW DO I RETURN/REPLACE ITEM?</h3>
                    <ul>
                        <li className={classes.description}>In order to return an order, go to “Account” section on Insha website, select “Order History”</li>
                        <li className={classes.description}>Login using registered mobile (used to place the order) and OTP received</li>
                        <li className={classes.description}>Select the order you wish to return/replace</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>ARE THERE ANY CHARGES FOR RETURN?</h3>
                    <ul>
                        <li className={classes.description}>There is no charges for return and will be arranged by us</li>
                        <li className={classes.description}>Original shipping charges is non-refundable</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>CAN I EXCHANGE MY PRODUCT?</h3>
                    <ul>
                        <li className={classes.description}>Yes you can request exchange within 25 working days of product delivery. Exchange are done in relation to size and product design. Please note that exchange value cannot be greater than the initial invoice value.</li>
                        <li className={classes.description}>To exchange kindly enter your order number and email address by clicking here.</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>HOW SOON WILL I GET MY REFUND?</h3>
                    <ul>
                        <li className={classes.description}>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.</li>
                        <li className={classes.description}>If you are approved, then your refund will be processed, and a credit will automatically be applied method of payment, within 7 business days.</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>WHAT ARE THE GUIDELINES TO ACCEPT SHIPMENTS?</h3>
                    <ul>
                        <li className={classes.description}>Before accepting shipment of any product, please ensure that the product{"'"}s packaging is not damaged or tampered.</li>
                        <li className={classes.description}>In case the package is damaged or tampered, you are requested to refuse to accept delivery. We will assure you refund upon such refused delivery, provided the order is prepaid.</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>CANCELLATION</h3>
                    <ul>
                        <li className={classes.description}>We reserve the right to cancel your order in case of any discrepancy. but within 7 days.</li>
                        <li className={classes.description}>If in case the amount is deducted from customers account and the transaction has failed, the same will be refunded back to your account.</li>
                        <li className={classes.description}>KINDLY NOTE:  if you are liable for a refund we request you to enter your account details in the {"'"}notes{"'"} section while submitting your return request. Refunds can only be done via Bank transfer and no cash refunds will be done.</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>ANY OTHER HELP?</h3>
                    <h5 className={classes.description}>
                        If you need help with something that is not mentioned above you can contact us in the following way:<br />
                        Phone: 9967457786/02240128886<br />
                        Email: info@inshabangles.com<br />
                    </h5>
                </GridItem>
            </GridContainer>
            <br />
        </div>
    );
}