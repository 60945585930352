import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import { Helmet } from "react-helmet";
import { SiWhatsapp } from "react-icons/si";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

const useStyles = makeStyles(contactsStyle);

export default function InshaContact() {
  const classes = useStyles();
  return (
    <div>
        <Helmet>
        <title>Insha Bangles</title>
        <meta name="description" content="Best Manufacture Comapnay" />
        <meta
          name="keywords"
          content="We are one of the top manufacturers and retailers in Maharastra & Karnataka"
        />
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" textAlign="center" />}
        fixed
        color="dark"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "dark",
        // }}
      />
      <div
        className={classes.contacts + " " + classes.section}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={5}>
              <h2 className={classes.title}>Contact us</h2>
              <h5 className={classes.description}>
                Feel free to ask us, If you have any Queries related to our
                Product.
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="Office Address"
                description={
                  <p>
                    Shop no.20, Asmita Jyoti CHS D/10/11 bldg, <br /> Charkop
                    Naka, Marve Road, <br /> Malad West Mumbai-400095 -
                    Maharashtra
                  </p>
                }
                icon={PinDrop}
              />
              <InfoArea
                className={classes.infoArea}
                title="Call us at"
                description={
                  <p>
                    +912249786664 <br />{" "}
                    <span>
                      <SiWhatsapp color="green" /> +919967457786
                    </span>{" "}
                    <br /> Monday – Saturday, <br />
                    09:00 AM to 09:00 PM <br /> Sunday - 09:00 AM to 03:00 PM
                  </p>
                }
                icon={Phone}
              />
              <a
                href={`https://api.whatsapp.com/send?phone=919967457786&text=https://inshabangles.com/%20Hello%20I%20am%20Interested%20in%20your%20Product&app_absent=0`}
              >
                <InfoArea
                  className={classes.infoArea}
                  title="Click on the Whatsapp icon to get connected with us"
                  description=""
                  icon={SiWhatsapp}
                  iconColor="success"
                />
              </a>
            </GridItem>
            <GridItem xs={12} sm={6} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader
                    contact
                    color="rose"
                    className={classes.textCenter}
                  >
                    <h4 className={classes.cardTitle}>Contact Us</h4>
                  </CardHeader>
                  <CardBody>
                        <CustomInput
                          labelText="First Name"
                          id="first"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        <CustomInput
                          labelText="Phone"
                          id="phone"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                    <CustomInput
                      labelText="Email Address"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <Button color="rose" className={classes.pullRight}>
                      Send Message
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}