// http://localhost:8085/api/product/downloadList/byAdminId/2
const productApi = [
  {
    id: 9,
    p_adminid: 2,
    productCategory: { id: 9, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 9,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7316,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7317,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7318,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7319,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7320,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7321,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
    ],
    productSets: { id: 9, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 9,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 9,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 9,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3668,
        imgId: 1,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_201903.jpg",
        cropName: "20220530_201903.jpg",
      },
      {
        id: 3669,
        imgId: 2,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_205025.jpg",
        cropName: "20220530_205025.jpg",
      },
      {
        id: 3670,
        imgId: 1439,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_202052.jpg",
        cropName: "20220530_202052.jpg",
      },
      {
        id: 3671,
        imgId: 1440,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_202633.jpg",
        cropName: "20220530_202633.jpg",
      },
      {
        id: 3672,
        imgId: 1441,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_202807.jpg",
        cropName: "20220530_202807.jpg",
      },
    ],
    modifyDate: "2023-09-12T11:01:29.003+00:00",
    createDate: "2023-08-10T00:58:14.000+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "140T-175-105M-065",
    prodManfDetails: "Full Lasiya",
    prodDescription:
      "We are a manufacturer and exporter of all types of 3-tone bangles. \nOur designer brass bangles can be worn with any kind of attire.",
    cartDescription: "40 Polythene bag of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1134.23,
    cashPrice: 1306.9786,
    taxAll: 118.6514,
    taxInclude: 1366.3043,
    active: true,
    gstCash: true,
    moqkg: 200.0,
    moq: 272.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 14,
        adminId: 2,
        productId: 9,
        stockCategoryId: 11,
        stockParentId: 111,
        cutOrFullSize: "1",
        createDate: "2023-10-21T08:23:44.561+00:00",
        s1x8: "1",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "0",
        s2x3: "0",
        s2x4: "0",
        s2x5: "0",
        s2x6: "0",
        s2x7: "0",
        s2x8: "0",
        s2x9: "0",
        s2x10: "0",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 1.0,
      },
    ],
  },
  {
    id: 10,
    p_adminid: 2,
    productCategory: { id: 10, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 10,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 9166,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9167,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 9168,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 9169,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 9170,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 9171,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 9172,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 9173,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
    ],
    productSets: { id: 10, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 10,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 10,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 10,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4287,
        imgId: 4,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_150801.jpg",
        cropName: "20160904_150801.jpg",
      },
      {
        id: 4288,
        imgId: 3,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_151120(0).jpg",
        cropName: "20160904_151120(0).jpg",
      },
      {
        id: 4289,
        imgId: 885,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_151226.jpg",
        cropName: "20160904_151226.jpg",
      },
      {
        id: 4290,
        imgId: 886,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_151325.jpg",
        cropName: "20160904_151325.jpg",
      },
      {
        id: 4291,
        imgId: 887,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_151412.jpg",
        cropName: "20160904_151412.jpg",
      },
      {
        id: 4292,
        imgId: 888,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_151501.jpg",
        cropName: "20160904_151501.jpg",
      },
      {
        id: 4293,
        imgId: 5,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194803.jpg",
        cropName: "20170306_194803.jpg",
      },
      {
        id: 4294,
        imgId: 6,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_193434.jpg",
        cropName: "20170306_193434.jpg",
      },
    ],
    modifyDate: "2023-11-29T12:11:34.061+00:00",
    createDate: "2023-08-10T01:00:45.000+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: 7120,
    itemCodeNumber: "20G-5.8M-095M-065G",
    prodManfDetails: "Micro Dell",
    prodDescription:
      " It is made from brass metal and we provide 3-tone gold plating bangles.",
    cartDescription: "40 Polythene bag of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1292.15,
    cashPrice: 1485.8035,
    taxAll: 128.877,
    taxInclude: 1550.242,
    active: true,
    gstCash: true,
    moqkg: 300.0,
    moq: 451.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 11,
    p_adminid: 2,
    productCategory: { id: 11, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 11,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 9174,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 9175,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9176,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 9177,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 9178,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 9179,
        lbrId: 1196,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Hand Cutting Single",
      },
      {
        id: 9180,
        lbrId: 1181,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Kanas Diamond",
      },
    ],
    productSets: { id: 11, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 11,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 11,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 11,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4295,
        imgId: 5,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194803.jpg",
        cropName: "20170306_194803.jpg",
      },
      {
        id: 4296,
        imgId: 6,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_193434.jpg",
        cropName: "20170306_193434.jpg",
      },
      {
        id: 4297,
        imgId: 927,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_195830.jpg",
        cropName: "20170306_195830.jpg",
      },
      {
        id: 4298,
        imgId: 929,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_200017.jpg",
        cropName: "20170306_200017.jpg",
      },
      {
        id: 4299,
        imgId: 932,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_200145.jpg",
        cropName: "20170306_200145.jpg",
      },
      {
        id: 4300,
        imgId: 933,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_200235.jpg",
        cropName: "20170306_200235.jpg",
      },
    ],
    modifyDate: "2023-11-29T12:12:15.597+00:00",
    createDate: "2023-08-10T01:06:20.000+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: 1722,
    itemCodeNumber: "00T-105M-065G",
    prodManfDetails: "Kanas Diamond & Hand Cutting",
    prodDescription:
      "Our attractive collection of bangles includes 3 Tone Bangles.\n",
    cartDescription: "40 Polythene bag of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1258.025,
    cashPrice: 1446.1047,
    taxAll: 124.5545,
    taxInclude: 1508.382,
    active: true,
    gstCash: true,
    moqkg: 300.0,
    moq: 408.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 14,
    p_adminid: 2,
    productCategory: { id: 14, adminId: 2, categoryId: 14, title: "WATCH" },
    productParent: {
      id: 14,
      adminId: 2,
      parentId: 223,
      categoryId: 14,
      title: "10MM",
    },
    productChild: [
      {
        id: 9070,
        lbrId: 6027,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 9071,
        lbrId: 6031,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 9072,
        lbrId: 6033,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 9073,
        lbrId: 6034,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Buffing",
      },
      {
        id: 9074,
        lbrId: 6035,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 9075,
        lbrId: 6050,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 9076,
        lbrId: 6051,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 9077,
        lbrId: 6052,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 9078,
        lbrId: 6044,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 9079,
        lbrId: 6056,
        price: 20.0,
        taxPercentage: 5.0,
        tax: 1.0,
        title: "Earring Die Cutting (Lock)",
      },
      {
        id: 9080,
        lbrId: 6057,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Earring Solder",
      },
      {
        id: 9081,
        lbrId: 6068,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "MFG. Transport\r\n",
      },
      {
        id: 9082,
        lbrId: 6067,
        price: 185.0,
        taxPercentage: 18.0,
        tax: 33.3,
        title: "Al 35E Complete Moment ",
      },
      {
        id: 9083,
        lbrId: 6063,
        price: 15.0,
        taxPercentage: 5.0,
        tax: 0.75,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 9084,
        lbrId: 6061,
        price: 5.0,
        taxPercentage: 5.0,
        tax: 0.25,
        title: "Plating Bright Silver",
      },
    ],
    productSets: { id: 14, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 14,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 14,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 14,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4234,
        imgId: 7,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160201_123202.jpg",
        cropName: "20160201_123202.jpg",
      },
      {
        id: 4235,
        imgId: 1242,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_224914.jpg",
        cropName: "20160203_224914.jpg",
      },
    ],
    modifyDate: "2023-11-15T08:30:24.215+00:00",
    createDate: "2023-08-10T01:55:16.000+00:00",
    metalGram: 0.015,
    extraGram: 5.0e-4,
    readyGram: 0.014,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20G-150M-140G",
    prodManfDetails: "",
    prodDescription:
      "The watch has a round dial with a minimalist design and a gorgeous 3-tone, silver, rose, and gold.",
    cartDescription: "72 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1537.75,
    cashPrice: 1808.4785,
    taxAll: 233.90701,
    taxInclude: 1925.432,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 1111.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 15,
    p_adminid: 2,
    productCategory: { id: 15, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 15,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 9181,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 9182,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9183,
        lbrId: 531,
        price: 4.0,
        taxPercentage: 5.0,
        tax: 0.2,
        title: "Plating 3tone\r\n",
      },
      {
        id: 9184,
        lbrId: 505,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 9185,
        lbrId: 513,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9186,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 15, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 15,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 15,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 15,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4301,
        imgId: 8,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_120657.jpg",
        cropName: "20161024_120657.jpg",
      },
      {
        id: 4302,
        imgId: 9,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_122136.jpg",
        cropName: "20161024_122136.jpg",
      },
      {
        id: 4303,
        imgId: 16,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_120657.jpg",
        cropName: "20161024_120657.jpg",
      },
      {
        id: 4304,
        imgId: 17,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_122136.jpg",
        cropName: "20161024_122136.jpg",
      },
    ],
    modifyDate: "2023-11-29T12:15:53.556+00:00",
    createDate: "2023-08-14T10:38:24.966+00:00",
    metalGram: 0.0065,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "80T-GS-065M-05G",
    prodManfDetails: "S",
    prodDescription:
      "Provided in a wide spectrum of intricate designs, alluring\npatterns, varied sizes, and eye-catching color combinations.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 298.526,
    cashPrice: 345.15195,
    taxAll: 33.54668,
    taxInclude: 361.9253,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 549.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 16,
    p_adminid: 2,
    productCategory: { id: 16, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 16,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 7359,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 7360,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7361,
        lbrId: 524,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 7362,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7363,
        lbrId: 513,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 16, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 16,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 16,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 16,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3682,
        imgId: 10,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_122841.jpg",
        cropName: "20160204_122841.jpg",
      },
    ],
    modifyDate: "2023-09-12T11:40:58.668+00:00",
    createDate: "2023-08-14T10:43:53.494+00:00",
    metalGram: 0.0045,
    extraGram: 5.0e-4,
    readyGram: 0.003,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "80T-GS-045M-035G",
    prodManfDetails: "s",
    prodDescription:
      "Kings of the bangle world, Finally a place you have looking for.",
    cartDescription: "60 boxes of in 1 Carton",
    cartQuantity: 5,
    cartPackCharge: 500.0,
    makingCharge: 327.482,
    cashPrice: 383.31357,
    taxAll: 46.16676,
    taxInclude: 406.39694,
    active: true,
    gstCash: true,
    moqkg: 97.2,
    moq: 300.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 17,
    p_adminid: 2,
    productCategory: { id: 17, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 17,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 7079,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 7080,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7081,
        lbrId: 524,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 7082,
        lbrId: 506,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 7083,
        lbrId: 512,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7084,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 17, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 17,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 17,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 17,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3550,
        imgId: 11,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_124800.jpg",
        cropName: "20160204_124800.jpg",
      },
    ],
    modifyDate: "2023-09-12T06:28:35.977+00:00",
    createDate: "2023-08-14T10:46:15.527+00:00",
    metalGram: 0.0045,
    extraGram: 5.0e-4,
    readyGram: 0.0035,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "80T-GS-045M-035G",
    prodManfDetails: "s",
    prodDescription:
      "Pure Brass Bangle inspired by traditional Indian designs!",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 323.882,
    cashPrice: 379.26358,
    taxAll: 45.98676,
    taxInclude: 402.25696,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 18,
    p_adminid: 2,
    productCategory: { id: 18, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 18,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 3791,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 3792,
        lbrId: 505,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3793,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3794,
        lbrId: 524,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3795,
        lbrId: 512,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 3796,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 18, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 18,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 18,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 18,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1941,
        imgId: 13,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_203538.jpg",
        cropName: "20160229_203538.jpg",
      },
      {
        id: 1942,
        imgId: 18,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_094759.jpg",
        cropName: "20161025_094759.jpg",
      },
      {
        id: 1943,
        imgId: 19,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_094802.jpg",
        cropName: "20161025_094802.jpg",
      },
      {
        id: 1944,
        imgId: 20,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_095354.jpg",
        cropName: "20161025_095354.jpg",
      },
      {
        id: 1945,
        imgId: 21,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_095543.jpg",
        cropName: "20161025_095543.jpg",
      },
      {
        id: 1946,
        imgId: 22,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_095735.jpg",
        cropName: "20161025_095735.jpg",
      },
      {
        id: 1947,
        imgId: 23,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_095917.jpg",
        cropName: "20161025_095917.jpg",
      },
      {
        id: 1948,
        imgId: 12,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160215_161320.jpg",
        cropName: "20160215_161320.jpg",
      },
    ],
    modifyDate: "2023-08-29T06:35:41.080+00:00",
    createDate: "2023-08-14T10:53:48.236+00:00",
    metalGram: 0.004,
    extraGram: 5.0e-4,
    readyGram: 0.003,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "80T-045M-035G",
    prodManfDetails: "S",
    prodDescription:
      "Pure Brass Bangle inspired by traditional Indian designs!",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 301.634,
    cashPrice: 352.78845,
    taxAll: 41.98212,
    taxInclude: 373.7795,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 347.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 19,
    p_adminid: 2,
    productCategory: { id: 19, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 19,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 3797,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 3798,
        lbrId: 505,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3799,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3800,
        lbrId: 531,
        price: 4.0,
        taxPercentage: 5.0,
        tax: 0.2,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3801,
        lbrId: 513,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3802,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 19, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 19,
      adminId: 2,
      boxId: 74,
      brandName: "H5 - My Bangles 24",
      boxDesc: "8 Sets In 1 Box",
      pkgCharge: 8.0,
      box: "8",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 19,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 19,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1949,
        imgId: 24,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_180640.jpg",
        cropName: "20170304_180640.jpg",
      },
      {
        id: 1950,
        imgId: 25,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181631.jpg",
        cropName: "20170304_181631.jpg",
      },
      {
        id: 1951,
        imgId: 26,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182926.jpg",
        cropName: "20170304_182926.jpg",
      },
      {
        id: 1952,
        imgId: 27,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_190402.jpg",
        cropName: "20170304_190402.jpg",
      },
    ],
    modifyDate: "2023-08-29T06:41:19.277+00:00",
    createDate: "2023-08-14T10:55:17.956+00:00",
    metalGram: 0.006,
    extraGram: 5.0e-4,
    readyGram: 0.0045,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "80T-GS-06M-045G",
    prodManfDetails: "S",
    prodDescription:
      "3-Tone Bangles We are offering a wide range of 3-tone plated bangles. These bangles are made by our professionals using quality raw material Products.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 547.298,
    cashPrice: 631.6366,
    taxAll: 59.217636,
    taxInclude: 661.2454,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 298.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 21,
    p_adminid: 2,
    productCategory: { id: 21, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 21,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 3803,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 3804,
        lbrId: 502,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 3805,
        lbrId: 505,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3806,
        lbrId: 524,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3807,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3808,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 3809,
        lbrId: 514,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
    ],
    productSets: { id: 21, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 21,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 21,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 21,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1953,
        imgId: 40,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220415-WA0003.jpg",
        cropName: "20220415-WA0003.jpg",
      },
    ],
    modifyDate: "2023-08-29T06:49:46.446+00:00",
    createDate: "2023-08-14T11:10:45.487+00:00",
    metalGram: 0.0045,
    extraGram: 5.0e-4,
    readyGram: 0.003,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "80T-GS-045M-030G",
    prodManfDetails: "S",
    prodDescription:
      "This gold-plated One-layer zigzag design Bangle has high-end fashion design which has elegance and style.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 365.482,
    cashPrice: 426.19357,
    taxAll: 48.32676,
    taxInclude: 450.35693,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 22,
    p_adminid: 2,
    productCategory: { id: 22, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 22,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 3810,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 3811,
        lbrId: 505,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3812,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3813,
        lbrId: 524,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3814,
        lbrId: 513,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3815,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 22, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 22,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 22,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 22,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1954,
        imgId: 29,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0042.jpg",
        cropName: "20180213-WA0042.jpg",
      },
      {
        id: 1955,
        imgId: 30,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0044.jpg",
        cropName: "20180213-WA0044.jpg",
      },
      {
        id: 1956,
        imgId: 33,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0049.jpg",
        cropName: "20180213-WA0049.jpg",
      },
      {
        id: 1957,
        imgId: 37,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0056.jpg",
        cropName: "20180213-WA0056.jpg",
      },
      {
        id: 1958,
        imgId: 36,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0052.jpg",
        cropName: "20180213-WA0052.jpg",
      },
    ],
    modifyDate: "2023-08-29T07:45:35.104+00:00",
    createDate: "2023-08-14T11:27:54.901+00:00",
    metalGram: 0.0055,
    extraGram: 5.0e-4,
    readyGram: 0.004,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "80T-GS-055M-040G",
    prodManfDetails: "S",
    prodDescription: "Party Wear Gold Plated Wire Brass Bangle.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 265.20197,
    cashPrice: 310.83835,
    taxAll: 38.23236,
    taxInclude: 329.95453,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 379.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 23,
    p_adminid: 2,
    productCategory: { id: 23, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 23,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 3828,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 3829,
        lbrId: 505,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3830,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3831,
        lbrId: 524,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3832,
        lbrId: 513,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3833,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 23, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 23,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 23,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 23,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1964,
        imgId: 31,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0045.jpg",
        cropName: "20180213-WA0045.jpg",
      },
      {
        id: 1965,
        imgId: 32,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0048.jpg",
        cropName: "20180213-WA0048.jpg",
      },
      {
        id: 1966,
        imgId: 34,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0050.jpg",
        cropName: "20180213-WA0050.jpg",
      },
      {
        id: 1967,
        imgId: 35,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0051.jpg",
        cropName: "20180213-WA0051.jpg",
      },
    ],
    modifyDate: "2023-08-29T07:53:19.640+00:00",
    createDate: "2023-08-14T11:30:38.718+00:00",
    metalGram: 0.0055,
    extraGram: 5.0e-4,
    readyGram: 0.004,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "80T-GS-055M-040G",
    prodManfDetails: "S",
    prodDescription:
      "Traditional Gold Plated Bracelet Bangles Set for Girls and Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.00198,
    cashPrice: 309.41037,
    taxAll: 38.01636,
    taxInclude: 328.41855,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 379.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 24,
    p_adminid: 2,
    productCategory: { id: 24, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 24,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 3822,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 3823,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3824,
        lbrId: 524,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3825,
        lbrId: 515,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 3826,
        lbrId: 504,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3827,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 24, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 24,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 24,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 24,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1962,
        imgId: 14,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160311_133346.jpg",
        cropName: "20160311_133346.jpg",
      },
      {
        id: 1963,
        imgId: 15,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160311_133456.jpg",
        cropName: "20160311_133456.jpg",
      },
    ],
    modifyDate: "2023-08-29T07:51:12.148+00:00",
    createDate: "2023-08-14T11:33:24.813+00:00",
    metalGram: 0.007,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "00T-GS-070M-055G",
    prodManfDetails: "S",
    prodDescription:
      "Latest trendy fashion with lightweight gold plated designer bangle collections for girls' office and college use. This is used by women of all ages.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 474.122,
    cashPrice: 555.70917,
    taxAll: 68.34996,
    taxInclude: 589.88416,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 198.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 25,
    p_adminid: 2,
    productCategory: { id: 25, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 25,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 8576,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 8577,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 8578,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8579,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 8580,
        lbrId: 549,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Kanas Diamond",
      },
      {
        id: 8581,
        lbrId: 556,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8582,
        lbrId: 568,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Gold Plated & Corum",
      },
      {
        id: 8583,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 25, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 25,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 25,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 25,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4073,
        imgId: 41,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/925450_571281839719336_1926478214.jpg",
        cropName: "925450_571281839719336_1926478214.jpg",
      },
    ],
    modifyDate: "2023-09-15T12:36:03.645+00:00",
    createDate: "2023-08-14T11:36:35.362+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "00T-GM-080M-055G",
    prodManfDetails: "M",
    prodDescription:
      "Elegant and Classic Design 2-Tone Bangles for Girls and Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 439.198,
    cashPrice: 509.59412,
    taxAll: 52.95264,
    taxInclude: 536.07043,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 298.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 26,
    p_adminid: 2,
    productCategory: { id: 26, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 26,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 8978,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 8979,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8980,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 8981,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 8982,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8983,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 26, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 26,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 26,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 26,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4219,
        imgId: 42,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232145.jpg",
        cropName: "20160203_232145.jpg",
      },
      {
        id: 4220,
        imgId: 43,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232338.jpg",
        cropName: "20160203_232338.jpg",
      },
      {
        id: 4221,
        imgId: 44,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232449.jpg",
        cropName: "20160203_232449.jpg",
      },
      {
        id: 4222,
        imgId: 53,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_172223.jpg",
        cropName: "20160204_172223.jpg",
      },
    ],
    modifyDate: "2023-09-29T13:20:04.794+00:00",
    createDate: "2023-08-14T11:43:48.897+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "00T-GM-080M-055G",
    prodManfDetails: "M",
    prodDescription:
      "These are our thicker Brass Bangles priced for a set of 8 Pcs.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 338.69,
    cashPrice: 398.1331,
    taxAll: 51.148205,
    taxInclude: 423.70718,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 260.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 27,
    p_adminid: 2,
    productCategory: { id: 27, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 27,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 7352,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 7353,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 7354,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 7355,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7356,
        lbrId: 567,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 7357,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7358,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 27, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 27,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 27,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 27,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3679,
        imgId: 45,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232704.jpg",
        cropName: "20160203_232704.jpg",
      },
      {
        id: 3680,
        imgId: 46,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232819.jpg",
        cropName: "20160203_232819.jpg",
      },
      {
        id: 3681,
        imgId: 47,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232939.jpg",
        cropName: "20160203_232939.jpg",
      },
    ],
    modifyDate: "2023-09-12T11:39:16.246+00:00",
    createDate: "2023-08-14T11:47:59.979+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "00T-GM-090M-055-065",
    prodManfDetails: "M",
    prodDescription:
      "Offering Gold Plated Traditional 4 Chudi Kanas Meena Brass Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 273.97,
    cashPrice: 320.2073,
    taxAll: 37.6806,
    taxInclude: 339.0476,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 397.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 28,
    p_adminid: 2,
    productCategory: { id: 28, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 28,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 7330,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 7331,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7332,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 7333,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 7334,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7335,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 7336,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 28, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 28,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 28,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 28,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3674,
        imgId: 48,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_233235.jpg",
        cropName: "20160203_233235.jpg",
      },
      {
        id: 3675,
        imgId: 49,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_233319.jpg",
        cropName: "20160203_233319.jpg",
      },
      {
        id: 3676,
        imgId: 50,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_233445.jpg",
        cropName: "20160203_233445.jpg",
      },
    ],
    modifyDate: "2023-09-12T11:17:28.225+00:00",
    createDate: "2023-08-14T11:50:14.591+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Offering Gold Plated Traditional 4 Chudi Brass Bangles for Ladies.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 254.65,
    cashPrice: 299.5825,
    taxAll: 38.935005,
    taxInclude: 319.05,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 29,
    p_adminid: 2,
    productCategory: { id: 29, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 29,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 3911,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3912,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3913,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3914,
        lbrId: 573,
        price: 4.5,
        taxPercentage: 5.0,
        tax: 0.225,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3915,
        lbrId: 556,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 3916,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3917,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 29, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 29,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 29,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 29,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2021,
        imgId: 51,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_143408.jpg",
        cropName: "20160204_143408.jpg",
      },
      {
        id: 2022,
        imgId: 52,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_143513.jpg",
        cropName: "20160204_143513.jpg",
      },
    ],
    modifyDate: "2023-08-29T09:10:15.839+00:00",
    createDate: "2023-08-14T11:55:07.563+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Check out our tri-tone bangle selection for the very best in unique or custom.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 933.225,
    cashPrice: 1081.6603,
    taxAll: 110.225494,
    taxInclude: 1136.773,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 136.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 30,
    p_adminid: 2,
    productCategory: { id: 30, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 30,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 3918,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3919,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3920,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3921,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3922,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3923,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3924,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 30, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 30,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 30,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 30,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2023,
        imgId: 53,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_172223.jpg",
        cropName: "20160204_172223.jpg",
      },
      {
        id: 2024,
        imgId: 54,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_173202.jpg",
        cropName: "20160204_173202.jpg",
      },
    ],
    modifyDate: "2023-08-29T09:13:42.831+00:00",
    createDate: "2023-08-14T11:57:26.020+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "000 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "The bangles are made of 100% recycled brass, making them eco-friendly and comfortable to wear every day.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 401.21,
    cashPrice: 472.0639,
    taxAll: 61.4658,
    taxInclude: 502.79678,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 219.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 31,
    p_adminid: 2,
    productCategory: { id: 31, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 31,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 3937,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3938,
        lbrId: 568,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Gold Plated & Corum",
      },
      {
        id: 3939,
        lbrId: 557,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 3940,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
    ],
    productSets: { id: 31, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 31,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 31,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 31,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2036,
        imgId: 1327,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_150018.jpg",
        cropName: "20171017_150018.jpg",
      },
      {
        id: 2037,
        imgId: 55,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160312_110012.jpg",
        cropName: "20160312_110012.jpg",
      },
    ],
    modifyDate: "2023-08-29T09:22:47.762+00:00",
    createDate: "2023-08-14T11:58:50.474+00:00",
    metalGram: 0.09,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "95 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "We offer Designer 2 Tone Bangles at the most affordable prices.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1677.0502,
    cashPrice: 1988.5986,
    taxAll: 287.687,
    taxInclude: 2132.4421,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 40.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 32,
    p_adminid: 2,
    productCategory: { id: 32, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 32,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4025,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4026,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 4027,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4028,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4029,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4030,
        lbrId: 567,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated & Meena",
      },
    ],
    productSets: { id: 32, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 32,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 32,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 32,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2122,
        imgId: 56,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_212331.jpg",
        cropName: "20160318_212331.jpg",
      },
      {
        id: 2123,
        imgId: 57,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_212550.jpg",
        cropName: "20160318_212550.jpg",
      },
      {
        id: 2124,
        imgId: 58,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_212655.jpg",
        cropName: "20160318_212655.jpg",
      },
      {
        id: 2125,
        imgId: 59,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_213056.jpg",
        cropName: "20160318_213056.jpg",
      },
      {
        id: 2126,
        imgId: 60,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_213056.jpg",
        cropName: "20160318_213056.jpg",
      },
    ],
    modifyDate: "2023-08-29T11:59:54.528+00:00",
    createDate: "2023-08-14T12:00:21.118+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "G",
    prodDescription:
      "Gold-plated Traditional Original Work Meena Bangles for Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 247.48201,
    cashPrice: 289.4146,
    taxAll: 34.36876,
    taxInclude: 306.599,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 446.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 33,
    p_adminid: 2,
    productCategory: { id: 33, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 33,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4031,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4032,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4033,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4034,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4035,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4036,
        lbrId: 547,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Diamond Dell",
      },
      {
        id: 4037,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 33, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 33,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 33,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 33,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2127,
        imgId: 61,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_213919.jpg",
        cropName: "20160318_213919.jpg",
      },
    ],
    modifyDate: "2023-08-29T12:02:45.608+00:00",
    createDate: "2023-08-14T12:07:32.414+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription: "Dailywear Golwire Gold Plated Brass Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 585.85,
    cashPrice: 689.8355,
    taxAll: 90.801,
    taxInclude: 735.236,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 139.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 34,
    p_adminid: 2,
    productCategory: { id: 34, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 34,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 335,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 336,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 337,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 338,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 339,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 34, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 34,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 34,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 34,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 103,
        imgId: 62,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214131.jpg",
        cropName: "20160318_214131.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-14T12:10:09.580+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "Gm",
    prodManfDetails: "M",
    prodDescription:
      "These are our thicker Brass Bangles priced for a set of 4 Pcs.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.28998,
    cashPrice: 280.2891,
    taxAll: 38.5402,
    taxInclude: 299.55917,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 35,
    p_adminid: 2,
    productCategory: { id: 35, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 35,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 340,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 341,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 342,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 343,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 344,
        lbrId: 567,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated & Meena",
      },
    ],
    productSets: { id: 35, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 35,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 35,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 35,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 104,
        imgId: 63,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214547.jpg",
        cropName: "20160318_214547.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-14T12:11:37.383+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Buy Gold Plated Red and Green Round Meena Bangles for the best price.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 279.28998,
    cashPrice: 327.53906,
    taxAll: 40.6402,
    taxInclude: 347.85916,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 36,
    p_adminid: 2,
    productCategory: { id: 36, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 36,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4038,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4039,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4040,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4041,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4042,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 4043,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4044,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4045,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 36, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 36,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 36,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 36,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2128,
        imgId: 65,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214840.jpg",
        cropName: "20160318_214840.jpg",
      },
    ],
    modifyDate: "2023-08-29T15:10:31.077+00:00",
    createDate: "2023-08-14T12:17:02.590+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "000 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "We are a manufacturer of Kanas Golwire Bangles in BGP (Brass Gold Plated).",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 435.66998,
    cashPrice: 510.44626,
    taxAll: 62.418602,
    taxInclude: 541.6556,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 216.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 37,
    p_adminid: 2,
    productCategory: { id: 37, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 37,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4046,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4047,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4048,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4049,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4050,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4051,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 4052,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 4053,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 37, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 37,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 37,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 37,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2129,
        imgId: 64,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214641.jpg",
        cropName: "20160318_214641.jpg",
      },
      {
        id: 2130,
        imgId: 70,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_170429.jpg",
        cropName: "20160330_170429.jpg",
      },
      {
        id: 2131,
        imgId: 72,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_170610.jpg",
        cropName: "20160330_170610.jpg",
      },
      {
        id: 2132,
        imgId: 71,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_170523.jpg",
        cropName: "20160330_170523.jpg",
      },
      {
        id: 2133,
        imgId: 61,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_213919.jpg",
        cropName: "20160318_213919.jpg",
      },
    ],
    modifyDate: "2023-08-30T12:44:48.241+00:00",
    createDate: "2023-08-14T12:18:41.043+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "000 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Exclusive Golwire Brass Bangles collection for Women and Girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 423.16998,
    cashPrice: 495.07626,
    taxAll: 59.178596,
    taxInclude: 524.6656,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 38,
    p_adminid: 2,
    productCategory: { id: 38, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 38,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4054,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4055,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4056,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4057,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4058,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4059,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 4060,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4061,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 38, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 38,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 38,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 38,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2134,
        imgId: 72,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_170610.jpg",
        cropName: "20160330_170610.jpg",
      },
      {
        id: 2135,
        imgId: 67,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_170035.jpg",
        cropName: "20160330_170035.jpg",
      },
      {
        id: 2136,
        imgId: 68,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_170120.jpg",
        cropName: "20160330_170120.jpg",
      },
      {
        id: 2137,
        imgId: 69,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_170120.jpg",
        cropName: "20160330_170120.jpg",
      },
      {
        id: 2138,
        imgId: 70,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_170429.jpg",
        cropName: "20160330_170429.jpg",
      },
      {
        id: 2139,
        imgId: 66,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_165704.jpg",
        cropName: "20160330_165704.jpg",
      },
    ],
    modifyDate: "2023-08-30T12:48:39.949+00:00",
    createDate: "2023-08-14T12:21:24.684+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "000 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Buy Gold Plates Bangles with beautiful design for Women and Girls",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 445.00998,
    cashPrice: 520.5979,
    taxAll: 62.173794,
    taxInclude: 551.68475,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 219.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 39,
    p_adminid: 2,
    productCategory: { id: 39, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 39,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4062,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4063,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4064,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4065,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4066,
        lbrId: 549,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Kanas Diamond",
      },
      {
        id: 4067,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 4068,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4069,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 39, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 39,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 39,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 39,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2140,
        imgId: 77,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_172535.jpg",
        cropName: "20160330_172535.jpg",
      },
      {
        id: 2141,
        imgId: 78,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_172854.jpg",
        cropName: "20160330_172854.jpg",
      },
      {
        id: 2142,
        imgId: 76,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_172319.jpg",
        cropName: "20160330_172319.jpg",
      },
      {
        id: 2143,
        imgId: 75,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_172016.jpg",
        cropName: "20160330_172016.jpg",
      },
      {
        id: 2144,
        imgId: 74,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_171433.jpg",
        cropName: "20160330_171433.jpg",
      },
      {
        id: 2145,
        imgId: 73,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_171345.jpg",
        cropName: "20160330_171345.jpg",
      },
    ],
    modifyDate: "2023-08-30T12:50:32.994+00:00",
    createDate: "2023-08-14T12:23:22.751+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "000 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "The best golwire gold-plated bangle from our collection of exclusive and customized.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 466.84998,
    cashPrice: 546.11945,
    taxAll: 65.169,
    taxInclude: 578.704,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 40,
    p_adminid: 2,
    productCategory: { id: 40, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 40,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4070,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4071,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4072,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4073,
        lbrId: 573,
        price: 4.5,
        taxPercentage: 5.0,
        tax: 0.225,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4074,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4075,
        lbrId: 556,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 4076,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 40, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 40,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 40,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 40,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2146,
        imgId: 86,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181631.jpg",
        cropName: "20170304_181631.jpg",
      },
      {
        id: 2147,
        imgId: 87,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182926.jpg",
        cropName: "20170304_182926.jpg",
      },
      {
        id: 2148,
        imgId: 88,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_190402.jpg",
        cropName: "20170304_190402.jpg",
      },
      {
        id: 2149,
        imgId: 89,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191652.jpg",
        cropName: "20170304_191652.jpg",
      },
    ],
    modifyDate: "2023-08-30T12:53:10.478+00:00",
    createDate: "2023-08-14T12:23:22.789+00:00",
    metalGram: 0.007,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Antique Gold Wire Bracelet with Tri-tone Colours with various designs.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 769.32,
    cashPrice: 886.61334,
    taxAll: 80.7226,
    taxInclude: 926.9746,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 204.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 41,
    p_adminid: 2,
    productCategory: { id: 41, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 41,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4077,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4078,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4079,
        lbrId: 573,
        price: 4.5,
        taxPercentage: 5.0,
        tax: 0.225,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4080,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4081,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 4082,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4083,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
    ],
    productSets: { id: 41, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 41,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 41,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 41,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2150,
        imgId: 79,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174002.jpg",
        cropName: "20170217_174002.jpg",
      },
      {
        id: 2151,
        imgId: 80,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174121.jpg",
        cropName: "20170217_174121.jpg",
      },
      {
        id: 2152,
        imgId: 81,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174219.jpg",
        cropName: "20170217_174219.jpg",
      },
      {
        id: 2153,
        imgId: 82,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174359.jpg",
        cropName: "20170217_174359.jpg",
      },
      {
        id: 2154,
        imgId: 83,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174434.jpg",
        cropName: "20170217_174434.jpg",
      },
      {
        id: 2155,
        imgId: 85,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_180640.jpg",
        cropName: "20170304_180640.jpg",
      },
      {
        id: 2156,
        imgId: 84,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174520.jpg",
        cropName: "20170217_174520.jpg",
      },
    ],
    modifyDate: "2023-08-30T12:55:22.834+00:00",
    createDate: "2023-08-14T12:31:32.772+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription: "3 Tone Micro Gold Plated Bracelet For Women and Girls.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 942.85,
    cashPrice: 1091.294,
    taxAll: 108.31799,
    taxInclude: 1145.453,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 136.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 42,
    p_adminid: 2,
    productCategory: { id: 42, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 42,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4084,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4085,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4086,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4087,
        lbrId: 573,
        price: 4.5,
        taxPercentage: 5.0,
        tax: 0.225,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4088,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4089,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 4090,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 42, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 42,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 42,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 42,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2157,
        imgId: 169,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174705.jpg",
        cropName: "20170217_174705.jpg",
      },
      {
        id: 2158,
        imgId: 170,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174815.jpg",
        cropName: "20170217_174815.jpg",
      },
      {
        id: 2159,
        imgId: 172,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_174916.jpg",
        cropName: "20170217_174916.jpg",
      },
      {
        id: 2160,
        imgId: 173,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170218_112813.jpg",
        cropName: "20170218_112813.jpg",
      },
    ],
    modifyDate: "2023-08-30T12:57:42.980+00:00",
    createDate: "2023-08-14T12:52:28.338+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Micron plated High finish three tone plated bangle fashion jewellery women daily wear the trendy bracelet.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 970.36,
    cashPrice: 1124.0309,
    taxAll: 113.26979,
    taxInclude: 1180.6658,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 130.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 43,
    p_adminid: 2,
    productCategory: { id: 43, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 43,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4096,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4097,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4098,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4099,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 4100,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 43, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 43,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 43,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 43,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2166,
        imgId: 90,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0043.jpg",
        cropName: "20180213-WA0043.jpg",
      },
      {
        id: 2167,
        imgId: 91,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0047.jpg",
        cropName: "20180213-WA0047.jpg",
      },
      {
        id: 2168,
        imgId: 92,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0055.jpg",
        cropName: "20180213-WA0055.jpg",
      },
      {
        id: 2169,
        imgId: 93,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0057.jpg",
        cropName: "20180213-WA0057.jpg",
      },
      {
        id: 2170,
        imgId: 94,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0080.jpg",
        cropName: "20180213-WA0080.jpg",
      },
    ],
    modifyDate: "2023-08-30T13:01:22.230+00:00",
    createDate: "2023-08-14T13:02:06.251+00:00",
    metalGram: 0.085,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "The best gold wire bangle from our collection of exclusive, customized & Beautiful products.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 2618.89,
    cashPrice: 3112.3508,
    taxAll: 463.1442,
    taxInclude: 3343.923,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 25.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 45,
    p_adminid: 2,
    productCategory: { id: 45, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 45,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4107,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4108,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4109,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4110,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4111,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 4112,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 45, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 45,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 45,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 45,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2176,
        imgId: 96,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_093622.jpg",
        cropName: "20190204_093622.jpg",
      },
      {
        id: 2177,
        imgId: 97,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_093739.jpg",
        cropName: "20190204_093739.jpg",
      },
      {
        id: 2178,
        imgId: 98,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094055.jpg",
        cropName: "20190204_094055.jpg",
      },
      {
        id: 2179,
        imgId: 99,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094141.jpg",
        cropName: "20190204_094141.jpg",
      },
      {
        id: 2180,
        imgId: 100,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094229.jpg",
        cropName: "20190204_094229.jpg",
      },
      {
        id: 2181,
        imgId: 101,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094331.jpg",
        cropName: "20190204_094331.jpg",
      },
    ],
    modifyDate: "2023-08-30T13:41:06.403+00:00",
    createDate: "2023-08-14T13:15:26.581+00:00",
    metalGram: 0.085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription: "Offering Gold Plated Ladies Wedding Wear Brass Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1547.69,
    cashPrice: 1838.8461,
    taxAll: 272.7742,
    taxInclude: 1975.2332,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 42.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 46,
    p_adminid: 2,
    productCategory: { id: 46, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 46,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4125,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4126,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4127,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4128,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4129,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 4130,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 46, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 46,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 46,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 46,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2199,
        imgId: 103,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100119.jpg",
        cropName: "20190204_100119.jpg",
      },
      {
        id: 2200,
        imgId: 106,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100402.jpg",
        cropName: "20190204_100402.jpg",
      },
      {
        id: 2201,
        imgId: 119,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101849.jpg",
        cropName: "20190204_101849.jpg",
      },
      {
        id: 2202,
        imgId: 95,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_10165.jpg",
        cropName: "20190204_10165.jpg",
      },
      {
        id: 2203,
        imgId: 110,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100738.jpg",
        cropName: "20190204_100738.jpg",
      },
      {
        id: 2204,
        imgId: 111,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100840.jpg",
        cropName: "20190204_100840.jpg",
      },
      {
        id: 2205,
        imgId: 113,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101349.jpg",
        cropName: "20190204_101349.jpg",
      },
      {
        id: 2206,
        imgId: 116,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101626.jpg",
        cropName: "20190204_101626.jpg",
      },
      {
        id: 2207,
        imgId: 118,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101823.jpg",
        cropName: "20190204_101823.jpg",
      },
      {
        id: 2208,
        imgId: 104,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100119.jpg",
        cropName: "20190204_100119.jpg",
      },
      {
        id: 2209,
        imgId: 109,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100641.jpg",
        cropName: "20190204_100641.jpg",
      },
      {
        id: 2210,
        imgId: 114,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101416.jpg",
        cropName: "20190204_101416.jpg",
      },
    ],
    modifyDate: "2023-08-30T13:48:13.449+00:00",
    createDate: "2023-08-14T13:19:39.623+00:00",
    metalGram: 0.08,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Manufacturer of Designer Bangles - Premium Gold Plated Brass Bangles offered.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1443.25,
    cashPrice: 1714.5625,
    taxAll: 253.975,
    taxInclude: 1841.5499,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 45.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 47,
    p_adminid: 2,
    productCategory: { id: 47, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 47,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4131,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4132,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4133,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4134,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4135,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 47, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 47,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 47,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 47,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2211,
        imgId: 110,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100738.jpg",
        cropName: "20190204_100738.jpg",
      },
      {
        id: 2212,
        imgId: 111,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100840.jpg",
        cropName: "20190204_100840.jpg",
      },
      {
        id: 2213,
        imgId: 108,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100548.jpg",
        cropName: "20190204_100548.jpg",
      },
      {
        id: 2214,
        imgId: 113,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101349.jpg",
        cropName: "20190204_101349.jpg",
      },
      {
        id: 2215,
        imgId: 116,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101626.jpg",
        cropName: "20190204_101626.jpg",
      },
      {
        id: 2216,
        imgId: 118,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101823.jpg",
        cropName: "20190204_101823.jpg",
      },
    ],
    modifyDate: "2023-08-30T13:50:30.252+00:00",
    createDate: "2023-08-14T13:21:26.765+00:00",
    metalGram: 0.007,
    extraGram: 5.0e-4,
    readyGram: 0.0045,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Modern Style are the Manufacturer and Supplier of Designer Gold Plated Brass Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 231.38602,
    cashPrice: 272.23535,
    taxAll: 35.42148,
    taxInclude: 289.9461,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 397.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 48,
    p_adminid: 2,
    productCategory: { id: 48, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 48,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4119,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4120,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4121,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4122,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4123,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 4124,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 48, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 48,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 48,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 48,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2193,
        imgId: 102,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100043.jpg",
        cropName: "20190204_100043.jpg",
      },
      {
        id: 2194,
        imgId: 105,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100310.jpg",
        cropName: "20190204_100310.jpg",
      },
      {
        id: 2195,
        imgId: 107,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_100500.jpg",
        cropName: "20190204_100500.jpg",
      },
      {
        id: 2196,
        imgId: 112,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101324.jpg",
        cropName: "20190204_101324.jpg",
      },
      {
        id: 2197,
        imgId: 115,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101548.jpg",
        cropName: "20190204_101548.jpg",
      },
      {
        id: 2198,
        imgId: 117,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_101756.jpg",
        cropName: "20190204_101756.jpg",
      },
    ],
    modifyDate: "2023-08-30T13:46:34.777+00:00",
    createDate: "2023-08-14T13:22:44.767+00:00",
    metalGram: 0.007,
    extraGram: 5.0e-4,
    readyGram: 0.0045,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "G M",
    prodManfDetails: "M",
    prodDescription:
      "Explore and discover high-quality Brass Bangles manufacturers, suppliers, producers, wholesalers, and exporters in India and across the world.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 304.898,
    cashPrice: 358.38864,
    taxAll: 46.00164,
    taxInclude: 381.38947,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 298.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 49,
    p_adminid: 2,
    productCategory: { id: 49, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 49,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4136,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4137,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4138,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4139,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4140,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4141,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4142,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 49, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 49,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 49,
      adminId: 2,
      rawpriceId: 191,
      title: "Rolling Die Patti",
      price: 670.0,
      different: 12.0,
      taxPercentage: 18.0,
      tax: 122.76,
    },
    productPacking: {
      id: 49,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2217,
        imgId: 135,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_145932.jpg",
        cropName: "20211006_145932.jpg",
      },
      {
        id: 2218,
        imgId: 137,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150112.jpg",
        cropName: "20211006_150112.jpg",
      },
      {
        id: 2219,
        imgId: 139,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150214.jpg",
        cropName: "20211006_150214.jpg",
      },
      {
        id: 2220,
        imgId: 129,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_145438.jpg",
        cropName: "20211006_145438.jpg",
      },
      {
        id: 2221,
        imgId: 130,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_145642.jpg",
        cropName: "20211006_145642.jpg",
      },
      {
        id: 2222,
        imgId: 132,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_145816.jpg",
        cropName: "20211006_145816.jpg",
      },
    ],
    modifyDate: "2023-09-01T05:53:12.928+00:00",
    createDate: "2023-08-14T13:24:58.237+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "00  Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "we are able to manufacture, export, and supply the finest quality of Brass Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 408.27396,
    cashPrice: 480.47,
    taxAll: 62.737316,
    taxInclude: 511.83865,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 50,
    p_adminid: 2,
    productCategory: { id: 50, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 50,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4143,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4144,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4145,
        lbrId: 567,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 4146,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 4147,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4148,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 50, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 50,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 50,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 50,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2223,
        imgId: 120,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_103451.jpg",
        cropName: "20190204_103451.jpg",
      },
      {
        id: 2224,
        imgId: 121,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_103659.jpg",
        cropName: "20190204_103659.jpg",
      },
      {
        id: 2225,
        imgId: 122,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_103835.jpg",
        cropName: "20190204_103835.jpg",
      },
      {
        id: 2226,
        imgId: 123,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_103928.jpg",
        cropName: "20190204_103928.jpg",
      },
      {
        id: 2227,
        imgId: 124,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_104044.jpg",
        cropName: "20190204_104044.jpg",
      },
      {
        id: 2228,
        imgId: 125,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_104141.jpg",
        cropName: "20190204_104141.jpg",
      },
    ],
    modifyDate: "2023-09-01T05:58:10.490+00:00",
    createDate: "2023-08-14T13:31:19.812+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Pretty red & green Mina Gold Plated Brass Bangles For Women & girls.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 261.23,
    cashPrice: 305.31973,
    taxAll: 35.933403,
    taxInclude: 323.28644,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 420.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 51,
    p_adminid: 2,
    productCategory: { id: 51, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 51,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4149,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4150,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4151,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4152,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4153,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4154,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 51, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 51,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 51,
      adminId: 2,
      rawpriceId: 192,
      title: "Rolling Dell Die Patti",
      price: 610.0,
      different: 18.0,
      taxPercentage: 18.0,
      tax: 113.04,
    },
    productPacking: {
      id: 51,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2229,
        imgId: 126,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_145043.jpg",
        cropName: "20211006_145043.jpg",
      },
      {
        id: 2230,
        imgId: 131,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_145729.jpg",
        cropName: "20211006_145729.jpg",
      },
      {
        id: 2231,
        imgId: 133,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_145852.jpg",
        cropName: "20211006_145852.jpg",
      },
      {
        id: 2232,
        imgId: 136,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150047.jpg",
        cropName: "20211006_150047.jpg",
      },
      {
        id: 2233,
        imgId: 138,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150144.jpg",
        cropName: "20211006_150144.jpg",
      },
      {
        id: 2234,
        imgId: 128,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_145612.jpg",
        cropName: "20211006_145612.jpg",
      },
    ],
    modifyDate: "2023-09-01T06:13:29.170+00:00",
    createDate: "2023-08-14T13:52:37.984+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      " These bangles are a best option for matching with daily entries in order to look attractive.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 239.306,
    cashPrice: 281.50513,
    taxAll: 36.53708,
    taxInclude: 299.77368,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 397.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 52,
    p_adminid: 2,
    productCategory: { id: 52, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 52,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 455,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 456,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 457,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 458,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 459,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 52, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 52,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 52,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 52,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 211,
        imgId: 140,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150320.jpg",
        cropName: "20211006_150320.jpg",
      },
      {
        id: 212,
        imgId: 143,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150525.jpg",
        cropName: "20211006_150525.jpg",
      },
      {
        id: 213,
        imgId: 146,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150738.jpg",
        cropName: "20211006_150738.jpg",
      },
      {
        id: 214,
        imgId: 149,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151002.jpg",
        cropName: "20211006_151002.jpg",
      },
      {
        id: 215,
        imgId: 152,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151129.jpg",
        cropName: "20211006_151129.jpg",
      },
      {
        id: 216,
        imgId: 155,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151325.jpg",
        cropName: "20211006_151325.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-14T13:54:18.781+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "The provided Brass Bangles that can be purchased from us in several designs and finishes at cost-effective prices.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.28998,
    cashPrice: 280.2891,
    taxAll: 38.5402,
    taxInclude: 299.55917,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 53,
    p_adminid: 2,
    productCategory: { id: 53, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 53,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 460,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 461,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 462,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 463,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 464,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 53, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 53,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 53,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 53,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 217,
        imgId: 161,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_113534.jpg",
        cropName: "20211126_113534.jpg",
      },
      {
        id: 218,
        imgId: 165,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_113727.jpg",
        cropName: "20211126_113727.jpg",
      },
      {
        id: 219,
        imgId: 168,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_114114.jpg",
        cropName: "20211126_114114.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-14T13:55:31.799+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Get the best deals on Brass Bangle Gold Bracelets for women when you shop the largest online selection at my website.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.28998,
    cashPrice: 280.2891,
    taxAll: 38.5402,
    taxInclude: 299.55917,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 54,
    p_adminid: 2,
    productCategory: { id: 54, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 54,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 465,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 466,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 467,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 468,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 469,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 54, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 54,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 54,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 54,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 220,
        imgId: 141,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150359.jpg",
        cropName: "20211006_150359.jpg",
      },
      {
        id: 221,
        imgId: 144,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150600.jpg",
        cropName: "20211006_150600.jpg",
      },
      {
        id: 222,
        imgId: 147,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150807.jpg",
        cropName: "20211006_150807.jpg",
      },
      {
        id: 223,
        imgId: 150,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151034.jpg",
        cropName: "20211006_151034.jpg",
      },
      {
        id: 224,
        imgId: 153,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151200.jpg",
        cropName: "20211006_151200.jpg",
      },
      {
        id: 225,
        imgId: 156,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151349.jpg",
        cropName: "20211006_151349.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-14T13:56:47.753+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "The bangles are made of 100% recycled brass, making it eco-friendly and comfortable to wear everyday.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 373.48996,
    cashPrice: 441.41705,
    taxAll: 61.156197,
    taxInclude: 471.99515,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 55,
    p_adminid: 2,
    productCategory: { id: 55, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 55,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 470,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 471,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 472,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 473,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 474,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 55, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 55,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 55,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 55,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 226,
        imgId: 158,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_113416.jpg",
        cropName: "20211126_113416.jpg",
      },
      {
        id: 227,
        imgId: 166,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_114018.jpg",
        cropName: "20211126_114018.jpg",
      },
      {
        id: 228,
        imgId: 163,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_113645.jpg",
        cropName: "20211126_113645.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-14T13:58:53.572+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Party Wear Ladies Brass Bangle · Trendy Ladies Brass Bangle.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 373.48996,
    cashPrice: 441.41705,
    taxAll: 61.156197,
    taxInclude: 471.99515,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 56,
    p_adminid: 2,
    productCategory: { id: 56, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 56,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 475,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 476,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 477,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 478,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 479,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 56, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 56,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 56,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 56,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 229,
        imgId: 164,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_113718.jpg",
        cropName: "20211126_113718.jpg",
      },
      {
        id: 230,
        imgId: 167,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_114059.jpg",
        cropName: "20211126_114059.jpg",
      },
      {
        id: 231,
        imgId: 159,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211126_113525.jpg",
        cropName: "20211126_113525.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-14T14:00:09.292+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "Gold Plated Brass Bangles Our Company provides our valued customers with a fine range of Brass Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 289.72995,
    cashPrice: 342.36664,
    taxAll: 47.327396,
    taxInclude: 366.03033,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 57,
    p_adminid: 2,
    productCategory: { id: 57, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 57,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 480,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 481,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 482,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 483,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 484,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 57, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 57,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 57,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 57,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 232,
        imgId: 142,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150439.jpg",
        cropName: "20211006_150439.jpg",
      },
      {
        id: 233,
        imgId: 145,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150623.jpg",
        cropName: "20211006_150623.jpg",
      },
      {
        id: 234,
        imgId: 148,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_150903.jpg",
        cropName: "20211006_150903.jpg",
      },
      {
        id: 235,
        imgId: 151,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151100.jpg",
        cropName: "20211006_151100.jpg",
      },
      {
        id: 236,
        imgId: 154,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151224.jpg",
        cropName: "20211006_151224.jpg",
      },
      {
        id: 237,
        imgId: 157,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151415.jpg",
        cropName: "20211006_151415.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-14T14:01:18.788+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "M",
    prodDescription:
      "They are available in different sizes and designs are more durable and compatible in nature and are also in demand in the market.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 542.80994,
    cashPrice: 641.65985,
    taxAll: 89.137794,
    taxInclude: 686.22876,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 139.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 58,
    p_adminid: 2,
    productCategory: { id: 58, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 58,
      adminId: 2,
      parentId: 103,
      categoryId: 11,
      title: "1MM",
    },
    productChild: [
      {
        id: 5224,
        lbrId: 583,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5225,
        lbrId: 590,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5226,
        lbrId: 607,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5227,
        lbrId: 589,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Zigzag",
      },
      {
        id: 5228,
        lbrId: 599,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5229,
        lbrId: 591,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 58, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 58,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 58,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 58,
      adminId: 2,
      packingmaterialId: 118,
      type: "Polythene PP",
      pkgdesc: "12 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2574,
        imgId: 174,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190329_115957.jpg",
        cropName: "20190329_115957.jpg",
      },
    ],
    modifyDate: "2023-09-08T06:03:38.156+00:00",
    createDate: "2023-08-14T14:04:10.258+00:00",
    metalGram: 0.0028,
    extraGram: 5.0e-4,
    readyGram: 0.002,
    golwireThaw: "58 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "1MM",
    prodManfDetails: "1",
    prodDescription: "High-Quality Gold Plated Bangles. Look Like Gold. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.44838,
    cashPrice: 276.17355,
    taxAll: 29.960709,
    taxInclude: 291.15393,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 496.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 59,
    p_adminid: 2,
    productCategory: { id: 59, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 59,
      adminId: 2,
      parentId: 103,
      categoryId: 11,
      title: "1MM",
    },
    productChild: [
      {
        id: 5230,
        lbrId: 583,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5231,
        lbrId: 590,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5232,
        lbrId: 597,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 5233,
        lbrId: 607,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5234,
        lbrId: 599,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5235,
        lbrId: 591,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5236,
        lbrId: 617,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 59, setsId: 36, setDesc: "24 Pcs in 1 Set", pcs: 24 },
    productBox: {
      id: 59,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 59,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 59,
      adminId: 2,
      packingmaterialId: 119,
      type: "Polythene PP",
      pkgdesc: "24 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2575,
        imgId: 175,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190329_120843.jpg",
        cropName: "20190329_120843.jpg",
      },
      {
        id: 2576,
        imgId: 176,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190329_120915.jpg",
        cropName: "20190329_120915.jpg",
      },
    ],
    modifyDate: "2023-09-08T06:05:13.589+00:00",
    createDate: "2023-08-14T14:05:19.807+00:00",
    metalGram: 0.0028,
    extraGram: 5.0e-4,
    readyGram: 0.002,
    golwireThaw: "58 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "1 MM",
    prodManfDetails: "1",
    prodDescription:
      "This combo set of 24 of the latest designs of traditional stylish party wear traditional bangle bracelet jewellery set can be worn on wedding bridal.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 482.04678,
    cashPrice: 559.5237,
    taxAll: 58.54442,
    taxInclude: 588.7959,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 248.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 8,
        adminId: 2,
        productId: 59,
        stockCategoryId: 11,
        stockParentId: 103,
        cutOrFullSize: "1",
        createDate: "2023-09-22T12:19:15.062+00:00",
        s1x8: "111",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "28",
        s2x3: "0",
        s2x4: "0",
        s2x5: "0",
        s2x6: "0",
        s2x7: "0",
        s2x8: "0",
        s2x9: "0",
        s2x10: "0",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 139.0,
      },
    ],
  },
  {
    id: 60,
    p_adminid: 2,
    productCategory: { id: 60, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 60,
      adminId: 2,
      parentId: 103,
      categoryId: 11,
      title: "1MM",
    },
    productChild: [
      {
        id: 8883,
        lbrId: 583,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 8884,
        lbrId: 590,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8885,
        lbrId: 597,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 8886,
        lbrId: 607,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 8887,
        lbrId: 589,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Zigzag",
      },
      {
        id: 8888,
        lbrId: 599,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8889,
        lbrId: 617,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 60, setsId: 36, setDesc: "24 Pcs in 1 Set", pcs: 24 },
    productBox: {
      id: 60,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 60,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 60,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4182,
        imgId: 178,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220324_144024.jpg",
        cropName: "20220324_144024.jpg",
      },
      {
        id: 4183,
        imgId: 177,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220324_143949.jpg",
        cropName: "20220324_143949.jpg",
      },
      {
        id: 4184,
        imgId: 179,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220324_144258.jpg",
        cropName: "20220324_144258.jpg",
      },
    ],
    modifyDate: "2023-09-22T14:15:09.994+00:00",
    createDate: "2023-08-14T14:06:37.777+00:00",
    metalGram: 0.0026,
    extraGram: 5.0e-4,
    readyGram: 0.0019,
    golwireThaw: "48 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "1 MM",
    prodManfDetails: "1",
    prodDescription:
      "Stylish gold-plated bangles for women from the house of Suprimo bangles.",
    cartDescription: "60 boxes of in 1 Carton",
    cartQuantity: 4,
    cartPackCharge: 500.0,
    makingCharge: 472.4756,
    cashPrice: 548.6019,
    taxAll: 57.757607,
    taxInclude: 577.4808,
    active: true,
    gstCash: true,
    moqkg: 44.928,
    moq: 120.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 10,
        adminId: 2,
        productId: 60,
        stockCategoryId: 11,
        stockParentId: 103,
        cutOrFullSize: "1",
        createDate: "2023-09-22T13:48:52.815+00:00",
        s1x8: "111",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "0",
        s2x3: "0",
        s2x4: "0",
        s2x5: "0",
        s2x6: "20",
        s2x7: "0",
        s2x8: "20",
        s2x9: "0",
        s2x10: "10",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 161.0,
      },
    ],
  },
  {
    id: 62,
    p_adminid: 2,
    productCategory: { id: 62, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 62,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 5244,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5245,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5246,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5247,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5248,
        lbrId: 683,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5249,
        lbrId: 693,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5250,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 62, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 62,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 62,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 62,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2582,
        imgId: 180,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201443.jpg",
        cropName: "20210906_201443.jpg",
      },
      {
        id: 2583,
        imgId: 183,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201641.jpg",
        cropName: "20210906_201641.jpg",
      },
      {
        id: 2584,
        imgId: 186,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201906.jpg",
        cropName: "20210906_201906.jpg",
      },
      {
        id: 2585,
        imgId: 189,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202148.jpg",
        cropName: "20210906_202148.jpg",
      },
      {
        id: 2586,
        imgId: 195,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202512.jpg",
        cropName: "20210906_202512.jpg",
      },
      {
        id: 2587,
        imgId: 192,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202304.jpg",
        cropName: "20210906_202304.jpg",
      },
    ],
    modifyDate: "2023-09-08T06:17:50.002+00:00",
    createDate: "2023-08-14T14:28:10.527+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription:
      "All you need for the festive season is now available in one place.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 341.76202,
    cashPrice: 401.78882,
    taxAll: 51.701164,
    taxInclude: 427.6394,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 260.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 63,
    p_adminid: 2,
    productCategory: { id: 63, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 63,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 5272,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5273,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5274,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5275,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5276,
        lbrId: 683,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5277,
        lbrId: 693,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5278,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 63, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 63,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 63,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 63,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2600,
        imgId: 181,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201515.jpg",
        cropName: "20210906_201515.jpg",
      },
      {
        id: 2601,
        imgId: 184,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201726.jpg",
        cropName: "20210906_201726.jpg",
      },
      {
        id: 2602,
        imgId: 187,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201928.jpg",
        cropName: "20210906_201928.jpg",
      },
      {
        id: 2603,
        imgId: 190,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202202.jpg",
        cropName: "20210906_202202.jpg",
      },
      {
        id: 2604,
        imgId: 193,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202318.jpg",
        cropName: "20210906_202318.jpg",
      },
    ],
    modifyDate: "2023-09-08T06:31:37.689+00:00",
    createDate: "2023-08-14T14:29:53.288+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2 MM",
    prodManfDetails: "2",
    prodDescription:
      "6 Pcs Gold plated bangles crafted with trendy latest stylish cut design with shining.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 265.634,
    cashPrice: 312.28845,
    taxAll: 40.18212,
    taxInclude: 332.37952,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 347.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 64,
    p_adminid: 2,
    productCategory: { id: 64, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 64,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 5265,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5266,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5267,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5268,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5269,
        lbrId: 683,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5270,
        lbrId: 693,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5271,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 64, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 64,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 64,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 64,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2595,
        imgId: 182,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201532.jpg",
        cropName: "20210906_201532.jpg",
      },
      {
        id: 2596,
        imgId: 185,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201737.jpg",
        cropName: "20210906_201737.jpg",
      },
      {
        id: 2597,
        imgId: 188,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201951.jpg",
        cropName: "20210906_201951.jpg",
      },
      {
        id: 2598,
        imgId: 191,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202214.jpg",
        cropName: "20210906_202214.jpg",
      },
      {
        id: 2599,
        imgId: 194,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202330.jpg",
        cropName: "20210906_202330.jpg",
      },
    ],
    modifyDate: "2023-09-08T06:30:55.224+00:00",
    createDate: "2023-08-14T14:32:03.345+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2 MM",
    prodManfDetails: "2",
    prodDescription:
      "This lovely bangle boasts a clean, classic design that provides the perfect finishing touch.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 218.082,
    cashPrice: 256.33957,
    taxAll: 32.89876,
    taxInclude: 272.78897,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 446.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 65,
    p_adminid: 2,
    productCategory: { id: 65, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 65,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 5258,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5259,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5260,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5261,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5262,
        lbrId: 683,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5263,
        lbrId: 693,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5264,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 65, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 65,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 65,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 65,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2593,
        imgId: 196,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202608.jpg",
        cropName: "20210906_202608.jpg",
      },
      {
        id: 2594,
        imgId: 195,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_202512.jpg",
        cropName: "20210906_202512.jpg",
      },
    ],
    modifyDate: "2023-09-08T06:30:13.764+00:00",
    createDate: "2023-08-14T14:33:30.658+00:00",
    metalGram: 0.007,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2 MM",
    prodManfDetails: "2",
    prodDescription:
      "This gorgeous collection of artisan, pure brass bangle bracelets is inspired by traditional Indian designs!",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 312.09802,
    cashPrice: 366.48865,
    taxAll: 46.36164,
    taxInclude: 389.66946,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 298.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 66,
    p_adminid: 2,
    productCategory: { id: 66, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 66,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5405,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5406,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5407,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5408,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5409,
        lbrId: 790,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 5410,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 66, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 66,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 66,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 66,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2634,
        imgId: 197,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8.52002.jpg",
        cropName: "8.52002.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:20:05.159+00:00",
    createDate: "2023-08-14T14:37:07.307+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Our adjustable brass stacking bangles bring a hint of sophistication to your ensemble, ideal for fashion-forward women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 518.89,
    cashPrice: 609.4331,
    taxAll: 77.3082,
    taxInclude: 648.08716,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 174.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 67,
    p_adminid: 2,
    productCategory: { id: 67, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 67,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5400,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5401,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5402,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5403,
        lbrId: 803,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Plated & Chrome",
      },
      {
        id: 5404,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 67, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 67,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 67,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 67,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2633,
        imgId: 199,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2003.jpg",
        cropName: "2003.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:19:35.577+00:00",
    createDate: "2023-08-14T14:38:08.723+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "It has an imitation rhodium plating to give it a bright silver finish.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 583.69,
    cashPrice: 682.3331,
    taxAll: 80.5482,
    taxInclude: 722.60724,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 174.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 68,
    p_adminid: 2,
    productCategory: { id: 68, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 68,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 6674,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6675,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6676,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 6677,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6678,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6679,
        lbrId: 798,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Hend Cutting Full",
      },
    ],
    productSets: { id: 68, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 68,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 68,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 68,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3310,
        imgId: 198,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/258.jpg",
        cropName: "258.jpg",
      },
    ],
    modifyDate: "2023-09-09T14:18:20.913+00:00",
    createDate: "2023-08-14T14:41:40.618+00:00",
    metalGram: 0.0125,
    extraGram: 5.0e-4,
    readyGram: 0.0095,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "These beautiful brass bangles aren't just a fashion piece and conversation starter. You can look good while helping your body!!",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 511.15,
    cashPrice: 597.99854,
    taxAll: 71.466995,
    taxInclude: 633.732,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 200.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 69,
    p_adminid: 2,
    productCategory: { id: 69, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 69,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5395,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5396,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5397,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5398,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5399,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 69, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 69,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 69,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 69,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2632,
        imgId: 200,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_165115.jpg",
        cropName: "20160330_165115.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:19:05.435+00:00",
    createDate: "2023-08-14T14:45:16.037+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Pair bangles are the perfect addition to any jewellery collection. Unlike regular bangles, worn in odd numbers, pair bangles come in two sets.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 360.72998,
    cashPrice: 425.59668,
    taxAll: 57.587402,
    taxInclude: 454.39038,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 70,
    p_adminid: 2,
    productCategory: { id: 70, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 70,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5371,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5372,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5373,
        lbrId: 803,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Plated & Chrome",
      },
      {
        id: 5374,
        lbrId: 774,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 5375,
        lbrId: 791,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5376,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 70, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 70,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 70,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 70,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2624,
        imgId: 201,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161117_100538.jpg",
        cropName: "20161117_100538.jpg",
      },
      {
        id: 2625,
        imgId: 204,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161117_101217.jpg",
        cropName: "20161117_101217.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:17:04.823+00:00",
    createDate: "2023-08-14T14:47:05.908+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "You can wear them on both hands to create a symmetrical and balanced look. \n\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 461.33,
    cashPrice: 538.44666,
    taxAll: 61.9674,
    taxInclude: 569.43036,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 71,
    p_adminid: 2,
    productCategory: { id: 71, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 71,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5377,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5378,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5379,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5380,
        lbrId: 803,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Plated & Chrome",
      },
      {
        id: 5381,
        lbrId: 791,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5382,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 71, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 71,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 71,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 71,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2626,
        imgId: 202,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161117_100705.jpg",
        cropName: "20161117_100705.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:17:36.434+00:00",
    createDate: "2023-08-14T14:48:44.078+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "These stylish bangles are versatile, and you can wear them with multiple outfits.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 405.05,
    cashPrice: 473.0395,
    taxAll: 54.969,
    taxInclude: 500.524,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 72,
    p_adminid: 2,
    productCategory: { id: 72, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 72,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5383,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5384,
        lbrId: 774,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 5385,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5386,
        lbrId: 803,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Plated & Chrome",
      },
      {
        id: 5387,
        lbrId: 791,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5388,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 72, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 72,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 72,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 72,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2627,
        imgId: 203,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161117_100932.jpg",
        cropName: "20161117_100932.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:18:01.904+00:00",
    createDate: "2023-08-14T14:50:54.308+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      " Whether you dress up for a casual or a formal event, the bangle pairs will never leave your side. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 410.13,
    cashPrice: 478.8247,
    taxAll: 55.363396,
    taxInclude: 506.5064,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 73,
    p_adminid: 2,
    productCategory: { id: 73, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 73,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5423,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5424,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5425,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5426,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5427,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5428,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5429,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 73, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 73,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 73,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 73,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2637,
        imgId: 205,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_132920.jpg",
        cropName: "20170418_132920.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:27:38.156+00:00",
    createDate: "2023-08-14T14:52:26.311+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      " we offer online bangles to suit every taste and preference. Our collection includes bangles in different materials, designs, and colours.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 439.57,
    cashPrice: 513.33234,
    taxAll: 59.610603,
    taxInclude: 543.13763,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 74,
    p_adminid: 2,
    productCategory: { id: 74, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 74,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5430,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5431,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5432,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5433,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5434,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5435,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5436,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 74, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 74,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 74,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 74,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2638,
        imgId: 207,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_141203.jpg",
        cropName: "20170418_141203.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:30:37.541+00:00",
    createDate: "2023-08-16T05:22:21.635+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3 MM",
    prodManfDetails: "3",
    prodDescription: "Gold-plated Pair Bangles with a Lustrous Finish.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 439.57,
    cashPrice: 513.33234,
    taxAll: 59.610603,
    taxInclude: 543.13763,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 75,
    p_adminid: 2,
    productCategory: { id: 75, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 75,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5437,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5438,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5439,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5440,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5441,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5442,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5443,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 75, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 75,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 75,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 75,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2639,
        imgId: 209,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_162843.jpg",
        cropName: "20170418_162843.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:32:15.284+00:00",
    createDate: "2023-08-16T05:23:46.423+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "We offer a stunning collection of pair bangles online and offline that are perfect for any occasion. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 439.57,
    cashPrice: 513.33234,
    taxAll: 59.610603,
    taxInclude: 543.13763,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 76,
    p_adminid: 2,
    productCategory: { id: 76, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 76,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5364,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5365,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5366,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5367,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5368,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5369,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5370,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 76, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 76,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 76,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 76,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2623,
        imgId: 206,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_133149.jpg",
        cropName: "20170418_133149.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:16:37.133+00:00",
    createDate: "2023-08-16T05:25:41.436+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Pair bangles are also versatile and can be worn with various outfits.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 386.75,
    cashPrice: 451.6525,
    taxAll: 52.455,
    taxInclude: 477.88,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 77,
    p_adminid: 2,
    productCategory: { id: 77, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 77,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5357,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5358,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5359,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5360,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5361,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5362,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5363,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 77, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 77,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 77,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 77,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2622,
        imgId: 208,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_141229.jpg",
        cropName: "20170418_141229.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:16:09.928+00:00",
    createDate: "2023-08-16T05:28:17.839+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Pair bangles are also versatile and can be worn with various outfits.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 386.75,
    cashPrice: 451.6525,
    taxAll: 52.455,
    taxInclude: 477.88,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 81,
    p_adminid: 2,
    productCategory: { id: 81, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 81,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5416,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5417,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5418,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5419,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5420,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5421,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5422,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 81, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 81,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 81,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 81,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2636,
        imgId: 210,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_162920.jpg",
        cropName: "20170418_162920.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:23:45.027+00:00",
    createDate: "2023-08-16T05:51:04.873+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "You can wear them on both hands for a balanced and symmetrical look. \n\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 386.75,
    cashPrice: 451.6525,
    taxAll: 52.455,
    taxInclude: 477.88,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 82,
    p_adminid: 2,
    productCategory: { id: 82, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 82,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5456,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5457,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5458,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5459,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5460,
        lbrId: 789,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 5461,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 82, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 82,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 82,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 82,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2652,
        imgId: 211,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_132725.jpg",
        cropName: "20170614_132725.jpg",
      },
      {
        id: 2653,
        imgId: 213,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133226.jpg",
        cropName: "20170614_133226.jpg",
      },
      {
        id: 2654,
        imgId: 215,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133357.jpg",
        cropName: "20170614_133357.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:40:34.640+00:00",
    createDate: "2023-08-16T05:52:33.358+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "These stylish bangles are versatile, and you can wear them with multiple outfits.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 238.31,
    cashPrice: 278.20688,
    taxAll: 32.1318,
    taxInclude: 294.2728,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 490.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 83,
    p_adminid: 2,
    productCategory: { id: 83, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 83,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5462,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5463,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5464,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5465,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5466,
        lbrId: 789,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 5467,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 83, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 83,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 83,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 83,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2655,
        imgId: 212,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133100.jpg",
        cropName: "20170614_133100.jpg",
      },
      {
        id: 2656,
        imgId: 214,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133312.jpg",
        cropName: "20170614_133312.jpg",
      },
      {
        id: 2657,
        imgId: 216,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133430.jpg",
        cropName: "20170614_133430.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:42:55.529+00:00",
    createDate: "2023-08-16T05:54:17.837+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Imitation bangles online and traditional bangles online to antique bangles online, find everything under one platform!",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 446.77002,
    cashPrice: 521.4323,
    taxAll: 59.9706,
    taxInclude: 551.4176,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 84,
    p_adminid: 2,
    productCategory: { id: 84, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 84,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5486,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5487,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5488,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5489,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5490,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5491,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5492,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 84, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 84,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 84,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 84,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2670,
        imgId: 223,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113437.jpg",
        cropName: "20170721_113437.jpg",
      },
      {
        id: 2671,
        imgId: 224,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113537.jpg",
        cropName: "20170721_113537.jpg",
      },
      {
        id: 2672,
        imgId: 225,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113654.jpg",
        cropName: "20170721_113654.jpg",
      },
      {
        id: 2673,
        imgId: 229,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114024.jpg",
        cropName: "20170721_114024.jpg",
      },
      {
        id: 2674,
        imgId: 231,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114222.jpg",
        cropName: "20170721_114222.jpg",
      },
      {
        id: 2675,
        imgId: 233,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114344.jpg",
        cropName: "20170721_114344.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:57:08.278+00:00",
    createDate: "2023-08-16T05:56:34.218+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "we are committed to offering our customers the best quality Brass Bangles at affordable prices. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 365.17,
    cashPrice: 429.63232,
    taxAll: 55.890602,
    taxInclude: 457.5776,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 85,
    p_adminid: 2,
    productCategory: { id: 85, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 85,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5480,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5481,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5482,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5483,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5484,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5485,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 85, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 85,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 85,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 85,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2664,
        imgId: 226,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113736.jpg",
        cropName: "20170721_113736.jpg",
      },
      {
        id: 2665,
        imgId: 227,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113825.jpg",
        cropName: "20170721_113825.jpg",
      },
      {
        id: 2666,
        imgId: 228,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113911.jpg",
        cropName: "20170721_113911.jpg",
      },
      {
        id: 2667,
        imgId: 230,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114108.jpg",
        cropName: "20170721_114108.jpg",
      },
      {
        id: 2668,
        imgId: 232,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114244.jpg",
        cropName: "20170721_114244.jpg",
      },
      {
        id: 2669,
        imgId: 234,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114420.jpg",
        cropName: "20170721_114420.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:54:44.855+00:00",
    createDate: "2023-08-16T05:57:56.991+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "We understand that gold-plated brass Bangles are not just an accessory but a symbol of beauty and elegance. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 193.91,
    cashPrice: 228.25691,
    taxAll: 29.9118,
    taxInclude: 243.21281,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 490.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 86,
    p_adminid: 2,
    productCategory: { id: 86, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 86,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5389,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5390,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5391,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5392,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5393,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5394,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 86, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 86,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 86,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 86,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2628,
        imgId: 235,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_161036.jpg",
        cropName: "20180127_161036.jpg",
      },
      {
        id: 2629,
        imgId: 236,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_124445.jpg",
        cropName: "20180128_124445.jpg",
      },
      {
        id: 2630,
        imgId: 237,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_124532.jpg",
        cropName: "20180128_124532.jpg",
      },
      {
        id: 2631,
        imgId: 238,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_124609.jpg",
        cropName: "20180128_124609.jpg",
      },
    ],
    modifyDate: "2023-09-08T08:18:39.302+00:00",
    createDate: "2023-08-16T06:00:11.743+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "To determine the correct size of a pair of bangles, you will need to measure the circumference of your wrist.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 306.45,
    cashPrice: 360.5155,
    taxAll: 46.841,
    taxInclude: 383.936,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 312.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 87,
    p_adminid: 2,
    productCategory: { id: 87, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 87,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 642,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 643,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 644,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 645,
        lbrId: 785,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 646,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 87, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 87,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 87,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 87,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 321,
        imgId: 239,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0007.jpg",
        cropName: "20180213-WA0007.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-16T06:01:31.611+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      " These bangles are made of Brass metal and have intricate designs inspired by traditional Indian jewellery.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 369.65,
    cashPrice: 437.3035,
    taxAll: 61.377003,
    taxInclude: 467.992,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 88,
    p_adminid: 2,
    productCategory: { id: 88, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 88,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5500,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5501,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5502,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5503,
        lbrId: 785,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 5504,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5505,
        lbrId: 786,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 5506,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5507,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 88, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 88,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 88,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 88,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2682,
        imgId: 240,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_093622.jpg",
        cropName: "20190204_093622.jpg",
      },
      {
        id: 2683,
        imgId: 241,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_093739.jpg",
        cropName: "20190204_093739.jpg",
      },
      {
        id: 2684,
        imgId: 242,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094055.jpg",
        cropName: "20190204_094055.jpg",
      },
      {
        id: 2685,
        imgId: 243,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094141.jpg",
        cropName: "20190204_094141.jpg",
      },
      {
        id: 2686,
        imgId: 244,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094229.jpg",
        cropName: "20190204_094229.jpg",
      },
      {
        id: 2687,
        imgId: 245,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094331.jpg",
        cropName: "20190204_094331.jpg",
      },
    ],
    modifyDate: "2023-09-08T09:49:58.814+00:00",
    createDate: "2023-08-16T06:02:44.036+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "They are popular for weddings, religious ceremonies, and other formal events. However, they are also worn daily by people.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 306.45,
    cashPrice: 360.5155,
    taxAll: 46.841,
    taxInclude: 383.936,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 312.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 89,
    p_adminid: 2,
    productCategory: { id: 89, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 89,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5508,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5509,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5510,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5511,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5512,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5513,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5514,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 89, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 89,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 89,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 89,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2688,
        imgId: 246,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094454.jpg",
        cropName: "20190204_094454.jpg",
      },
      {
        id: 2689,
        imgId: 247,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094610.jpg",
        cropName: "20190204_094610.jpg",
      },
      {
        id: 2690,
        imgId: 248,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094653.jpg",
        cropName: "20190204_094653.jpg",
      },
      {
        id: 2691,
        imgId: 249,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094744.jpg",
        cropName: "20190204_094744.jpg",
      },
      {
        id: 2692,
        imgId: 250,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094840.jpg",
        cropName: "20190204_094840.jpg",
      },
      {
        id: 2693,
        imgId: 251,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_094917.jpg",
        cropName: "20190204_094917.jpg",
      },
    ],
    modifyDate: "2023-09-08T09:54:54.313+00:00",
    createDate: "2023-08-16T06:03:52.879+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Gold-plated brass bangles are a great choice whether you're wearing a saree or a Suit outfit.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 306.45,
    cashPrice: 360.5155,
    taxAll: 46.841,
    taxInclude: 383.936,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 312.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 90,
    p_adminid: 2,
    productCategory: { id: 90, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 90,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5515,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5516,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5517,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5518,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5519,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5520,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5521,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 90, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 90,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 90,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 90,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2694,
        imgId: 252,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_102001.jpg",
        cropName: "20190204_102001.jpg",
      },
      {
        id: 2695,
        imgId: 253,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_102049.jpg",
        cropName: "20190204_102049.jpg",
      },
      {
        id: 2696,
        imgId: 254,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_102131.jpg",
        cropName: "20190204_102131.jpg",
      },
      {
        id: 2697,
        imgId: 255,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_102210.jpg",
        cropName: "20190204_102210.jpg",
      },
      {
        id: 2698,
        imgId: 256,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_102304.jpg",
        cropName: "20190204_102304.jpg",
      },
      {
        id: 2699,
        imgId: 257,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_102346.jpg",
        cropName: "20190204_102346.jpg",
      },
    ],
    modifyDate: "2023-09-08T09:58:29.437+00:00",
    createDate: "2023-08-16T06:05:42.041+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "we offer a wide range of bangles that can add a touch of glamour to your wardrobe.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 324.75,
    cashPrice: 381.9025,
    taxAll: 49.355003,
    taxInclude: 406.58002,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 91,
    p_adminid: 2,
    productCategory: { id: 91, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 91,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5493,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5494,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5495,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5496,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5497,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5498,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5499,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 91, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 91,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 91,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 91,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2676,
        imgId: 258,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_104334.jpg",
        cropName: "20190204_104334.jpg",
      },
      {
        id: 2677,
        imgId: 259,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_104427.jpg",
        cropName: "20190204_104427.jpg",
      },
      {
        id: 2678,
        imgId: 260,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_104527.jpg",
        cropName: "20190204_104527.jpg",
      },
      {
        id: 2679,
        imgId: 261,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_104625.jpg",
        cropName: "20190204_104625.jpg",
      },
      {
        id: 2680,
        imgId: 262,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_104729.jpg",
        cropName: "20190204_104729.jpg",
      },
      {
        id: 2681,
        imgId: 263,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_104826.jpg",
        cropName: "20190204_104826.jpg",
      },
    ],
    modifyDate: "2023-09-08T09:45:50.382+00:00",
    createDate: "2023-08-16T06:07:32.097+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Our collection includes both traditional and modern designs to suit your style and occasion. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 306.45,
    cashPrice: 360.5155,
    taxAll: 46.841,
    taxInclude: 383.936,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 312.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 92,
    p_adminid: 2,
    productCategory: { id: 92, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 92,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 9199,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 9200,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9201,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 9202,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 9203,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 9204,
        lbrId: 780,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 9205,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 9206,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 92, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 92,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 92,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 92,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4311,
        imgId: 264,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_105011.jpg",
        cropName: "20190204_105011.jpg",
      },
      {
        id: 4312,
        imgId: 265,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_105107.jpg",
        cropName: "20190204_105107.jpg",
      },
      {
        id: 4313,
        imgId: 266,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_105202.jpg",
        cropName: "20190204_105202.jpg",
      },
      {
        id: 4314,
        imgId: 267,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_105244.jpg",
        cropName: "20190204_105244.jpg",
      },
      {
        id: 4315,
        imgId: 268,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_105244.jpg",
        cropName: "20190204_105244.jpg",
      },
      {
        id: 4316,
        imgId: 269,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_105352.jpg",
        cropName: "20190204_105352.jpg",
      },
    ],
    modifyDate: "2023-12-01T05:27:48.500+00:00",
    createDate: "2023-08-16T06:09:00.405+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: 7117,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "our latest bangles designs and compare prices and styles from the comfort of your home.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 361.05,
    cashPrice: 423.5395,
    taxAll: 52.769,
    taxInclude: 449.924,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 93,
    p_adminid: 2,
    productCategory: { id: 93, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 93,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 672,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 673,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 674,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 675,
        lbrId: 785,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 676,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 93, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 93,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 93,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 93,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 352,
        imgId: 270,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_105441.jpg",
        cropName: "20190204_105441.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-16T06:10:07.762+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "you are looking for simple everyday wear or a bold statement piece, our bangles are versatile and can elevate any outfit.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 235.04999,
    cashPrice: 277.8895,
    taxAll: 38.669,
    taxInclude: 297.224,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 94,
    p_adminid: 2,
    productCategory: { id: 94, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 94,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5530,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5531,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5532,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5533,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5534,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5535,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5536,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 94, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 94,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 94,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 94,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2706,
        imgId: 271,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190510_132118.jpg",
        cropName: "20190510_132118.jpg",
      },
    ],
    modifyDate: "2023-09-08T10:21:42.009+00:00",
    createDate: "2023-08-16T06:11:00.448+00:00",
    metalGram: 0.007,
    extraGram: 5.0e-4,
    readyGram: 0.0045,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 287.85,
    cashPrice: 337.99152,
    taxAll: 42.712997,
    taxInclude: 359.34802,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 95,
    p_adminid: 2,
    productCategory: { id: 95, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 95,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 682,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 683,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 684,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 685,
        lbrId: 785,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 686,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 95, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 95,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 95,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 95,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 354,
        imgId: 272,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20191106-WA0006.jpg",
        cropName: "20191106-WA0006.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-16T06:11:49.102+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription: "3",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 235.04999,
    cashPrice: 277.8895,
    taxAll: 38.669,
    taxInclude: 297.224,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 96,
    p_adminid: 2,
    productCategory: { id: 96, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 96,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 9207,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 9208,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9209,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 9210,
        lbrId: 787,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 9211,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 96, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 96,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 96,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 96,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4317,
        imgId: 273,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151512.jpg",
        cropName: "20211006_151512.jpg",
      },
    ],
    modifyDate: "2023-12-01T05:29:00.106+00:00",
    createDate: "2023-08-16T06:13:06.596+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: 7117,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Our quality and craftsmanship ensure that our bangles are durable and long-lasting, making them a valuable addition to your jewellery collection.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 276.11,
    cashPrice: 325.6819,
    taxAll: 43.9218,
    taxInclude: 347.64276,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 97,
    p_adminid: 2,
    productCategory: { id: 97, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 97,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6686,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6687,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6688,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6689,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6690,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6691,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 97, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 97,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 97,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 97,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3312,
        imgId: 274,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/5016.jpg",
        cropName: "5016.jpg",
      },
    ],
    modifyDate: "2023-09-09T14:24:16.084+00:00",
    createDate: "2023-08-16T06:16:36.138+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "The most beautifully crafted and latest collection of Gold Plated Brass Bangles for Women and Girls.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 323.72,
    cashPrice: 381.17682,
    taxAll: 50.1696,
    taxInclude: 406.2616,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 265.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 98,
    p_adminid: 2,
    productCategory: { id: 98, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 98,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5537,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5538,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5539,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 5540,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5541,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5542,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5543,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5544,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5545,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5546,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 98, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 98,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 98,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 98,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2707,
        imgId: 275,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_193550.jpg",
        cropName: "20150910_193550.jpg",
      },
      {
        id: 2708,
        imgId: 276,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_193608.jpg",
        cropName: "20150910_193608.jpg",
      },
      {
        id: 2709,
        imgId: 277,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_193631.jpg",
        cropName: "20150910_193631.jpg",
      },
    ],
    modifyDate: "2023-09-08T10:28:35.072+00:00",
    createDate: "2023-08-16T06:18:28.934+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Micron plated High finish three tone plated bangle fashion jewellery women daily wear the trendy bracelet.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 645.7,
    cashPrice: 741.813,
    taxAll: 63.086,
    taxInclude: 773.356,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 312.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 99,
    p_adminid: 2,
    productCategory: { id: 99, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 99,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5547,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5548,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 5549,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5550,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5551,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5552,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 99, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 99,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 99,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 99,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2710,
        imgId: 278,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_224442.jpg",
        cropName: "20160203_224442.jpg",
      },
      {
        id: 2711,
        imgId: 279,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_224914.jpg",
        cropName: "20160203_224914.jpg",
      },
      {
        id: 2712,
        imgId: 280,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_225107.jpg",
        cropName: "20160203_225107.jpg",
      },
      {
        id: 2713,
        imgId: 281,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_225217.jpg",
        cropName: "20160203_225217.jpg",
      },
      {
        id: 2714,
        imgId: 282,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_225614.jpg",
        cropName: "20160203_225614.jpg",
      },
    ],
    modifyDate: "2023-09-08T10:32:36.524+00:00",
    createDate: "2023-08-16T06:19:49.620+00:00",
    metalGram: 0.013,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "We are offering a wide range of 3-tone plated bangles. These bangles are made by Micron Gold Plated Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 669.45,
    cashPrice: 776.6355,
    taxAll: 80.481,
    taxInclude: 816.87604,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 192.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 100,
    p_adminid: 2,
    productCategory: { id: 100, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 100,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5565,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5566,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5567,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5568,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5569,
        lbrId: 888,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 5570,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 100, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 100,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 100,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 100,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2717,
        imgId: 283,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_153932.jpg",
        cropName: "20160204_153932.jpg",
      },
    ],
    modifyDate: "2023-09-08T10:39:22.160+00:00",
    createDate: "2023-08-16T06:25:48.867+00:00",
    metalGram: 0.013,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Stylish Gold Plated Bangles Elevate Your Style Shop Online.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.55,
    cashPrice: 296.10452,
    taxAll: 38.799,
    taxInclude: 315.50403,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 385.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 101,
    p_adminid: 2,
    productCategory: { id: 101, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 101,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 712,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 713,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 714,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 715,
        lbrId: 884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 716,
        lbrId: 901,
        price: 1.85,
        taxPercentage: 5.0,
        tax: 0.0925,
        title: "Plating Gold Plated & Chrome",
      },
    ],
    productSets: { id: 101, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 101,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 101,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 101,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 366,
        imgId: 284,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_160917.jpg",
        cropName: "20160204_160917.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-16T06:28:04.864+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: " Gold Plated Traditional Gold Bangles Design for Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 307.84998,
    cashPrice: 359.61447,
    taxAll: 41.958996,
    taxInclude: 380.59396,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 102,
    p_adminid: 2,
    productCategory: { id: 102, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 102,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5768,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5769,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5770,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5771,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5772,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5773,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5774,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5775,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5776,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 102, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 102,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 102,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 102,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2806,
        imgId: 287,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_215307.jpg",
        cropName: "20160510_215307.jpg",
      },
      {
        id: 2807,
        imgId: 289,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_215600.jpg",
        cropName: "20160510_215600.jpg",
      },
      {
        id: 2808,
        imgId: 291,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_215755.jpg",
        cropName: "20160510_215755.jpg",
      },
      {
        id: 2809,
        imgId: 285,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_214724.jpg",
        cropName: "20160510_214724.jpg",
      },
      {
        id: 2810,
        imgId: 293,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_220009.jpg",
        cropName: "20160510_220009.jpg",
      },
      {
        id: 2811,
        imgId: 295,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_220448.jpg",
        cropName: "20160510_220448.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:21:19.781+00:00",
    createDate: "2023-08-16T06:30:15.941+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Gold Plated Brass Bangles Accessories In wedding wear.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1109.1001,
    cashPrice: 1275.0266,
    taxAll: 110.03301,
    taxInclude: 1330.0431,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 179.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 103,
    p_adminid: 2,
    productCategory: { id: 103, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 103,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5759,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5760,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5761,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5762,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5763,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5764,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5765,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5766,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5767,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 103, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 103,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 103,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 103,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2804,
        imgId: 299,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_220858.jpg",
        cropName: "20160510_220858.jpg",
      },
      {
        id: 2805,
        imgId: 297,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_220655.jpg",
        cropName: "20160510_220655.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:20:34.420+00:00",
    createDate: "2023-08-16T06:31:28.145+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Indian Traditional South Indian Gold Plated Brass Bangles Bangle Set For Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1109.1001,
    cashPrice: 1275.0266,
    taxAll: 110.03301,
    taxInclude: 1330.0431,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 179.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 104,
    p_adminid: 2,
    productCategory: { id: 104, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 104,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5614,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5615,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5616,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5617,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5618,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5619,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5620,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5621,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5622,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 104, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 104,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 104,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 104,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2739,
        imgId: 286,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_214807.jpg",
        cropName: "20160510_214807.jpg",
      },
      {
        id: 2740,
        imgId: 288,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_215342.jpg",
        cropName: "20160510_215342.jpg",
      },
      {
        id: 2741,
        imgId: 290,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_215653.jpg",
        cropName: "20160510_215653.jpg",
      },
      {
        id: 2742,
        imgId: 292,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_215826.jpg",
        cropName: "20160510_215826.jpg",
      },
      {
        id: 2743,
        imgId: 294,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_220106.jpg",
        cropName: "20160510_220106.jpg",
      },
      {
        id: 2744,
        imgId: 296,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_220541.jpg",
        cropName: "20160510_220541.jpg",
      },
    ],
    modifyDate: "2023-09-08T11:39:32.280+00:00",
    createDate: "2023-08-16T06:33:18.116+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "We take pleasure in introducing ourselves as one of the leading manufacturers, exporters and suppliers of Designer Gold Plated Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 330.1,
    cashPrice: 379.05402,
    taxAll: 31.888,
    taxInclude: 394.99802,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 105,
    p_adminid: 2,
    productCategory: { id: 105, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 105,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5669,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5670,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5671,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5672,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5673,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5674,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5675,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5676,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5677,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 105, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 105,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 105,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 105,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2763,
        imgId: 298,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_220739.jpg",
        cropName: "20160510_220739.jpg",
      },
      {
        id: 2764,
        imgId: 300,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_220926.jpg",
        cropName: "20160510_220926.jpg",
      },
    ],
    modifyDate: "2023-09-08T11:49:00.149+00:00",
    createDate: "2023-08-16T06:34:23.432+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "We are manufacturer and exporter of all type of 3-Tone Bangle.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 330.1,
    cashPrice: 379.05402,
    taxAll: 31.888,
    taxInclude: 394.99802,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 106,
    p_adminid: 2,
    productCategory: { id: 106, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 106,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5724,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5725,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5726,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5727,
        lbrId: 904,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 5728,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5729,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5730,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5731,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5732,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 106, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 106,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 106,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 106,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2785,
        imgId: 301,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_221944.jpg",
        cropName: "20160510_221944.jpg",
      },
      {
        id: 2786,
        imgId: 302,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_223742.jpg",
        cropName: "20160510_223742.jpg",
      },
      {
        id: 2787,
        imgId: 303,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_223906.jpg",
        cropName: "20160510_223906.jpg",
      },
      {
        id: 2788,
        imgId: 304,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_224034.jpg",
        cropName: "20160510_224034.jpg",
      },
      {
        id: 2789,
        imgId: 305,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_224330.jpg",
        cropName: "20160510_224330.jpg",
      },
      {
        id: 2790,
        imgId: 306,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_224437.jpg",
        cropName: "20160510_224437.jpg",
      },
    ],
    modifyDate: "2023-09-08T11:56:14.967+00:00",
    createDate: "2023-08-19T05:48:39.565+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Daily use Micro Gold Plated Bangles, premium quality brass.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1074.1001,
    cashPrice: 1235.6516,
    taxAll: 108.283005,
    taxInclude: 1289.7931,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 179.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 107,
    p_adminid: 2,
    productCategory: { id: 107, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 107,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5777,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5778,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5779,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5780,
        lbrId: 904,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 5781,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5782,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5783,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5784,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5785,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 107, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 107,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 107,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 107,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2812,
        imgId: 307,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_224537.jpg",
        cropName: "20160510_224537.jpg",
      },
      {
        id: 2813,
        imgId: 308,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_224805.jpg",
        cropName: "20160510_224805.jpg",
      },
      {
        id: 2814,
        imgId: 309,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_225314.jpg",
        cropName: "20160510_225314.jpg",
      },
      {
        id: 2815,
        imgId: 310,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_225714.jpg",
        cropName: "20160510_225714.jpg",
      },
      {
        id: 2816,
        imgId: 311,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_230005.jpg",
        cropName: "20160510_230005.jpg",
      },
      {
        id: 2817,
        imgId: 312,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_231326.jpg",
        cropName: "20160510_231326.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:23:31.505+00:00",
    createDate: "2023-08-19T05:57:01.181+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Micro Gold Plated Daily Wear Designer Cutting Bangles can be worn every day or for a special occasion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1074.1001,
    cashPrice: 1235.6516,
    taxAll: 108.283005,
    taxInclude: 1289.7931,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 179.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 108,
    p_adminid: 2,
    productCategory: { id: 108, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 108,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5786,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5787,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5788,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5789,
        lbrId: 904,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 5790,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5791,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5792,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5793,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5794,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 108, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 108,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 108,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 108,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2818,
        imgId: 313,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160510_231424.jpg",
        cropName: "20160510_231424.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:24:20.115+00:00",
    createDate: "2023-08-19T05:58:56.882+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This pair of thin bangles is ideal for sarees and other traditional costumes as a pair or set.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1074.1001,
    cashPrice: 1235.6516,
    taxAll: 108.283005,
    taxInclude: 1289.7931,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 179.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 109,
    p_adminid: 2,
    productCategory: { id: 109, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 109,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 757,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 758,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 759,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 760,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 761,
        lbrId: 904,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Plating Micro Gold\r\n",
      },
    ],
    productSets: { id: 109, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 109,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 109,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 109,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 402,
        imgId: 314,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_132522.jpg",
        cropName: "20160914_132522.jpg",
      },
      {
        id: 403,
        imgId: 315,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_132817.jpg",
        cropName: "20160914_132817.jpg",
      },
      {
        id: 404,
        imgId: 316,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_132915.jpg",
        cropName: "20160914_132915.jpg",
      },
      {
        id: 405,
        imgId: 317,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133021.jpg",
        cropName: "20160914_133021.jpg",
      },
      {
        id: 406,
        imgId: 318,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133117.jpg",
        cropName: "20160914_133117.jpg",
      },
      {
        id: 407,
        imgId: 319,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133200.jpg",
        cropName: "20160914_133200.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T06:03:12.559+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Our designer brass bangles can be worn with any kind of attire as they are available in various attractive designs and styles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 400.25,
    cashPrice: 463.56448,
    taxAll: 46.578995,
    taxInclude: 486.854,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 110,
    p_adminid: 2,
    productCategory: { id: 110, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 110,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5795,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5796,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5797,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5798,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5799,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5800,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 5801,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 5802,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 5803,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 110, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 110,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 110,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 110,
      adminId: 2,
      packingmaterialId: 111,
      type: "Polythene PP",
      pkgdesc: "5 Pcs",
      hsncode: "39202020",
      price: 1.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2819,
        imgId: 320,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161023_135158.jpg",
        cropName: "20161023_135158.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:29:53.165+00:00",
    createDate: "2023-08-19T06:05:21.917+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      'Gold Plated Brass Bangles With Traditional Look For Woman " online at best prices in India.',
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 787.0,
    cashPrice: 904.63,
    taxAll: 77.86,
    taxInclude: 943.56,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 111,
    p_adminid: 2,
    productCategory: { id: 111, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 111,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5838,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5839,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5840,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 5841,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 5842,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 111, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 111,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 111,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 111,
      adminId: 2,
      packingmaterialId: 111,
      type: "Polythene PP",
      pkgdesc: "5 Pcs",
      hsncode: "39202020",
      price: 1.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2840,
        imgId: 321,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_102851.jpg",
        cropName: "20161024_102851.jpg",
      },
      {
        id: 2841,
        imgId: 323,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_104630.jpg",
        cropName: "20161024_104630.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:50:53.914+00:00",
    createDate: "2023-08-19T06:06:50.480+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "3-Tone Plated Daily Wear Designer Cutting Bracelet can be worn every day or for a special occasion. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 743.75,
    cashPrice: 859.5,
    taxAll: 82.75,
    taxInclude: 900.875,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 182.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 112,
    p_adminid: 2,
    productCategory: { id: 112, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 112,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5843,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5844,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5845,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5846,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5847,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5848,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 5849,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 112, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 112,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 112,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 112,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2842,
        imgId: 324,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_104715.jpg",
        cropName: "20161024_104715.jpg",
      },
      {
        id: 2843,
        imgId: 322,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_102927.jpg",
        cropName: "20161024_102927.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:51:22.989+00:00",
    createDate: "2023-08-19T06:08:46.627+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "3-Tone Plated Daily Wear Designer Cutting Bracelet can be worn every day or for a special occasion. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 635.4,
    cashPrice: 733.846,
    taxAll: 69.812004,
    taxInclude: 768.752,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 217.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 113,
    p_adminid: 2,
    productCategory: { id: 113, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 113,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5804,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5805,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5806,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5807,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5808,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5809,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 113, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 113,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 113,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 113,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2820,
        imgId: 325,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123_135157.jpg",
        cropName: "20170123_135157.jpg",
      },
      {
        id: 2821,
        imgId: 326,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123_135315.jpg",
        cropName: "20170123_135315.jpg",
      },
      {
        id: 2822,
        imgId: 327,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123_135410.jpg",
        cropName: "20170123_135410.jpg",
      },
      {
        id: 2823,
        imgId: 328,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123_135514.jpg",
        cropName: "20170123_135514.jpg",
      },
      {
        id: 2824,
        imgId: 329,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123_135604.jpg",
        cropName: "20170123_135604.jpg",
      },
      {
        id: 2825,
        imgId: 330,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123_135654.jpg",
        cropName: "20170123_135654.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:32:41.193+00:00",
    createDate: "2023-08-19T06:11:19.749+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Their striking look and elegant design at reasonable price.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 221.56998,
    cashPrice: 260.78827,
    taxAll: 34.122597,
    taxInclude: 277.84958,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 114,
    p_adminid: 2,
    productCategory: { id: 114, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 114,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5856,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5857,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5858,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5859,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5860,
        lbrId: 876,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 5861,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 114, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 114,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 114,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 114,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2848,
        imgId: 331,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123_135746.jpg",
        cropName: "20170123_135746.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:55:09.040+00:00",
    createDate: "2023-08-19T06:13:15.518+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These bangles are perfect for weddings, festivals, and other special occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 370.33,
    cashPrice: 436.09668,
    taxAll: 57.4674,
    taxInclude: 464.83038,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 115,
    p_adminid: 2,
    productCategory: { id: 115, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 115,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6015,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6016,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6017,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6018,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6019,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6020,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 115, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 115,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 115,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 115,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2915,
        imgId: 332,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_181409.jpg",
        cropName: "20171013_181409.jpg",
      },
      {
        id: 2916,
        imgId: 333,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_183403.jpg",
        cropName: "20171013_183403.jpg",
      },
      {
        id: 2917,
        imgId: 335,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123-WA0029.jpg",
        cropName: "20170123-WA0029.jpg",
      },
      {
        id: 2918,
        imgId: 336,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123-WA0030.jpg",
        cropName: "20170123-WA0030.jpg",
      },
      {
        id: 2919,
        imgId: 337,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_183123.jpg",
        cropName: "20171013_183123.jpg",
      },
    ],
    modifyDate: "2023-09-09T06:08:49.295+00:00",
    createDate: "2023-08-19T06:15:04.491+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      " This stunning set of four bangles is made of high-quality brass with gold plated and has a smooth finish that feels comfortable on your wrist.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 335.05,
    cashPrice: 394.0395,
    taxAll: 50.969,
    taxInclude: 419.524,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 116,
    p_adminid: 2,
    productCategory: { id: 116, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 116,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5830,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5831,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5832,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5833,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5834,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5835,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5836,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 5837,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 116, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 116,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 116,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 116,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2836,
        imgId: 343,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_161335.jpg",
        cropName: "20170217_161335.jpg",
      },
      {
        id: 2837,
        imgId: 341,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_154745.jpg",
        cropName: "20170217_154745.jpg",
      },
      {
        id: 2838,
        imgId: 339,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_154033.jpg",
        cropName: "20170217_154033.jpg",
      },
      {
        id: 2839,
        imgId: 344,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_161620.jpg",
        cropName: "20170217_161620.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:49:15.628+00:00",
    createDate: "2023-08-19T06:18:03.864+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "They will match any Western or fusion outfit and add a touch of glamour to your look.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1111.55,
    cashPrice: 1282.7196,
    taxAll: 120.02899,
    taxInclude: 1342.7341,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 130.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 117,
    p_adminid: 2,
    productCategory: { id: 117, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 117,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5850,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5851,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5852,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5853,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5854,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5855,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
    ],
    productSets: { id: 117, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 117,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 117,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 117,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2844,
        imgId: 342,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_154834.jpg",
        cropName: "20170217_154834.jpg",
      },
      {
        id: 2845,
        imgId: 340,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_154625.jpg",
        cropName: "20170217_154625.jpg",
      },
      {
        id: 2846,
        imgId: 345,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_161420.jpg",
        cropName: "20170217_161420.jpg",
      },
      {
        id: 2847,
        imgId: 346,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_161656.jpg",
        cropName: "20170217_161656.jpg",
      },
    ],
    modifyDate: "2023-09-08T12:52:48.763+00:00",
    createDate: "2023-08-19T06:19:48.264+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These versatile, elegant 3-tone designer bangles are classic and traditional but in fashion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 617.1,
    cashPrice: 712.459,
    taxAll: 67.298004,
    taxInclude: 746.10803,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 227.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 118,
    p_adminid: 2,
    productCategory: { id: 118, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 118,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5870,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5871,
        lbrId: 904,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 5872,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5873,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5874,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5875,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5876,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 118, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 118,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 118,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 118,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2850,
        imgId: 347,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170218_104213.jpg",
        cropName: "20170218_104213.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:06:38.413+00:00",
    createDate: "2023-08-19T06:22:54.338+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This wedding bangle is apt for weddings, engagements and casual occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1034.55,
    cashPrice: 1196.0945,
    taxAll: 116.17899,
    taxInclude: 1254.184,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 130.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 119,
    p_adminid: 2,
    productCategory: { id: 119, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 119,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5884,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5885,
        lbrId: 905,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Plating Rose Gold\r\n",
      },
      {
        id: 5886,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5887,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5888,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5889,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5890,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 119, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 119,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 119,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 119,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2852,
        imgId: 349,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170218_104513.jpg",
        cropName: "20170218_104513.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:14:09.163+00:00",
    createDate: "2023-08-19T06:27:36.645+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This pair of beautiful Rose gold-plated bangles can be worn every day or for a special occasion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1034.55,
    cashPrice: 1196.0945,
    taxAll: 116.17899,
    taxInclude: 1254.184,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 130.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 120,
    p_adminid: 2,
    productCategory: { id: 120, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 120,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5877,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5878,
        lbrId: 903,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Plating Rhodium\r\n",
      },
      {
        id: 5879,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5880,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5881,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5882,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5883,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 120, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 120,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 120,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 120,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2851,
        imgId: 348,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170218_104331.jpg",
        cropName: "20170218_104331.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:11:22.857+00:00",
    createDate: "2023-08-19T06:29:18.965+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      " Rhodium Plated Bangle Gold Costume Bracelets and get the best deals at the lowest prices.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 824.55,
    cashPrice: 959.8445,
    taxAll: 105.67899,
    taxInclude: 1012.684,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 130.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 121,
    p_adminid: 2,
    productCategory: { id: 121, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 121,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5891,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5892,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5893,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5894,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5895,
        lbrId: 907,
        price: 5.5,
        taxPercentage: 5.0,
        tax: 0.275,
        title: "Plating Tri Color",
      },
      {
        id: 5896,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5897,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 121, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 121,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 121,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 121,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2853,
        imgId: 350,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170218_104714.jpg",
        cropName: "20170218_104714.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:20:05.693+00:00",
    createDate: "2023-08-19T06:30:37.635+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This pair of beautiful 3 tone bangles can be worn every day or for a special occasion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 999.55,
    cashPrice: 1156.7195,
    taxAll: 114.42899,
    taxInclude: 1213.934,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 130.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 122,
    p_adminid: 2,
    productCategory: { id: 122, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 122,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 8677,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 8678,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8679,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 8680,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8681,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 8682,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 8683,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 8684,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 122, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 122,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 122,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 122,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4092,
        imgId: 351,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_183820.jpg",
        cropName: "20171013_183820.jpg",
      },
      {
        id: 4093,
        imgId: 352,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_184234.jpg",
        cropName: "20171013_184234.jpg",
      },
      {
        id: 4094,
        imgId: 353,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_184130.jpg",
        cropName: "20171013_184130.jpg",
      },
      {
        id: 4095,
        imgId: 354,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_183945.jpg",
        cropName: "20171013_183945.jpg",
      },
      {
        id: 4096,
        imgId: 355,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_183659.jpg",
        cropName: "20171013_183659.jpg",
      },
      {
        id: 4097,
        imgId: 356,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_184349.jpg",
        cropName: "20171013_184349.jpg",
      },
    ],
    modifyDate: "2023-09-16T08:33:18.974+00:00",
    createDate: "2023-08-19T06:32:09.815+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These designer gold Plated Bangles are highly demanded and appreciated by our precious clients.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 258.40997,
    cashPrice: 303.14587,
    taxAll: 37.7898,
    taxInclude: 322.04077,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 379.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 127,
    p_adminid: 2,
    productCategory: { id: 127, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 127,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 851,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 852,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 853,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 854,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 855,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 127, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 127,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 127,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 127,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 460,
        imgId: 372,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222326.jpg",
        cropName: "20190821_222326.jpg",
      },
      {
        id: 461,
        imgId: 373,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222415.jpg",
        cropName: "20190821_222415.jpg",
      },
      {
        id: 462,
        imgId: 374,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222540.jpg",
        cropName: "20190821_222540.jpg",
      },
      {
        id: 463,
        imgId: 375,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222808.jpg",
        cropName: "20190821_222808.jpg",
      },
      {
        id: 464,
        imgId: 376,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_155607.jpg",
        cropName: "20180127_155607.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T07:13:18.542+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "The complete range of bangles is used to match with sarees and suits on festivals, marriages and other special occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.84999,
    cashPrice: 280.86447,
    taxAll: 38.458996,
    taxInclude: 300.09396,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 128,
    p_adminid: 2,
    productCategory: { id: 128, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 128,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5906,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5907,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5908,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5909,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5910,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5911,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 5912,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 128, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 128,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 128,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 128,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2860,
        imgId: 376,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_155607.jpg",
        cropName: "20180127_155607.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:31:44.657+00:00",
    createDate: "2023-08-19T07:15:14.325+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: " Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "The gold plated brass bangles is a wardrobe essential for those who are in love with the ethnics.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 383.94998,
    cashPrice: 451.45047,
    taxAll: 58.211,
    taxInclude: 480.55597,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 238.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 129,
    p_adminid: 2,
    productCategory: { id: 129, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 129,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5913,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5914,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5915,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5916,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5917,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5918,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 5919,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 129, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 129,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 129,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 129,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2861,
        imgId: 377,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_155648.jpg",
        cropName: "20180127_155648.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:34:21.160+00:00",
    createDate: "2023-08-19T07:16:37.145+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Ladies Party Wear Light weighted Gold-Plated Brass Traditional Artificial Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 332.72,
    cashPrice: 391.30182,
    taxAll: 50.619602,
    taxInclude: 416.6116,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 265.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 130,
    p_adminid: 2,
    productCategory: { id: 130, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 130,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5938,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5939,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5940,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5941,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5942,
        lbrId: 876,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 5943,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5944,
        lbrId: 914,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Adjusted 1\r\n",
      },
      {
        id: 5945,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 130, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 130,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 130,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 130,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2874,
        imgId: 378,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_155818.jpg",
        cropName: "20180127_155818.jpg",
      },
      {
        id: 2875,
        imgId: 381,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160128.jpg",
        cropName: "20180127_160128.jpg",
      },
      {
        id: 2876,
        imgId: 384,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160524.jpg",
        cropName: "20180127_160524.jpg",
      },
      {
        id: 2877,
        imgId: 387,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160814.jpg",
        cropName: "20180127_160814.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:45:04.768+00:00",
    createDate: "2023-08-19T07:18:25.249+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This bangle is made of high-quality brass wire, which is very durable and strong. The polished gold plated finish.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 377.53,
    cashPrice: 444.1967,
    taxAll: 57.8274,
    taxInclude: 473.11038,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 131,
    p_adminid: 2,
    productCategory: { id: 131, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 131,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5926,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5927,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5928,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5929,
        lbrId: 876,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 5930,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5931,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 131, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 131,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 131,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 131,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2866,
        imgId: 379,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_155906.jpg",
        cropName: "20180127_155906.jpg",
      },
      {
        id: 2867,
        imgId: 382,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160239.jpg",
        cropName: "20180127_160239.jpg",
      },
      {
        id: 2868,
        imgId: 385,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160622.jpg",
        cropName: "20180127_160622.jpg",
      },
      {
        id: 2869,
        imgId: 388,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160909.jpg",
        cropName: "20180127_160909.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:40:57.668+00:00",
    createDate: "2023-08-19T07:20:04.757+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "It shiny and smooth to wear. It's perfect for any occasion like parties and wedding.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 283.31,
    cashPrice: 333.5569,
    taxAll: 43.8318,
    taxInclude: 355.47278,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 132,
    p_adminid: 2,
    productCategory: { id: 132, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 132,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5932,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5933,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5934,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5935,
        lbrId: 876,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 5936,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5937,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 132, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 132,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 132,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 132,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2870,
        imgId: 380,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_155950.jpg",
        cropName: "20180127_155950.jpg",
      },
      {
        id: 2871,
        imgId: 383,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160321.jpg",
        cropName: "20180127_160321.jpg",
      },
      {
        id: 2872,
        imgId: 386,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160716.jpg",
        cropName: "20180127_160716.jpg",
      },
      {
        id: 2873,
        imgId: 390,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_160948.jpg",
        cropName: "20180127_160948.jpg",
      },
    ],
    modifyDate: "2023-09-08T13:41:53.183+00:00",
    createDate: "2023-08-19T07:22:25.867+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Trendy Gold Plated Medium Designer Dailywear Bangles.  Ideal for sarees and other traditional costumes.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 329.05,
    cashPrice: 387.2895,
    taxAll: 50.669,
    taxInclude: 412.624,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 133,
    p_adminid: 2,
    productCategory: { id: 133, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 133,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5959,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5960,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5961,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5962,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5963,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5964,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 133, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 133,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 133,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 133,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2886,
        imgId: 393,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_162803.jpg",
        cropName: "20180127_162803.jpg",
      },
      {
        id: 2887,
        imgId: 396,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163040.jpg",
        cropName: "20180127_163040.jpg",
      },
      {
        id: 2888,
        imgId: 399,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163328.jpg",
        cropName: "20180127_163328.jpg",
      },
      {
        id: 2889,
        imgId: 402,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163611.jpg",
        cropName: "20180127_163611.jpg",
      },
    ],
    modifyDate: "2023-09-08T14:02:25.078+00:00",
    createDate: "2023-08-19T07:25:32.825+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These imitation bangles have been designed exclusively to complement the modern woman’s wardrobe and tastes.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 338.13,
    cashPrice: 397.5747,
    taxAll: 51.263397,
    taxInclude: 423.2064,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 134,
    p_adminid: 2,
    productCategory: { id: 134, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 134,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5953,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5954,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5955,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5956,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5957,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5958,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 134, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 134,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 134,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 134,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2882,
        imgId: 392,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_162724.jpg",
        cropName: "20180127_162724.jpg",
      },
      {
        id: 2883,
        imgId: 395,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163000.jpg",
        cropName: "20180127_163000.jpg",
      },
      {
        id: 2884,
        imgId: 398,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163244.jpg",
        cropName: "20180127_163244.jpg",
      },
      {
        id: 2885,
        imgId: 401,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163538.jpg",
        cropName: "20180127_163538.jpg",
      },
    ],
    modifyDate: "2023-09-08T14:01:32.316+00:00",
    createDate: "2023-08-19T07:38:58.542+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This ethnic jewellery goes well with matching traditional Mala with ethnic wear on festive occasions and Marriage functions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 291.482,
    cashPrice: 342.81357,
    taxAll: 44.36676,
    taxInclude: 364.99695,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 135,
    p_adminid: 2,
    productCategory: { id: 135, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 135,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5965,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5966,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5967,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5968,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5969,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5970,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 135, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 135,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 135,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 135,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2890,
        imgId: 394,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_162926.jpg",
        cropName: "20180127_162926.jpg",
      },
      {
        id: 2891,
        imgId: 391,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_162617.jpg",
        cropName: "20180127_162617.jpg",
      },
      {
        id: 2892,
        imgId: 397,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163145.jpg",
        cropName: "20180127_163145.jpg",
      },
      {
        id: 2893,
        imgId: 400,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163441.jpg",
        cropName: "20180127_163441.jpg",
      },
    ],
    modifyDate: "2023-09-08T14:10:15.114+00:00",
    createDate: "2023-08-19T07:41:21.778+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "It also can be worn by classical dancers and brides.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 379.93,
    cashPrice: 446.89667,
    taxAll: 57.947403,
    taxInclude: 475.87036,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 136,
    p_adminid: 2,
    productCategory: { id: 136, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 136,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5978,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5979,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5980,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 5981,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5982,
        lbrId: 876,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 5983,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 5984,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 136, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 136,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 136,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 136,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2900,
        imgId: 403,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165323.jpg",
        cropName: "20180127_165323.jpg",
      },
      {
        id: 2901,
        imgId: 404,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165420.jpg",
        cropName: "20180127_165420.jpg",
      },
      {
        id: 2902,
        imgId: 405,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165459.jpg",
        cropName: "20180127_165459.jpg",
      },
      {
        id: 2903,
        imgId: 406,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165620.jpg",
        cropName: "20180127_165620.jpg",
      },
      {
        id: 2904,
        imgId: 407,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165655.jpg",
        cropName: "20180127_165655.jpg",
      },
      {
        id: 2905,
        imgId: 408,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165741.jpg",
        cropName: "20180127_165741.jpg",
      },
    ],
    modifyDate: "2023-09-08T14:21:41.818+00:00",
    createDate: "2023-08-19T08:10:08.557+00:00",
    metalGram: 0.013,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Antique Gold Plated Bangles have an awesome look with an antique finish.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 269.93,
    cashPrice: 318.02472,
    taxAll: 42.203403,
    taxInclude: 339.1264,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 321.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 137,
    p_adminid: 2,
    productCategory: { id: 137, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 137,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5985,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5986,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5987,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5988,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5989,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5990,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 137, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 137,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 137,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 137,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2906,
        imgId: 411,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_170556.jpg",
        cropName: "20180127_170556.jpg",
      },
    ],
    modifyDate: "2023-09-08T14:24:07.033+00:00",
    createDate: "2023-08-19T08:11:22.008+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This pair of beautiful bangles goes well with ethnic wear on festive occasions and Marriage functions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 331.05,
    cashPrice: 389.5395,
    taxAll: 50.769,
    taxInclude: 414.924,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 138,
    p_adminid: 2,
    productCategory: { id: 138, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 138,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5991,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5992,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5993,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5994,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5995,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 5996,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 138, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 138,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 138,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 138,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2907,
        imgId: 410,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_170417.jpg",
        cropName: "20180127_170417.jpg",
      },
    ],
    modifyDate: "2023-09-09T05:47:25.126+00:00",
    createDate: "2023-08-19T08:16:09.026+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 285.11,
    cashPrice: 335.58188,
    taxAll: 43.9218,
    taxInclude: 357.5428,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 139,
    p_adminid: 2,
    productCategory: { id: 139, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 139,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6003,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6004,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6005,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6006,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6007,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6008,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 139, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 139,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 139,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 139,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2909,
        imgId: 409,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_170343.jpg",
        cropName: "20180127_170343.jpg",
      },
    ],
    modifyDate: "2023-09-09T05:51:32.719+00:00",
    createDate: "2023-08-19T08:17:38.471+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 372.72998,
    cashPrice: 438.7967,
    taxAll: 57.587402,
    taxInclude: 467.5904,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 140,
    p_adminid: 2,
    productCategory: { id: 140, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 140,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6061,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6062,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6063,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6064,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6065,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6066,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 140, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 140,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 140,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 140,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2957,
        imgId: 414,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_171929.jpg",
        cropName: "20180127_171929.jpg",
      },
      {
        id: 2958,
        imgId: 417,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172119.jpg",
        cropName: "20180127_172119.jpg",
      },
      {
        id: 2959,
        imgId: 420,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172324.jpg",
        cropName: "20180127_172324.jpg",
      },
      {
        id: 2960,
        imgId: 423,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172710.jpg",
        cropName: "20180127_172710.jpg",
      },
      {
        id: 2961,
        imgId: 426,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173004.jpg",
        cropName: "20180127_173004.jpg",
      },
      {
        id: 2962,
        imgId: 429,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173209.jpg",
        cropName: "20180127_173209.jpg",
      },
    ],
    modifyDate: "2023-09-09T06:47:11.113+00:00",
    createDate: "2023-08-19T08:19:47.962+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Party Wear Gold Plated Bangles for Women are very beautiful and impressive jewellery.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 335.05,
    cashPrice: 394.0395,
    taxAll: 50.969,
    taxInclude: 419.524,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 141,
    p_adminid: 2,
    productCategory: { id: 141, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 141,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6049,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6050,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6051,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6052,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6053,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6054,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 141, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 141,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 141,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 141,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2946,
        imgId: 433,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173407.jpg",
        cropName: "20180127_173407.jpg",
      },
      {
        id: 2947,
        imgId: 436,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173546.jpg",
        cropName: "20180127_173546.jpg",
      },
      {
        id: 2948,
        imgId: 439,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173747.jpg",
        cropName: "20180127_173747.jpg",
      },
      {
        id: 2949,
        imgId: 442,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173945.jpg",
        cropName: "20180127_173945.jpg",
      },
      {
        id: 2950,
        imgId: 445,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174128.jpg",
        cropName: "20180127_174128.jpg",
      },
    ],
    modifyDate: "2023-09-09T06:42:48.551+00:00",
    createDate: "2023-08-19T08:22:38.089+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These bangles have been designed solely to complement the modern woman wardrobe and taste.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 335.05,
    cashPrice: 394.0395,
    taxAll: 50.969,
    taxInclude: 419.524,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 142,
    p_adminid: 2,
    productCategory: { id: 142, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 142,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6079,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6080,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6081,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6082,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6083,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6084,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 142, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 142,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 142,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 142,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2975,
        imgId: 413,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_171854.jpg",
        cropName: "20180127_171854.jpg",
      },
      {
        id: 2976,
        imgId: 416,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172055.jpg",
        cropName: "20180127_172055.jpg",
      },
      {
        id: 2977,
        imgId: 419,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172253.jpg",
        cropName: "20180127_172253.jpg",
      },
      {
        id: 2978,
        imgId: 422,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172651.jpg",
        cropName: "20180127_172651.jpg",
      },
      {
        id: 2979,
        imgId: 425,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172939.jpg",
        cropName: "20180127_172939.jpg",
      },
      {
        id: 2980,
        imgId: 428,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173132.jpg",
        cropName: "20180127_173132.jpg",
      },
    ],
    modifyDate: "2023-09-09T06:55:28.986+00:00",
    createDate: "2023-08-19T08:26:06.748+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Bangles that have a luxurious look.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 288.71,
    cashPrice: 339.6319,
    taxAll: 44.1018,
    taxInclude: 361.6828,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 143,
    p_adminid: 2,
    productCategory: { id: 143, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 143,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6091,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6092,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6093,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6094,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6095,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6096,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 143, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 143,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 143,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 143,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2986,
        imgId: 444,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174054.jpg",
        cropName: "20180127_174054.jpg",
      },
      {
        id: 2987,
        imgId: 441,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173922.jpg",
        cropName: "20180127_173922.jpg",
      },
      {
        id: 2988,
        imgId: 438,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173718.jpg",
        cropName: "20180127_173718.jpg",
      },
      {
        id: 2989,
        imgId: 435,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173525.jpg",
        cropName: "20180127_173525.jpg",
      },
      {
        id: 2990,
        imgId: 432,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173342.jpg",
        cropName: "20180127_173342.jpg",
      },
    ],
    modifyDate: "2023-09-09T07:19:21.893+00:00",
    createDate: "2023-08-19T08:32:58.724+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "A beautiful and affordable gift for women on special occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 288.71,
    cashPrice: 339.6319,
    taxAll: 44.1018,
    taxInclude: 361.6828,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 144,
    p_adminid: 2,
    productCategory: { id: 144, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 144,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 9212,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 9213,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9214,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 9215,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 9216,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9217,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 144, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 144,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 144,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 144,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4318,
        imgId: 421,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172625.jpg",
        cropName: "20180127_172625.jpg",
      },
      {
        id: 4319,
        imgId: 415,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172024.jpg",
        cropName: "20180127_172024.jpg",
      },
      {
        id: 4320,
        imgId: 412,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_171822.jpg",
        cropName: "20180127_171822.jpg",
      },
      {
        id: 4321,
        imgId: 418,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172220.jpg",
        cropName: "20180127_172220.jpg",
      },
      {
        id: 4322,
        imgId: 424,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_172811.jpg",
        cropName: "20180127_172811.jpg",
      },
      {
        id: 4323,
        imgId: 427,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173058.jpg",
        cropName: "20180127_173058.jpg",
      },
    ],
    modifyDate: "2023-12-01T05:34:27.946+00:00",
    createDate: "2023-08-19T08:36:07.333+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: 7117,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This premium quality brass bangle can be used alone or in pairs, it lights up your wrist and adds style to any outfit. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 377.53,
    cashPrice: 444.1967,
    taxAll: 57.8274,
    taxInclude: 473.11038,
    active: true,
    gstCash: false,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 145,
    p_adminid: 2,
    productCategory: { id: 145, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 145,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 939,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 940,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 941,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 942,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 943,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 145, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 145,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 145,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 145,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 528,
        imgId: 437,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173646.jpg",
        cropName: "20180127_173646.jpg",
      },
      {
        id: 529,
        imgId: 443,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174035.jpg",
        cropName: "20180127_174035.jpg",
      },
      {
        id: 530,
        imgId: 440,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173854.jpg",
        cropName: "20180127_173854.jpg",
      },
      {
        id: 531,
        imgId: 434,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173452.jpg",
        cropName: "20180127_173452.jpg",
      },
      {
        id: 532,
        imgId: 431,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_173302.jpg",
        cropName: "20180127_173302.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T08:37:52.715+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "It can be worn daily or occasionally. This versatile, elegant bangle has a stylish design with a matte finish.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 376.25,
    cashPrice: 444.5455,
    taxAll: 61.341003,
    taxInclude: 475.216,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 146,
    p_adminid: 2,
    productCategory: { id: 146, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 146,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6120,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6121,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6122,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6123,
        lbrId: 868,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "V Pokal Solder",
      },
      {
        id: 6124,
        lbrId: 892,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 6125,
        lbrId: 912,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6126,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 146, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 146,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 146,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 146,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3000,
        imgId: 450,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190622_153345.jpg",
        cropName: "20190622_153345.jpg",
      },
    ],
    modifyDate: "2023-09-09T07:41:22.501+00:00",
    createDate: "2023-08-19T08:39:42.602+00:00",
    metalGram: 0.006,
    extraGram: 5.0e-4,
    readyGram: 0.0045,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Traditional Red Gold Plated Pola Bangles for women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 239.81,
    cashPrice: 277.4239,
    taxAll: 27.2658,
    taxInclude: 291.0568,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 694.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 147,
    p_adminid: 2,
    productCategory: { id: 147, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 147,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6140,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6141,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6142,
        lbrId: 912,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6143,
        lbrId: 868,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "V Pokal Solder",
      },
      {
        id: 6144,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6145,
        lbrId: 892,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 6146,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 147, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 147,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 147,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 147,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3003,
        imgId: 451,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190622_154125.jpg",
        cropName: "20190622_154125.jpg",
      },
    ],
    modifyDate: "2023-09-09T07:49:00.947+00:00",
    createDate: "2023-08-19T08:42:00.560+00:00",
    metalGram: 0.006,
    extraGram: 5.0e-4,
    readyGram: 0.0045,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Its micro gold plated pola bangles are set for married women to wear on any occasion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 341.09,
    cashPrice: 394.2421,
    taxAll: 38.0862,
    taxInclude: 413.28522,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 463.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 148,
    p_adminid: 2,
    productCategory: { id: 148, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 148,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6113,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6114,
        lbrId: 912,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6115,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6116,
        lbrId: 868,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "V Pokal Solder",
      },
      {
        id: 6117,
        lbrId: 892,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 6118,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6119,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 148, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 148,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 148,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 148,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2999,
        imgId: 452,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190622_155549.jpg",
        cropName: "20190622_155549.jpg",
      },
    ],
    modifyDate: "2023-09-09T07:37:44.433+00:00",
    createDate: "2023-08-19T08:43:32.831+00:00",
    metalGram: 0.006,
    extraGram: 5.0e-4,
    readyGram: 0.0045,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Red Bangle and Pola Gold Bangles for Women Online from the best Indian jewellers.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 224.45001,
    cashPrice: 260.0205,
    taxAll: 26.251,
    taxInclude: 273.14603,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 149,
    p_adminid: 2,
    productCategory: { id: 149, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 149,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6133,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6134,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6135,
        lbrId: 912,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6136,
        lbrId: 868,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "V Pokal Solder",
      },
      {
        id: 6137,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6138,
        lbrId: 892,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 6139,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 149, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 149,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 149,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 149,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3002,
        imgId: 449,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190622_152823.jpg",
        cropName: "20190622_152823.jpg",
      },
    ],
    modifyDate: "2023-09-09T07:47:29.816+00:00",
    createDate: "2023-08-19T08:45:15.786+00:00",
    metalGram: 0.0065,
    extraGram: 5.0e-4,
    readyGram: 0.0045,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This premium quality bangle can be used alone or in pairs, it lights up your wrist and adds style to any outfit.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 462.13,
    cashPrice: 534.5747,
    taxAll: 52.4634,
    taxInclude: 560.8064,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 321.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 150,
    p_adminid: 2,
    productCategory: { id: 150, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 150,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 966,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 967,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 968,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 969,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 970,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 150, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 150,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 150,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 150,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 537,
        imgId: 446,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_183527.jpg",
        cropName: "20180127_183527.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T08:47:48.832+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription:
      "This Nice stylish bangles can be used as pairs or sets. It lights up your wrist and adds a perfect look to your outfit.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.84999,
    cashPrice: 280.86447,
    taxAll: 38.458996,
    taxInclude: 300.09396,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 151,
    p_adminid: 2,
    productCategory: { id: 151, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 151,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5559,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5560,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5561,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5562,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 5563,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 5564,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 151, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 151,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 151,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 151,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2716,
        imgId: 448,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190510_132248.jpg",
        cropName: "20190510_132248.jpg",
      },
    ],
    modifyDate: "2023-09-08T10:38:06.797+00:00",
    createDate: "2023-08-19T08:50:31.595+00:00",
    metalGram: 0.012,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Elegant Gold Plated Design Bangles for girls and women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 252.76999,
    cashPrice: 297.6823,
    taxAll: 39.2706,
    taxInclude: 317.3176,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 347.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 152,
    p_adminid: 2,
    productCategory: { id: 152, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 152,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6108,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6109,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6110,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6111,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6112,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 152, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 152,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 152,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 152,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2998,
        imgId: 447,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190329_120500.jpg",
        cropName: "20190329_120500.jpg",
      },
    ],
    modifyDate: "2023-09-09T07:33:38.470+00:00",
    createDate: "2023-08-19T08:52:48.725+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These versatile, elegant medium-sized bangles are classic and traditional but in fashion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 323.05,
    cashPrice: 380.5395,
    taxAll: 50.369,
    taxInclude: 405.72397,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 153,
    p_adminid: 2,
    productCategory: { id: 153, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 153,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6442,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6443,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6444,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6445,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6446,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6447,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6448,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6449,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
    ],
    productSets: { id: 153, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 153,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 153,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 153,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3162,
        imgId: 454,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123008.jpg",
        cropName: "20190821_123008.jpg",
      },
      {
        id: 3163,
        imgId: 459,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123421.jpg",
        cropName: "20190821_123421.jpg",
      },
      {
        id: 3164,
        imgId: 470,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_131457.jpg",
        cropName: "20190821_131457.jpg",
      },
      {
        id: 3165,
        imgId: 477,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132102.jpg",
        cropName: "20190821_132102.jpg",
      },
      {
        id: 3166,
        imgId: 484,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132435.jpg",
        cropName: "20190821_132435.jpg",
      },
      {
        id: 3167,
        imgId: 491,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132953.jpg",
        cropName: "20190821_132953.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:20:49.709+00:00",
    createDate: "2023-08-19T08:58:49.538+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Golden Simple Daily Wear Bangles for girls. This pair of beautiful gold-plated lightweight bangles can be worn every day or for a special occasion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 381.65,
    cashPrice: 448.0635,
    taxAll: 56.496998,
    taxInclude: 476.312,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 154,
    p_adminid: 2,
    productCategory: { id: 154, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 154,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6435,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6436,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6437,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6438,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6439,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6440,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6441,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 154, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 154,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 154,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 154,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3156,
        imgId: 505,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150621.jpg",
        cropName: "20190821_150621.jpg",
      },
      {
        id: 3157,
        imgId: 513,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151007.jpg",
        cropName: "20190821_151007.jpg",
      },
      {
        id: 3158,
        imgId: 520,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151710.jpg",
        cropName: "20190821_151710.jpg",
      },
      {
        id: 3159,
        imgId: 527,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152057.jpg",
        cropName: "20190821_152057.jpg",
      },
      {
        id: 3160,
        imgId: 534,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152454.jpg",
        cropName: "20190821_152454.jpg",
      },
      {
        id: 3161,
        imgId: 498,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133319.jpg",
        cropName: "20190821_133319.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:20:10.351+00:00",
    createDate: "2023-08-19T09:51:05.480+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Our designer brass bangles can be worn with any kind of attire as they are available in various attractive designs and styles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 353.34998,
    cashPrice: 415.42645,
    taxAll: 53.482994,
    taxInclude: 442.16797,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 263.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 155,
    p_adminid: 2,
    productCategory: { id: 155, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 155,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6237,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6238,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6239,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6240,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6241,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6242,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6243,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6244,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 6245,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6246,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
    ],
    productSets: { id: 155, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 155,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 155,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 155,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3055,
        imgId: 541,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_153223.jpg",
        cropName: "20190821_153223.jpg",
      },
    ],
    modifyDate: "2023-09-09T09:50:58.497+00:00",
    createDate: "2023-08-19T09:53:55.626+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "It Is Made From Metal and We Provide Gold Plated Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 413.65,
    cashPrice: 484.06348,
    taxAll: 58.097,
    taxInclude: 513.112,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 156,
    p_adminid: 2,
    productCategory: { id: 156, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 156,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6458,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6459,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6460,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6461,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6462,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6463,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6464,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6465,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 156, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 156,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 156,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 156,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3173,
        imgId: 457,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123313.jpg",
        cropName: "20190821_123313.jpg",
      },
      {
        id: 3174,
        imgId: 468,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_131327.jpg",
        cropName: "20190821_131327.jpg",
      },
      {
        id: 3175,
        imgId: 475,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132021.jpg",
        cropName: "20190821_132021.jpg",
      },
      {
        id: 3176,
        imgId: 482,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132358.jpg",
        cropName: "20190821_132358.jpg",
      },
      {
        id: 3177,
        imgId: 489,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132918.jpg",
        cropName: "20190821_132918.jpg",
      },
      {
        id: 3178,
        imgId: 496,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133253.jpg",
        cropName: "20190821_133253.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:26:39.042+00:00",
    createDate: "2023-08-19T09:57:53.171+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Designer Micro Gold Plated Dailywear Bangles for Women. Ideal for sarees and other traditional costumes.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 330.65,
    cashPrice: 388.2535,
    taxAll: 49.077,
    taxInclude: 412.792,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 157,
    p_adminid: 2,
    productCategory: { id: 157, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 157,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6466,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6467,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6468,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6469,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6470,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6471,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6472,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 157, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 157,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 157,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 157,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3179,
        imgId: 503,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150537.jpg",
        cropName: "20190821_150537.jpg",
      },
      {
        id: 3180,
        imgId: 511,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150936.jpg",
        cropName: "20190821_150936.jpg",
      },
      {
        id: 3181,
        imgId: 519,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151633.jpg",
        cropName: "20190821_151633.jpg",
      },
      {
        id: 3182,
        imgId: 525,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152028.jpg",
        cropName: "20190821_152028.jpg",
      },
      {
        id: 3183,
        imgId: 532,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152423.jpg",
        cropName: "20190821_152423.jpg",
      },
      {
        id: 3184,
        imgId: 539,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_153148.jpg",
        cropName: "20190821_153148.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:27:50.384+00:00",
    createDate: "2023-08-19T10:02:32.924+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Gorgeous Pair of Gold Plated Designer Brass Bangles for Ladies.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 305.18,
    cashPrice: 358.8802,
    taxAll: 46.3644,
    taxInclude: 382.0624,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 292.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 158,
    p_adminid: 2,
    productCategory: { id: 158, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 158,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6147,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6148,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6149,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6150,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6151,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6152,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6153,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6154,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 158, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 158,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 158,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 158,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3004,
        imgId: 465,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123913.jpg",
        cropName: "20190821_123913.jpg",
      },
      {
        id: 3005,
        imgId: 461,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123507.jpg",
        cropName: "20190821_123507.jpg",
      },
      {
        id: 3006,
        imgId: 472,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_131529.jpg",
        cropName: "20190821_131529.jpg",
      },
      {
        id: 3007,
        imgId: 479,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132141.jpg",
        cropName: "20190821_132141.jpg",
      },
      {
        id: 3008,
        imgId: 486,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132501.jpg",
        cropName: "20190821_132501.jpg",
      },
      {
        id: 3009,
        imgId: 493,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133024.jpg",
        cropName: "20190821_133024.jpg",
      },
    ],
    modifyDate: "2023-09-09T07:53:27.882+00:00",
    createDate: "2023-08-19T10:05:12.835+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Gold Plated Brass Bangles made with Brass Alloy. Totally made with High-Quality Jewellery Standard Brass Alloy and plated.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 218.65,
    cashPrice: 256.6935,
    taxAll: 32.357,
    taxInclude: 272.872,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 160,
    p_adminid: 2,
    productCategory: { id: 160, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 160,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6488,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6489,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6490,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6491,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6492,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6493,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6494,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 160, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 160,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 160,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 160,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3197,
        imgId: 515,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151049.jpg",
        cropName: "20190821_151049.jpg",
      },
      {
        id: 3198,
        imgId: 508,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150710.jpg",
        cropName: "20190821_150710.jpg",
      },
      {
        id: 3199,
        imgId: 500,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133353.jpg",
        cropName: "20190821_133353.jpg",
      },
      {
        id: 3200,
        imgId: 522,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151747.jpg",
        cropName: "20190821_151747.jpg",
      },
      {
        id: 3201,
        imgId: 529,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152134.jpg",
        cropName: "20190821_152134.jpg",
      },
      {
        id: 3202,
        imgId: 536,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152528.jpg",
        cropName: "20190821_152528.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:33:49.665+00:00",
    createDate: "2023-08-19T10:16:14.873+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4",
    prodManfDetails: "4",
    prodDescription:
      "Gold Plated Brass Bangles made with Brass Alloy. Totally made with High-Quality Jewellery Standard Brass Alloy and Gold Plated.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 204.49998,
    cashPrice: 240.37498,
    taxAll: 30.849997,
    taxInclude: 255.79997,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 526.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 161,
    p_adminid: 2,
    productCategory: { id: 161, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 161,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6342,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6343,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6344,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6345,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6346,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6347,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6348,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6349,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6350,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 6351,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
    ],
    productSets: { id: 161, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 161,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 161,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 161,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3103,
        imgId: 543,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_153253.jpg",
        cropName: "20190821_153253.jpg",
      },
    ],
    modifyDate: "2023-09-09T10:28:48.093+00:00",
    createDate: "2023-08-19T10:19:04.384+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "High-quality brass, these bangles, plated with premium gold, serve as an exquisite and timeless accessory for any special occasion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 240.65,
    cashPrice: 281.44348,
    taxAll: 33.456997,
    taxInclude: 298.172,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 162,
    p_adminid: 2,
    productCategory: { id: 162, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 162,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6281,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6282,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6283,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6284,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6285,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6286,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6287,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6288,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
    ],
    productSets: { id: 162, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 162,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 162,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 162,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3075,
        imgId: 478,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132125.jpg",
        cropName: "20190821_132125.jpg",
      },
      {
        id: 3076,
        imgId: 471,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_131510.jpg",
        cropName: "20190821_131510.jpg",
      },
      {
        id: 3077,
        imgId: 464,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123854.jpg",
        cropName: "20190821_123854.jpg",
      },
      {
        id: 3078,
        imgId: 460,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123445.jpg",
        cropName: "20190821_123445.jpg",
      },
      {
        id: 3079,
        imgId: 485,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132447.jpg",
        cropName: "20190821_132447.jpg",
      },
      {
        id: 3080,
        imgId: 492,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133011.jpg",
        cropName: "20190821_133011.jpg",
      },
    ],
    modifyDate: "2023-09-09T10:15:20.186+00:00",
    createDate: "2023-08-19T11:15:20.404+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This set of ornate bangles is perfect for special occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 300.15,
    cashPrice: 352.37848,
    taxAll: 44.427,
    taxInclude: 374.59198,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 163,
    p_adminid: 2,
    productCategory: { id: 163, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 163,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6495,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6496,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6497,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6498,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6499,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6500,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6501,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 163, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 163,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 163,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 163,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3203,
        imgId: 521,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151727.jpg",
        cropName: "20190821_151727.jpg",
      },
      {
        id: 3204,
        imgId: 514,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151023.jpg",
        cropName: "20190821_151023.jpg",
      },
      {
        id: 3205,
        imgId: 506,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150646.jpg",
        cropName: "20190821_150646.jpg",
      },
      {
        id: 3206,
        imgId: 499,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133333.jpg",
        cropName: "20190821_133333.jpg",
      },
      {
        id: 3207,
        imgId: 535,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152503.jpg",
        cropName: "20190821_152503.jpg",
      },
      {
        id: 3208,
        imgId: 528,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152111.jpg",
        cropName: "20190821_152111.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:37:03.373+00:00",
    createDate: "2023-08-19T11:17:32.339+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Brass is an alloy made mainly of copper and zinc. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 278.925,
    cashPrice: 327.90073,
    taxAll: 42.1665,
    taxInclude: 348.98398,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 351.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 164,
    p_adminid: 2,
    productCategory: { id: 164, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 164,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6502,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6503,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6504,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6505,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6506,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6507,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6508,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6509,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6510,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 6511,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
    ],
    productSets: { id: 164, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 164,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 164,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 164,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3209,
        imgId: 542,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_153237.jpg",
        cropName: "20190821_153237.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:38:53.459+00:00",
    createDate: "2023-08-19T11:19:44.820+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 324.15,
    cashPrice: 379.3785,
    taxAll: 45.627,
    taxInclude: 402.19202,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 165,
    p_adminid: 2,
    productCategory: { id: 165, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 165,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6352,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6353,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6354,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6355,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6356,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6357,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6358,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6359,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 165, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 165,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 165,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 165,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3104,
        imgId: 469,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_131437.jpg",
        cropName: "20190821_131437.jpg",
      },
      {
        id: 3105,
        imgId: 458,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123348.jpg",
        cropName: "20190821_123348.jpg",
      },
      {
        id: 3106,
        imgId: 463,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123801.jpg",
        cropName: "20190821_123801.jpg",
      },
      {
        id: 3107,
        imgId: 476,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132044.jpg",
        cropName: "20190821_132044.jpg",
      },
      {
        id: 3108,
        imgId: 483,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132417.jpg",
        cropName: "20190821_132417.jpg",
      },
      {
        id: 3109,
        imgId: 490,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132933.jpg",
        cropName: "20190821_132933.jpg",
      },
    ],
    modifyDate: "2023-09-09T10:31:38.801+00:00",
    createDate: "2023-08-19T11:27:00.167+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Brass has been an important metal throughout history. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 281.75,
    cashPrice: 330.8425,
    taxAll: 41.835,
    taxInclude: 351.75998,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 166,
    p_adminid: 2,
    productCategory: { id: 166, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 166,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6512,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6513,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6514,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6515,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6516,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6517,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6518,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 166, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 166,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 166,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 166,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3210,
        imgId: 504,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150602.jpg",
        cropName: "20190821_150602.jpg",
      },
      {
        id: 3211,
        imgId: 497,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133305.jpg",
        cropName: "20190821_133305.jpg",
      },
      {
        id: 3212,
        imgId: 519,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151633.jpg",
        cropName: "20190821_151633.jpg",
      },
      {
        id: 3213,
        imgId: 526,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152040.jpg",
        cropName: "20190821_152040.jpg",
      },
      {
        id: 3214,
        imgId: 533,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152438.jpg",
        cropName: "20190821_152438.jpg",
      },
      {
        id: 3215,
        imgId: 512,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150952.jpg",
        cropName: "20190821_150952.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:40:53.073+00:00",
    createDate: "2023-08-19T11:30:05.602+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Its yellowish warm tones can gleam as good as gold-plated Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 260.525,
    cashPrice: 306.36475,
    taxAll: 39.5745,
    taxInclude: 326.15198,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 351.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 167,
    p_adminid: 2,
    productCategory: { id: 167, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 167,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6368,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6369,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6370,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6371,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6372,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6373,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6374,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 6375,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6376,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 6377,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 167, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 167,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 167,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 167,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3116,
        imgId: 540,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_153208.jpg",
        cropName: "20190821_153208.jpg",
      },
    ],
    modifyDate: "2023-09-09T10:36:27.880+00:00",
    createDate: "2023-08-19T11:31:28.437+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Brass Bangles is one of the most popularly used metals for jewellery, partly due to its resemblance to gold.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 305.75,
    cashPrice: 357.8425,
    taxAll: 43.035,
    taxInclude: 379.36002,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 168,
    p_adminid: 2,
    productCategory: { id: 168, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 168,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6394,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6395,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6396,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6397,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6398,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6399,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6400,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6401,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 168, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 168,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 168,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 168,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3129,
        imgId: 453,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_122912.jpg",
        cropName: "20190821_122912.jpg",
      },
      {
        id: 3130,
        imgId: 456,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123251.jpg",
        cropName: "20190821_123251.jpg",
      },
      {
        id: 3131,
        imgId: 467,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_131255.jpg",
        cropName: "20190821_131255.jpg",
      },
      {
        id: 3132,
        imgId: 474,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132005.jpg",
        cropName: "20190821_132005.jpg",
      },
      {
        id: 3133,
        imgId: 481,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132345.jpg",
        cropName: "20190821_132345.jpg",
      },
      {
        id: 3134,
        imgId: 488,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132859.jpg",
        cropName: "20190821_132859.jpg",
      },
    ],
    modifyDate: "2023-09-09T10:42:02.206+00:00",
    createDate: "2023-08-19T11:39:11.901+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "New Design Extremely Premium Quality Pure Brass Gold Plated Bangles Long Lasting Anti Sweat.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 384.55,
    cashPrice: 451.6145,
    taxAll: 57.219,
    taxInclude: 480.224,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 238.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 169,
    p_adminid: 2,
    productCategory: { id: 169, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 169,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6519,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6520,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6521,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6522,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6523,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6524,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6525,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 169, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 169,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 169,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 169,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3216,
        imgId: 518,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151616.jpg",
        cropName: "20190821_151616.jpg",
      },
      {
        id: 3217,
        imgId: 495,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133242.jpg",
        cropName: "20190821_133242.jpg",
      },
      {
        id: 3218,
        imgId: 502,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150522.jpg",
        cropName: "20190821_150522.jpg",
      },
      {
        id: 3219,
        imgId: 510,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150924.jpg",
        cropName: "20190821_150924.jpg",
      },
      {
        id: 3220,
        imgId: 524,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152010.jpg",
        cropName: "20190821_152010.jpg",
      },
      {
        id: 3221,
        imgId: 531,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152405.jpg",
        cropName: "20190821_152405.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:48:40.481+00:00",
    createDate: "2023-08-19T11:42:48.844+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: " Its graceful designs complement ethnic/casual outfits.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 354.83502,
    cashPrice: 417.34567,
    taxAll: 54.0543,
    taxInclude: 444.3728,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 251.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 170,
    p_adminid: 2,
    productCategory: { id: 170, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 170,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6534,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6535,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6536,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6537,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6538,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6539,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6540,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6541,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 170, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 170,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 170,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 170,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3228,
        imgId: 473,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_131843.jpg",
        cropName: "20190821_131843.jpg",
      },
      {
        id: 3229,
        imgId: 462,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123644.jpg",
        cropName: "20190821_123644.jpg",
      },
      {
        id: 3230,
        imgId: 455,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_123222.jpg",
        cropName: "20190821_123222.jpg",
      },
      {
        id: 3231,
        imgId: 466,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_131222.jpg",
        cropName: "20190821_131222.jpg",
      },
      {
        id: 3232,
        imgId: 487,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132825.jpg",
        cropName: "20190821_132825.jpg",
      },
      {
        id: 3233,
        imgId: 480,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_132324.jpg",
        cropName: "20190821_132324.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:57:14.802+00:00",
    createDate: "2023-08-19T11:58:27.620+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "The complete range of bangles is used to match with sarees and suits on festivals, marriages and other special occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 433.45,
    cashPrice: 509.0255,
    taxAll: 64.461006,
    taxInclude: 541.25604,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 171,
    p_adminid: 2,
    productCategory: { id: 171, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 171,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6542,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6543,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6544,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6545,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6546,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6547,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6548,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 171, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 171,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 171,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 171,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3234,
        imgId: 523,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151954.jpg",
        cropName: "20190821_151954.jpg",
      },
      {
        id: 3235,
        imgId: 530,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_152352.jpg",
        cropName: "20190821_152352.jpg",
      },
      {
        id: 3236,
        imgId: 509,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150908.jpg",
        cropName: "20190821_150908.jpg",
      },
      {
        id: 3237,
        imgId: 516,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_151556.jpg",
        cropName: "20190821_151556.jpg",
      },
      {
        id: 3238,
        imgId: 501,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_150512.jpg",
        cropName: "20190821_150512.jpg",
      },
      {
        id: 3239,
        imgId: 494,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_133230.jpg",
        cropName: "20190821_133230.jpg",
      },
    ],
    modifyDate: "2023-09-09T11:58:24.592+00:00",
    createDate: "2023-08-19T12:03:49.144+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Women love jewellery especially traditional jewellery adore women. They wear it on different occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 399.49,
    cashPrice: 469.86108,
    taxAll: 60.8442,
    taxInclude: 500.2832,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 219.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 172,
    p_adminid: 2,
    productCategory: { id: 172, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 172,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6425,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6426,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6427,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6428,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6429,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6430,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6431,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6432,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 6433,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 6434,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 172, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 172,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 172,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 172,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3155,
        imgId: 537,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_153114.jpg",
        cropName: "20190821_153114.jpg",
      },
    ],
    modifyDate: "2023-09-09T10:50:14.844+00:00",
    createDate: "2023-08-19T12:06:36.032+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Design Brass Bangles typically come as a set, featuring multiple individual bangles that can be worn together or separately.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 471.85,
    cashPrice: 552.2255,
    taxAll: 66.381004,
    taxInclude: 585.416,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 173,
    p_adminid: 2,
    productCategory: { id: 173, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 173,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6558,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6559,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6560,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6561,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6562,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6563,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 6564,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 6565,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6566,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 173, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 173,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 173,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 173,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3241,
        imgId: 544,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_153753.jpg",
        cropName: "20190821_153753.jpg",
      },
      {
        id: 3242,
        imgId: 545,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_221523.jpg",
        cropName: "20190821_221523.jpg",
      },
      {
        id: 3243,
        imgId: 546,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_221617.jpg",
        cropName: "20190821_221617.jpg",
      },
      {
        id: 3244,
        imgId: 547,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_221815.jpg",
        cropName: "20190821_221815.jpg",
      },
      {
        id: 3245,
        imgId: 548,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_221936.jpg",
        cropName: "20190821_221936.jpg",
      },
      {
        id: 3246,
        imgId: 549,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222134.jpg",
        cropName: "20190821_222134.jpg",
      },
    ],
    modifyDate: "2023-09-09T12:21:16.457+00:00",
    createDate: "2023-08-19T12:08:38.708+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.0085,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "The Set of 4 Brass Bangles is durable and comfortable to wear.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 440.55002,
    cashPrice: 516.7245,
    taxAll: 64.239,
    taxInclude: 548.844,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 217.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 174,
    p_adminid: 2,
    productCategory: { id: 174, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 174,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6567,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6568,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6569,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6570,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6571,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6572,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 6573,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 6574,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6575,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 174, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 174,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 174,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 174,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3247,
        imgId: 550,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222222.jpg",
        cropName: "20190821_222222.jpg",
      },
      {
        id: 3248,
        imgId: 551,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222326.jpg",
        cropName: "20190821_222326.jpg",
      },
      {
        id: 3249,
        imgId: 552,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222415.jpg",
        cropName: "20190821_222415.jpg",
      },
      {
        id: 3250,
        imgId: 553,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222540.jpg",
        cropName: "20190821_222540.jpg",
      },
      {
        id: 3251,
        imgId: 554,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222808.jpg",
        cropName: "20190821_222808.jpg",
      },
      {
        id: 3252,
        imgId: 555,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_222915.jpg",
        cropName: "20190821_222915.jpg",
      },
    ],
    modifyDate: "2023-09-09T12:25:32.083+00:00",
    createDate: "2023-08-19T12:10:20.988+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.0085,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "High Gold Plated Brass Bangles Online with the design adds an earthy and organic touch.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 440.55002,
    cashPrice: 516.7245,
    taxAll: 64.239,
    taxInclude: 548.844,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 217.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 175,
    p_adminid: 2,
    productCategory: { id: 175, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 175,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1147,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1148,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1149,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1150,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1151,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 175, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 175,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 175,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 175,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 698,
        imgId: 560,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190502.jpg",
        cropName: "20210906_190502.jpg",
      },
      {
        id: 699,
        imgId: 563,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190731.jpg",
        cropName: "20210906_190731.jpg",
      },
      {
        id: 700,
        imgId: 620,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194728.jpg",
        cropName: "20210906_194728.jpg",
      },
      {
        id: 701,
        imgId: 596,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193104.jpg",
        cropName: "20210906_193104.jpg",
      },
    ],
    modifyDate: "2023-08-19T12:39:23.577+00:00",
    createDate: "2023-08-19T12:13:20.429+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "A designer brass antique bangle set is a stunning collection of bangles that combines the beauty of floral motifs with the elegance of brass.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.84999,
    cashPrice: 280.86447,
    taxAll: 38.458996,
    taxInclude: 300.09396,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 176,
    p_adminid: 2,
    productCategory: { id: 176, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 176,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1152,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1153,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1154,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1155,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1156,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 176, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 176,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 176,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 176,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 702,
        imgId: 595,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193048.jpg",
        cropName: "20210906_193048.jpg",
      },
      {
        id: 703,
        imgId: 619,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194718.jpg",
        cropName: "20210906_194718.jpg",
      },
      {
        id: 704,
        imgId: 557,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190233.jpg",
        cropName: "20210906_190233.jpg",
      },
      {
        id: 705,
        imgId: 562,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190718.jpg",
        cropName: "20210906_190718.jpg",
      },
    ],
    modifyDate: "2023-08-19T12:41:53.010+00:00",
    createDate: "2023-08-19T12:15:29.177+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Artificial Brass Bangles Design sets are versatile accessories that can be worn for various occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 290.45,
    cashPrice: 343.10654,
    taxAll: 47.223,
    taxInclude: 366.71802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 177,
    p_adminid: 2,
    productCategory: { id: 177, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 177,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1167,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1168,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1169,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1170,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1171,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 177, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 177,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 177,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 177,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 716,
        imgId: 619,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194718.jpg",
        cropName: "20210906_194718.jpg",
      },
      {
        id: 717,
        imgId: 594,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193023.jpg",
        cropName: "20210906_193023.jpg",
      },
      {
        id: 718,
        imgId: 561,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190711.jpg",
        cropName: "20210906_190711.jpg",
      },
      {
        id: 719,
        imgId: 556,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190210.jpg",
        cropName: "20210906_190210.jpg",
      },
    ],
    modifyDate: "2023-08-19T12:46:14.683+00:00",
    createDate: "2023-08-19T12:18:40.057+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "The Brass Antique Bangles Set adds a touch of vintage charm to your jewellery collection.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 376.25,
    cashPrice: 444.5455,
    taxAll: 61.341003,
    taxInclude: 475.216,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 178,
    p_adminid: 2,
    productCategory: { id: 178, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 178,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6629,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6630,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6631,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6632,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6633,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6634,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6635,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 178, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 178,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 178,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 178,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3287,
        imgId: 578,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191910.jpg",
        cropName: "20210906_191910.jpg",
      },
      {
        id: 3288,
        imgId: 575,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191622.jpg",
        cropName: "20210906_191622.jpg",
      },
      {
        id: 3289,
        imgId: 581,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192123.jpg",
        cropName: "20210906_192123.jpg",
      },
      {
        id: 3290,
        imgId: 605,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193634.jpg",
        cropName: "20210906_193634.jpg",
      },
      {
        id: 3291,
        imgId: 617,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194534.jpg",
        cropName: "20210906_194534.jpg",
      },
    ],
    modifyDate: "2023-09-09T13:39:05.732+00:00",
    createDate: "2023-08-19T12:21:02.572+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Brass Antique Bangles Set adds a touch of vintage charm to your jewellery collection.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 328.75,
    cashPrice: 386.1525,
    taxAll: 49.055004,
    taxInclude: 410.68,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 179,
    p_adminid: 2,
    productCategory: { id: 179, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 179,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6615,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6616,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6617,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6618,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6619,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6620,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6621,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 179, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 179,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 179,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 179,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3277,
        imgId: 580,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192109.jpg",
        cropName: "20210906_192109.jpg",
      },
      {
        id: 3278,
        imgId: 577,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191903.jpg",
        cropName: "20210906_191903.jpg",
      },
      {
        id: 3279,
        imgId: 574,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191615.jpg",
        cropName: "20210906_191615.jpg",
      },
      {
        id: 3280,
        imgId: 604,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193628.jpg",
        cropName: "20210906_193628.jpg",
      },
      {
        id: 3281,
        imgId: 616,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194418.jpg",
        cropName: "20210906_194418.jpg",
      },
    ],
    modifyDate: "2023-09-09T13:31:10.690+00:00",
    createDate: "2023-08-19T12:26:38.256+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Antique 6 Piece Bangles Set is made of high-quality fashion jewellery that is both durable and affordable.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 283.04,
    cashPrice: 332.5336,
    taxAll: 42.3792,
    taxInclude: 353.7232,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 327.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 180,
    p_adminid: 2,
    productCategory: { id: 180, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 180,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6622,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6623,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6624,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6625,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6626,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6627,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6628,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 180, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 180,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 180,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 180,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3282,
        imgId: 573,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191533.jpg",
        cropName: "20210906_191533.jpg",
      },
      {
        id: 3283,
        imgId: 576,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191857.jpg",
        cropName: "20210906_191857.jpg",
      },
      {
        id: 3284,
        imgId: 579,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192054.jpg",
        cropName: "20210906_192054.jpg",
      },
      {
        id: 3285,
        imgId: 603,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193608.jpg",
        cropName: "20210906_193608.jpg",
      },
      {
        id: 3286,
        imgId: 615,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194327.jpg",
        cropName: "20210906_194327.jpg",
      },
    ],
    modifyDate: "2023-09-09T13:36:54.481+00:00",
    createDate: "2023-08-19T12:28:15.497+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Provided Bangles are designed using finest quality gold under the supervision of our deft professionals.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 369.97,
    cashPrice: 434.7323,
    taxAll: 55.5306,
    taxInclude: 462.49762,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 181,
    p_adminid: 2,
    productCategory: { id: 181, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 181,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6597,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6598,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6599,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6600,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6601,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6602,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 181, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 181,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 181,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 181,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3264,
        imgId: 566,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190929.jpg",
        cropName: "20210906_190929.jpg",
      },
      {
        id: 3265,
        imgId: 572,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191402.jpg",
        cropName: "20210906_191402.jpg",
      },
      {
        id: 3266,
        imgId: 569,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191251.jpg",
        cropName: "20210906_191251.jpg",
      },
      {
        id: 3267,
        imgId: 599,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193339.jpg",
        cropName: "20210906_193339.jpg",
      },
    ],
    modifyDate: "2023-09-09T12:55:56.197+00:00",
    createDate: "2023-08-19T12:59:01.258+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "4 4-piece brass bangles Set in different sizes, making it easy to mix and match with your favourite outfits for festive wear.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 322.75,
    cashPrice: 379.4025,
    taxAll: 48.755005,
    taxInclude: 403.78,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: null,
    igst: null,
    stockSize: [],
  },
  {
    id: 182,
    p_adminid: 2,
    productCategory: { id: 182, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 182,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1182,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1183,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1184,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1185,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1186,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 182, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 182,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 182,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 182,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 727,
        imgId: 571,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191349.jpg",
        cropName: "20210906_191349.jpg",
      },
      {
        id: 728,
        imgId: 568,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191222.jpg",
        cropName: "20210906_191222.jpg",
      },
      {
        id: 729,
        imgId: 565,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190917.jpg",
        cropName: "20210906_190917.jpg",
      },
      {
        id: 730,
        imgId: 598,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193305.jpg",
        cropName: "20210906_193305.jpg",
      },
      {
        id: 731,
        imgId: 607,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193759.jpg",
        cropName: "20210906_193759.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:03:43.425+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These brass bangles are also a great option for accessorizing an outfit for daily wear, party wear or office wear.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 290.45,
    cashPrice: 343.10654,
    taxAll: 47.223,
    taxInclude: 366.71802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 183,
    p_adminid: 2,
    productCategory: { id: 183, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 183,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 7737,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7738,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 7739,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 7740,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 7741,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7742,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 183, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 183,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 183,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 183,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3808,
        imgId: 567,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191153.jpg",
        cropName: "20210906_191153.jpg",
      },
      {
        id: 3809,
        imgId: 564,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_190907.jpg",
        cropName: "20210906_190907.jpg",
      },
      {
        id: 3810,
        imgId: 570,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_191337.jpg",
        cropName: "20210906_191337.jpg",
      },
      {
        id: 3811,
        imgId: 597,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193258.jpg",
        cropName: "20210906_193258.jpg",
      },
      {
        id: 3812,
        imgId: 606,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193747.jpg",
        cropName: "20210906_193747.jpg",
      },
    ],
    modifyDate: "2023-09-14T10:39:31.597+00:00",
    createDate: "2023-08-19T13:06:48.213+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Brass Bangles Set for Ladies is extremely durable, making them great for everyday wear.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 368.594,
    cashPrice: 432.93887,
    taxAll: 54.97092,
    taxInclude: 460.42432,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 184,
    p_adminid: 2,
    productCategory: { id: 184, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 184,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1197,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1198,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1199,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1200,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1201,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 184, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 184,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 184,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 184,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 743,
        imgId: 590,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192716.jpg",
        cropName: "20210906_192716.jpg",
      },
    ],
    modifyDate: "2023-08-19T13:15:45.599+00:00",
    createDate: "2023-08-19T13:10:14.873+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "High Gold Plated Bangle is made from high-quality gold plated, these bangles are durable and long-lasting. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.84999,
    cashPrice: 280.86447,
    taxAll: 38.458996,
    taxInclude: 300.09396,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 185,
    p_adminid: 2,
    productCategory: { id: 185, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 185,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1202,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1203,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1204,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1205,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1206,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 185, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 185,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 185,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 185,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 744,
        imgId: 589,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192709.jpg",
        cropName: "20210906_192709.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:17:22.167+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Perfect fashion jewellery accessory for weddings, festivals, and other special occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 290.45,
    cashPrice: 343.10654,
    taxAll: 47.223,
    taxInclude: 366.71802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 186,
    p_adminid: 2,
    productCategory: { id: 186, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 186,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1207,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1208,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1209,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1210,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1211,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 186, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 186,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 186,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 186,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 745,
        imgId: 588,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192657.jpg",
        cropName: "20210906_192657.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:19:04.900+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Bangles are versatile and can be worn with both traditional and modern outfits. Shop for these beautiful bangles today.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 376.25,
    cashPrice: 444.5455,
    taxAll: 61.341003,
    taxInclude: 475.216,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 187,
    p_adminid: 2,
    productCategory: { id: 187, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 187,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6603,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6604,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6605,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6606,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 6607,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 187, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 187,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 187,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 187,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3268,
        imgId: 614,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194240.jpg",
        cropName: "20210906_194240.jpg",
      },
      {
        id: 3269,
        imgId: 611,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193946.jpg",
        cropName: "20210906_193946.jpg",
      },
      {
        id: 3270,
        imgId: 608,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193809.jpg",
        cropName: "20210906_193809.jpg",
      },
      {
        id: 3271,
        imgId: 620,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194728.jpg",
        cropName: "20210906_194728.jpg",
      },
    ],
    modifyDate: "2023-09-09T13:02:09.271+00:00",
    createDate: "2023-08-19T13:20:35.885+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Brass Bangle collection today and experience the beauty and precision of these unique accessories.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.84999,
    cashPrice: 280.86447,
    taxAll: 38.458996,
    taxInclude: 300.09396,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 188,
    p_adminid: 2,
    productCategory: { id: 188, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 188,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1217,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1218,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1219,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1220,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1221,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 188, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 188,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 188,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 188,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 747,
        imgId: 613,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194232.jpg",
        cropName: "20210906_194232.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:21:35.675+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Brass bangles look great, but they are also very affordable and long-lasting.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 290.45,
    cashPrice: 343.10654,
    taxAll: 47.223,
    taxInclude: 366.71802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 189,
    p_adminid: 2,
    productCategory: { id: 189, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 189,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1222,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1223,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1224,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1225,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1226,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 189, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 189,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 189,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 189,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 748,
        imgId: 612,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194142.jpg",
        cropName: "20210906_194142.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:23:14.475+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription:
      "They are perfect for any occasion, to add a touch of glamour to your everyday look.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 376.25,
    cashPrice: 444.5455,
    taxAll: 61.341003,
    taxInclude: 475.216,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 190,
    p_adminid: 2,
    productCategory: { id: 190, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 190,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6589,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6590,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6591,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6592,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 6593,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6594,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6595,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6596,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 190, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 190,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 190,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 190,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3259,
        imgId: 584,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192341.jpg",
        cropName: "20210906_192341.jpg",
      },
      {
        id: 3260,
        imgId: 587,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192553.jpg",
        cropName: "20210906_192553.jpg",
      },
      {
        id: 3261,
        imgId: 593,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192901.jpg",
        cropName: "20210906_192901.jpg",
      },
      {
        id: 3262,
        imgId: 602,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193516.jpg",
        cropName: "20210906_193516.jpg",
      },
      {
        id: 3263,
        imgId: 611,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193946.jpg",
        cropName: "20210906_193946.jpg",
      },
    ],
    modifyDate: "2023-09-09T12:41:24.725+00:00",
    createDate: "2023-08-19T13:25:41.833+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These brass bangles are made of high-quality materials and are sure to last you a long time.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 334.75,
    cashPrice: 392.9025,
    taxAll: 49.355003,
    taxInclude: 417.58002,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 191,
    p_adminid: 2,
    productCategory: { id: 191, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 191,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1232,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1233,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1234,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1235,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1236,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 191, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 191,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 191,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 191,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 754,
        imgId: 586,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192538.jpg",
        cropName: "20210906_192538.jpg",
      },
      {
        id: 755,
        imgId: 583,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192333.jpg",
        cropName: "20210906_192333.jpg",
      },
      {
        id: 756,
        imgId: 592,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192852.jpg",
        cropName: "20210906_192852.jpg",
      },
      {
        id: 757,
        imgId: 601,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193509.jpg",
        cropName: "20210906_193509.jpg",
      },
      {
        id: 758,
        imgId: 610,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193926.jpg",
        cropName: "20210906_193926.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:27:49.159+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Brass Bangles for women! these bangles are a type of bracelet that is often worn by women in India.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 337.25003,
    cashPrice: 398.37704,
    taxAll: 54.804005,
    taxInclude: 425.77905,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 238.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 192,
    p_adminid: 2,
    productCategory: { id: 192, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 192,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1237,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1238,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1239,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1240,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1241,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 192, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 192,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 192,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 192,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 759,
        imgId: 609,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193914.jpg",
        cropName: "20210906_193914.jpg",
      },
      {
        id: 760,
        imgId: 600,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_193456.jpg",
        cropName: "20210906_193456.jpg",
      },
      {
        id: 761,
        imgId: 591,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192845.jpg",
        cropName: "20210906_192845.jpg",
      },
      {
        id: 762,
        imgId: 585,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192506.jpg",
        cropName: "20210906_192506.jpg",
      },
      {
        id: 763,
        imgId: 582,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_192253.jpg",
        cropName: "20210906_192253.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:29:45.752+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "They are made of a metal called brass, and they are usually decorated with intricate designs.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 376.25,
    cashPrice: 444.5455,
    taxAll: 61.341003,
    taxInclude: 475.216,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 193,
    p_adminid: 2,
    productCategory: { id: 193, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 193,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6636,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6637,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6638,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6639,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6640,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6641,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6642,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 193, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 193,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 193,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 193,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3292,
        imgId: 621,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_195032.jpg",
        cropName: "20210906_195032.jpg",
      },
    ],
    modifyDate: "2023-09-09T13:43:07.936+00:00",
    createDate: "2023-08-19T13:30:59.851+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Brass Bangles Set is designed to fit any wrist size comfortably, ensuring that you can wear them all day without any discomfort.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 390.25,
    cashPrice: 459.3375,
    taxAll: 60.125004,
    taxInclude: 489.4,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 227.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 194,
    p_adminid: 2,
    productCategory: { id: 194, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 194,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6651,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6652,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6653,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6654,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6655,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 6656,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6657,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6658,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 194, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 194,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 194,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 194,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3299,
        imgId: 627,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200132.jpg",
        cropName: "20210906_200132.jpg",
      },
      {
        id: 3300,
        imgId: 624,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200035.jpg",
        cropName: "20210906_200035.jpg",
      },
    ],
    modifyDate: "2023-09-09T13:51:41.360+00:00",
    createDate: "2023-08-19T13:32:46.743+00:00",
    metalGram: 0.0125,
    extraGram: 5.0e-4,
    readyGram: 0.0095,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Brass Bangles Set for Women is designed to give you a touch of antique charm while keeping you in the wedding function.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 250.4,
    cashPrice: 294.411,
    taxAll: 37.941998,
    taxInclude: 313.382,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 400.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 195,
    p_adminid: 2,
    productCategory: { id: 195, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 195,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6643,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6644,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6645,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6646,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6647,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6648,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6649,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 6650,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 195, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 195,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 195,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 195,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3293,
        imgId: 628,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200253.jpg",
        cropName: "20210906_200253.jpg",
      },
      {
        id: 3294,
        imgId: 622,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_195105.jpg",
        cropName: "20210906_195105.jpg",
      },
      {
        id: 3295,
        imgId: 626,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200117.jpg",
        cropName: "20210906_200117.jpg",
      },
      {
        id: 3296,
        imgId: 623,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200009.jpg",
        cropName: "20210906_200009.jpg",
      },
      {
        id: 3297,
        imgId: 629,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200351.jpg",
        cropName: "20210906_200351.jpg",
      },
      {
        id: 3298,
        imgId: 630,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200439.jpg",
        cropName: "20210906_200439.jpg",
      },
    ],
    modifyDate: "2023-09-09T13:48:41.339+00:00",
    createDate: "2023-08-19T13:35:00.131+00:00",
    metalGram: 0.0125,
    extraGram: 5.0e-4,
    readyGram: 0.0095,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "These modern brass Bangles feature a beautiful design and a unique pattern that is sure to turn heads.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 445.15,
    cashPrice: 523.4985,
    taxAll: 67.667,
    taxInclude: 557.332,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 200.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 196,
    p_adminid: 2,
    productCategory: { id: 196, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 196,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6659,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6660,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6661,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6662,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6663,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6664,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 196, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 196,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 196,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 196,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3301,
        imgId: 636,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201238.jpg",
        cropName: "20210906_201238.jpg",
      },
      {
        id: 3302,
        imgId: 632,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200939.jpg",
        cropName: "20210906_200939.jpg",
      },
      {
        id: 3303,
        imgId: 633,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201027.jpg",
        cropName: "20210906_201027.jpg",
      },
      {
        id: 3304,
        imgId: 634,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201117.jpg",
        cropName: "20210906_201117.jpg",
      },
      {
        id: 3305,
        imgId: 635,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_201155.jpg",
        cropName: "20210906_201155.jpg",
      },
      {
        id: 3306,
        imgId: 631,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200910.jpg",
        cropName: "20210906_200910.jpg",
      },
    ],
    modifyDate: "2023-09-09T14:01:37.259+00:00",
    createDate: "2023-08-19T13:37:31.066+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4mm",
    prodManfDetails: "4",
    prodDescription:
      "The design of these brass bangles is exclusive and fancy, making them a perfect addition to any jewellery collection.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 198.49998,
    cashPrice: 233.62497,
    taxAll: 30.549995,
    taxInclude: 248.89996,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 526.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 197,
    p_adminid: 2,
    productCategory: { id: 197, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 197,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 1261,
        lbrId: 2573,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 1262,
        lbrId: 2583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1263,
        lbrId: 2576,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 1264,
        lbrId: 2598,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating Gold Plated",
      },
      {
        id: 1265,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 1266,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 1267,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1268,
        lbrId: 883,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1269,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 197, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 197,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 197,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 197,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 779,
        imgId: 637,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151535.jpg",
        cropName: "20211006_151535.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:39:37.777+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4mm",
    prodManfDetails: "4",
    prodDescription: "Gold-plated mining linning brass bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 380.45,
    cashPrice: 449.0755,
    taxAll: 61.161003,
    taxInclude: 479.656,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 198,
    p_adminid: 2,
    productCategory: { id: 198, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 198,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6576,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6577,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6578,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6579,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6580,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 6581,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6582,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 198, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 198,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 198,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 198,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3253,
        imgId: 638,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211202_131719.jpg",
        cropName: "20211202_131719.jpg",
      },
    ],
    modifyDate: "2023-09-09T12:30:01.205+00:00",
    createDate: "2023-08-19T13:45:24.213+00:00",
    metalGram: 0.0125,
    extraGram: 5.0e-4,
    readyGram: 0.0095,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4mm",
    prodManfDetails: "4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 438.65,
    cashPrice: 516.2835,
    taxAll: 67.536995,
    taxInclude: 550.052,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 200.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 199,
    p_adminid: 2,
    productCategory: { id: 199, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 199,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6814,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6815,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6816,
        lbrId: 976,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 6817,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6818,
        lbrId: 990,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6819,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 199, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 199,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 199,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 199,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3376,
        imgId: 663,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-51.jpg",
        cropName: "2021-08-12-4-45-51.jpg",
      },
      {
        id: 3377,
        imgId: 664,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-52.jpg",
        cropName: "2021-08-12-4-45-52.jpg",
      },
      {
        id: 3378,
        imgId: 665,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-53.jpg",
        cropName: "2021-08-12-4-45-53.jpg",
      },
      {
        id: 3379,
        imgId: 666,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-54.jpg",
        cropName: "2021-08-12-4-45-54.jpg",
      },
      {
        id: 3380,
        imgId: 667,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-55.jpg",
        cropName: "2021-08-12-4-45-55.jpg",
      },
      {
        id: 3381,
        imgId: 668,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-56.jpg",
        cropName: "2021-08-12-4-45-56.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:22:10.943+00:00",
    createDate: "2023-08-19T13:48:53.149+00:00",
    metalGram: 0.014,
    extraGram: 5.0e-4,
    readyGram: 0.0095,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Beautifully crafted pure brass premium quality real designer openable brass bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 285.89,
    cashPrice: 336.93912,
    taxAll: 44.9202,
    taxInclude: 359.3992,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 298.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 200,
    p_adminid: 2,
    productCategory: { id: 200, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 200,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6820,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6821,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6822,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6823,
        lbrId: 990,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6824,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 200, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 200,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 200,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 200,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3382,
        imgId: 673,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-61.jpg",
        cropName: "2021-08-12-4-45-61.jpg",
      },
      {
        id: 3383,
        imgId: 674,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-62.jpg",
        cropName: "2021-08-12-4-45-62.jpg",
      },
      {
        id: 3384,
        imgId: 669,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-57.jpg",
        cropName: "2021-08-12-4-45-57.jpg",
      },
      {
        id: 3385,
        imgId: 670,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-58.jpg",
        cropName: "2021-08-12-4-45-58.jpg",
      },
      {
        id: 3386,
        imgId: 671,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-59.jpg",
        cropName: "2021-08-12-4-45-59.jpg",
      },
      {
        id: 3387,
        imgId: 672,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-60.jpg",
        cropName: "2021-08-12-4-45-60.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:27:44.898+00:00",
    createDate: "2023-08-19T13:50:33.568+00:00",
    metalGram: 0.013,
    extraGram: 5.0e-4,
    readyGram: 0.0085,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "A quite simple and elegant brass bangles design is widely carried by women also. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 271.13,
    cashPrice: 319.37473,
    taxAll: 42.2634,
    taxInclude: 340.5064,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 321.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 201,
    p_adminid: 2,
    productCategory: { id: 201, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 201,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 1286,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 1287,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1288,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1289,
        lbrId: 986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1290,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 1291,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
    ],
    productSets: { id: 201, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 201,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 201,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 201,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 793,
        imgId: 675,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/5019.JPG",
        cropName: "5019.JPG",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T13:53:58.105+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "5",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 310.65,
    cashPrice: 362.8555,
    taxAll: 42.281,
    taxInclude: 383.996,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 202,
    p_adminid: 2,
    productCategory: { id: 202, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 202,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6692,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6693,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6694,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6695,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6696,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6697,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 202, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 202,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 202,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 202,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3313,
        imgId: 676,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_132732.jpg",
        cropName: "20160204_132732.jpg",
      },
      {
        id: 3314,
        imgId: 677,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_133003.jpg",
        cropName: "20160204_133003.jpg",
      },
      {
        id: 3315,
        imgId: 678,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_133232.jpg",
        cropName: "20160204_133232.jpg",
      },
      {
        id: 3316,
        imgId: 680,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_133354.jpg",
        cropName: "20160204_133354.jpg",
      },
      {
        id: 3317,
        imgId: 681,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_133533.jpg",
        cropName: "20160204_133533.jpg",
      },
      {
        id: 3318,
        imgId: 682,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_133637.jpg",
        cropName: "20160204_133637.jpg",
      },
    ],
    modifyDate: "2023-09-09T14:26:30.496+00:00",
    createDate: "2023-08-19T13:55:20.452+00:00",
    metalGram: 0.0125,
    extraGram: 5.0e-4,
    readyGram: 0.0095,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "A stylish women's brass bangles design is quite trendy these days.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 263.75,
    cashPrice: 310.5925,
    taxAll: 40.934998,
    taxInclude: 331.06,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 203,
    p_adminid: 2,
    productCategory: { id: 203, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 203,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6698,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6699,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6700,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6701,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6702,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6703,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 203, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 203,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 203,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 203,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3319,
        imgId: 683,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_135318.jpg",
        cropName: "20160204_135318.jpg",
      },
      {
        id: 3320,
        imgId: 684,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_135733.jpg",
        cropName: "20160204_135733.jpg",
      },
    ],
    modifyDate: "2023-09-09T14:29:29.859+00:00",
    createDate: "2023-08-19T13:56:55.048+00:00",
    metalGram: 0.0135,
    extraGram: 5.0e-4,
    readyGram: 0.0105,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "The brass bangle designs are now worn in marriage functions as the modernized designs with attractive designs are ethically good combinations.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 278.50998,
    cashPrice: 328.15686,
    taxAll: 43.591797,
    taxInclude: 349.95276,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 204,
    p_adminid: 2,
    productCategory: { id: 204, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 204,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6704,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6705,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6706,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6707,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6708,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6709,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 204, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 204,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 204,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 204,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3321,
        imgId: 688,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160225_145525.jpg",
        cropName: "20160225_145525.jpg",
      },
      {
        id: 3322,
        imgId: 689,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160225_145600.jpg",
        cropName: "20160225_145600.jpg",
      },
      {
        id: 3323,
        imgId: 1372,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_141111.jpg",
        cropName: "20160204_141111.jpg",
      },
      {
        id: 3324,
        imgId: 1373,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160225_145308.jpg",
        cropName: "20160225_145308.jpg",
      },
      {
        id: 3325,
        imgId: 1374,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160225_145403.jpg",
        cropName: "20160225_145403.jpg",
      },
      {
        id: 3326,
        imgId: 1375,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160225_145435.jpg",
        cropName: "20160225_145435.jpg",
      },
    ],
    modifyDate: "2023-09-09T14:36:55.926+00:00",
    createDate: "2023-08-19T13:58:22.895+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.0085,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "We offer a wide range of designs in gold plated finish that are suitable for tender skin.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 248.98999,
    cashPrice: 293.02808,
    taxAll: 38.2782,
    taxInclude: 312.16718,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 362.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 205,
    p_adminid: 2,
    productCategory: { id: 205, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 205,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6758,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6759,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6760,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6761,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6762,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6763,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6764,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 205, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 205,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 205,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 205,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3363,
        imgId: 685,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_145819.jpg",
        cropName: "20160204_145819.jpg",
      },
    ],
    modifyDate: "2023-09-11T11:39:22.503+00:00",
    createDate: "2023-08-19T13:59:26.657+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.05,
    cashPrice: 272.41953,
    taxAll: 29.929,
    taxInclude: 287.38403,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 207,
    p_adminid: 2,
    productCategory: { id: 207, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 207,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6790,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6791,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6792,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6793,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6794,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6795,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 207, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 207,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 207,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 207,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3372,
        imgId: 687,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_153932.jpg",
        cropName: "20160204_153932.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:05:09.313+00:00",
    createDate: "2023-08-19T14:05:59.126+00:00",
    metalGram: 0.0145,
    extraGram: 5.0e-4,
    readyGram: 0.0105,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Our bangles are made from high quality materials that will last for long without causing any harm to your child's skin.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 269.0,
    cashPrice: 316.935,
    taxAll: 42.069996,
    taxInclude: 337.97,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 345.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 208,
    p_adminid: 2,
    productCategory: { id: 208, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 208,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6765,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6766,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6767,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6768,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6769,
        lbrId: 991,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 6770,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 208, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 208,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 208,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 208,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3364,
        imgId: 690,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_225543.jpg",
        cropName: "20160226_225543.jpg",
      },
      {
        id: 3365,
        imgId: 691,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_225739.jpg",
        cropName: "20160226_225739.jpg",
      },
      {
        id: 3366,
        imgId: 692,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_225934.jpg",
        cropName: "20160226_225934.jpg",
      },
      {
        id: 3367,
        imgId: 693,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_230325.jpg",
        cropName: "20160226_230325.jpg",
      },
      {
        id: 3368,
        imgId: 694,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_230435.jpg",
        cropName: "20160226_230435.jpg",
      },
    ],
    modifyDate: "2023-09-11T11:45:15.660+00:00",
    createDate: "2023-08-19T14:07:17.463+00:00",
    metalGram: 0.0145,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 739.19995,
    cashPrice: 855.4179,
    taxAll: 84.59599,
    taxInclude: 897.71594,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 172.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 209,
    p_adminid: 2,
    productCategory: { id: 209, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 209,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6784,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6785,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6786,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6787,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6788,
        lbrId: 995,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 6789,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 209, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 209,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 209,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 209,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3371,
        imgId: 706,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_215725.jpg",
        cropName: "20160318_215725.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:02:49.627+00:00",
    createDate: "2023-08-19T14:08:53.285+00:00",
    metalGram: 0.014,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "1. Set of eight gold-plated bangles for women\n2. Stylish latest design party wear traditional bangles set\n3. Special gift for wife, girlfriend, girls,",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 311.09003,
    cashPrice: 365.28912,
    taxAll: 46.180202,
    taxInclude: 388.37924,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 298.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 210,
    p_adminid: 2,
    productCategory: { id: 210, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 210,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6771,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6772,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6773,
        lbrId: 1003,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 6774,
        lbrId: 967,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 6775,
        lbrId: 995,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 6776,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6777,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 210, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 210,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 210,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 210,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3369,
        imgId: 704,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160312_105924.jpg",
        cropName: "20160312_105924.jpg",
      },
    ],
    modifyDate: "2023-09-11T11:55:28.483+00:00",
    createDate: "2023-08-19T14:10:11.612+00:00",
    metalGram: 0.014,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 363.89,
    cashPrice: 424.68912,
    taxAll: 48.8202,
    taxInclude: 449.0992,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 298.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 211,
    p_adminid: 2,
    productCategory: { id: 211, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 211,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6778,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6779,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6780,
        lbrId: 1003,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 6781,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6782,
        lbrId: 995,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 6783,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 211, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 211,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 211,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 211,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3370,
        imgId: 705,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160312_110117.jpg",
        cropName: "20160312_110117.jpg",
      },
    ],
    modifyDate: "2023-09-11T11:59:46.422+00:00",
    createDate: "2023-08-19T14:11:15.461+00:00",
    metalGram: 0.013,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "2 Tone Gold Plated bangles with Silver mindi for girls and women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 181.1,
    cashPrice: 211.6515,
    taxAll: 24.883001,
    taxInclude: 224.093,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 769.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 212,
    p_adminid: 2,
    productCategory: { id: 212, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 212,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7098,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7099,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7100,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 7101,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7102,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7103,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7104,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 212, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 212,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 212,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 212,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3559,
        imgId: 695,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_125601.jpg",
        cropName: "20160229_125601.jpg",
      },
      {
        id: 3560,
        imgId: 696,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_125759.jpg",
        cropName: "20160229_125759.jpg",
      },
      {
        id: 3561,
        imgId: 697,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_130304.jpg",
        cropName: "20160229_130304.jpg",
      },
    ],
    modifyDate: "2023-09-12T07:36:51.492+00:00",
    createDate: "2023-08-19T14:13:03.689+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Give your traditional outfit an ethereal grace with this gold plated pola bangle designed with a stunning Design.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 243.05,
    cashPrice: 282.54453,
    taxAll: 30.379002,
    taxInclude: 297.734,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 213,
    p_adminid: 2,
    productCategory: { id: 213, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 213,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7345,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7346,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7347,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 7348,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7349,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7350,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7351,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 213, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 213,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 213,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 213,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3678,
        imgId: 698,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_130425.jpg",
        cropName: "20160229_130425.jpg",
      },
    ],
    modifyDate: "2023-09-12T11:37:02.758+00:00",
    createDate: "2023-08-19T14:14:42.094+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Wear this traditional gold plated pola with your favorite ethnic sari and set to charm everyone.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 243.05,
    cashPrice: 282.54453,
    taxAll: 30.379002,
    taxInclude: 297.734,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 214,
    p_adminid: 2,
    productCategory: { id: 214, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 214,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7127,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7128,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7129,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 7130,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7131,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7132,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7133,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 214, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 214,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 214,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 214,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3569,
        imgId: 699,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_130559.jpg",
        cropName: "20160229_130559.jpg",
      },
    ],
    modifyDate: "2023-09-12T07:53:30.839+00:00",
    createDate: "2023-08-19T14:15:55.350+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "The creative decoration on this red gold Plated pola with them is all set to make you look dignified and classy.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 240.20001,
    cashPrice: 279.738,
    taxAll: 31.036003,
    taxInclude: 295.256,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 588.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 215,
    p_adminid: 2,
    productCategory: { id: 215, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 215,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 1366,
        lbrId: 1475,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1367,
        lbrId: 1478,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1368,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 1369,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 1370,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1371,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1372,
        lbrId: 986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1373,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 1374,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
    ],
    productSets: { id: 215, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 215,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 215,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 215,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 820,
        imgId: 700,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_130655.jpg",
        cropName: "20160229_130655.jpg",
      },
      {
        id: 821,
        imgId: 701,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_130754.jpg",
        cropName: "20160229_130754.jpg",
      },
      {
        id: 822,
        imgId: 702,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_130924.jpg",
        cropName: "20160229_130924.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-19T14:26:52.325+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Your wedding day by choosing this masterpiece from our Bengali Summer Wedding collection.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 190.95,
    cashPrice: 222.5055,
    taxAll: 24.920998,
    taxInclude: 234.966,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 216,
    p_adminid: 2,
    productCategory: { id: 216, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 216,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7134,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7135,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7136,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 7137,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7138,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7139,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7140,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 216, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 216,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 216,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 216,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3570,
        imgId: 703,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160229_131318.jpg",
        cropName: "20160229_131318.jpg",
      },
    ],
    modifyDate: "2023-09-12T08:04:03.538+00:00",
    createDate: "2023-08-19T14:27:58.449+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "The gold plated pola bangle is exquisitely crafted with a touch of tradition.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.05,
    cashPrice: 272.41953,
    taxAll: 29.929,
    taxInclude: 287.38403,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 217,
    p_adminid: 2,
    productCategory: { id: 217, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 217,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 9226,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 9227,
        lbrId: 972,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "CNC/Laser Marking",
      },
      {
        id: 9228,
        lbrId: 979,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 9229,
        lbrId: 990,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 9230,
        lbrId: 1012,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "ABC Charges\r\n",
      },
      {
        id: 9231,
        lbrId: 1010,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Extra Work\r\n",
      },
      {
        id: 9232,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 9233,
        lbrId: 1003,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Plating Gold Plated & Chrome",
      },
    ],
    productSets: { id: 217, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 217,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 217,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 217,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4325,
        imgId: 707,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160407_122610.jpg",
        cropName: "20160407_122610.jpg",
      },
    ],
    modifyDate: "2023-12-01T05:36:58.130+00:00",
    createDate: "2023-08-19T14:29:17.210+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: 7117,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "This premium quality 2 tone  brass bangle can be used alone or in pairs.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 310.00998,
    cashPrice: 361.1959,
    taxAll: 40.3698,
    taxInclude: 381.3808,
    active: true,
    gstCash: false,
    moqkg: 100.0,
    moq: 379.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 218,
    p_adminid: 2,
    productCategory: { id: 218, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 218,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6839,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6840,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6841,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6842,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6843,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6844,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 218, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 218,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 218,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 218,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3390,
        imgId: 708,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160908_140547.jpg",
        cropName: "20160908_140547.jpg",
      },
      {
        id: 3391,
        imgId: 709,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160908_140936.jpg",
        cropName: "20160908_140936.jpg",
      },
      {
        id: 3392,
        imgId: 710,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160908_141102.jpg",
        cropName: "20160908_141102.jpg",
      },
      {
        id: 3393,
        imgId: 711,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160908_141253.jpg",
        cropName: "20160908_141253.jpg",
      },
      {
        id: 3394,
        imgId: 712,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160908_141850.jpg",
        cropName: "20160908_141850.jpg",
      },
      {
        id: 3395,
        imgId: 713,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160908_142003.jpg",
        cropName: "20160908_142003.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:39:15.695+00:00",
    createDate: "2023-08-19T14:30:58.851+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "The brass metal used for manufacturing is copper, so the jewellery will last longer and will look more attractive over time.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 226.85,
    cashPrice: 266.68152,
    taxAll: 34.293,
    taxInclude: 283.828,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 219,
    p_adminid: 2,
    productCategory: { id: 219, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 219,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6845,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6846,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6847,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6848,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6849,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6850,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 219, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 219,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 219,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 219,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3396,
        imgId: 714,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_130835.jpg",
        cropName: "20160914_130835.jpg",
      },
      {
        id: 3397,
        imgId: 715,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_130944.jpg",
        cropName: "20160914_130944.jpg",
      },
      {
        id: 3398,
        imgId: 716,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_131016.jpg",
        cropName: "20160914_131016.jpg",
      },
      {
        id: 3399,
        imgId: 717,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_131112.jpg",
        cropName: "20160914_131112.jpg",
      },
      {
        id: 3400,
        imgId: 718,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_131224.jpg",
        cropName: "20160914_131224.jpg",
      },
      {
        id: 3401,
        imgId: 719,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_131333.jpg",
        cropName: "20160914_131333.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:54:31.542+00:00",
    createDate: "2023-08-19T14:32:40.421+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "They are perfect for daily wear or occasional use, and they will look great while doing so! Plus, they come in a range of designs that will suit every",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 227.56998,
    cashPrice: 267.53827,
    taxAll: 34.422596,
    taxInclude: 284.74957,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 220,
    p_adminid: 2,
    productCategory: { id: 220, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 220,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 9140,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 9141,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9142,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 9143,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 9144,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9145,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 220, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 220,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 220,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 220,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4264,
        imgId: 720,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_134258.jpg",
        cropName: "20160914_134258.jpg",
      },
      {
        id: 4265,
        imgId: 721,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_134535.jpg",
        cropName: "20160914_134535.jpg",
      },
      {
        id: 4266,
        imgId: 722,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_134803.jpg",
        cropName: "20160914_134803.jpg",
      },
      {
        id: 4267,
        imgId: 723,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_134955.jpg",
        cropName: "20160914_134955.jpg",
      },
      {
        id: 4268,
        imgId: 724,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135045.jpg",
        cropName: "20160914_135045.jpg",
      },
      {
        id: 4269,
        imgId: 725,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135144.jpg",
        cropName: "20160914_135144.jpg",
      },
    ],
    modifyDate: "2023-11-29T05:50:51.454+00:00",
    createDate: "2023-08-19T14:33:57.158+00:00",
    metalGram: 0.0125,
    extraGram: 5.0e-4,
    readyGram: 0.0095,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: 7119,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Customers looking to buy Simple Gold Plated Bangles normally would like to wear simple design imitation jewellery.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 263.75,
    cashPrice: 310.5925,
    taxAll: 40.934998,
    taxInclude: 331.06,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 224,
    p_adminid: 2,
    productCategory: { id: 224, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 224,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6862,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6863,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6864,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6865,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6866,
        lbrId: 979,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 6867,
        lbrId: 990,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6868,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 224, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 224,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 224,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 224,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3414,
        imgId: 726,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135515.jpg",
        cropName: "20160914_135515.jpg",
      },
      {
        id: 3415,
        imgId: 727,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135654.jpg",
        cropName: "20160914_135654.jpg",
      },
      {
        id: 3416,
        imgId: 728,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135800.jpg",
        cropName: "20160914_135800.jpg",
      },
      {
        id: 3417,
        imgId: 1388,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135302.jpg",
        cropName: "20160914_135302.jpg",
      },
      {
        id: 3418,
        imgId: 1389,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135405.jpg",
        cropName: "20160914_135405.jpg",
      },
      {
        id: 3419,
        imgId: 1390,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135443.jpg",
        cropName: "20160914_135443.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:00:35.384+00:00",
    createDate: "2023-08-21T06:51:40.440+00:00",
    metalGram: 0.015,
    extraGram: 5.0e-4,
    readyGram: 0.011,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Attractive finish Classy look Striking design Beautiful pattern.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 306.65,
    cashPrice: 361.2535,
    taxAll: 47.877003,
    taxInclude: 385.19202,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 225,
    p_adminid: 2,
    productCategory: { id: 225, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 225,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6869,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6870,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6871,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6872,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6873,
        lbrId: 990,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 6874,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 225, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 225,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 225,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 225,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3420,
        imgId: 729,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_135910.jpg",
        cropName: "20160914_135910.jpg",
      },
      {
        id: 3421,
        imgId: 730,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140005.jpg",
        cropName: "20160914_140005.jpg",
      },
      {
        id: 3422,
        imgId: 731,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140034.jpg",
        cropName: "20160914_140034.jpg",
      },
      {
        id: 3423,
        imgId: 732,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140106.jpg",
        cropName: "20160914_140106.jpg",
      },
      {
        id: 3424,
        imgId: 733,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140143.jpg",
        cropName: "20160914_140143.jpg",
      },
      {
        id: 3425,
        imgId: 734,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140207.jpg",
        cropName: "20160914_140207.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:03:08.114+00:00",
    createDate: "2023-08-21T06:54:11.702+00:00",
    metalGram: 0.0145,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Simple Gold Plated Brass Bangles with a mild pattern smooth inner surface plus beautifully crafted bangles set.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 296.87,
    cashPrice: 349.7713,
    taxAll: 46.428596,
    taxInclude: 372.9856,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 287.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 226,
    p_adminid: 2,
    productCategory: { id: 226, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 226,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7141,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7142,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7143,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 7144,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7145,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7146,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7147,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 226, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 226,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 226,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 226,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3571,
        imgId: 735,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140259.jpg",
        cropName: "20160914_140259.jpg",
      },
      {
        id: 3572,
        imgId: 738,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140513.jpg",
        cropName: "20160914_140513.jpg",
      },
    ],
    modifyDate: "2023-09-12T08:15:10.538+00:00",
    createDate: "2023-08-21T06:58:14.360+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Shine through the simplicity of this gold plated pola bangle designed with numerous circular patterns simultaneously placed.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 240.20001,
    cashPrice: 279.738,
    taxAll: 31.036003,
    taxInclude: 295.256,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 588.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 227,
    p_adminid: 2,
    productCategory: { id: 227, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 227,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7148,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7149,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7150,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7151,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7152,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7153,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7154,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 227, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 227,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 227,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 227,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3573,
        imgId: 736,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140346.jpg",
        cropName: "20160914_140346.jpg",
      },
      {
        id: 3574,
        imgId: 737,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140450.jpg",
        cropName: "20160914_140450.jpg",
      },
      {
        id: 3575,
        imgId: 739,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140542.jpg",
        cropName: "20160914_140542.jpg",
      },
      {
        id: 3576,
        imgId: 740,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140610.jpg",
        cropName: "20160914_140610.jpg",
      },
      {
        id: 3577,
        imgId: 741,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161007_155236.jpg",
        cropName: "20161007_155236.jpg",
      },
      {
        id: 3578,
        imgId: 742,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161007_155442.jpg",
        cropName: "20161007_155442.jpg",
      },
    ],
    modifyDate: "2023-09-12T08:19:35.212+00:00",
    createDate: "2023-08-21T07:00:43.595+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.05,
    cashPrice: 272.41953,
    taxAll: 29.929,
    taxInclude: 287.38403,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 228,
    p_adminid: 2,
    productCategory: { id: 228, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 228,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 1428,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 1429,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1430,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1431,
        lbrId: 986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1432,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 1433,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
    ],
    productSets: { id: 228, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 228,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 228,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 228,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 864,
        imgId: 743,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161007_155827.jpg",
        cropName: "20161007_155827.jpg",
      },
      {
        id: 865,
        imgId: 744,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161007_155924.jpg",
        cropName: "20161007_155924.jpg",
      },
      {
        id: 866,
        imgId: 745,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161007_160030.jpg",
        cropName: "20161007_160030.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-21T07:06:39.602+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "5",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 175.54999,
    cashPrice: 205.18048,
    taxAll: 24.150997,
    taxInclude: 217.25598,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 229,
    p_adminid: 2,
    productCategory: { id: 229, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 229,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6875,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6876,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6877,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6878,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6879,
        lbrId: 979,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 6880,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6881,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 229, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 229,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 229,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 229,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3426,
        imgId: 746,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_101830.jpg",
        cropName: "20161025_101830.jpg",
      },
      {
        id: 3427,
        imgId: 747,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_102056.jpg",
        cropName: "20161025_102056.jpg",
      },
      {
        id: 3428,
        imgId: 748,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_102209.jpg",
        cropName: "20161025_102209.jpg",
      },
      {
        id: 3429,
        imgId: 749,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_102314.jpg",
        cropName: "20161025_102314.jpg",
      },
      {
        id: 3430,
        imgId: 750,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_102502.jpg",
        cropName: "20161025_102502.jpg",
      },
      {
        id: 3431,
        imgId: 751,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161025_102625.jpg",
        cropName: "20161025_102625.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:04:54.330+00:00",
    createDate: "2023-08-21T07:25:52.972+00:00",
    metalGram: 0.014,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      " Beautiful design self-cutting mild delicate cuttings beautifully crafted bangles set to finish and customers need to handle carefully.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 288.29,
    cashPrice: 339.63913,
    taxAll: 45.040203,
    taxInclude: 362.1592,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 298.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 230,
    p_adminid: 2,
    productCategory: { id: 230, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 230,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6890,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6891,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6892,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6893,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6894,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6895,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6896,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 230, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 230,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 230,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 230,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3438,
        imgId: 752,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_133233.jpg",
        cropName: "20170418_133233.jpg",
      },
      {
        id: 3439,
        imgId: 755,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_133830.jpg",
        cropName: "20170418_133830.jpg",
      },
      {
        id: 3440,
        imgId: 757,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_134138.jpg",
        cropName: "20170418_134138.jpg",
      },
      {
        id: 3441,
        imgId: 760,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_134442.jpg",
        cropName: "20170418_134442.jpg",
      },
      {
        id: 3442,
        imgId: 763,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_134902.jpg",
        cropName: "20170418_134902.jpg",
      },
      {
        id: 3443,
        imgId: 765,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_135417.jpg",
        cropName: "20170418_135417.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:31:45.590+00:00",
    createDate: "2023-08-21T07:27:45.491+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.53,
    cashPrice: 272.9907,
    taxAll: 30.0154,
    taxInclude: 287.9984,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 231,
    p_adminid: 2,
    productCategory: { id: 231, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 231,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6911,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6912,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6913,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6914,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6915,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6916,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6917,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 231, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 231,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 231,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 231,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3456,
        imgId: 753,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_133311.jpg",
        cropName: "20170418_133311.jpg",
      },
      {
        id: 3457,
        imgId: 754,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_133648.jpg",
        cropName: "20170418_133648.jpg",
      },
      {
        id: 3458,
        imgId: 756,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_134103.jpg",
        cropName: "20170418_134103.jpg",
      },
      {
        id: 3459,
        imgId: 758,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_134412.jpg",
        cropName: "20170418_134412.jpg",
      },
      {
        id: 3460,
        imgId: 762,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_134801.jpg",
        cropName: "20170418_134801.jpg",
      },
      {
        id: 3461,
        imgId: 764,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_135339.jpg",
        cropName: "20170418_135339.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:43:46.014+00:00",
    createDate: "2023-08-21T07:29:06.135+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.906,
    cashPrice: 292.98816,
    taxAll: 31.783081,
    taxInclude: 308.8797,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 521.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 232,
    p_adminid: 2,
    productCategory: { id: 232, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 232,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6882,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6883,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6884,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6885,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6886,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6887,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6888,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6889,
        lbrId: 998,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "Hend Cutting Full",
      },
    ],
    productSets: { id: 232, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 232,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 232,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 232,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3432,
        imgId: 766,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_135916.jpg",
        cropName: "20170418_135916.jpg",
      },
      {
        id: 3433,
        imgId: 767,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_140442.jpg",
        cropName: "20170418_140442.jpg",
      },
      {
        id: 3434,
        imgId: 768,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_140549.jpg",
        cropName: "20170418_140549.jpg",
      },
      {
        id: 3435,
        imgId: 769,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_140635.jpg",
        cropName: "20170418_140635.jpg",
      },
      {
        id: 3436,
        imgId: 770,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_140756.jpg",
        cropName: "20170418_140756.jpg",
      },
      {
        id: 3437,
        imgId: 771,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_141634.jpg",
        cropName: "20170418_141634.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:21:32.077+00:00",
    createDate: "2023-08-21T07:31:08.772+00:00",
    metalGram: 0.015,
    extraGram: 5.0e-4,
    readyGram: 0.013,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Traditional gold plated bangle set (set of 4)  is a timeless piece that will provide you with years of beautiful wear.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 371.44998,
    cashPrice: 434.62146,
    taxAll: 52.052998,
    taxInclude: 460.64798,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 233,
    p_adminid: 2,
    productCategory: { id: 233, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 233,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6904,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6905,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6906,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6907,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6908,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6909,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6910,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 233, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 233,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 233,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 233,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3450,
        imgId: 773,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_144603.jpg",
        cropName: "20170418_144603.jpg",
      },
      {
        id: 3451,
        imgId: 774,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_154532.jpg",
        cropName: "20170418_154532.jpg",
      },
      {
        id: 3452,
        imgId: 776,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_154847.jpg",
        cropName: "20170418_154847.jpg",
      },
      {
        id: 3453,
        imgId: 778,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_155135.jpg",
        cropName: "20170418_155135.jpg",
      },
      {
        id: 3454,
        imgId: 780,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_161748.jpg",
        cropName: "20170418_161748.jpg",
      },
      {
        id: 3455,
        imgId: 781,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_161942.jpg",
        cropName: "20170418_161942.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:41:05.185+00:00",
    createDate: "2023-08-21T07:32:17.832+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.53,
    cashPrice: 272.9907,
    taxAll: 30.0154,
    taxInclude: 287.9984,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 234,
    p_adminid: 2,
    productCategory: { id: 234, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 234,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6918,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6919,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6920,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6921,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6922,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6923,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6924,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 234, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 234,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 234,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 234,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3462,
        imgId: 772,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_144528.jpg",
        cropName: "20170418_144528.jpg",
      },
      {
        id: 3463,
        imgId: 775,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_154719.jpg",
        cropName: "20170418_154719.jpg",
      },
      {
        id: 3464,
        imgId: 777,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_155048.jpg",
        cropName: "20170418_155048.jpg",
      },
      {
        id: 3465,
        imgId: 779,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_160429.jpg",
        cropName: "20170418_160429.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:47:50.541+00:00",
    createDate: "2023-08-21T07:33:55.730+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.906,
    cashPrice: 292.98816,
    taxAll: 31.783081,
    taxInclude: 308.8797,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 521.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 235,
    p_adminid: 2,
    productCategory: { id: 235, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 235,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6925,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6926,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6927,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6928,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6929,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6930,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6931,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 235, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 235,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 235,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 235,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3466,
        imgId: 783,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_162515.jpg",
        cropName: "20170418_162515.jpg",
      },
      {
        id: 3467,
        imgId: 785,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_163359.jpg",
        cropName: "20170418_163359.jpg",
      },
      {
        id: 3468,
        imgId: 788,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_163843.jpg",
        cropName: "20170418_163843.jpg",
      },
      {
        id: 3469,
        imgId: 791,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_164632.jpg",
        cropName: "20170418_164632.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:52:04.989+00:00",
    createDate: "2023-08-21T07:42:19.008+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.05,
    cashPrice: 272.41953,
    taxAll: 29.929,
    taxInclude: 287.38403,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 236,
    p_adminid: 2,
    productCategory: { id: 236, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 236,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6932,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6933,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6934,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6935,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 6936,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6937,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6938,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 236, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 236,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 236,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 236,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3470,
        imgId: 782,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_162222.jpg",
        cropName: "20170418_162222.jpg",
      },
      {
        id: 3471,
        imgId: 784,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_163228.jpg",
        cropName: "20170418_163228.jpg",
      },
      {
        id: 3472,
        imgId: 786,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_163521.jpg",
        cropName: "20170418_163521.jpg",
      },
      {
        id: 3473,
        imgId: 787,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_163739.jpg",
        cropName: "20170418_163739.jpg",
      },
      {
        id: 3474,
        imgId: 789,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_164250.jpg",
        cropName: "20170418_164250.jpg",
      },
      {
        id: 3475,
        imgId: 790,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_164539.jpg",
        cropName: "20170418_164539.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:55:51.109+00:00",
    createDate: "2023-08-21T07:43:27.145+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.906,
    cashPrice: 292.98816,
    taxAll: 31.783081,
    taxInclude: 308.8797,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 521.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 237,
    p_adminid: 2,
    productCategory: { id: 237, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 237,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6939,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6940,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6941,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6942,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6943,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6944,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 237, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 237,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 237,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 237,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3476,
        imgId: 792,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_154656.jpg",
        cropName: "20180127_154656.jpg",
      },
    ],
    modifyDate: "2023-09-11T13:59:31.978+00:00",
    createDate: "2023-08-21T07:45:27.834+00:00",
    metalGram: 0.012,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "The metal-brass alloy with gold plating is made to last, while the bangle a touch of unmatched elegance.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 260.83398,
    cashPrice: 306.88846,
    taxAll: 39.94212,
    taxInclude: 326.8595,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 347.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 238,
    p_adminid: 2,
    productCategory: { id: 238, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 238,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 1485,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 1486,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1487,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1488,
        lbrId: 986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1489,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 238, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 238,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 238,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 238,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 912,
        imgId: 793,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_154849.jpg",
        cropName: "20180127_154849.jpg",
      },
      {
        id: 913,
        imgId: 794,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_155111.jpg",
        cropName: "20180127_155111.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-21T07:46:58.500+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Stunning antique gold Plated bangle set (set of 4)  is a timeless piece that will provide you with years of beautiful wear.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 247.65,
    cashPrice: 291.98047,
    taxAll: 39.130997,
    taxInclude: 311.54596,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 239,
    p_adminid: 2,
    productCategory: { id: 239, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 239,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6951,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6952,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6953,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6954,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6955,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 6956,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 239, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 239,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 239,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 239,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3478,
        imgId: 795,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_182748.jpg",
        cropName: "20180127_182748.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:10:20.700+00:00",
    createDate: "2023-08-21T07:48:29.368+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Different sizes are available and if customers choose the correct size, these gold-plated bangles would be beautiful to wear. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 226.85,
    cashPrice: 266.68152,
    taxAll: 34.293,
    taxInclude: 283.828,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 240,
    p_adminid: 2,
    productCategory: { id: 240, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 240,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 1495,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 1496,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1497,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1498,
        lbrId: 986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1499,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 240, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 240,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 240,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 240,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 915,
        imgId: 796,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_182942.jpg",
        cropName: "20180127_182942.jpg",
      },
      {
        id: 916,
        imgId: 797,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_183101.jpg",
        cropName: "20180127_183101.jpg",
      },
      {
        id: 917,
        imgId: 798,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_183240.jpg",
        cropName: "20180127_183240.jpg",
      },
      {
        id: 918,
        imgId: 799,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_183322.jpg",
        cropName: "20180127_183322.jpg",
      },
      {
        id: 919,
        imgId: 800,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_183438.jpg",
        cropName: "20180127_183438.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-21T07:52:23.813+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "For daily wear, the colour life will last from 4 months to 6 months. For occasional wear, colour life will last longer.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 247.65,
    cashPrice: 291.98047,
    taxAll: 39.130997,
    taxInclude: 311.54596,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 241,
    p_adminid: 2,
    productCategory: { id: 241, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 241,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6963,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6964,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6965,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6966,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6967,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6968,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 241, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 241,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 241,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 241,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3482,
        imgId: 801,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_123451.jpg",
        cropName: "20180128_123451.jpg",
      },
      {
        id: 3483,
        imgId: 803,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_123811.jpg",
        cropName: "20180128_123811.jpg",
      },
      {
        id: 3484,
        imgId: 807,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_124351.jpg",
        cropName: "20180128_124351.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:17:50.725+00:00",
    createDate: "2023-08-21T07:53:48.002+00:00",
    metalGram: 0.0135,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 278.50998,
    cashPrice: 328.15686,
    taxAll: 43.591797,
    taxInclude: 349.95276,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 242,
    p_adminid: 2,
    productCategory: { id: 242, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 242,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6957,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6958,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6959,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6960,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6961,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6962,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 242, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 242,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 242,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 242,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3479,
        imgId: 802,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_123556.jpg",
        cropName: "20180128_123556.jpg",
      },
      {
        id: 3480,
        imgId: 804,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_123856.jpg",
        cropName: "20180128_123856.jpg",
      },
      {
        id: 3481,
        imgId: 806,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_124145.jpg",
        cropName: "20180128_124145.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:14:47.100+00:00",
    createDate: "2023-08-21T07:54:56.126+00:00",
    metalGram: 0.0135,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.69998,
    cashPrice: 302.298,
    taxAll: 39.856,
    taxInclude: 322.22598,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 370.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 243,
    p_adminid: 2,
    productCategory: { id: 243, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 243,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6969,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6970,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6971,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6972,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6973,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 6974,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 243, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 243,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 243,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 243,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3485,
        imgId: 808,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_095136.jpg",
        cropName: "20190204_095136.jpg",
      },
      {
        id: 3486,
        imgId: 809,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_095346.jpg",
        cropName: "20190204_095346.jpg",
      },
      {
        id: 3487,
        imgId: 810,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_095440.jpg",
        cropName: "20190204_095440.jpg",
      },
      {
        id: 3488,
        imgId: 811,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_095536.jpg",
        cropName: "20190204_095536.jpg",
      },
      {
        id: 3489,
        imgId: 812,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_095638.jpg",
        cropName: "20190204_095638.jpg",
      },
      {
        id: 3490,
        imgId: 813,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190204_095743.jpg",
        cropName: "20190204_095743.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:19:16.742+00:00",
    createDate: "2023-08-21T07:56:25.000+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 227.56998,
    cashPrice: 267.53827,
    taxAll: 34.422596,
    taxInclude: 284.74957,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 244,
    p_adminid: 2,
    productCategory: { id: 244, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 244,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6989,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6990,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6991,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6992,
        lbrId: 1006,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 6993,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6994,
        lbrId: 991,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 6995,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6996,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 244, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 244,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 244,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 244,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3493,
        imgId: 814,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190501_200754.jpg",
        cropName: "20190501_200754.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:29:15.069+00:00",
    createDate: "2023-08-21T08:11:01.419+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 680.65,
    cashPrice: 785.273,
    taxAll: 73.116,
    taxInclude: 821.83105,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 238.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 245,
    p_adminid: 2,
    productCategory: { id: 245, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 245,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6982,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6983,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6984,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6985,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6986,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6987,
        lbrId: 991,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 6988,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 245, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 245,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 245,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 245,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3492,
        imgId: 815,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190501_200832.jpg",
        cropName: "20190501_200832.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:26:17.559+00:00",
    createDate: "2023-08-21T08:12:19.273+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 352.44998,
    cashPrice: 413.37646,
    taxAll: 51.363,
    taxInclude: 439.05798,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 246,
    p_adminid: 2,
    productCategory: { id: 246, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 246,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6975,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6976,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6977,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 6978,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6979,
        lbrId: 991,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 6980,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6981,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 246, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 246,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 246,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 246,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3491,
        imgId: 816,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190501_200926.jpg",
        cropName: "20190501_200926.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:24:14.165+00:00",
    createDate: "2023-08-21T08:13:46.805+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "These bangles are perfect for weddings, festivals, and other special occasions.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 261.28998,
    cashPrice: 306.72906,
    taxAll: 38.620197,
    taxInclude: 326.03918,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 379.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 247,
    p_adminid: 2,
    productCategory: { id: 247, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 247,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6997,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6998,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6999,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 7000,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7001,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7002,
        lbrId: 990,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7003,
        lbrId: 979,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 7004,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 247, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 247,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 247,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 247,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3494,
        imgId: 817,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_170436.jpg",
        cropName: "20190509_170436.jpg",
      },
      {
        id: 3495,
        imgId: 818,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_171107.jpg",
        cropName: "20190509_171107.jpg",
      },
      {
        id: 3496,
        imgId: 819,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_171354.jpg",
        cropName: "20190509_171354.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:31:18.076+00:00",
    createDate: "2023-08-21T08:15:26.555+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Designer Pola Fitting Brass Bangle Kangan Set of 4 Pieces.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 286.85,
    cashPrice: 334.18152,
    taxAll: 37.293,
    taxInclude: 352.828,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 248,
    p_adminid: 2,
    productCategory: { id: 248, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 248,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 1536,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 1537,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1538,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1539,
        lbrId: 986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1540,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 248, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 248,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 248,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 248,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 938,
        imgId: 820,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190510_125102.jpg",
        cropName: "20190510_125102.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-21T08:56:17.902+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "They will match any Western or fusion outfit and add a touch of glamour to your look.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 247.65,
    cashPrice: 291.98047,
    taxAll: 39.130997,
    taxInclude: 311.54596,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 249,
    p_adminid: 2,
    productCategory: { id: 249, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 249,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7005,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7006,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7007,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7008,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7009,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7010,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 249, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 249,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 249,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 249,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3497,
        imgId: 821,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190510_125930.jpg",
        cropName: "20190510_125930.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:32:59.862+00:00",
    createDate: "2023-08-21T08:57:45.055+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "This stunning set of four bangles is made of high-quality brass with gold plating.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 230.45001,
    cashPrice: 270.7315,
    taxAll: 34.473,
    taxInclude: 287.96802,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 250,
    p_adminid: 2,
    productCategory: { id: 250, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 250,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7011,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7012,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7013,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7014,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7015,
        lbrId: 988,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7016,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 250, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 250,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 250,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 250,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3498,
        imgId: 822,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190510_132418.jpg",
        cropName: "20190510_132418.jpg",
      },
    ],
    modifyDate: "2023-09-11T14:34:51.445+00:00",
    createDate: "2023-08-21T09:01:30.446+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.0085,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Daily Wear Gold Plated Ladies' Brass Bangles for casual occasions. Imitation daily wears cute bangles make a perfect jewellery gift for girls & women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 249.818,
    cashPrice: 294.01343,
    taxAll: 38.42724,
    taxInclude: 313.22705,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 362.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 251,
    p_adminid: 2,
    productCategory: { id: 251, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 251,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6808,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6809,
        lbrId: 1003,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 6810,
        lbrId: 970,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "V Pokal Solder",
      },
      {
        id: 6811,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6812,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6813,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
    ],
    productSets: { id: 251, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 251,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 251,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 251,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3375,
        imgId: 823,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194838.jpg",
        cropName: "20210906_194838.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:19:16.967+00:00",
    createDate: "2023-08-21T09:02:51.416+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "These dual-tone bangles showcase an enchanting blend of grace and modernity.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 305.68997,
    cashPrice: 356.67908,
    taxAll: 40.8402,
    taxInclude: 377.09918,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 379.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 252,
    p_adminid: 2,
    productCategory: { id: 252, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 252,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6802,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6803,
        lbrId: 970,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "V Pokal Solder",
      },
      {
        id: 6804,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6805,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 6806,
        lbrId: 1003,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 6807,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 252, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 252,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 252,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 252,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3374,
        imgId: 824,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_194907.jpg",
        cropName: "20210906_194907.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:17:54.124+00:00",
    createDate: "2023-08-21T09:04:04.392+00:00",
    metalGram: 0.012,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Magnificent Two-Tone Gold Plated Bangles for Girls and Women. It can be worn daily or occasionally.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 292.05,
    cashPrice: 341.17947,
    taxAll: 39.849,
    taxInclude: 361.10397,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 253,
    p_adminid: 2,
    productCategory: { id: 253, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 253,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 7722,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7723,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7724,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7725,
        lbrId: 1013,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7726,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7727,
        lbrId: 991,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 7728,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 253, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 253,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 253,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 253,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3806,
        imgId: 825,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220325_135641.jpg",
        cropName: "20220325_135641.jpg",
      },
    ],
    modifyDate: "2023-09-14T09:52:04.472+00:00",
    createDate: "2023-08-21T09:05:25.529+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      " Wear this traditional gold Plated pola with your favourite ethnic sari and set to charm everyone.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 282.53796,
    cashPrice: 328.73816,
    taxAll: 35.892838,
    taxInclude: 346.68457,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 463.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 254,
    p_adminid: 2,
    productCategory: { id: 254, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 254,
      adminId: 2,
      parentId: 113,
      categoryId: 11,
      title: "5/3MM",
    },
    productChild: [
      {
        id: 7155,
        lbrId: 1067,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Solder",
      },
      {
        id: 7156,
        lbrId: 1073,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7157,
        lbrId: 1084,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 7158,
        lbrId: 1100,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7159,
        lbrId: 1112,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7160,
        lbrId: 1075,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Micro Dell",
      },
      {
        id: 7161,
        lbrId: 1086,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7162,
        lbrId: 1110,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 254, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 254,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 254,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 254,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3579,
        imgId: 639,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_190341.jpg",
        cropName: "20150910_190341.jpg",
      },
    ],
    modifyDate: "2023-09-12T08:31:39.629+00:00",
    createDate: "2023-08-21T09:15:08.785+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5/3MM",
    prodManfDetails: "5",
    prodDescription:
      "The creative decoration on this green gold plated pola with channel work in it is all set to make you look dignified and classy.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 373.55798,
    cashPrice: 434.751,
    taxAll: 47.674442,
    taxInclude: 458.58823,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 327.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 255,
    p_adminid: 2,
    productCategory: { id: 255, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 255,
      adminId: 2,
      parentId: 113,
      categoryId: 11,
      title: "5/3MM",
    },
    productChild: [
      {
        id: 7177,
        lbrId: 1067,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Solder",
      },
      {
        id: 7178,
        lbrId: 1073,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7179,
        lbrId: 1100,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7180,
        lbrId: 1112,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7181,
        lbrId: 1075,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Micro Dell",
      },
      {
        id: 7182,
        lbrId: 1086,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7183,
        lbrId: 1110,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 255, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 255,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 255,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 255,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3586,
        imgId: 640,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_133428.jpg",
        cropName: "20170418_133428.jpg",
      },
      {
        id: 3587,
        imgId: 641,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_133938.jpg",
        cropName: "20170418_133938.jpg",
      },
      {
        id: 3588,
        imgId: 642,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_134224.jpg",
        cropName: "20170418_134224.jpg",
      },
      {
        id: 3589,
        imgId: 643,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_134558.jpg",
        cropName: "20170418_134558.jpg",
      },
      {
        id: 3590,
        imgId: 644,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_135018.jpg",
        cropName: "20170418_135018.jpg",
      },
      {
        id: 3591,
        imgId: 645,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_135454.jpg",
        cropName: "20170418_135454.jpg",
      },
    ],
    modifyDate: "2023-09-12T08:41:44.314+00:00",
    createDate: "2023-08-21T09:18:16.357+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5/3MM",
    prodManfDetails: "5",
    prodDescription:
      "Married women are blessed with this pola to ward off evil from their lives.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 368.158,
    cashPrice: 428.676,
    taxAll: 47.40444,
    taxInclude: 452.37823,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 327.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 256,
    p_adminid: 2,
    productCategory: { id: 256, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 256,
      adminId: 2,
      parentId: 113,
      categoryId: 11,
      title: "5/3MM",
    },
    productChild: [
      {
        id: 7226,
        lbrId: 1067,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Solder",
      },
      {
        id: 7227,
        lbrId: 1073,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7228,
        lbrId: 1076,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 7229,
        lbrId: 1100,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7230,
        lbrId: 1112,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7231,
        lbrId: 1086,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7232,
        lbrId: 1110,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 256, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 256,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 256,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 256,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3612,
        imgId: 646,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_144342.jpg",
        cropName: "20170418_144342.jpg",
      },
      {
        id: 3613,
        imgId: 647,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_154431.jpg",
        cropName: "20170418_154431.jpg",
      },
      {
        id: 3614,
        imgId: 649,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_154938.jpg",
        cropName: "20170418_154938.jpg",
      },
      {
        id: 3615,
        imgId: 650,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_155230.jpg",
        cropName: "20170418_155230.jpg",
      },
      {
        id: 3616,
        imgId: 651,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_160319.jpg",
        cropName: "20170418_160319.jpg",
      },
      {
        id: 3617,
        imgId: 652,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_162037.jpg",
        cropName: "20170418_162037.jpg",
      },
    ],
    modifyDate: "2023-09-12T09:14:31.489+00:00",
    createDate: "2023-08-21T09:20:09.459+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5/3MM",
    prodManfDetails: "5",
    prodDescription:
      "The gold-plated pasting pola is made for your casual wear to bring strength and happiness to your life.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 364.55798,
    cashPrice: 424.626,
    taxAll: 47.22444,
    taxInclude: 448.23822,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 327.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 257,
    p_adminid: 2,
    productCategory: { id: 257, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 257,
      adminId: 2,
      parentId: 113,
      categoryId: 11,
      title: "5/3MM",
    },
    productChild: [
      {
        id: 7233,
        lbrId: 1067,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Solder",
      },
      {
        id: 7234,
        lbrId: 1073,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7235,
        lbrId: 1100,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7236,
        lbrId: 1112,
        price: 2.25,
        taxPercentage: 5.0,
        tax: 0.1125,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7237,
        lbrId: 1086,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7238,
        lbrId: 1075,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Micro Dell",
      },
      {
        id: 7239,
        lbrId: 1110,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 257, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 257,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 257,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 257,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3618,
        imgId: 653,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_162733.jpg",
        cropName: "20170418_162733.jpg",
      },
      {
        id: 3619,
        imgId: 654,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_163058.jpg",
        cropName: "20170418_163058.jpg",
      },
      {
        id: 3620,
        imgId: 655,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_164025.jpg",
        cropName: "20170418_164025.jpg",
      },
      {
        id: 3621,
        imgId: 656,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_164136.jpg",
        cropName: "20170418_164136.jpg",
      },
      {
        id: 3622,
        imgId: 657,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_164739.jpg",
        cropName: "20170418_164739.jpg",
      },
      {
        id: 3623,
        imgId: 658,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_164904.jpg",
        cropName: "20170418_164904.jpg",
      },
    ],
    modifyDate: "2023-09-12T09:18:40.071+00:00",
    createDate: "2023-08-21T09:23:41.815+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.0055,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5/3MM",
    prodManfDetails: "5",
    prodDescription:
      "Wear this modern channel gold plated pola bangle as a casual use with any attire to magnify your beauty.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 368.158,
    cashPrice: 428.676,
    taxAll: 47.40444,
    taxInclude: 452.37823,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 327.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 258,
    p_adminid: 2,
    productCategory: { id: 258, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 258,
      adminId: 2,
      parentId: 113,
      categoryId: 11,
      title: "5/3MM",
    },
    productChild: [
      {
        id: 7240,
        lbrId: 1067,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Solder",
      },
      {
        id: 7241,
        lbrId: 1073,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7242,
        lbrId: 1100,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7243,
        lbrId: 1075,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Micro Dell",
      },
      {
        id: 7244,
        lbrId: 1088,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7245,
        lbrId: 1110,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 258, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 258,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 258,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 258,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3624,
        imgId: 659,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_123032.jpg",
        cropName: "20180128_123032.jpg",
      },
      {
        id: 3625,
        imgId: 660,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_123317.jpg",
        cropName: "20180128_123317.jpg",
      },
      {
        id: 3626,
        imgId: 661,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180128_124230.jpg",
        cropName: "20180128_124230.jpg",
      },
    ],
    modifyDate: "2023-09-12T09:23:09.636+00:00",
    createDate: "2023-08-21T09:25:36.553+00:00",
    metalGram: 0.016,
    extraGram: 5.0e-4,
    readyGram: 0.0012,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5/3MM",
    prodManfDetails: "5",
    prodDescription:
      "This pair of exquisite gold-plated weightless brass bangles can be worn every day or for a special occasion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 458.09,
    cashPrice: 540.25806,
    taxAll: 72.7182,
    taxInclude: 576.6172,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 174.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 259,
    p_adminid: 2,
    productCategory: { id: 259, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 259,
      adminId: 2,
      parentId: 113,
      categoryId: 11,
      title: "5/3MM",
    },
    productChild: [
      {
        id: 7246,
        lbrId: 1067,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Solder",
      },
      {
        id: 7247,
        lbrId: 1073,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7248,
        lbrId: 1100,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 7249,
        lbrId: 1075,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Micro Dell",
      },
      {
        id: 7250,
        lbrId: 1086,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7251,
        lbrId: 1110,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 259, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 259,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 259,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 259,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3627,
        imgId: 662,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190510_132122.jpg",
        cropName: "20190510_132122.jpg",
      },
    ],
    modifyDate: "2023-09-12T09:28:21.307+00:00",
    createDate: "2023-08-21T09:26:51.736+00:00",
    metalGram: 0.012,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5/3MM",
    prodManfDetails: "5",
    prodDescription:
      "This Pair of beautiful brass Bangles can be worn daily or on a special occasion. Ideal for sarees and other traditional costumes.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 360.026,
    cashPrice: 424.26395,
    taxAll: 56.47068,
    taxInclude: 452.49927,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 231.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 260,
    p_adminid: 2,
    productCategory: { id: 260, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 260,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7371,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7372,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7373,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 7374,
        lbrId: 1178,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 7375,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7376,
        lbrId: 1189,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
    ],
    productSets: { id: 260, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 260,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 260,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 260,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3689,
        imgId: 826,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-51.jpg",
        cropName: "2021-08-12-4-45-51.jpg",
      },
      {
        id: 3690,
        imgId: 827,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-52.jpg",
        cropName: "2021-08-12-4-45-52.jpg",
      },
      {
        id: 3691,
        imgId: 828,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-53.jpg",
        cropName: "2021-08-12-4-45-53.jpg",
      },
      {
        id: 3692,
        imgId: 829,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-54.jpg",
        cropName: "2021-08-12-4-45-54.jpg",
      },
      {
        id: 3693,
        imgId: 830,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-55.jpg",
        cropName: "2021-08-12-4-45-55.jpg",
      },
      {
        id: 3694,
        imgId: 831,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-56.jpg",
        cropName: "2021-08-12-4-45-56.jpg",
      },
    ],
    modifyDate: "2023-09-12T13:19:35.759+00:00",
    createDate: "2023-08-21T09:29:36.927+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0085,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "A versatile pair of imitation bangles is for the cute hands of one. These beautiful pretty bangles make perfect jewellery.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 236.45001,
    cashPrice: 277.4815,
    taxAll: 34.773003,
    taxInclude: 294.868,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 261,
    p_adminid: 2,
    productCategory: { id: 261, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 261,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1611,
        lbrId: 722,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 1612,
        lbrId: 727,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 1613,
        lbrId: 730,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 1614,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1615,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1616,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1617,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1618,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 261, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 261,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 261,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 261,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 979,
        imgId: 832,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-57.jpg",
        cropName: "2021-08-12-4-45-57.jpg",
      },
      {
        id: 980,
        imgId: 833,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-58.jpg",
        cropName: "2021-08-12-4-45-58.jpg",
      },
      {
        id: 981,
        imgId: 834,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-59.jpg",
        cropName: "2021-08-12-4-45-59.jpg",
      },
      {
        id: 982,
        imgId: 835,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-60.jpg",
        cropName: "2021-08-12-4-45-60.jpg",
      },
      {
        id: 983,
        imgId: 836,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-61.jpg",
        cropName: "2021-08-12-4-45-61.jpg",
      },
      {
        id: 984,
        imgId: 837,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2021-08-12-4-45-62.jpg",
        cropName: "2021-08-12-4-45-62.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-21T09:33:15.794+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Gorgeous Gold Plated Bangles for daily wear, Can be used as pairs or sets.  Ideal for sarees and other traditional costumes for any age women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 257.45,
    cashPrice: 303.0055,
    taxAll: 39.620995,
    taxInclude: 322.816,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 262,
    p_adminid: 2,
    productCategory: { id: 262, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 262,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7520,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7521,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7522,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 7523,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 7524,
        lbrId: 1178,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 7525,
        lbrId: 1190,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 7526,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 262, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 262,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 262,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 262,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3741,
        imgId: 851,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_203956.jpg",
        cropName: "20160318_203956.jpg",
      },
      {
        id: 3742,
        imgId: 852,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_204203.jpg",
        cropName: "20160318_204203.jpg",
      },
      {
        id: 3743,
        imgId: 853,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_204319.jpg",
        cropName: "20160318_204319.jpg",
      },
      {
        id: 3744,
        imgId: 854,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_204419.jpg",
        cropName: "20160318_204419.jpg",
      },
      {
        id: 3745,
        imgId: 855,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_204518.jpg",
        cropName: "20160318_204518.jpg",
      },
      {
        id: 3746,
        imgId: 856,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_204746.jpg",
        cropName: "20160318_204746.jpg",
      },
    ],
    modifyDate: "2023-09-14T07:58:42.472+00:00",
    createDate: "2023-08-21T09:35:07.411+00:00",
    metalGram: 0.017,
    extraGram: 5.0e-4,
    readyGram: 0.0125,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "This pair of beautiful gold-plated bangles can be worn every day or for a special occasion. Ideal for sarees and other traditional costumes.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 348.17,
    cashPrice: 409.8823,
    taxAll: 53.7906,
    taxInclude: 436.7776,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 263,
    p_adminid: 2,
    productCategory: { id: 263, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 263,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1624,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1625,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1626,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1627,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1628,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 263, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 263,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 263,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 263,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 991,
        imgId: 857,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214245.jpg",
        cropName: "20160318_214245.jpg",
      },
      {
        id: 992,
        imgId: 858,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214442.jpg",
        cropName: "20160318_214442.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-21T09:36:32.516+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Gold Plated Designer Daily Wear brass Bangles for all age women. It can be used as pairs or sets.  Ideal for sarees and other traditional costumes.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 257.45,
    cashPrice: 303.0055,
    taxAll: 39.620995,
    taxInclude: 322.816,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 264,
    p_adminid: 2,
    productCategory: { id: 264, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 264,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7401,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7402,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7403,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 7404,
        lbrId: 1180,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Kanas Meena",
      },
      {
        id: 7405,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7406,
        lbrId: 1181,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Kanas Diamond",
      },
      {
        id: 7407,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 264, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 264,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 264,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 264,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3710,
        imgId: 859,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164622.jpg",
        cropName: "20160330_164622.jpg",
      },
      {
        id: 3711,
        imgId: 860,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164706.jpg",
        cropName: "20160330_164706.jpg",
      },
      {
        id: 3712,
        imgId: 861,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164806.jpg",
        cropName: "20160330_164806.jpg",
      },
      {
        id: 3713,
        imgId: 862,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164853.jpg",
        cropName: "20160330_164853.jpg",
      },
      {
        id: 3714,
        imgId: 863,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164940.jpg",
        cropName: "20160330_164940.jpg",
      },
      {
        id: 3715,
        imgId: 864,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_165303.jpg",
        cropName: "20160330_165303.jpg",
      },
    ],
    modifyDate: "2023-09-12T13:55:30.062+00:00",
    createDate: "2023-08-21T09:38:23.168+00:00",
    metalGram: 0.0175,
    extraGram: 5.0e-4,
    readyGram: 0.0115,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "These unique stylish high-quality brass bangles pair light up your wrist and add style to any outfit. ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 385.73,
    cashPrice: 452.8627,
    taxAll: 57.119404,
    taxInclude: 481.42242,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 238.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 265,
    p_adminid: 2,
    productCategory: { id: 265, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 265,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1634,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1635,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 1636,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1637,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 1638,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 265, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 265,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 265,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 265,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 999,
        imgId: 838,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150703-WA0040.jpg",
        cropName: "20150703-WA0040.jpg",
      },
      {
        id: 1000,
        imgId: 839,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150703-WA0041.jpg",
        cropName: "20150703-WA0041.jpg",
      },
      {
        id: 1001,
        imgId: 840,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150703-WA0042.jpg",
        cropName: "20150703-WA0042.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T06:18:10.515+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6mm",
    prodManfDetails: "6",
    prodDescription:
      "Indian Manufacturer Gold Plated Brass Bangles 3 Tone Top Quality Latest Designed Fancy Bangles For Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 335.5,
    cashPrice: 385.74,
    taxAll: 33.38,
    taxInclude: 402.43,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 266,
    p_adminid: 2,
    productCategory: { id: 266, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 266,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7252,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7253,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7254,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 7255,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7256,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7257,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7258,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7259,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7260,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 266, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 266,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 266,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 266,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3628,
        imgId: 844,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_193129.jpg",
        cropName: "20150910_193129.jpg",
      },
      {
        id: 3629,
        imgId: 845,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_193141.jpg",
        cropName: "20150910_193141.jpg",
      },
      {
        id: 3630,
        imgId: 846,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_193152.jpg",
        cropName: "20150910_193152.jpg",
      },
      {
        id: 3631,
        imgId: 847,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_193243.jpg",
        cropName: "20150910_193243.jpg",
      },
      {
        id: 3632,
        imgId: 848,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_193251.jpg",
        cropName: "20150910_193251.jpg",
      },
    ],
    modifyDate: "2023-09-12T09:41:32.879+00:00",
    createDate: "2023-08-22T06:47:18.073+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "The 3 tone bangles are a classic yet contemporary set of bangles for that very special occasion such as a birthday, push present or just to complement",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 408.6,
    cashPrice: 469.674,
    taxAll: 40.428,
    taxInclude: 489.88803,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 476.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 267,
    p_adminid: 2,
    productCategory: { id: 267, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 267,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 9023,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 9024,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 9025,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 9026,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 9027,
        lbrId: 1170,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Eaching",
      },
      {
        id: 9028,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9029,
        lbrId: 1191,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 9030,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 267, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 267,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 267,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 267,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4228,
        imgId: 2463,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_183010.jpg",
        cropName: "20150910_183010.jpg",
      },
    ],
    modifyDate: "2023-10-19T08:09:43.055+00:00",
    createDate: "2023-08-22T06:48:48.762+00:00",
    metalGram: 0.012,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Fancy Style 6 MM Three Tone Plated Eaching Mother Merry Bangle fashion jewellery for women to use daily.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 503.1,
    cashPrice: 575.88904,
    taxAll: 44.958,
    taxInclude: 598.36804,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 268,
    p_adminid: 2,
    productCategory: { id: 268, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 268,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7511,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7512,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7513,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 7514,
        lbrId: 1170,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Eaching",
      },
      {
        id: 7515,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7516,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7517,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7518,
        lbrId: 1191,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7519,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 268, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 268,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 268,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 268,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3739,
        imgId: 842,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_183347.jpg",
        cropName: "20150910_183347.jpg",
      },
      {
        id: 3740,
        imgId: 843,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_183347.jpg",
        cropName: "20150910_183347.jpg",
      },
    ],
    modifyDate: "2023-09-14T07:56:42.388+00:00",
    createDate: "2023-08-22T06:49:49.511+00:00",
    metalGram: 0.012,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "3-Tone High-Quality Luxury Design Fashion Jewelry Eaching Elephant Bangle.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 273.8,
    cashPrice: 313.4545,
    taxAll: 24.549002,
    taxInclude: 325.729,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 269,
    p_adminid: 2,
    productCategory: { id: 269, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 269,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1656,
        lbrId: 1171,
        price: 5.0,
        taxPercentage: 5.0,
        tax: 0.25,
        title: "CNC/Laser Marking",
      },
      {
        id: 1657,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1658,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1659,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 1660,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 269, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 269,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 269,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 269,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1010,
        imgId: 849,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_154907.jpg",
        cropName: "20160204_154907.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T06:52:27.389+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 278.5,
    cashPrice: 321.61502,
    taxAll: 30.53,
    taxInclude: 336.88,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 270,
    p_adminid: 2,
    productCategory: { id: 270, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 270,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1661,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1662,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 1663,
        lbrId: 1171,
        price: 5.0,
        taxPercentage: 5.0,
        tax: 0.25,
        title: "CNC/Laser Marking",
      },
      {
        id: 1664,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1665,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 270, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 270,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 270,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 270,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1011,
        imgId: 850,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_155215.jpg",
        cropName: "20160204_155215.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T06:53:43.463+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 278.5,
    cashPrice: 321.61502,
    taxAll: 30.53,
    taxInclude: 336.88,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 271,
    p_adminid: 2,
    productCategory: { id: 271, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 271,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7417,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7418,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7419,
        lbrId: 1170,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Eaching",
      },
      {
        id: 7420,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7421,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7422,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7423,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7424,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7425,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 271, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 271,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 271,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 271,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3718,
        imgId: 866,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160629_160529.jpg",
        cropName: "20160629_160529.jpg",
      },
      {
        id: 3719,
        imgId: 870,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160629_160653.jpg",
        cropName: "20160629_160653.jpg",
      },
    ],
    modifyDate: "2023-09-12T14:08:10.883+00:00",
    createDate: "2023-08-22T06:56:53.028+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "3 Tone Kada bangles bracelet\n* Premium Indian collections.\n* Gold, silver and Rose gold Plating.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.7,
    cashPrice: 602.998,
    taxAll: 47.256,
    taxInclude: 626.626,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 455.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 272,
    p_adminid: 2,
    productCategory: { id: 272, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 272,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7408,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7409,
        lbrId: 1170,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Eaching",
      },
      {
        id: 7410,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7411,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7412,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7413,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7414,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7415,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7416,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 272, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 272,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 272,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 272,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3716,
        imgId: 865,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160629_160500.jpg",
        cropName: "20160629_160500.jpg",
      },
      {
        id: 3717,
        imgId: 869,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160629_160625.jpg",
        cropName: "20160629_160625.jpg",
      },
    ],
    modifyDate: "2023-09-12T13:59:15.675+00:00",
    createDate: "2023-08-22T06:59:34.134+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Manufacturer Gold Plated Brass Bangles 3 Tone Top Quality Latest Designed Fancy Bangles For Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.7,
    cashPrice: 602.998,
    taxAll: 47.256,
    taxInclude: 626.626,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 455.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 273,
    p_adminid: 2,
    productCategory: { id: 273, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 273,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7426,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7427,
        lbrId: 1170,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Eaching",
      },
      {
        id: 7428,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7429,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7430,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7431,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7432,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7433,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7434,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 273, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 273,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 273,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 273,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3720,
        imgId: 867,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160629_160554.jpg",
        cropName: "20160629_160554.jpg",
      },
    ],
    modifyDate: "2023-09-12T14:12:10.201+00:00",
    createDate: "2023-08-22T07:01:15.702+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Stylish Beautifully Designed Three Tone Plated 6 MM Bangle which looks colourful and shiny and adds ups to your fashion.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.7,
    cashPrice: 602.998,
    taxAll: 47.256,
    taxInclude: 626.626,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 455.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 274,
    p_adminid: 2,
    productCategory: { id: 274, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 274,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1686,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 1687,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 1688,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 1689,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 1690,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1691,
        lbrId: 1205,
        price: 7.5,
        taxPercentage: 5.0,
        tax: 0.375,
        title: "Plating Micro Gold\r\n",
      },
    ],
    productSets: { id: 274, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 274,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 274,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 274,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1017,
        imgId: 868,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190913-WA0030.jpeg",
        cropName: "20190913-WA0030.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T07:02:36.001+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 182.0,
    cashPrice: 209.38,
    taxAll: 18.36,
    taxInclude: 218.56,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 275,
    p_adminid: 2,
    productCategory: { id: 275, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 275,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7261,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7262,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7263,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7264,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7265,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7266,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7267,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 7268,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 275, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 275,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 275,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 275,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3633,
        imgId: 871,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160819_100208.jpg",
        cropName: "20160819_100208.jpg",
      },
      {
        id: 3634,
        imgId: 872,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160819_100550.jpg",
        cropName: "20160819_100550.jpg",
      },
      {
        id: 3635,
        imgId: 873,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160819_100804.jpg",
        cropName: "20160819_100804.jpg",
      },
      {
        id: 3636,
        imgId: 875,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160819_101117.jpg",
        cropName: "20160819_101117.jpg",
      },
      {
        id: 3637,
        imgId: 876,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160819_101523.jpg",
        cropName: "20160819_101523.jpg",
      },
      {
        id: 3638,
        imgId: 877,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160819_101610.jpg",
        cropName: "20160819_101610.jpg",
      },
    ],
    modifyDate: "2023-09-12T09:49:33.608+00:00",
    createDate: "2023-08-22T07:05:36.208+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.008,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6 MM Three-Tone Plated Fancy Bangle with creative designs with a pleasant look which gives a creative touch to your everyday look.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 753.4,
    cashPrice: 866.596,
    taxAll: 75.712006,
    taxInclude: 904.452,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 217.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 276,
    p_adminid: 2,
    productCategory: { id: 276, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 276,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7269,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7270,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7271,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 7272,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7273,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7274,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7275,
        lbrId: 1186,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7276,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7277,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 276, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 276,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 276,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 276,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3639,
        imgId: 878,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_150248.jpg",
        cropName: "20160904_150248.jpg",
      },
      {
        id: 3640,
        imgId: 879,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_150712.jpg",
        cropName: "20160904_150712.jpg",
      },
      {
        id: 3641,
        imgId: 881,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_150801.jpg",
        cropName: "20160904_150801.jpg",
      },
      {
        id: 3642,
        imgId: 882,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_150917.jpg",
        cropName: "20160904_150917.jpg",
      },
      {
        id: 3643,
        imgId: 883,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_151022.jpg",
        cropName: "20160904_151022.jpg",
      },
      {
        id: 3644,
        imgId: 884,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160904_151120.jpg",
        cropName: "20160904_151120.jpg",
      },
    ],
    modifyDate: "2023-09-12T10:10:18.134+00:00",
    createDate: "2023-08-22T07:10:30.819+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "Daily Wear Regular 3-Tone Bangle Bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1362.85,
    cashPrice: 1568.5715,
    taxAll: 138.873,
    taxInclude: 1638.008,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 136.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 277,
    p_adminid: 2,
    productCategory: { id: 277, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 277,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7303,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7304,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7305,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7306,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7307,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7308,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 277, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 277,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 277,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 277,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3663,
        imgId: 897,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_113444.jpg",
        cropName: "20161024_113444.jpg",
      },
      {
        id: 3664,
        imgId: 895,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_113255.jpg",
        cropName: "20161024_113255.jpg",
      },
    ],
    modifyDate: "2023-09-12T10:56:28.313+00:00",
    createDate: "2023-08-22T07:12:53.572+00:00",
    metalGram: 0.016,
    extraGram: 5.0e-4,
    readyGram: 0.011,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "New Style Indian 3 Tone Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1417.66,
    cashPrice: 1641.303,
    taxAll: 163.75381,
    taxInclude: 1723.1798,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 89.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 278,
    p_adminid: 2,
    productCategory: { id: 278, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 278,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1718,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 1719,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 1720,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 1721,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 1722,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1723,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 278, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 278,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 278,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 278,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1038,
        imgId: 896,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_113335.jpg",
        cropName: "20161024_113335.jpg",
      },
      {
        id: 1039,
        imgId: 898,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_113515.jpg",
        cropName: "20161024_113515.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T07:21:39.719+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "3 Tone High-Quality Luxury Design Fashion Jewelry Bangle.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 656.5,
    cashPrice: 755.185,
    taxAll: 66.07,
    taxInclude: 788.22003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 279,
    p_adminid: 2,
    productCategory: { id: 279, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 279,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7471,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7472,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7473,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 7474,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 7475,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7476,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7477,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7478,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7479,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 279, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 279,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 279,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 279,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3725,
        imgId: 889,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133329.jpg",
        cropName: "20160914_133329.jpg",
      },
    ],
    modifyDate: "2023-09-12T14:25:59.316+00:00",
    createDate: "2023-08-22T07:25:03.719+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Manufacturer Jewelry Wholesale Traditional Trendy Gold Tone Textured Flower Design Bangle Bridal Wedding Indian Bangle Set.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 468.5,
    cashPrice: 546.15497,
    taxAll: 61.61,
    taxInclude: 576.95996,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 280,
    p_adminid: 2,
    productCategory: { id: 280, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 280,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1729,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 1730,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1731,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 1732,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 1733,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1734,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1735,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
    ],
    productSets: { id: 280, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 280,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 280,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 280,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1041,
        imgId: 890,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133453.jpg",
        cropName: "20160914_133453.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T07:30:10.052+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Jewellery Manufactures Gold Plated Glossy Designer Flower design Kada Bangle Bracelet Jewelry For Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 599.5,
    cashPrice: 703.865,
    taxAll: 88.83,
    taxInclude: 748.28,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 281,
    p_adminid: 2,
    productCategory: { id: 281, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 281,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7462,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7463,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7464,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7465,
        lbrId: 1205,
        price: 7.5,
        taxPercentage: 5.0,
        tax: 0.375,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 7466,
        lbrId: 1170,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Eaching",
      },
      {
        id: 7467,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7468,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7469,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7470,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 281, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 281,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 281,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 281,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3724,
        imgId: 891,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133651.jpg",
        cropName: "20160914_133651.jpg",
      },
    ],
    modifyDate: "2023-09-12T14:23:02.690+00:00",
    createDate: "2023-08-22T07:32:54.359+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6 MM imitation Micro gold plated high polished women's bangle classic and trendy modern hand bracelet fashion and fancy brand new design.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1012.9,
    cashPrice: 1160.451,
    taxAll: 92.522,
    taxInclude: 1206.712,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 227.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 282,
    p_adminid: 2,
    productCategory: { id: 282, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 282,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7453,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7454,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7455,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7456,
        lbrId: 1205,
        price: 7.5,
        taxPercentage: 5.0,
        tax: 0.375,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 7457,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7458,
        lbrId: 1170,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Eaching",
      },
      {
        id: 7459,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7460,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7461,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
    ],
    productSets: { id: 282, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 282,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 282,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 282,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3723,
        imgId: 892,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133844.jpg",
        cropName: "20160914_133844.jpg",
      },
    ],
    modifyDate: "2023-09-12T14:18:25.502+00:00",
    createDate: "2023-08-22T07:34:22.559+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Wholesale Jewelry Micro Gold Plated Glossy flower Designer Bangle Bracelet For Women Jewelry.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 516.7,
    cashPrice: 591.748,
    taxAll: 46.756,
    taxInclude: 615.126,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 455.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 283,
    p_adminid: 2,
    productCategory: { id: 283, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 283,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7435,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7436,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7437,
        lbrId: 1170,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Eaching",
      },
      {
        id: 7438,
        lbrId: 1205,
        price: 7.5,
        taxPercentage: 5.0,
        tax: 0.375,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 7439,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7440,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7441,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7442,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7443,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 283, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 283,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 283,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 283,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3721,
        imgId: 894,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133933.jpg",
        cropName: "20160914_133933.jpg",
      },
    ],
    modifyDate: "2023-09-12T14:14:49.486+00:00",
    createDate: "2023-08-22T07:37:05.296+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Traditional Bangle Wholesale Eaching Bangle Bridal Wedding Bracelet Bangle Indian Manufacturer Jewelry.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 516.7,
    cashPrice: 591.748,
    taxAll: 46.756,
    taxInclude: 615.126,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 455.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 284,
    p_adminid: 2,
    productCategory: { id: 284, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 284,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7337,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7338,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7339,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7340,
        lbrId: 1203,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Plating Bright Silver",
      },
      {
        id: 7341,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7342,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7343,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7344,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 284, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 284,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 284,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 284,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3677,
        imgId: 899,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_113634.jpg",
        cropName: "20161024_113634.jpg",
      },
    ],
    modifyDate: "2023-09-12T11:20:57.075+00:00",
    createDate: "2023-08-22T07:38:52.192+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Elegantly tracing the natural contour of a wrist, this beautiful silver bangle is a stunning example of Scandinavian minimalism.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 932.525,
    cashPrice: 1079.9174,
    taxAll: 108.279495,
    taxInclude: 1134.0571,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 136.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 285,
    p_adminid: 2,
    productCategory: { id: 285, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 285,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7322,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7323,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7324,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7325,
        lbrId: 1203,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Plating Bright Silver",
      },
      {
        id: 7326,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7327,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7328,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7329,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 285, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 285,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 285,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 285,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3673,
        imgId: 900,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_113741.jpg",
        cropName: "20161024_113741.jpg",
      },
    ],
    modifyDate: "2023-09-12T11:07:32.558+00:00",
    createDate: "2023-08-22T07:46:12.913+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Worn singly or stacked with other bracelets, it makes the perfect gift for a stylish and sophisticated woman.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 578.7,
    cashPrice: 671.053,
    taxAll: 68.966,
    taxInclude: 705.536,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 238.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 286,
    p_adminid: 2,
    productCategory: { id: 286, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 286,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7480,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7481,
        lbrId: 1202,
        price: 2.45,
        taxPercentage: 5.0,
        tax: 0.1225,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 7482,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7483,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7484,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7485,
        lbrId: 1191,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7486,
        lbrId: 1193,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7487,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7488,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 286, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 286,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 286,
      adminId: 2,
      rawpriceId: 204,
      title: "17 Gauge Brass Tube 1.4mm",
      price: 610.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 121.5,
    },
    productPacking: {
      id: 286,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3726,
        imgId: 901,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_123443.jpg",
        cropName: "20170217_123443.jpg",
      },
      {
        id: 3727,
        imgId: 902,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_124210.jpg",
        cropName: "20170217_124210.jpg",
      },
      {
        id: 3728,
        imgId: 906,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_125117.jpg",
        cropName: "20170217_125117.jpg",
      },
      {
        id: 3729,
        imgId: 908,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_125540.jpg",
        cropName: "20170217_125540.jpg",
      },
      {
        id: 3730,
        imgId: 903,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_124404.jpg",
        cropName: "20170217_124404.jpg",
      },
    ],
    modifyDate: "2023-09-12T14:29:50.992+00:00",
    createDate: "2023-08-22T07:59:28.060+00:00",
    metalGram: 0.016,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 483.65002,
    cashPrice: 562.8355,
    taxAll: 61.641003,
    taxInclude: 593.656,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 260.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 287,
    p_adminid: 2,
    productCategory: { id: 287, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 287,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1776,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1777,
        lbrId: 1171,
        price: 5.0,
        taxPercentage: 5.0,
        tax: 0.25,
        title: "CNC/Laser Marking",
      },
      {
        id: 1778,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1779,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1780,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1781,
        lbrId: 1202,
        price: 2.45,
        taxPercentage: 5.0,
        tax: 0.1225,
        title: "Plating Gold Plated & Chrome",
      },
    ],
    productSets: { id: 287, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 287,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 287,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 287,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1052,
        imgId: 904,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_124734.jpg",
        cropName: "20170217_124734.jpg",
      },
      {
        id: 1053,
        imgId: 905,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_124904.jpg",
        cropName: "20170217_124904.jpg",
      },
      {
        id: 1054,
        imgId: 907,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170217_125218.jpg",
        cropName: "20170217_125218.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T08:01:06.294+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 453.45,
    cashPrice: 523.5055,
    taxAll: 49.420998,
    taxInclude: 548.216,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 288,
    p_adminid: 2,
    productCategory: { id: 288, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 288,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7287,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7288,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7289,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7290,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7291,
        lbrId: 1180,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Kanas Meena",
      },
      {
        id: 7292,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7293,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 288, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 288,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 288,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 288,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3651,
        imgId: 911,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_193824.jpg",
        cropName: "20170306_193824.jpg",
      },
      {
        id: 3652,
        imgId: 912,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_193934.jpg",
        cropName: "20170306_193934.jpg",
      },
      {
        id: 3653,
        imgId: 914,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194339.jpg",
        cropName: "20170306_194339.jpg",
      },
      {
        id: 3654,
        imgId: 916,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194624.jpg",
        cropName: "20170306_194624.jpg",
      },
      {
        id: 3655,
        imgId: 921,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194954.jpg",
        cropName: "20170306_194954.jpg",
      },
      {
        id: 3656,
        imgId: 925,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_195407.jpg",
        cropName: "20170306_195407.jpg",
      },
    ],
    modifyDate: "2023-09-12T10:48:19.322+00:00",
    createDate: "2023-08-22T08:03:52.551+00:00",
    metalGram: 0.016,
    extraGram: 5.0e-4,
    readyGram: 0.011,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Fashionable Three Tone Plated 2 MM Bangle which is perfect to wear every day whether it is your casual or professional look.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1505.16,
    cashPrice: 1739.7405,
    taxAll: 168.12881,
    taxInclude: 1823.8048,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 89.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 289,
    p_adminid: 2,
    productCategory: { id: 289, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 289,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7294,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7295,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 7296,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7297,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7298,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7299,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7300,
        lbrId: 1180,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Kanas Meena",
      },
      {
        id: 7301,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7302,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 289, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 289,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 289,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 289,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3657,
        imgId: 913,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194007.jpg",
        cropName: "20170306_194007.jpg",
      },
      {
        id: 3658,
        imgId: 915,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194453.jpg",
        cropName: "20170306_194453.jpg",
      },
      {
        id: 3659,
        imgId: 918,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194649.jpg",
        cropName: "20170306_194649.jpg",
      },
      {
        id: 3660,
        imgId: 920,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_194817.jpg",
        cropName: "20170306_194817.jpg",
      },
      {
        id: 3661,
        imgId: 922,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_195021.jpg",
        cropName: "20170306_195021.jpg",
      },
      {
        id: 3662,
        imgId: 923,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_195121.jpg",
        cropName: "20170306_195121.jpg",
      },
    ],
    modifyDate: "2023-09-12T10:51:40.152+00:00",
    createDate: "2023-08-22T08:12:38.612+00:00",
    metalGram: 0.015,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6 MM three-tone imitation stylish women's bangle classic trending hand bracelet brand new modern designs all occasion and event.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 865.3,
    cashPrice: 998.19696,
    taxAll: 92.73399,
    taxInclude: 1044.564,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 167.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 290,
    p_adminid: 2,
    productCategory: { id: 290, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 290,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1801,
        lbrId: 817,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 1802,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 1803,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 1804,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 1805,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1806,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 1807,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 290, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 290,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 290,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 290,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1073,
        imgId: 924,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_195219.jpg",
        cropName: "20170306_195219.jpg",
      },
      {
        id: 1074,
        imgId: 926,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_195444.jpg",
        cropName: "20170306_195444.jpg",
      },
      {
        id: 1075,
        imgId: 928,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_195846.jpg",
        cropName: "20170306_195846.jpg",
      },
      {
        id: 1076,
        imgId: 930,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_200050.jpg",
        cropName: "20170306_200050.jpg",
      },
      {
        id: 1077,
        imgId: 934,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_200253.jpg",
        cropName: "20170306_200253.jpg",
      },
      {
        id: 1078,
        imgId: 935,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_200324.jpg",
        cropName: "20170306_200324.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T08:15:35.468+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Beautiful Three Tone Plated 6 MM imitation luxury bangle and bracelets super classic and trendy.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 656.5,
    cashPrice: 755.185,
    taxAll: 66.07,
    taxInclude: 788.22003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 291,
    p_adminid: 2,
    productCategory: { id: 291, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 291,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7489,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7490,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7491,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 7492,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7493,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7494,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 291, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 291,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 291,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 291,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3731,
        imgId: 936,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_155157.jpg",
        cropName: "20180127_155157.jpg",
      },
    ],
    modifyDate: "2023-09-14T07:44:07.256+00:00",
    createDate: "2023-08-22T08:17:14.930+00:00",
    metalGram: 0.0145,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Jewelry Bangles Bracelet Gold Plated Glossy Engraved Bangle Bracelet Jewelry Manufacture, Wholesaler Supplier.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 325.66998,
    cashPrice: 382.17126,
    taxAll: 47.8686,
    taxInclude: 406.10556,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 287.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 292,
    p_adminid: 2,
    productCategory: { id: 292, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 292,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7495,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7496,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7497,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7498,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 7499,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7500,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7501,
        lbrId: 1182,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 7502,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 292, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 292,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 292,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 292,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3732,
        imgId: 937,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_181838.jpg",
        cropName: "20180127_181838.jpg",
      },
      {
        id: 3733,
        imgId: 938,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_182023.jpg",
        cropName: "20180127_182023.jpg",
      },
      {
        id: 3734,
        imgId: 940,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_182149.jpg",
        cropName: "20180127_182149.jpg",
      },
      {
        id: 3735,
        imgId: 941,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_182316.jpg",
        cropName: "20180127_182316.jpg",
      },
      {
        id: 3736,
        imgId: 942,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_182422.jpg",
        cropName: "20180127_182422.jpg",
      },
      {
        id: 3737,
        imgId: 943,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_182514.jpg",
        cropName: "20180127_182514.jpg",
      },
    ],
    modifyDate: "2023-09-14T07:48:09.951+00:00",
    createDate: "2023-08-22T08:19:27.848+00:00",
    metalGram: 0.0145,
    extraGram: 5.0e-4,
    readyGram: 0.0095,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Manufacture Jewelry Gold Plated Glossy Designer Bangle Bracelet For Women and Girls.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 324.314,
    cashPrice: 380.71365,
    taxAll: 47.936516,
    taxInclude: 404.68192,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 287.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 293,
    p_adminid: 2,
    productCategory: { id: 293, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 293,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1821,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1822,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1823,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1824,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1825,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 293, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 293,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 293,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 293,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1086,
        imgId: 944,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0008.jpg",
        cropName: "20180213-WA0008.jpg",
      },
      {
        id: 1087,
        imgId: 945,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0009.jpg",
        cropName: "20180213-WA0009.jpg",
      },
      {
        id: 1088,
        imgId: 946,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0010.jpg",
        cropName: "20180213-WA0010.jpg",
      },
      {
        id: 1089,
        imgId: 947,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0011.jpg",
        cropName: "20180213-WA0011.jpg",
      },
      {
        id: 1090,
        imgId: 948,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0012.jpg",
        cropName: "20180213-WA0012.jpg",
      },
      {
        id: 1091,
        imgId: 949,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0013.jpg",
        cropName: "20180213-WA0013.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T08:21:19.895+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Traditional Jewelry Antique Designer Gold Tone Bridal Wedding Bangle Indian Manufacturer Jewelry Wholesale Bangle Set.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 259.55,
    cashPrice: 305.5045,
    taxAll: 39.998997,
    taxInclude: 325.50397,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 294,
    p_adminid: 2,
    productCategory: { id: 294, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 294,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1826,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1827,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1828,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1829,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1830,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 1831,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 294, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 294,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 294,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 294,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1092,
        imgId: 950,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0017.jpg",
        cropName: "20180213-WA0017.jpg",
      },
      {
        id: 1093,
        imgId: 951,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0018.jpg",
        cropName: "20180213-WA0018.jpg",
      },
      {
        id: 1094,
        imgId: 952,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0019.jpg",
        cropName: "20180213-WA0019.jpg",
      },
      {
        id: 1095,
        imgId: 953,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0022.jpg",
        cropName: "20180213-WA0022.jpg",
      },
      {
        id: 1096,
        imgId: 954,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0023.jpg",
        cropName: "20180213-WA0023.jpg",
      },
      {
        id: 1097,
        imgId: 955,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0024.jpg",
        cropName: "20180213-WA0024.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T09:19:40.850+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Bangle Set Gold Plated Glossy Designer Engraved Bangle Bracelet Jewellery Manufactures Wholesaler.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 263.75,
    cashPrice: 310.2295,
    taxAll: 40.209,
    taxInclude: 330.33398,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 295,
    p_adminid: 2,
    productCategory: { id: 295, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 295,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1832,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1833,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1834,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1835,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1836,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 1837,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 295, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 295,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 295,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 295,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1098,
        imgId: 956,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0025.jpg",
        cropName: "20180213-WA0025.jpg",
      },
      {
        id: 1099,
        imgId: 957,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0026.jpg",
        cropName: "20180213-WA0026.jpg",
      },
      {
        id: 1100,
        imgId: 958,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0027.jpg",
        cropName: "20180213-WA0027.jpg",
      },
      {
        id: 1101,
        imgId: 959,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0029.jpg",
        cropName: "20180213-WA0029.jpg",
      },
      {
        id: 1102,
        imgId: 960,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0032.jpg",
        cropName: "20180213-WA0032.jpg",
      },
      {
        id: 1103,
        imgId: 961,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0034.jpg",
        cropName: "20180213-WA0034.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T09:37:18.857+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Bangle Set Traditional Trendy Gold Tone Textured Engraved Bangle Bridal Wedding Bangle Indian Manufacturer Jewelry.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 263.75,
    cashPrice: 310.2295,
    taxAll: 40.209,
    taxInclude: 330.33398,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 296,
    p_adminid: 2,
    productCategory: { id: 296, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 296,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1838,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1839,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1840,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1841,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1842,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 296, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 296,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 296,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 296,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1104,
        imgId: 962,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0035.jpg",
        cropName: "20180213-WA0035.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T09:38:41.450+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Indian Manufacturer Jewelry Wholesale Traditional Trendy Gold Plated Bangle Bridal Wedding Indian Bangle Set.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 259.55,
    cashPrice: 305.5045,
    taxAll: 39.998997,
    taxInclude: 325.50397,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 297,
    p_adminid: 2,
    productCategory: { id: 297, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 297,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 2979,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 2980,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 2981,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 2982,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 2983,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2984,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 2985,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2986,
        lbrId: 1194,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 297, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 297,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 297,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 297,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1664,
        imgId: 963,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_163215.jpg",
        cropName: "20190509_163215.jpg",
      },
      {
        id: 1665,
        imgId: 965,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_163610.jpg",
        cropName: "20190509_163610.jpg",
      },
      {
        id: 1666,
        imgId: 966,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_164127.jpg",
        cropName: "20190509_164127.jpg",
      },
      {
        id: 1667,
        imgId: 967,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_164357.jpg",
        cropName: "20190509_164357.jpg",
      },
      {
        id: 1668,
        imgId: 968,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_164535.jpg",
        cropName: "20190509_164535.jpg",
      },
      {
        id: 1669,
        imgId: 969,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190509_164812.jpg",
        cropName: "20190509_164812.jpg",
      },
    ],
    modifyDate: "2023-08-26T05:02:22.410+00:00",
    createDate: "2023-08-22T09:40:45.459+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "00 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6 MM three-tone gold plated fancy and fashionable bangle super trendy and classic occasion wear.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 744.3,
    cashPrice: 855.50696,
    taxAll: 73.554,
    taxInclude: 892.284,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 263.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 298,
    p_adminid: 2,
    productCategory: { id: 298, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 298,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1850,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1851,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1852,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1853,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1854,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 1855,
        lbrId: 1196,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Hand Cutting Single",
      },
    ],
    productSets: { id: 298, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 298,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 298,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 298,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1112,
        imgId: 972,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190913-WA0046.jpg",
        cropName: "20190913-WA0046.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T09:53:33.424+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 287.55,
    cashPrice: 337.0045,
    taxAll: 41.399,
    taxInclude: 357.70398,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 299,
    p_adminid: 2,
    productCategory: { id: 299, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 299,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7682,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7683,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7684,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7685,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 7686,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7687,
        lbrId: 1180,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Kanas Meena",
      },
      {
        id: 7688,
        lbrId: 1186,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
    ],
    productSets: { id: 299, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 299,
      adminId: 2,
      boxId: 71,
      brandName: "Plastic Box No.7",
      boxDesc: "15 Sets In 1 Box",
      pkgCharge: 15.0,
      box: "15",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 299,
      adminId: 2,
      rawpriceId: 192,
      title: "Rolling Dell Die Patti",
      price: 610.0,
      different: 18.0,
      taxPercentage: 18.0,
      tax: 113.04,
    },
    productPacking: {
      id: 299,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3797,
        imgId: 971,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190913-WA0030.jpeg",
        cropName: "20190913-WA0030.jpeg",
      },
      {
        id: 3798,
        imgId: 1413,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_182705.jpg",
        cropName: "20171013_182705.jpg",
      },
    ],
    modifyDate: "2023-09-14T09:36:06.901+00:00",
    createDate: "2023-08-22T09:56:02.762+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6mm",
    prodManfDetails: "6",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 662.93005,
    cashPrice: 774.6668,
    taxAll: 90.8874,
    taxInclude: 820.1105,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 152.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 300,
    p_adminid: 2,
    productCategory: { id: 300, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 300,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 1862,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1863,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1864,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1865,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1866,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 1867,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 300, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 300,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 300,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 300,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1114,
        imgId: 973,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200648.jpg",
        cropName: "20210906_200648.jpg",
      },
      {
        id: 1115,
        imgId: 974,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20210906_200750.jpg",
        cropName: "20210906_200750.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:01:05.563+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 263.75,
    cashPrice: 310.2295,
    taxAll: 40.209,
    taxInclude: 330.33398,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 301,
    p_adminid: 2,
    productCategory: { id: 301, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 301,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 1868,
        lbrId: 1368,
        price: 0.7,
        taxPercentage: 5.0,
        tax: 0.035,
        title: "Solder",
      },
      {
        id: 1869,
        lbrId: 1374,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 1870,
        lbrId: 1377,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1871,
        lbrId: 1385,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1872,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 1873,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 301, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 301,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 301,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 301,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1116,
        imgId: 997,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_173846.jpg",
        cropName: "20190813_173846.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:03:03.854+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7MM",
    prodManfDetails: "7",
    prodDescription: "7",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 134.6,
    cashPrice: 157.81502,
    taxAll: 19.509998,
    taxInclude: 167.57,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 302,
    p_adminid: 2,
    productCategory: { id: 302, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 302,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 1874,
        lbrId: 1374,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 1875,
        lbrId: 1377,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1876,
        lbrId: 1368,
        price: 0.7,
        taxPercentage: 5.0,
        tax: 0.035,
        title: "Solder",
      },
      {
        id: 1877,
        lbrId: 1385,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1878,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 1879,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 302, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 302,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 302,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 302,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1117,
        imgId: 999,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174321.jpg",
        cropName: "20190813_174321.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:05:55.671+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7MM",
    prodManfDetails: "7",
    prodDescription: "7",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 230.9,
    cashPrice: 270.727,
    taxAll: 33.474003,
    taxInclude: 287.464,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 303,
    p_adminid: 2,
    productCategory: { id: 303, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 303,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 1880,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 1881,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 1882,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 1883,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1884,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 1885,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 1886,
        lbrId: 1368,
        price: 0.7,
        taxPercentage: 5.0,
        tax: 0.035,
        title: "Solder",
      },
      {
        id: 1887,
        lbrId: 1374,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 1888,
        lbrId: 1377,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1889,
        lbrId: 1385,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1890,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 303, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 303,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 303,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 303,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1118,
        imgId: 998,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174208.jpg",
        cropName: "20190813_174208.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:08:30.924+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7MM",
    prodManfDetails: "7",
    prodDescription: "7",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 502.10004,
    cashPrice: 585.81104,
    taxAll: 67.002,
    taxInclude: 619.3121,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 304,
    p_adminid: 2,
    productCategory: { id: 304, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 304,
      adminId: 2,
      parentId: 120,
      categoryId: 11,
      title: "7.2MM",
    },
    productChild: [
      {
        id: 7533,
        lbrId: 1418,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 7534,
        lbrId: 1416,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Cutting",
      },
      {
        id: 7535,
        lbrId: 1456,
        price: 9.0,
        taxPercentage: 5.0,
        tax: 0.45,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7536,
        lbrId: 1459,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7537,
        lbrId: 1425,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 7538,
        lbrId: 1423,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7539,
        lbrId: 1440,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
    ],
    productSets: { id: 304, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 304,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 304,
      adminId: 2,
      rawpriceId: 192,
      title: "Rolling Dell Die Patti",
      price: 610.0,
      different: 18.0,
      taxPercentage: 18.0,
      tax: 113.04,
    },
    productPacking: {
      id: 304,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3748,
        imgId: 975,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_175458.jpg",
        cropName: "20160204_175458.jpg",
      },
      {
        id: 3749,
        imgId: 976,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_175938.jpg",
        cropName: "20160204_175938.jpg",
      },
      {
        id: 3750,
        imgId: 977,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_180128.jpg",
        cropName: "20160204_180128.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:06:09.430+00:00",
    createDate: "2023-08-22T10:12:07.700+00:00",
    metalGram: 0.016,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7.2MM",
    prodManfDetails: "7",
    prodDescription:
      "Newest fashion 3-tone gold plated filled bracelet high-quality Dubai 3-tone bangles bracelets for women gold plated bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1603.86,
    cashPrice: 1851.5059,
    taxAll: 174.5198,
    taxInclude: 1938.7657,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 89.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 305,
    p_adminid: 2,
    productCategory: { id: 305, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 305,
      adminId: 2,
      parentId: 120,
      categoryId: 11,
      title: "7.2MM",
    },
    productChild: [
      {
        id: 7701,
        lbrId: 1418,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 7702,
        lbrId: 1416,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Cutting",
      },
      {
        id: 7703,
        lbrId: 1423,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7704,
        lbrId: 1459,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7705,
        lbrId: 1449,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Plating Gold Plated",
      },
      {
        id: 7706,
        lbrId: 1425,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 7707,
        lbrId: 1440,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
    ],
    productSets: { id: 305, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 305,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 305,
      adminId: 2,
      rawpriceId: 192,
      title: "Rolling Dell Die Patti",
      price: 610.0,
      different: 18.0,
      taxPercentage: 18.0,
      tax: 113.04,
    },
    productPacking: {
      id: 305,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3801,
        imgId: 978,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20191005-WA0101.jpg",
        cropName: "20191005-WA0101.jpg",
      },
    ],
    modifyDate: "2023-09-14T09:39:52.155+00:00",
    createDate: "2023-08-22T10:14:24.446+00:00",
    metalGram: 0.016,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7.2MM",
    prodManfDetails: "7",
    prodDescription:
      "Newest fashion gold plated filled bracelet high-quality gold plated bangles bracelets for women gold plated bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 366.002,
    cashPrice: 429.30838,
    taxAll: 53.412365,
    taxInclude: 456.01456,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 260.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 306,
    p_adminid: 2,
    productCategory: { id: 306, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 306,
      adminId: 2,
      parentId: 122,
      categoryId: 11,
      title: "7.8MM",
    },
    productChild: [
      {
        id: 1902,
        lbrId: 1518,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Cutting",
      },
      {
        id: 1903,
        lbrId: 1519,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Solder",
      },
      {
        id: 1904,
        lbrId: 1525,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 1905,
        lbrId: 1533,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1906,
        lbrId: 1548,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating Gold Plated",
      },
      {
        id: 1907,
        lbrId: 1558,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 306, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 306,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 306,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 306,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1123,
        imgId: 979,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_231658.jpg",
        cropName: "20160203_231658.jpg",
      },
      {
        id: 1124,
        imgId: 980,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_231835.jpg",
        cropName: "20160203_231835.jpg",
      },
      {
        id: 1125,
        imgId: 981,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232005.jpg",
        cropName: "20160203_232005.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:16:44.772+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7.8MM",
    prodManfDetails: "7",
    prodDescription:
      "Latest Exclusive Designer Light Weight with Matt Gold Bangles and Bracelets in all sizes Collection For Women And Girls 2023",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 195.05,
    cashPrice: 231.1015,
    taxAll: 33.093002,
    taxInclude: 247.64801,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 307,
    p_adminid: 2,
    productCategory: { id: 307, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 307,
      adminId: 2,
      parentId: 122,
      categoryId: 11,
      title: "7.8MM",
    },
    productChild: [
      {
        id: 1908,
        lbrId: 1518,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Cutting",
      },
      {
        id: 1909,
        lbrId: 1519,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Solder",
      },
      {
        id: 1910,
        lbrId: 1525,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 1911,
        lbrId: 1523,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 1912,
        lbrId: 1533,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1913,
        lbrId: 1548,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating Gold Plated",
      },
      {
        id: 1914,
        lbrId: 1558,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 307, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 307,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 307,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 307,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1126,
        imgId: 983,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_203516.jpg",
        cropName: "20160226_203516.jpg",
      },
      {
        id: 1127,
        imgId: 984,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_203849.jpg",
        cropName: "20160226_203849.jpg",
      },
      {
        id: 1128,
        imgId: 985,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_204036.jpg",
        cropName: "20160226_204036.jpg",
      },
      {
        id: 1129,
        imgId: 986,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_204142.jpg",
        cropName: "20160226_204142.jpg",
      },
      {
        id: 1130,
        imgId: 987,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_204329.jpg",
        cropName: "20160226_204329.jpg",
      },
      {
        id: 1131,
        imgId: 988,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_204531.jpg",
        cropName: "20160226_204531.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:18:33.026+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7.8MM",
    prodManfDetails: "7",
    prodDescription:
      "Summer HOT Gold Plated Bangle Jewelry Brides Luxury Bracelet for women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 133.55,
    cashPrice: 158.0215,
    taxAll: 22.232998,
    taxInclude: 169.138,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 308,
    p_adminid: 2,
    productCategory: { id: 308, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 308,
      adminId: 2,
      parentId: 122,
      categoryId: 11,
      title: "7.8MM",
    },
    productChild: [
      {
        id: 1915,
        lbrId: 1518,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Cutting",
      },
      {
        id: 1916,
        lbrId: 1519,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Solder",
      },
      {
        id: 1917,
        lbrId: 1525,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 1918,
        lbrId: 1523,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 1919,
        lbrId: 1548,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating Gold Plated",
      },
      {
        id: 1920,
        lbrId: 1558,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 308, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 308,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 308,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 308,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1132,
        imgId: 989,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_204848.jpg",
        cropName: "20160226_204848.jpg",
      },
      {
        id: 1133,
        imgId: 990,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_205300.jpg",
        cropName: "20160226_205300.jpg",
      },
      {
        id: 1134,
        imgId: 991,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_205438.jpg",
        cropName: "20160226_205438.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:20:14.427+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7.8MM",
    prodManfDetails: "7",
    prodDescription:
      "Designer Gold plated 4pc Trendy Pure Brass Antique Bangles Set jewellery for women with best price Wristband.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 131.45001,
    cashPrice: 155.65901,
    taxAll: 22.127998,
    taxInclude: 166.72302,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 309,
    p_adminid: 2,
    productCategory: { id: 309, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 309,
      adminId: 2,
      parentId: 122,
      categoryId: 11,
      title: "7.8MM",
    },
    productChild: [
      {
        id: 1921,
        lbrId: 1518,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Cutting",
      },
      {
        id: 1922,
        lbrId: 1519,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Solder",
      },
      {
        id: 1923,
        lbrId: 1525,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 1924,
        lbrId: 1523,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 1925,
        lbrId: 1548,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating Gold Plated",
      },
      {
        id: 1926,
        lbrId: 1558,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 309, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 309,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 309,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 309,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1135,
        imgId: 992,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0014.jpg",
        cropName: "20180213-WA0014.jpg",
      },
      {
        id: 1136,
        imgId: 993,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0015.jpg",
        cropName: "20180213-WA0015.jpg",
      },
      {
        id: 1137,
        imgId: 994,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0016.jpg",
        cropName: "20180213-WA0016.jpg",
      },
      {
        id: 1138,
        imgId: 995,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0021.jpg",
        cropName: "20180213-WA0021.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:21:24.838+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7.8MM",
    prodManfDetails: "7",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 221.74998,
    cashPrice: 262.9795,
    taxAll: 38.108997,
    taxInclude: 282.034,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 310,
    p_adminid: 2,
    productCategory: { id: 310, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 310,
      adminId: 2,
      parentId: 121,
      categoryId: 11,
      title: "7/3MM",
    },
    productChild: [
      {
        id: 7527,
        lbrId: 1468,
        price: 0.7,
        taxPercentage: 5.0,
        tax: 0.035,
        title: "Solder",
      },
      {
        id: 7528,
        lbrId: 1475,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7529,
        lbrId: 1502,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Plating Gold Plated",
      },
      {
        id: 7530,
        lbrId: 1512,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7531,
        lbrId: 1477,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 7532,
        lbrId: 1489,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 310, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 310,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 310,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 310,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3747,
        imgId: 996,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190510_132547.jpg",
        cropName: "20190510_132547.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:02:47.550+00:00",
    createDate: "2023-08-22T10:23:21.873+00:00",
    metalGram: 0.014,
    extraGram: 5.0e-4,
    readyGram: 0.0105,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7/3MM",
    prodManfDetails: "7",
    prodDescription:
      "Latest Exclusive Designer Light Weight with Gold plated Bangles and Bracelets in all sizes Collection for Women and Girls 2023.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 432.92203,
    cashPrice: 509.48923,
    taxAll: 66.549965,
    taxInclude: 542.7642,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 198.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 311,
    p_adminid: 2,
    productCategory: { id: 311, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 311,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 1933,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 1934,
        lbrId: 1570,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 1935,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 1936,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 1937,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1938,
        lbrId: 1605,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating 3tone\r\n",
      },
      {
        id: 1939,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 311, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 311,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 311,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 311,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1140,
        imgId: 1000,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_231302.jpg",
        cropName: "20160203_231302.jpg",
      },
      {
        id: 1141,
        imgId: 1001,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_231346.jpg",
        cropName: "20160203_231346.jpg",
      },
      {
        id: 1142,
        imgId: 1002,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_231441.jpg",
        cropName: "20160203_231441.jpg",
      },
      {
        id: 1143,
        imgId: 1003,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_231559.jpg",
        cropName: "20160203_231559.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:25:29.245+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Designer 8 MM 3-Tone Plated trendy Fashion Bangle jewellery for women to use daily.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 410.5,
    cashPrice: 470.115,
    taxAll: 37.13,
    taxInclude: 488.68,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 312,
    p_adminid: 2,
    productCategory: { id: 312, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 312,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 1940,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 1941,
        lbrId: 1569,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "CNC/Laser Marking",
      },
      {
        id: 1942,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 1943,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1944,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 312, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 312,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 312,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 312,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1144,
        imgId: 1004,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_154054.jpg",
        cropName: "20160204_154054.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:28:16.274+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 134.9,
    cashPrice: 158.32,
    taxAll: 19.860003,
    taxInclude: 168.25,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 313,
    p_adminid: 2,
    productCategory: { id: 313, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 313,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 1945,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1946,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 1947,
        lbrId: 1569,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "CNC/Laser Marking",
      },
      {
        id: 1948,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 1949,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1950,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 313, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 313,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 313,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 313,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1145,
        imgId: 1005,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_154508.jpg",
        cropName: "20160204_154508.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:29:40.823+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 247.0,
    cashPrice: 290.33752,
    taxAll: 37.274998,
    taxInclude: 308.975,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 314,
    p_adminid: 2,
    productCategory: { id: 314, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 314,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 1965,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 1966,
        lbrId: 1570,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 1967,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1968,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 1969,
        lbrId: 1578,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Kanas Meena",
      },
      {
        id: 1970,
        lbrId: 1599,
        price: 2.2,
        taxPercentage: 5.0,
        tax: 0.11,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 1971,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 314, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 314,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 314,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 314,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1148,
        imgId: 1006,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160311_132819.jpg",
        cropName: "20160311_132819.jpg",
      },
    ],
    modifyDate: "2023-08-22T10:40:38.716+00:00",
    createDate: "2023-08-22T10:31:24.588+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Artificial Fashionable Gold Plated Mina Bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 174.65,
    cashPrice: 203.23749,
    taxAll: 22.245,
    taxInclude: 214.35999,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 315,
    p_adminid: 2,
    productCategory: { id: 315, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 315,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 1972,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1973,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 1974,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 1975,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 1976,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 1977,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
    ],
    productSets: { id: 315, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 315,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 315,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 315,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1149,
        imgId: 1007,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_202917.jpg",
        cropName: "20160318_202917.jpg",
      },
      {
        id: 1150,
        imgId: 1008,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_203344.jpg",
        cropName: "20160318_203344.jpg",
      },
      {
        id: 1151,
        imgId: 1009,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_203425.jpg",
        cropName: "20160318_203425.jpg",
      },
      {
        id: 1152,
        imgId: 1010,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_203512.jpg",
        cropName: "20160318_203512.jpg",
      },
      {
        id: 1153,
        imgId: 1011,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_203709.jpg",
        cropName: "20160318_203709.jpg",
      },
      {
        id: 1154,
        imgId: 1012,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_203840.jpg",
        cropName: "20160318_203840.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:42:49.707+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "The 8MM gold-plated finished bangle design classic fashionable modern design for women and girls.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.65,
    cashPrice: 275.6515,
    taxAll: 35.073,
    taxInclude: 293.188,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 316,
    p_adminid: 2,
    productCategory: { id: 316, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 316,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 1978,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 1979,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 1980,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 1981,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 1982,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 1983,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 316, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 316,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 316,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 316,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1155,
        imgId: 1013,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214245.jpg",
        cropName: "20160318_214245.jpg",
      },
      {
        id: 1156,
        imgId: 1014,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214447.jpg",
        cropName: "20160318_214447.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:44:59.309+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Original Design Gold Plated Bracelets Bangles 4 Pieces Bracelet Gold Plated Bangles for Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 233.45,
    cashPrice: 274.22348,
    taxAll: 34.857,
    taxInclude: 291.65198,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 317,
    p_adminid: 2,
    productCategory: { id: 317, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 317,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 9246,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 9247,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 9248,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 9249,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 9250,
        lbrId: 1587,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 9251,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 317, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 317,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 317,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 317,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4332,
        imgId: 1015,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_165452.jpg",
        cropName: "20160330_165452.jpg",
      },
      {
        id: 4333,
        imgId: 1016,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_165548.jpg",
        cropName: "20160330_165548.jpg",
      },
    ],
    modifyDate: "2023-12-01T07:06:03.293+00:00",
    createDate: "2023-08-22T10:45:49.496+00:00",
    metalGram: 0.0155,
    extraGram: 5.0e-4,
    readyGram: 0.0115,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: 7117,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Gold plated Bangle Bracelet Gold Plated Women's BANGLES Brass jewellery.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 338.146,
    cashPrice: 397.56375,
    taxAll: 51.20628,
    taxInclude: 423.16687,
    active: true,
    gstCash: false,
    moqkg: 100.0,
    moq: 269.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 318,
    p_adminid: 2,
    productCategory: { id: 318, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 318,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 9239,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 9240,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 9241,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 9242,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 9243,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 9244,
        lbrId: 1576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 9245,
        lbrId: 1589,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
    ],
    productSets: { id: 318, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 318,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 318,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 318,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4327,
        imgId: 1017,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161117_101509.jpg",
        cropName: "20161117_101509.jpg",
      },
      {
        id: 4328,
        imgId: 1018,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161117_101638.jpg",
        cropName: "20161117_101638.jpg",
      },
      {
        id: 4329,
        imgId: 1019,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161117_101747.jpg",
        cropName: "20161117_101747.jpg",
      },
      {
        id: 4330,
        imgId: 1037,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_140832.jpg",
        cropName: "20171017_140832.jpg",
      },
      {
        id: 4331,
        imgId: 1038,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_140610.jpg",
        cropName: "20171017_140610.jpg",
      },
    ],
    modifyDate: "2023-12-01T07:05:14.176+00:00",
    createDate: "2023-08-22T10:50:18.535+00:00",
    metalGram: 0.021,
    extraGram: 5.0e-4,
    readyGram: 0.017,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: 7117,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Good Quality Bone Bangles For Woman Bracelets Antique Gold Plated Wedding WOMEN'S Trendy Resin Lucite Acrylic Party Gift.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 373.94998,
    cashPrice: 440.20047,
    taxAll: 57.711,
    taxInclude: 469.05597,
    active: true,
    gstCash: false,
    moqkg: 100.0,
    moq: 238.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 319,
    p_adminid: 2,
    productCategory: { id: 319, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 319,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 2017,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 2018,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2019,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2020,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2021,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 319, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 319,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 319,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 319,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1182,
        imgId: 1026,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164455.jpg",
        cropName: "20180127_164455.jpg",
      },
      {
        id: 1183,
        imgId: 1020,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163747.jpg",
        cropName: "20180127_163747.jpg",
      },
      {
        id: 1184,
        imgId: 1024,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164245.jpg",
        cropName: "20180127_164245.jpg",
      },
      {
        id: 1185,
        imgId: 1022,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163953.jpg",
        cropName: "20180127_163953.jpg",
      },
      {
        id: 1186,
        imgId: 1032,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165128.jpg",
        cropName: "20180127_165128.jpg",
      },
      {
        id: 1187,
        imgId: 1030,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164850.jpg",
        cropName: "20180127_164850.jpg",
      },
    ],
    modifyDate: "2023-08-22T11:01:44.705+00:00",
    createDate: "2023-08-22T10:55:08.195+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Trendy gold-plated bangles best designer Bangles Set For Women's Jewellery.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 218.65,
    cashPrice: 256.9535,
    taxAll: 32.877,
    taxInclude: 273.392,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 320,
    p_adminid: 2,
    productCategory: { id: 320, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 320,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 2000,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 2001,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2002,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2003,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2004,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 2005,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 320, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 320,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 320,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 320,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1169,
        imgId: 1021,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163830.jpg",
        cropName: "20180127_163830.jpg",
      },
      {
        id: 1170,
        imgId: 1023,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164032.jpg",
        cropName: "20180127_164032.jpg",
      },
      {
        id: 1171,
        imgId: 1025,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164324.jpg",
        cropName: "20180127_164324.jpg",
      },
      {
        id: 1172,
        imgId: 1027,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164526.jpg",
        cropName: "20180127_164526.jpg",
      },
      {
        id: 1173,
        imgId: 1029,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164749.jpg",
        cropName: "20180127_164749.jpg",
      },
      {
        id: 1174,
        imgId: 1031,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164938.jpg",
        cropName: "20180127_164938.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:57:54.604+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Traditional Women's Wear Gold Plated Bangles Hot Sale Wedding Wears Fancy Artificial Gold Bangles At Reasonable Price.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.65001,
    cashPrice: 275.65152,
    taxAll: 35.073,
    taxInclude: 293.18802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 321,
    p_adminid: 2,
    productCategory: { id: 321, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 321,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 2006,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2007,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2008,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 2009,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2010,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 2011,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 321, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 321,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 321,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 321,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1175,
        imgId: 1033,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165248.jpg",
        cropName: "20180127_165248.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T10:59:40.718+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Factory custom fashion jewellery bracelets bangles gold plated female bangle bracelet New top designer bangles for women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 234.65001,
    cashPrice: 275.65152,
    taxAll: 35.073,
    taxInclude: 293.18802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 322,
    p_adminid: 2,
    productCategory: { id: 322, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 322,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 2022,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 2023,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2024,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2025,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2026,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 2027,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 322, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 322,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 322,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 322,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1188,
        imgId: 1028,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164707.jpg",
        cropName: "20180127_164707.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T11:03:04.986+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Bracelets bangle gold plated female bangle bracelet New top designer bangles for women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 156.65001,
    cashPrice: 184.009,
    taxAll: 23.387999,
    taxInclude: 195.703,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 323,
    p_adminid: 2,
    productCategory: { id: 323, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 323,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 2028,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 2029,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2030,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2031,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2032,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 2033,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 323, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 323,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 323,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 323,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1189,
        imgId: 1034,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_173927.jpg",
        cropName: "20190813_173927.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T11:05:07.991+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 232.1,
    cashPrice: 272.077,
    taxAll: 33.534004,
    taxInclude: 288.844,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 324,
    p_adminid: 2,
    productCategory: { id: 324, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 324,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 2034,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2035,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2036,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 2037,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2038,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 2039,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 324, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 324,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 324,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 324,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1190,
        imgId: 1036,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174349.jpg",
        cropName: "20190813_174349.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T11:07:34.461+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 135.3,
    cashPrice: 158.60251,
    taxAll: 19.544998,
    taxInclude: 168.375,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 325,
    p_adminid: 2,
    productCategory: { id: 325, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 325,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 2040,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 2041,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2042,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2043,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2044,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 2045,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 325, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 325,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 325,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 325,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1191,
        imgId: 1035,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174118.jpg",
        cropName: "20190813_174118.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T11:09:06.121+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 432.50003,
    cashPrice: 507.51105,
    taxAll: 63.522003,
    taxInclude: 539.27203,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 326,
    p_adminid: 2,
    productCategory: { id: 326, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 326,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 2955,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 2956,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2957,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2958,
        lbrId: 1583,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2959,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 326, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 326,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 326,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 326,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1641,
        imgId: 1426,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_174035.jpg",
        cropName: "20171013_174035.jpg",
      },
      {
        id: 1642,
        imgId: 1427,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_174739.jpg",
        cropName: "20171013_174739.jpg",
      },
      {
        id: 1643,
        imgId: 1428,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_175028.jpg",
        cropName: "20171013_175028.jpg",
      },
      {
        id: 1644,
        imgId: 1429,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_175445.jpg",
        cropName: "20171013_175445.jpg",
      },
      {
        id: 1645,
        imgId: 1430,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_175815.jpg",
        cropName: "20171013_175815.jpg",
      },
      {
        id: 1646,
        imgId: 1431,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_180137.jpg",
        cropName: "20171013_180137.jpg",
      },
    ],
    modifyDate: "2023-08-25T14:02:32.248+00:00",
    createDate: "2023-08-22T11:15:24.091+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8mm",
    prodManfDetails: "8",
    prodDescription:
      "Party Design Gold Plated fashion jewelry bracelets bangles For Engagement Anniversary Wedding Jewelry Bracelets & Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 154.55,
    cashPrice: 181.6465,
    taxAll: 23.282999,
    taxInclude: 193.28801,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 327,
    p_adminid: 2,
    productCategory: { id: 327, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 327,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 6833,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 6834,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 6835,
        lbrId: 1600,
        price: 2.6,
        taxPercentage: 5.0,
        tax: 0.13,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 6836,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6837,
        lbrId: 1592,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 6838,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 327, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 327,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 327,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 327,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3389,
        imgId: 1039,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220303-WA0023.jpg",
        cropName: "20220303-WA0023.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:36:05.817+00:00",
    createDate: "2023-08-22T11:16:19.737+00:00",
    metalGram: 0.017,
    extraGram: 5.0e-4,
    readyGram: 0.012,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Latest Products Women Gold Plated Jewelry Two Tone Color Brass Bangle. \n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 374.75,
    cashPrice: 437.9025,
    taxAll: 51.355003,
    taxInclude: 463.58002,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 328,
    p_adminid: 2,
    productCategory: { id: 328, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 328,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 2069,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 2070,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2071,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2072,
        lbrId: 1647,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Plating Gold Plated",
      },
      {
        id: 2073,
        lbrId: 1657,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 328, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 328,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 328,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 328,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1197,
        imgId: 1040,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8004.jpg",
        cropName: "8004.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T11:24:59.592+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 328.25,
    cashPrice: 385.7485,
    taxAll: 49.347004,
    taxInclude: 410.42203,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 329,
    p_adminid: 2,
    productCategory: { id: 329, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 329,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 2079,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 2080,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2081,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2082,
        lbrId: 1633,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2083,
        lbrId: 1647,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 329, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 329,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 329,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 329,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1199,
        imgId: 1054,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205329.jpg",
        cropName: "20160318_205329.jpg",
      },
      {
        id: 1200,
        imgId: 1053,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205430.jpg",
        cropName: "20160318_205430.jpg",
      },
      {
        id: 1201,
        imgId: 1052,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205058.jpg",
        cropName: "20160318_205058.jpg",
      },
      {
        id: 1202,
        imgId: 1055,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205207.jpg",
        cropName: "20160318_205207.jpg",
      },
      {
        id: 1203,
        imgId: 1056,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205002.jpg",
        cropName: "20160318_205002.jpg",
      },
      {
        id: 1204,
        imgId: 1057,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_204853.jpg",
        cropName: "20160318_204853.jpg",
      },
    ],
    modifyDate: "2023-08-22T11:45:36.561+00:00",
    createDate: "2023-08-22T11:44:39.782+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription:
      "Indian Bangle Set Bollywood Traditional Bracelet Jewelry for Women (Set of 6).\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 331.49,
    cashPrice: 389.60406,
    taxAll: 49.9302,
    taxInclude: 414.56918,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 330,
    p_adminid: 2,
    productCategory: { id: 330, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 330,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 2084,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 2085,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2086,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2087,
        lbrId: 1633,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2088,
        lbrId: 1647,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 330, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 330,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 330,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 330,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1205,
        imgId: 1058,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_135347.jpg",
        cropName: "20170308_135347.jpg",
      },
      {
        id: 1206,
        imgId: 1059,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_135613.jpg",
        cropName: "20170308_135613.jpg",
      },
      {
        id: 1207,
        imgId: 1060,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_135757.jpg",
        cropName: "20170308_135757.jpg",
      },
      {
        id: 1208,
        imgId: 1061,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_135932.jpg",
        cropName: "20170308_135932.jpg",
      },
      {
        id: 1209,
        imgId: 1062,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_140102.jpg",
        cropName: "20170308_140102.jpg",
      },
      {
        id: 1210,
        imgId: 1063,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_140239.jpg",
        cropName: "20170308_140239.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T11:47:00.732+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3mm",
    prodManfDetails: "8",
    prodDescription:
      "High-quality Brass Gold Plated Bracelet Bangle for Women Opening Bangles Factory Wholesale.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 328.25,
    cashPrice: 385.7485,
    taxAll: 49.347004,
    taxInclude: 410.42203,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 331,
    p_adminid: 2,
    productCategory: { id: 331, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 331,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 2089,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 2090,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2091,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2092,
        lbrId: 1633,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2093,
        lbrId: 1647,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Plating Gold Plated",
      },
      {
        id: 2094,
        lbrId: 1657,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 331, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 331,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 331,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 331,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1211,
        imgId: 1064,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_163716.jpg",
        cropName: "20180127_163716.jpg",
      },
      {
        id: 1212,
        imgId: 1065,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164131.jpg",
        cropName: "20180127_164131.jpg",
      },
      {
        id: 1213,
        imgId: 1066,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164410.jpg",
        cropName: "20180127_164410.jpg",
      },
      {
        id: 1214,
        imgId: 1067,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_164624.jpg",
        cropName: "20180127_164624.jpg",
      },
      {
        id: 1215,
        imgId: 1068,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165050.jpg",
        cropName: "20180127_165050.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T11:48:44.472+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Metal Bangle Set Multi-design Bangles for Women on Traditional Occasions.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 333.65,
    cashPrice: 391.8235,
    taxAll: 49.617004,
    taxInclude: 416.632,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 332,
    p_adminid: 2,
    productCategory: { id: 332, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 332,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 2095,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 2096,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2097,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2098,
        lbrId: 1633,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2099,
        lbrId: 1650,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Plating Bright Silver",
      },
      {
        id: 2100,
        lbrId: 1657,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 332, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 332,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 332,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 332,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1216,
        imgId: 1069,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/202045688432.jpg",
        cropName: "202045688432.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T11:54:35.030+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3MM",
    prodManfDetails: "8",
    prodDescription:
      "Women's fashion Trendy 2 Tone Colour Beautifully Bridal Wedding Design Bangle Bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 360.65,
    cashPrice: 422.1985,
    taxAll: 50.967003,
    taxInclude: 447.682,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 333,
    p_adminid: 2,
    productCategory: { id: 333, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 333,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 7560,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 7561,
        lbrId: 1657,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7562,
        lbrId: 1654,
        price: 11.5,
        taxPercentage: 5.0,
        tax: 0.575,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7563,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7564,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 7565,
        lbrId: 1627,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Kangaril/Dell Kachi",
      },
      {
        id: 7566,
        lbrId: 1639,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
    ],
    productSets: { id: 333, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 333,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 333,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 333,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3761,
        imgId: 1041,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_230400.jpg",
        cropName: "20160203_230400.jpg",
      },
      {
        id: 3762,
        imgId: 1042,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_230542.jpg",
        cropName: "20160203_230542.jpg",
      },
      {
        id: 3763,
        imgId: 1043,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_231122.jpg",
        cropName: "20160203_231122.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:21:15.593+00:00",
    createDate: "2023-08-22T11:57:14.884+00:00",
    metalGram: 0.022,
    extraGram: 5.0e-4,
    readyGram: 0.017,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3MM",
    prodManfDetails: "8",
    prodDescription:
      "Fancy and Elegant Three Tone Plated Fashion Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1030.13,
    cashPrice: 1192.4377,
    taxAll: 118.58941,
    taxInclude: 1251.7324,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 126.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 334,
    p_adminid: 2,
    productCategory: { id: 334, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 334,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 2107,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 2108,
        lbrId: 1624,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 2109,
        lbrId: 1625,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 2110,
        lbrId: 1633,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2111,
        lbrId: 1654,
        price: 11.5,
        taxPercentage: 5.0,
        tax: 0.575,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 334, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 334,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 334,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 334,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1220,
        imgId: 1044,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_223052.jpg",
        cropName: "20160226_223052.jpg",
      },
      {
        id: 1221,
        imgId: 1045,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_223233.jpg",
        cropName: "20160226_223233.jpg",
      },
      {
        id: 1222,
        imgId: 1046,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_223433.jpg",
        cropName: "20160226_223433.jpg",
      },
      {
        id: 1223,
        imgId: 1047,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_223538.jpg",
        cropName: "20160226_223538.jpg",
      },
      {
        id: 1224,
        imgId: 1048,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_223711.jpg",
        cropName: "20160226_223711.jpg",
      },
      {
        id: 1225,
        imgId: 1049,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_223838.jpg",
        cropName: "20160226_223838.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:01:55.657+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3MM",
    prodManfDetails: "8",
    prodDescription:
      "New Arrivals Fashion Jewelry brass Bangle Bracelet for Women's Accessories.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 720.65,
    cashPrice: 827.19855,
    taxAll: 68.967,
    taxInclude: 861.682,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 335,
    p_adminid: 2,
    productCategory: { id: 335, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 335,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 2112,
        lbrId: 1633,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2113,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 2114,
        lbrId: 1624,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 2115,
        lbrId: 1625,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 2116,
        lbrId: 1647,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Plating Gold Plated",
      },
      {
        id: 2117,
        lbrId: 1657,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 335, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 335,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 335,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 335,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1226,
        imgId: 1050,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_225025.jpg",
        cropName: "20160226_225025.jpg",
      },
      {
        id: 1227,
        imgId: 1051,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_225244.jpg",
        cropName: "20160226_225244.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:02:55.556+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3MM",
    prodManfDetails: "8",
    prodDescription:
      "3-tone jewellery set women 2023 wholesale new fashion luxury Brass bangle bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 330.05,
    cashPrice: 387.7735,
    taxAll: 49.437004,
    taxInclude: 412.492,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 336,
    p_adminid: 2,
    productCategory: { id: 336, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 336,
      adminId: 2,
      parentId: 126,
      categoryId: 11,
      title: "9MM",
    },
    productChild: [
      {
        id: 2118,
        lbrId: 1713,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Solder",
      },
      {
        id: 2119,
        lbrId: 1722,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2120,
        lbrId: 1719,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2121,
        lbrId: 1727,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 2122,
        lbrId: 1743,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2123,
        lbrId: 1753,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 336, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 336,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 336,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 336,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1228,
        imgId: 1095,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174008.jpg",
        cropName: "20190813_174008.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:05:23.242+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9MM",
    prodManfDetails: "9",
    prodDescription: "9",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 270.5,
    cashPrice: 315.27698,
    taxAll: 35.454002,
    taxInclude: 333.004,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 337,
    p_adminid: 2,
    productCategory: { id: 337, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 337,
      adminId: 2,
      parentId: 126,
      categoryId: 11,
      title: "9MM",
    },
    productChild: [
      {
        id: 2124,
        lbrId: 1713,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Solder",
      },
      {
        id: 2125,
        lbrId: 1722,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2126,
        lbrId: 1719,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2127,
        lbrId: 1730,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2128,
        lbrId: 1743,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2129,
        lbrId: 1753,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 337, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 337,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 337,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 337,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1229,
        imgId: 1097,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_1743210.jpg",
        cropName: "20190813_1743210.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:06:40.622+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9MM",
    prodManfDetails: "9",
    prodDescription: "9",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 262.1,
    cashPrice: 305.827,
    taxAll: 35.034004,
    taxInclude: 323.344,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 338,
    p_adminid: 2,
    productCategory: { id: 338, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 338,
      adminId: 2,
      parentId: 126,
      categoryId: 11,
      title: "9MM",
    },
    productChild: [
      {
        id: 2130,
        lbrId: 1713,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Solder",
      },
      {
        id: 2131,
        lbrId: 1722,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2132,
        lbrId: 1719,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2133,
        lbrId: 1730,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2134,
        lbrId: 1743,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2135,
        lbrId: 1753,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 338, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 338,
      adminId: 2,
      boxId: 69,
      brandName: "Plastic Box No.6",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "39231000",
      price: 0.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 338,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 338,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1230,
        imgId: 1096,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174114.jpg",
        cropName: "20190813_174114.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:07:57.720+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9MM",
    prodManfDetails: "9",
    prodDescription: "9",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 237.9,
    cashPrice: 277.569,
    taxAll: 31.758001,
    taxInclude: 293.448,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 339,
    p_adminid: 2,
    productCategory: { id: 339, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 339,
      adminId: 2,
      parentId: 127,
      categoryId: 11,
      title: "9.2MM",
    },
    productChild: [
      {
        id: 2136,
        lbrId: 1761,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 2137,
        lbrId: 1759,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Die Cutting",
      },
      {
        id: 2138,
        lbrId: 1767,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2139,
        lbrId: 1770,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2140,
        lbrId: 1791,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 339, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 339,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 339,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 339,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1231,
        imgId: 1070,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_172327.jpg",
        cropName: "20160204_172327.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:09:13.173+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9.2MM",
    prodManfDetails: "9",
    prodDescription:
      "Machine Die cut gold plated brass bangles Manufacture and Exporter.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 169.95001,
    cashPrice: 198.97151,
    taxAll: 24.053,
    taxInclude: 210.998,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 340,
    p_adminid: 2,
    productCategory: { id: 340, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 340,
      adminId: 2,
      parentId: 127,
      categoryId: 11,
      title: "9.2MM",
    },
    productChild: [
      {
        id: 2141,
        lbrId: 1759,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Die Cutting",
      },
      {
        id: 2142,
        lbrId: 1761,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 2143,
        lbrId: 1778,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2144,
        lbrId: 1767,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2145,
        lbrId: 1770,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2146,
        lbrId: 1791,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 2147,
        lbrId: 1801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 340, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 340,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 340,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 340,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1232,
        imgId: 1071,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214945.jpg",
        cropName: "20160318_214945.jpg",
      },
      {
        id: 1233,
        imgId: 1072,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_215232.jpg",
        cropName: "20160318_215232.jpg",
      },
      {
        id: 1234,
        imgId: 1073,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_162532.jpg",
        cropName: "20160319_162532.jpg",
      },
      {
        id: 1235,
        imgId: 1074,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_162750.jpg",
        cropName: "20160319_162750.jpg",
      },
      {
        id: 1236,
        imgId: 1075,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_162857.jpg",
        cropName: "20160319_162857.jpg",
      },
      {
        id: 1237,
        imgId: 1076,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_162946.jpg",
        cropName: "20160319_162946.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:12:35.978+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9.2MM",
    prodManfDetails: "9",
    prodDescription:
      "Extraordinary Brass Gold Plated Die Cutting brass Bangle Artificial jewellery.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.65,
    cashPrice: 309.4015,
    taxAll: 36.573,
    taxInclude: 327.688,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 341,
    p_adminid: 2,
    productCategory: { id: 341, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 341,
      adminId: 2,
      parentId: 127,
      categoryId: 11,
      title: "9.2MM",
    },
    productChild: [
      {
        id: 2148,
        lbrId: 1761,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 2149,
        lbrId: 1759,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Die Cutting",
      },
      {
        id: 2150,
        lbrId: 1778,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2151,
        lbrId: 1767,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2152,
        lbrId: 1770,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2153,
        lbrId: 1791,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 2154,
        lbrId: 1801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 341, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 341,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 341,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 341,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1238,
        imgId: 1077,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_170021.jpg",
        cropName: "20180127_170021.jpg",
      },
      {
        id: 1239,
        imgId: 1080,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_170819.jpg",
        cropName: "20180127_170819.jpg",
      },
      {
        id: 1240,
        imgId: 1082,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_171158.jpg",
        cropName: "20180127_171158.jpg",
      },
      {
        id: 1241,
        imgId: 1083,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_171510.jpg",
        cropName: "20180127_171510.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:14:09.258+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9.2MM",
    prodManfDetails: "9",
    prodDescription:
      "Unique Design High-Quality Simple Style Brass Bangle Bracelet For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 174.15001,
    cashPrice: 203.6965,
    taxAll: 24.262999,
    taxInclude: 215.828,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 342,
    p_adminid: 2,
    productCategory: { id: 342, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 342,
      adminId: 2,
      parentId: 127,
      categoryId: 11,
      title: "9.2MM",
    },
    productChild: [
      {
        id: 2155,
        lbrId: 1759,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Die Cutting",
      },
      {
        id: 2156,
        lbrId: 1761,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 2157,
        lbrId: 1778,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2158,
        lbrId: 1767,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2159,
        lbrId: 1770,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2160,
        lbrId: 1791,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 2161,
        lbrId: 1801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 342, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 342,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 342,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 342,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1242,
        imgId: 1078,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_170235.jpg",
        cropName: "20180127_170235.jpg",
      },
      {
        id: 1243,
        imgId: 1079,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_170757.jpg",
        cropName: "20180127_170757.jpg",
      },
      {
        id: 1244,
        imgId: 1081,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_171127.jpg",
        cropName: "20180127_171127.jpg",
      },
      {
        id: 1245,
        imgId: 1084,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_171605.jpg",
        cropName: "20180127_171605.jpg",
      },
      {
        id: 1246,
        imgId: 1085,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220309_145924.jpg",
        cropName: "20220309_145924.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:15:08.932+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9.2MM",
    prodManfDetails: "9",
    prodDescription:
      "Personalized Simple Design Gold Plated Engraved Custom Bangle Bracelet Popular.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.65,
    cashPrice: 309.4015,
    taxAll: 36.573,
    taxInclude: 327.688,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 343,
    p_adminid: 2,
    productCategory: { id: 343, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 343,
      adminId: 2,
      parentId: 128,
      categoryId: 11,
      title: "9/3MM",
    },
    productChild: [
      {
        id: 2162,
        lbrId: 1807,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Die Cutting",
      },
      {
        id: 2163,
        lbrId: 1810,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 2164,
        lbrId: 1851,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 2165,
        lbrId: 1848,
        price: 12.5,
        taxPercentage: 5.0,
        tax: 0.625,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2166,
        lbrId: 1817,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 2167,
        lbrId: 1818,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 343, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 343,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 343,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 343,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1247,
        imgId: 1086,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_224020.jpg",
        cropName: "20160226_224020.jpg",
      },
      {
        id: 1248,
        imgId: 1087,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_224124.jpg",
        cropName: "20160226_224124.jpg",
      },
      {
        id: 1249,
        imgId: 1088,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_224317.jpg",
        cropName: "20160226_224317.jpg",
      },
      {
        id: 1250,
        imgId: 1089,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_224445.jpg",
        cropName: "20160226_224445.jpg",
      },
      {
        id: 1251,
        imgId: 1090,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160226_224910.jpg",
        cropName: "20160226_224910.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:16:43.179+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9/3MM",
    prodManfDetails: "9",
    prodDescription:
      "3-tone Unique Design High-Quality Amazing Simple Style Bangle Bracelet For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 801.65,
    cashPrice: 918.3235,
    taxAll: 73.017,
    taxInclude: 954.83203,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 344,
    p_adminid: 2,
    productCategory: { id: 344, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 344,
      adminId: 2,
      parentId: 128,
      categoryId: 11,
      title: "9/3MM",
    },
    productChild: [
      {
        id: 7567,
        lbrId: 1841,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 7568,
        lbrId: 1851,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7569,
        lbrId: 1809,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
    ],
    productSets: { id: 344, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 344,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 344,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 344,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3764,
        imgId: 1091,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170123_135837.jpg",
        cropName: "20170123_135837.jpg",
      },
      {
        id: 3765,
        imgId: 1092,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_165926.jpg",
        cropName: "20180127_165926.jpg",
      },
      {
        id: 3766,
        imgId: 1093,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_170926.jpg",
        cropName: "20180127_170926.jpg",
      },
      {
        id: 3767,
        imgId: 1094,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_171303.jpg",
        cropName: "20180127_171303.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:23:24.235+00:00",
    createDate: "2023-08-22T12:17:44.415+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9/3MM",
    prodManfDetails: "9",
    prodDescription:
      "High quality top selling wholesale price Coloured and Polished Finish Brass Coloured Bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 288.49,
    cashPrice: 341.3591,
    taxAll: 48.0402,
    taxInclude: 365.37918,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 345,
    p_adminid: 2,
    productCategory: { id: 345, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 345,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2174,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2175,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2176,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2177,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2178,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 345, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 345,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 345,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 345,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1256,
        imgId: 1098,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_181928.jpg",
        cropName: "20150910_181928.jpg",
      },
      {
        id: 1257,
        imgId: 1099,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20150910_183313.jpg",
        cropName: "20150910_183313.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:19:57.375+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription:
      "Fancy and Elegant Three Tone Plated Vany Fashion Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 458.5,
    cashPrice: 524.115,
    taxAll: 39.53,
    taxInclude: 543.88,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 346,
    p_adminid: 2,
    productCategory: { id: 346, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 346,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2179,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2180,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2181,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2182,
        lbrId: 1861,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 2183,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2184,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2185,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 346, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 346,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 346,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 346,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1258,
        imgId: 1100,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_131852.jpg",
        cropName: "20160204_131852.jpg",
      },
      {
        id: 1259,
        imgId: 1101,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_131959.jpg",
        cropName: "20160204_131959.jpg",
      },
      {
        id: 1260,
        imgId: 1102,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_132212.jpg",
        cropName: "20160204_132212.jpg",
      },
      {
        id: 1261,
        imgId: 1103,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_132341.jpg",
        cropName: "20160204_132341.jpg",
      },
      {
        id: 1262,
        imgId: 1104,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_132447.jpg",
        cropName: "20160204_132447.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:21:23.276+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription:
      "Exquisite Gold Plated Chakari Pattern Inlaid Shiny Zircon Smooth Bracelets Bangles Women's Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 273.65,
    cashPrice: 319.7215,
    taxAll: 37.413,
    taxInclude: 338.42798,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 347,
    p_adminid: 2,
    productCategory: { id: 347, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 347,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2186,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2187,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2188,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2189,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2190,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2191,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 347, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 347,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 347,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 347,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1263,
        imgId: 1105,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_181051.jpg",
        cropName: "20160204_181051.jpg",
      },
      {
        id: 1264,
        imgId: 1106,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_181351.jpg",
        cropName: "20160204_181351.jpg",
      },
      {
        id: 1265,
        imgId: 1107,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_181743.jpg",
        cropName: "20160204_181743.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:23:31.975+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription:
      "Luxury Famous Designers Jewelry brass Bangle Bracelets With vanny 3 tone gold Plated Bracelet.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1569.0,
    cashPrice: 1794.5525,
    taxAll: 137.305,
    taxInclude: 1863.2051,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 348,
    p_adminid: 2,
    productCategory: { id: 348, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 348,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2192,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2193,
        lbrId: 1860,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "CNC/Laser Marking",
      },
      {
        id: 2194,
        lbrId: 1859,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 2195,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2196,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2197,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2198,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2199,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 348, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 348,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 348,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 348,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1266,
        imgId: 1108,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_135211.jpg",
        cropName: "20160317_135211.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:24:57.513+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "3-Tone Mother Merry Brass Bangle Bracelet.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 511.5,
    cashPrice: 583.74005,
    taxAll: 42.18,
    taxInclude: 604.83,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 349,
    p_adminid: 2,
    productCategory: { id: 349, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 349,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2200,
        lbrId: 1859,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 2201,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2202,
        lbrId: 1860,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "CNC/Laser Marking",
      },
      {
        id: 2203,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2204,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2205,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2206,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2207,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 349, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 349,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 349,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 349,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1267,
        imgId: 1109,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_154334.jpg",
        cropName: "20160317_154334.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:26:49.435+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription:
      "Designer 10MM Three-Tone Plated trendy Fashion Eaching Bangle jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 511.5,
    cashPrice: 583.74005,
    taxAll: 42.18,
    taxInclude: 604.83,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 350,
    p_adminid: 2,
    productCategory: { id: 350, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 350,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2208,
        lbrId: 1859,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 2209,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2210,
        lbrId: 1860,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "CNC/Laser Marking",
      },
      {
        id: 2211,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2212,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2213,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2214,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2215,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 350, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 350,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 350,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 350,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1268,
        imgId: 1110,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_154445.jpg",
        cropName: "20160317_154445.jpg",
      },
      {
        id: 1269,
        imgId: 1111,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_154532.jpg",
        cropName: "20160317_154532.jpg",
      },
      {
        id: 1270,
        imgId: 1112,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_154647.jpg",
        cropName: "20160317_154647.jpg",
      },
      {
        id: 1271,
        imgId: 1113,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_154941.jpg",
        cropName: "20160317_154941.jpg",
      },
      {
        id: 1272,
        imgId: 1114,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_155216.jpg",
        cropName: "20160317_155216.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:29:00.220+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription:
      "Indian Manufacturer Gold Plated Brass Bangles 3 Tone Top Quality Latest Designed Eaching Fancy Bangles For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 511.5,
    cashPrice: 583.74005,
    taxAll: 42.18,
    taxInclude: 604.83,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 351,
    p_adminid: 2,
    productCategory: { id: 351, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 351,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2216,
        lbrId: 1859,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 2217,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2218,
        lbrId: 1860,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "CNC/Laser Marking",
      },
      {
        id: 2219,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2220,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2221,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2222,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2223,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 351, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 351,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 351,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 351,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1273,
        imgId: 1115,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_155725.jpg",
        cropName: "20160317_155725.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:30:12.945+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription:
      "10MM Three-Tone Plated Fancy Bangle with creative designs with a pleasant look which gives a creative touch to your everyday look.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 273.5,
    cashPrice: 312.3175,
    taxAll: 22.935,
    taxInclude: 323.785,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 352,
    p_adminid: 2,
    productCategory: { id: 352, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 352,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2224,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2225,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2226,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2227,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2228,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2229,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 352, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 352,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 352,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 352,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1274,
        imgId: 1116,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214342.jpg",
        cropName: "20160318_214342.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:32:14.282+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription:
      "Indian Manufacturer Gold Plated Brass Bangles Top Quality Latest Designed Fancy Bangles For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 260.45,
    cashPrice: 304.87152,
    taxAll: 36.753,
    taxInclude: 323.24802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 353,
    p_adminid: 2,
    productCategory: { id: 353, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 353,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2230,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2231,
        lbrId: 1860,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "CNC/Laser Marking",
      },
      {
        id: 2232,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2233,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2234,
        lbrId: 1859,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 2235,
        lbrId: 1892,
        price: 13.0,
        taxPercentage: 5.0,
        tax: 0.65,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 2236,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 353, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 353,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 353,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 353,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1275,
        imgId: 1117,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160407_121116.jpg",
        cropName: "20160407_121116.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:34:38.177+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      "Indian Manufacturer Micro Gold Plated Brass Bangles Top Quality Latest Eaching Flower Designed Fancy Bangles For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 498.5,
    cashPrice: 569.115,
    taxAll: 41.53,
    taxInclude: 589.88,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 354,
    p_adminid: 2,
    productCategory: { id: 354, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 354,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2237,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2238,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2239,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2240,
        lbrId: 1859,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 2241,
        lbrId: 1892,
        price: 13.0,
        taxPercentage: 5.0,
        tax: 0.65,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 2242,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 354, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 354,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 354,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 354,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1276,
        imgId: 1118,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160407_122215.jpg",
        cropName: "20160407_122215.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:36:06.593+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      " Bracelet Micro gold plated brass fashion jewellery bangles for women's bangles designs.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 242.0,
    cashPrice: 276.88,
    taxAll: 21.36,
    taxInclude: 287.56,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 355,
    p_adminid: 2,
    productCategory: { id: 355, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 355,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2243,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2244,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2245,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2246,
        lbrId: 1860,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "CNC/Laser Marking",
      },
      {
        id: 2247,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2248,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2249,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 355, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 355,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 355,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 355,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1277,
        imgId: 1119,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_134122.jpg",
        cropName: "20160914_134122.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:37:37.916+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      "Bangles Designer Gold plated 2pc Trendy Pure Brass Punching Bangles Set for women with the best price.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.5,
    cashPrice: 296.865,
    taxAll: 29.43,
    taxInclude: 311.58,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 356,
    p_adminid: 2,
    productCategory: { id: 356, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 356,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2250,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2251,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2252,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2253,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2254,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2255,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 356, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 356,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 356,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 356,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1278,
        imgId: 1140,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174008.jpg",
        cropName: "20190813_174008.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:38:51.118+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 254.9,
    cashPrice: 297.727,
    taxAll: 34.674004,
    taxInclude: 315.064,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 357,
    p_adminid: 2,
    productCategory: { id: 357, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 357,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2256,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2257,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2258,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2259,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2260,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2261,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 357, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 357,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 357,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 357,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1279,
        imgId: 1141,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_1743210.jpg",
        cropName: "20190813_1743210.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:40:25.293+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 254.9,
    cashPrice: 297.727,
    taxAll: 34.674004,
    taxInclude: 315.064,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 358,
    p_adminid: 2,
    productCategory: { id: 358, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 358,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2262,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2263,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2264,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2265,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2266,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2267,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 358, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 358,
      adminId: 2,
      boxId: 69,
      brandName: "Plastic Box No.6",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "39231000",
      price: 0.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 358,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 358,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1280,
        imgId: 1142,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174114.jpg",
        cropName: "20190813_174114.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:41:35.418+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 230.70001,
    cashPrice: 269.46902,
    taxAll: 31.398,
    taxInclude: 285.16803,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 359,
    p_adminid: 2,
    productCategory: { id: 359, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 359,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 7708,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 7709,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7710,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 7711,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 7712,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7713,
        lbrId: 1882,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7714,
        lbrId: 1883,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 359, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 359,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 359,
      adminId: 2,
      rawpriceId: 205,
      title: "18 Gauge Brass Tube 1.2mm",
      price: 610.0,
      different: 75.0,
      taxPercentage: 18.0,
      tax: 123.3,
    },
    productPacking: {
      id: 359,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3802,
        imgId: 1143,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220323-WA0003.jpg",
        cropName: "20220323-WA0003.jpg",
      },
    ],
    modifyDate: "2023-09-14T09:42:51.168+00:00",
    createDate: "2023-08-22T12:42:53.011+00:00",
    metalGram: 0.023,
    extraGram: 5.0e-4,
    readyGram: 0.017,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set jewelry for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 445.6,
    cashPrice: 522.089,
    taxAll: 63.858,
    taxInclude: 554.018,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 217.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 360,
    p_adminid: 2,
    productCategory: { id: 360, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 360,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2274,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2275,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2276,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2277,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2278,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 2279,
        lbrId: 1868,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Diamond Dell/CNC",
      },
    ],
    productSets: { id: 360, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 360,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 360,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 360,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1282,
        imgId: 1120,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140842.jpg",
        cropName: "20160914_140842.jpg",
      },
      {
        id: 1283,
        imgId: 1121,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_140916.jpg",
        cropName: "20160914_140916.jpg",
      },
      {
        id: 1284,
        imgId: 1124,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_141152.jpg",
        cropName: "20160914_141152.jpg",
      },
      {
        id: 1285,
        imgId: 1125,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_141319.jpg",
        cropName: "20160914_141319.jpg",
      },
      {
        id: 1286,
        imgId: 1126,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_141631.jpg",
        cropName: "20160914_141631.jpg",
      },
      {
        id: 1287,
        imgId: 1127,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_141849.jpg",
        cropName: "20160914_141849.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:44:41.127+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      "European and American Bangle Charms Wholesale Three Colors Jewelry Set Bracelets & Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 936.5,
    cashPrice: 1070.185,
    taxAll: 80.07,
    taxInclude: 1110.22,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 361,
    p_adminid: 2,
    productCategory: { id: 361, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 361,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2280,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2281,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2282,
        lbrId: 1868,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Diamond Dell/CNC",
      },
      {
        id: 2283,
        lbrId: 1861,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 2284,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2285,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 361, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 361,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 361,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 361,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1288,
        imgId: 1134,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142428.jpg",
        cropName: "20160914_142428.jpg",
      },
      {
        id: 1289,
        imgId: 1136,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142542.jpg",
        cropName: "20160914_142542.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:46:19.020+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      "Bangles Designer 3 tone bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 952.5,
    cashPrice: 1088.185,
    taxAll: 80.869995,
    taxInclude: 1128.62,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 362,
    p_adminid: 2,
    productCategory: { id: 362, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 362,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2286,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2287,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2288,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2289,
        lbrId: 1861,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 2290,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2291,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 2292,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 362, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 362,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 362,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 362,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1290,
        imgId: 1122,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_141007.jpg",
        cropName: "20160914_141007.jpg",
      },
      {
        id: 1291,
        imgId: 1123,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_141048.jpg",
        cropName: "20160914_141048.jpg",
      },
      {
        id: 1292,
        imgId: 1128,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_141959.jpg",
        cropName: "20160914_141959.jpg",
      },
      {
        id: 1293,
        imgId: 1129,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142056.jpg",
        cropName: "20160914_142056.jpg",
      },
      {
        id: 1294,
        imgId: 1130,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142138.jpg",
        cropName: "20160914_142138.jpg",
      },
      {
        id: 1295,
        imgId: 1131,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142211.jpg",
        cropName: "20160914_142211.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:47:57.112+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      "10MM Three Tone Plated Gold finished bangle design classic fashionable modern design for women and girl.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 422.5,
    cashPrice: 491.935,
    taxAll: 54.37,
    taxInclude: 519.12,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 363,
    p_adminid: 2,
    productCategory: { id: 363, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 363,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 2293,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2294,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 2295,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 2296,
        lbrId: 1874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2297,
        lbrId: 1861,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 2298,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2299,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 363, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 363,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 363,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 363,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1296,
        imgId: 1135,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142509.jpg",
        cropName: "20160914_142509.jpg",
      },
      {
        id: 1297,
        imgId: 1138,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142751.jpg",
        cropName: "20160914_142751.jpg",
      },
      {
        id: 1298,
        imgId: 1137,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142623.jpg",
        cropName: "20160914_142623.jpg",
      },
      {
        id: 1299,
        imgId: 1139,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142840.jpg",
        cropName: "20160914_142840.jpg",
      },
      {
        id: 1300,
        imgId: 1132,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142305.jpg",
        cropName: "20160914_142305.jpg",
      },
      {
        id: 1301,
        imgId: 1133,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_142354.jpg",
        cropName: "20160914_142354.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:49:57.163+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      "Trendy 10 MM 3 tone Plated Textured Brass Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 924.5,
    cashPrice: 1056.685,
    taxAll: 79.47,
    taxInclude: 1096.42,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 364,
    p_adminid: 2,
    productCategory: { id: 364, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 364,
      adminId: 2,
      parentId: 130,
      categoryId: 11,
      title: "10/3MM",
    },
    productChild: [
      {
        id: 2300,
        lbrId: 1903,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 2301,
        lbrId: 1908,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 2302,
        lbrId: 1911,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 2303,
        lbrId: 1919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2304,
        lbrId: 1932,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating Gold Plated",
      },
      {
        id: 2305,
        lbrId: 1942,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 364, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 364,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 364,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 364,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1302,
        imgId: 1144,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214756(1).jpg",
        cropName: "20160318_214756(1).jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:53:00.139+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10/3MM",
    prodManfDetails: "10",
    prodDescription: "10",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 275.44998,
    cashPrice: 326.5435,
    taxAll: 47.097,
    taxInclude: 350.09198,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 365,
    p_adminid: 2,
    productCategory: { id: 365, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 365,
      adminId: 2,
      parentId: 130,
      categoryId: 11,
      title: "10/3MM",
    },
    productChild: [
      {
        id: 2306,
        lbrId: 1903,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 2307,
        lbrId: 1908,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 2308,
        lbrId: 1911,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 2309,
        lbrId: 1919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2310,
        lbrId: 1932,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating Gold Plated",
      },
      {
        id: 2311,
        lbrId: 1942,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 365, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 365,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 365,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 365,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1303,
        imgId: 1145,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214756(2).jpg",
        cropName: "20160318_214756(2).jpg",
      },
      {
        id: 1304,
        imgId: 1146,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214756(3).jpg",
        cropName: "20160318_214756(3).jpg",
      },
      {
        id: 1305,
        imgId: 1147,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214756(4).jpg",
        cropName: "20160318_214756(4).jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:54:04.500+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10/3MM",
    prodManfDetails: "10",
    prodDescription:
      "10/3 MM Indian Artificial Fashionable Gold Plated Imitation Bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 275.44998,
    cashPrice: 326.5435,
    taxAll: 47.097,
    taxInclude: 350.09198,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 366,
    p_adminid: 2,
    productCategory: { id: 366, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 366,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 2312,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2313,
        lbrId: 2129,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "CNC/Laser Marking",
      },
      {
        id: 2314,
        lbrId: 2132,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 2315,
        lbrId: 2135,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2316,
        lbrId: 2157,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2317,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 366, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 366,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 366,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 366,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1306,
        imgId: 1154,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_130841.jpg",
        cropName: "20160204_130841.jpg",
      },
      {
        id: 1307,
        imgId: 1155,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_130954.jpg",
        cropName: "20160204_130954.jpg",
      },
      {
        id: 1308,
        imgId: 1156,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_131209.jpg",
        cropName: "20160204_131209.jpg",
      },
      {
        id: 1309,
        imgId: 1157,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_131255.jpg",
        cropName: "20160204_131255.jpg",
      },
      {
        id: 1310,
        imgId: 1158,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_131402.jpg",
        cropName: "20160204_131402.jpg",
      },
      {
        id: 1311,
        imgId: 1159,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_131519.jpg",
        cropName: "20160204_131519.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:56:05.905+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "1",
    prodDescription:
      "New Imitation Jewelry Women's fashion Trendy 2 Tone Colour Beautifully Bridal Wedding best Design Bangle Bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 200.45001,
    cashPrice: 232.37952,
    taxAll: 23.769,
    taxInclude: 244.264,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 367,
    p_adminid: 2,
    productCategory: { id: 367, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 367,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 2318,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2319,
        lbrId: 2129,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "CNC/Laser Marking",
      },
      {
        id: 2320,
        lbrId: 2135,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2321,
        lbrId: 2132,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 2322,
        lbrId: 2155,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Plating Gold Plated",
      },
      {
        id: 2323,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 367, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 367,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 367,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 367,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1312,
        imgId: 1161,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_213341.jpg",
        cropName: "20160318_213341.jpg",
      },
      {
        id: 1313,
        imgId: 1162,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_213428.jpg",
        cropName: "20160318_213428.jpg",
      },
      {
        id: 1314,
        imgId: 1160,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_213229.jpg",
        cropName: "20160318_213229.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:58:06.137+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "1",
    prodDescription: "Machine-cut gold-plated brass bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 168.05,
    cashPrice: 195.9295,
    taxAll: 22.148998,
    taxInclude: 207.00401,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 368,
    p_adminid: 2,
    productCategory: { id: 368, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 368,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 2324,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2325,
        lbrId: 2132,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 2326,
        lbrId: 2135,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2327,
        lbrId: 2142,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2328,
        lbrId: 2155,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Plating Gold Plated",
      },
      {
        id: 2329,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 368, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 368,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 368,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 368,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1315,
        imgId: 1163,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_214042.jpg",
        cropName: "20160318_214042.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T12:59:56.786+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "1",
    prodDescription:
      "New Arrival Adjustable Bracelet Set  Wholesale Brass Gold Plated Bracelet Party Fashion Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 145.85,
    cashPrice: 170.95451,
    taxAll: 21.039,
    taxInclude: 181.47401,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 369,
    p_adminid: 2,
    productCategory: { id: 369, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 369,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 2330,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2331,
        lbrId: 2132,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 2332,
        lbrId: 2135,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2333,
        lbrId: 2142,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2334,
        lbrId: 2155,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Plating Gold Plated",
      },
      {
        id: 2335,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 369, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 369,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 369,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 369,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1316,
        imgId: 1164,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_163050.jpg",
        cropName: "20160319_163050.jpg",
      },
      {
        id: 1317,
        imgId: 1165,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_163204.jpg",
        cropName: "20160319_163204.jpg",
      },
      {
        id: 1318,
        imgId: 1166,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_163302.jpg",
        cropName: "20160319_163302.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:01:31.918+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "1",
    prodDescription:
      "Bangles Designer Gold plated 2pc Trendy Pure Brass Broad Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 145.85,
    cashPrice: 170.95451,
    taxAll: 21.039,
    taxInclude: 181.47401,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 370,
    p_adminid: 2,
    productCategory: { id: 370, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 370,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 2336,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2337,
        lbrId: 2132,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 2338,
        lbrId: 2135,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2339,
        lbrId: 2157,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2340,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 370, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 370,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 370,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 370,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1319,
        imgId: 1170,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0059.jpg",
        cropName: "20180213-WA0059.jpg",
      },
      {
        id: 1320,
        imgId: 1171,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0060.jpg",
        cropName: "20180213-WA0060.jpg",
      },
      {
        id: 1321,
        imgId: 1172,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0061.jpg",
        cropName: "20180213-WA0061.jpg",
      },
      {
        id: 1322,
        imgId: 1173,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0062.jpg",
        cropName: "20180213-WA0062.jpg",
      },
      {
        id: 1323,
        imgId: 1174,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0063.jpg",
        cropName: "20180213-WA0063.jpg",
      },
      {
        id: 1324,
        imgId: 1175,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0064.jpg",
        cropName: "20180213-WA0064.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:03:55.619+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "1",
    prodDescription:
      "high-quality selling wholesale price Coloured and Polished Finish Brass and 2 tone Coloured Bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 179.9,
    cashPrice: 208.945,
    taxAll: 22.11,
    taxInclude: 220.0,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 371,
    p_adminid: 2,
    productCategory: { id: 371, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 371,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 2341,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2342,
        lbrId: 2132,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 2343,
        lbrId: 2135,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2344,
        lbrId: 2142,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2345,
        lbrId: 2157,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2346,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 371, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 371,
      adminId: 2,
      boxId: 70,
      brandName: "Plastic Box No.7",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 371,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 371,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1325,
        imgId: 1176,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0065.jpg",
        cropName: "20180213-WA0065.jpg",
      },
      {
        id: 1326,
        imgId: 1177,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0066.jpg",
        cropName: "20180213-WA0066.jpg",
      },
      {
        id: 1327,
        imgId: 1178,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0067.jpg",
        cropName: "20180213-WA0067.jpg",
      },
      {
        id: 1328,
        imgId: 1179,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0068.jpg",
        cropName: "20180213-WA0068.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:05:42.262+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "1",
    prodDescription:
      "Extraordinary Brass  2 Tone Gold Plated Bezel Set Bangle Artificial jewellery for women and girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 181.70001,
    cashPrice: 210.97002,
    taxAll: 22.2,
    taxInclude: 222.07,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 372,
    p_adminid: 2,
    productCategory: { id: 372, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 372,
      adminId: 2,
      parentId: 136,
      categoryId: 11,
      title: "12/3MM",
    },
    productChild: [
      {
        id: 2347,
        lbrId: 2169,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Pipe Cutting",
      },
      {
        id: 2348,
        lbrId: 2177,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Dell",
      },
      {
        id: 2349,
        lbrId: 2174,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2350,
        lbrId: 2184,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2351,
        lbrId: 2198,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Plating Gold Plated",
      },
      {
        id: 2352,
        lbrId: 2208,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 372, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 372,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 372,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 372,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1329,
        imgId: 1148,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_212226.jpg",
        cropName: "20160318_212226.jpg",
      },
      {
        id: 1330,
        imgId: 1149,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_2122262.jpg",
        cropName: "20160318_2122262.jpg",
      },
      {
        id: 1331,
        imgId: 1150,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_2122263.jpg",
        cropName: "20160318_2122263.jpg",
      },
      {
        id: 1332,
        imgId: 1151,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_2122264.jpg",
        cropName: "20160318_2122264.jpg",
      },
      {
        id: 1333,
        imgId: 1152,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_2122265.jpg",
        cropName: "20160318_2122265.jpg",
      },
      {
        id: 1334,
        imgId: 1153,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_2122266.jpg",
        cropName: "20160318_2122266.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:08:14.111+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12/3MM",
    prodManfDetails: "12",
    prodDescription:
      "Trendy 12/3 MM Gold Plated Textured Brass Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 361.85,
    cashPrice: 423.7435,
    taxAll: 51.417,
    taxInclude: 449.452,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 373,
    p_adminid: 2,
    productCategory: { id: 373, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 373,
      adminId: 2,
      parentId: 141,
      categoryId: 11,
      title: "15MM",
    },
    productChild: [
      {
        id: 2353,
        lbrId: 2390,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Pipe Cutting",
      },
      {
        id: 2354,
        lbrId: 2392,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "CNC/Laser Marking",
      },
      {
        id: 2355,
        lbrId: 2395,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 2356,
        lbrId: 2398,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2357,
        lbrId: 2420,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Plating Gold Plated",
      },
      {
        id: 2358,
        lbrId: 2430,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 373, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 373,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 373,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 373,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1335,
        imgId: 1180,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205557.jpg",
        cropName: "20160318_205557.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:10:14.030+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "15MM",
    prodManfDetails: "1",
    prodDescription:
      "Brass Bangle Bracelet Gold Plated Wedding WOMEN'S Trendy Party Circling Setting Gift for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 141.65,
    cashPrice: 166.22949,
    taxAll: 20.828999,
    taxInclude: 176.64398,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 374,
    p_adminid: 2,
    productCategory: { id: 374, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 374,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2359,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2360,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2361,
        lbrId: 2843,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Dell",
      },
      {
        id: 2362,
        lbrId: 2850,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2363,
        lbrId: 2865,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Plating Gold Plated",
      },
      {
        id: 2364,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 374, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 374,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 374,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 374,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1336,
        imgId: 1167,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_163427.jpg",
        cropName: "20160319_163427.jpg",
      },
      {
        id: 1337,
        imgId: 1168,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_163517.jpg",
        cropName: "20160319_163517.jpg",
      },
      {
        id: 1338,
        imgId: 1169,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_163606.jpg",
        cropName: "20160319_163606.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:17:02.020+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 166.85,
    cashPrice: 194.7745,
    taxAll: 22.479,
    taxInclude: 206.014,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 375,
    p_adminid: 2,
    productCategory: { id: 375, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 375,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2365,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2366,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2367,
        lbrId: 2843,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Dell",
      },
      {
        id: 2368,
        lbrId: 2850,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2369,
        lbrId: 2865,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Plating Gold Plated",
      },
      {
        id: 2370,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
      {
        id: 2371,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
    ],
    productSets: { id: 375, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 375,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 375,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 375,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1339,
        imgId: 1191,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205637.jpg",
        cropName: "20160318_205637.jpg",
      },
      {
        id: 1340,
        imgId: 1192,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205858.jpg",
        cropName: "20160318_205858.jpg",
      },
      {
        id: 1341,
        imgId: 1193,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_211336.jpg",
        cropName: "20160318_211336.jpg",
      },
      {
        id: 1342,
        imgId: 1194,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_211415.jpg",
        cropName: "20160318_211415.jpg",
      },
      {
        id: 1343,
        imgId: 1195,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_211450.jpg",
        cropName: "20160318_211450.jpg",
      },
      {
        id: 1344,
        imgId: 1196,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_213229.jpg",
        cropName: "20160318_213229.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:20:09.779+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "Indian Traditional Women's Wear Gold Plated Bangles Hot Sale Wedding Wears Fancy Artificial Gold Bangles At Reasonable Price.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 358.85,
    cashPrice: 410.7745,
    taxAll: 32.079002,
    taxInclude: 426.81403,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 376,
    p_adminid: 2,
    productCategory: { id: 376, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 376,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2407,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2408,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
      {
        id: 2409,
        lbrId: 2843,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Dell",
      },
      {
        id: 2410,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2411,
        lbrId: 2867,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2412,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 376, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 376,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 376,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 376,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1360,
        imgId: 1197,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164048.jpg",
        cropName: "20160319_164048.jpg",
      },
      {
        id: 1361,
        imgId: 1198,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164137.jpg",
        cropName: "20160319_164137.jpg",
      },
      {
        id: 1362,
        imgId: 1199,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164255.jpg",
        cropName: "20160319_164255.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:29:36.484+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "2-tone Gold plated Color Dubai Adjustable Gold Bangles for Women Gold Color Ethiopian Bracelets African Bangles Bracelets Jewelry.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 381.05,
    cashPrice: 435.7495,
    taxAll: 33.189003,
    taxInclude: 452.344,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 377,
    p_adminid: 2,
    productCategory: { id: 377, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 377,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2413,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2414,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
      {
        id: 2415,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2416,
        lbrId: 2843,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Dell",
      },
      {
        id: 2417,
        lbrId: 2850,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2418,
        lbrId: 2867,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2419,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 377, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 377,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 377,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 377,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1363,
        imgId: 1201,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164509.jpg",
        cropName: "20160319_164509.jpg",
      },
      {
        id: 1364,
        imgId: 1202,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164618.jpg",
        cropName: "20160319_164618.jpg",
      },
      {
        id: 1365,
        imgId: 1203,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164711.jpg",
        cropName: "20160319_164711.jpg",
      },
      {
        id: 1366,
        imgId: 1204,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164825.jpg",
        cropName: "20160319_164825.jpg",
      },
      {
        id: 1367,
        imgId: 1205,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164911.jpg",
        cropName: "20160319_164911.jpg",
      },
      {
        id: 1368,
        imgId: 1206,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164950.jpg",
        cropName: "20160319_164950.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:31:13.597+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "Fashion Jewelry Women's Gold Bangle Set 2 Tone plated wedding bracelet bangle for women's fancy bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 382.84998,
    cashPrice: 437.77448,
    taxAll: 33.279,
    taxInclude: 454.41397,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 378,
    p_adminid: 2,
    productCategory: { id: 378, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 378,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2420,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
      {
        id: 2421,
        lbrId: 2843,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Dell",
      },
      {
        id: 2422,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2423,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2424,
        lbrId: 2867,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2425,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 378, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 378,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 378,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 378,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1369,
        imgId: 1200,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160319_164405.jpg",
        cropName: "20160319_164405.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:32:55.528+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "Fashion Wedding Jewelry 2-tone Gold Plated Bangle Bracelet Brass Bangle Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 209.15,
    cashPrice: 239.76851,
    taxAll: 19.407,
    taxInclude: 249.472,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1667.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 379,
    p_adminid: 2,
    productCategory: { id: 379, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 379,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2426,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2427,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
      {
        id: 2428,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 2429,
        lbrId: 2865,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Plating Gold Plated",
      },
      {
        id: 2430,
        lbrId: 2845,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Diamond Dell",
      },
    ],
    productSets: { id: 379, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 379,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 379,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 379,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1370,
        imgId: 1181,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_175720.jpg",
        cropName: "20151024_175720.jpg",
      },
      {
        id: 1371,
        imgId: 1184,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_180052.jpg",
        cropName: "20151024_180052.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:35:06.260+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 668.5,
    cashPrice: 760.365,
    taxAll: 50.03,
    taxInclude: 785.38,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 380,
    p_adminid: 2,
    productCategory: { id: 380, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 380,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2431,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2432,
        lbrId: 2836,
        price: 18.0,
        taxPercentage: 5.0,
        tax: 0.9,
        title: "Eaching",
      },
      {
        id: 2433,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 2434,
        lbrId: 2845,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Diamond Dell",
      },
      {
        id: 2435,
        lbrId: 2850,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2436,
        lbrId: 2872,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2437,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
      {
        id: 2438,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
    ],
    productSets: { id: 380, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 380,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 380,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 380,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1372,
        imgId: 1182,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_175842.jpg",
        cropName: "20151024_175842.jpg",
      },
      {
        id: 1373,
        imgId: 1183,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_180017.jpg",
        cropName: "20151024_180017.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:37:07.108+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1466.5,
    cashPrice: 1658.115,
    taxAll: 89.93,
    taxInclude: 1703.0801,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 381,
    p_adminid: 2,
    productCategory: { id: 381, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 381,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2439,
        lbrId: 2836,
        price: 18.0,
        taxPercentage: 5.0,
        tax: 0.9,
        title: "Eaching",
      },
      {
        id: 2440,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2441,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 2442,
        lbrId: 2845,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Diamond Dell",
      },
      {
        id: 2443,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
      {
        id: 2444,
        lbrId: 2872,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2445,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 381, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 381,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 381,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 381,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1374,
        imgId: 1186,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_180215.jpg",
        cropName: "20151024_180215.jpg",
      },
      {
        id: 1375,
        imgId: 1187,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_180303.jpg",
        cropName: "20151024_180303.jpg",
      },
      {
        id: 1376,
        imgId: 1188,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_180351.jpg",
        cropName: "20151024_180351.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:40:24.585+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 749.5,
    cashPrice: 847.8175,
    taxAll: 46.735,
    taxInclude: 871.185,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 382,
    p_adminid: 2,
    productCategory: { id: 382, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 382,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2446,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2447,
        lbrId: 2836,
        price: 18.0,
        taxPercentage: 5.0,
        tax: 0.9,
        title: "Eaching",
      },
      {
        id: 2448,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 2449,
        lbrId: 2842,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 2450,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
      {
        id: 2451,
        lbrId: 2872,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2452,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 382, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 382,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 382,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 382,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1377,
        imgId: 1185,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_180129.jpg",
        cropName: "20151024_180129.jpg",
      },
      {
        id: 1378,
        imgId: 1189,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151024_180447.jpg",
        cropName: "20151024_180447.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:42:42.655+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 687.5,
    cashPrice: 778.0675,
    taxAll: 43.635,
    taxInclude: 799.885,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 383,
    p_adminid: 2,
    productCategory: { id: 383, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 383,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 2453,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 2454,
        lbrId: 2836,
        price: 18.0,
        taxPercentage: 5.0,
        tax: 0.9,
        title: "Eaching",
      },
      {
        id: 2455,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 2456,
        lbrId: 2842,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 2457,
        lbrId: 2850,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2458,
        lbrId: 2872,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2459,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
      {
        id: 2460,
        lbrId: 2837,
        price: 16.0,
        taxPercentage: 5.0,
        tax: 0.8,
        title: "CNC/Laser Marking",
      },
    ],
    productSets: { id: 383, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 383,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 383,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 383,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1379,
        imgId: 1190,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317_132841.jpg",
        cropName: "20160317_132841.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T13:44:26.683+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1342.5,
    cashPrice: 1518.615,
    taxAll: 83.729996,
    taxInclude: 1560.48,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 384,
    p_adminid: 2,
    productCategory: { id: 384, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 384,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 7570,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7571,
        lbrId: 2842,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7572,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 7573,
        lbrId: 2838,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Half Round",
      },
      {
        id: 7574,
        lbrId: 2872,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7575,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7576,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7577,
        lbrId: 2852,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7578,
        lbrId: 2859,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7579,
        lbrId: 2856,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7580,
        lbrId: 2858,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7581,
        lbrId: 2851,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7582,
        lbrId: 2854,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7583,
        lbrId: 2857,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
    ],
    productSets: { id: 384, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 384,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 384,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 384,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3768,
        imgId: 1210,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_160741.jpg",
        cropName: "20170304_160741.jpg",
      },
      {
        id: 3769,
        imgId: 1211,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_161601.jpg",
        cropName: "20170304_161601.jpg",
      },
      {
        id: 3770,
        imgId: 1212,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_161655.jpg",
        cropName: "20170304_161655.jpg",
      },
      {
        id: 3771,
        imgId: 1213,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_161747.jpg",
        cropName: "20170304_161747.jpg",
      },
      {
        id: 3772,
        imgId: 1214,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_161859.jpg",
        cropName: "20170304_161859.jpg",
      },
      {
        id: 3773,
        imgId: 1215,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_161947.jpg",
        cropName: "20170304_161947.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:31:19.853+00:00",
    createDate: "2023-08-22T13:48:59.283+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "20",
    prodDescription:
      "Good Quality Brass Bangles For Woman Bracelets Antique 3-tone Plated Wedding WOMEN'S.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1134.7,
    cashPrice: 1305.4579,
    taxAll: 114.576004,
    taxInclude: 1362.746,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 161.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 385,
    p_adminid: 2,
    productCategory: { id: 385, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 385,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 7599,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7600,
        lbrId: 2838,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Half Round",
      },
      {
        id: 7601,
        lbrId: 2842,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7602,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 7603,
        lbrId: 2872,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7604,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7605,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7606,
        lbrId: 2851,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7607,
        lbrId: 2852,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7608,
        lbrId: 2854,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7609,
        lbrId: 2856,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7610,
        lbrId: 2857,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7611,
        lbrId: 2858,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7612,
        lbrId: 2859,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 385, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 385,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 385,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 385,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3778,
        imgId: 1216,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_165812.jpg",
        cropName: "20170304_165812.jpg",
      },
      {
        id: 3779,
        imgId: 1217,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_170018.jpg",
        cropName: "20170304_170018.jpg",
      },
      {
        id: 3780,
        imgId: 1218,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_170305.jpg",
        cropName: "20170304_170305.jpg",
      },
      {
        id: 3781,
        imgId: 1219,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_170420.jpg",
        cropName: "20170304_170420.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:35:55.742+00:00",
    createDate: "2023-08-22T13:50:44.479+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "Factory custom fashion jewellery bracelets bangles 3-tone female bangle bracelet New top designer bangles for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1134.7,
    cashPrice: 1305.4579,
    taxAll: 114.576,
    taxInclude: 1362.746,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 161.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 386,
    p_adminid: 2,
    productCategory: { id: 386, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 386,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 7613,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7614,
        lbrId: 2838,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Half Round",
      },
      {
        id: 7615,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 7616,
        lbrId: 2872,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7617,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7618,
        lbrId: 2851,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7619,
        lbrId: 2852,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7620,
        lbrId: 2854,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7621,
        lbrId: 2856,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7622,
        lbrId: 2859,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7623,
        lbrId: 2857,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7624,
        lbrId: 2858,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7625,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 386, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 386,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 386,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 386,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3782,
        imgId: 1207,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_114411.jpg",
        cropName: "20161024_114411.jpg",
      },
      {
        id: 3783,
        imgId: 1208,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_115411.jpg",
        cropName: "20161024_115411.jpg",
      },
      {
        id: 3784,
        imgId: 1209,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161024_120256.jpg",
        cropName: "20161024_120256.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:38:11.862+00:00",
    createDate: "2023-08-22T13:52:22.894+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "Fashion Dubai 3-tone Gold Plated Copper Brass Jewelry Bangle for Women Summer Bracelet Factory Wholesale.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 577.1,
    cashPrice: 664.1765,
    taxAll: 58.733,
    taxInclude: 693.54297,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 387,
    p_adminid: 2,
    productCategory: { id: 387, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 387,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 7626,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7627,
        lbrId: 2842,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7628,
        lbrId: 2838,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Half Round",
      },
      {
        id: 7629,
        lbrId: 2870,
        price: 23.0,
        taxPercentage: 5.0,
        tax: 1.15,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 7630,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7631,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7632,
        lbrId: 2853,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7633,
        lbrId: 2852,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7634,
        lbrId: 2856,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7635,
        lbrId: 2857,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7636,
        lbrId: 2858,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7637,
        lbrId: 2859,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7638,
        lbrId: 2855,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
    ],
    productSets: { id: 387, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 387,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 387,
      adminId: 2,
      rawpriceId: 205,
      title: "18 Gauge Brass Tube 1.2mm",
      price: 610.0,
      different: 75.0,
      taxPercentage: 18.0,
      tax: 123.3,
    },
    productPacking: {
      id: 387,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3785,
        imgId: 1221,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180406-WA0003.jpg",
        cropName: "20180406-WA0003.jpg",
      },
      {
        id: 3786,
        imgId: 1220,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180406-WA0002.jpg",
        cropName: "20180406-WA0002.jpg",
      },
      {
        id: 3787,
        imgId: 1223,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180406-WA0004.jpg",
        cropName: "20180406-WA0004.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:40:24.720+00:00",
    createDate: "2023-08-22T13:53:49.117+00:00",
    metalGram: 0.043,
    extraGram: 5.0e-4,
    readyGram: 0.027,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "New style gold plated bangle bracelet for women's fashion jewellery.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1286.6001,
    cashPrice: 1486.0242,
    taxAll: 141.52802,
    taxInclude: 1556.7881,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 116.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 388,
    p_adminid: 2,
    productCategory: { id: 388, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 388,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 7639,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7640,
        lbrId: 2838,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Half Round",
      },
      {
        id: 7641,
        lbrId: 2842,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7642,
        lbrId: 2870,
        price: 23.0,
        taxPercentage: 5.0,
        tax: 1.15,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 7643,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7644,
        lbrId: 2840,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7645,
        lbrId: 2853,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7646,
        lbrId: 2852,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7647,
        lbrId: 2855,
        price: 0.9,
        taxPercentage: 5.0,
        tax: 0.045,
        title: "Cutting 6 Cut",
      },
      {
        id: 7648,
        lbrId: 2856,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7649,
        lbrId: 2857,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7650,
        lbrId: 2858,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7651,
        lbrId: 2859,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 388, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 388,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 388,
      adminId: 2,
      rawpriceId: 205,
      title: "18 Gauge Brass Tube 1.2mm",
      price: 610.0,
      different: 75.0,
      taxPercentage: 18.0,
      tax: 123.3,
    },
    productPacking: {
      id: 388,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3788,
        imgId: 1224,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180406-WA0005.jpg",
        cropName: "20180406-WA0005.jpg",
      },
      {
        id: 3789,
        imgId: 1225,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180406-WA0007.jpg",
        cropName: "20180406-WA0007.jpg",
      },
      {
        id: 3790,
        imgId: 1226,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180406-WA0008.jpg",
        cropName: "20180406-WA0008.jpg",
      },
      {
        id: 3791,
        imgId: 1227,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180406-WA0010.jpg",
        cropName: "20180406-WA0010.jpg",
      },
      {
        id: 3792,
        imgId: 1228,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180406-WA0011.jpg",
        cropName: "20180406-WA0011.jpg",
      },
    ],
    modifyDate: "2023-09-14T08:46:50.225+00:00",
    createDate: "2023-08-22T13:55:23.589+00:00",
    metalGram: 0.043,
    extraGram: 5.0e-4,
    readyGram: 0.027,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "Wholesale Custom Dubai Wedding luxury Bracelet Brass Fashion Jewelry Gold Plated Carving Design Big Width Bangles For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 661.05005,
    cashPrice: 763.45953,
    taxAll: 72.60901,
    taxInclude: 799.76404,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 233.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 389,
    p_adminid: 2,
    productCategory: {
      id: 389,
      adminId: 2,
      categoryId: 15,
      title: "PUNJABI KADA",
    },
    productParent: {
      id: 389,
      adminId: 2,
      parentId: 237,
      categoryId: 15,
      title: "6MM",
    },
    productChild: [
      {
        id: 7695,
        lbrId: 6634,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 7696,
        lbrId: 6659,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7697,
        lbrId: 6628,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Solder",
      },
      {
        id: 7698,
        lbrId: 6632,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7699,
        lbrId: 6647,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7700,
        lbrId: 6650,
        price: 2.45,
        taxPercentage: 5.0,
        tax: 0.1225,
        title: "Plating Gold Plated & Chrome",
      },
    ],
    productSets: { id: 389, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 389,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 389,
      adminId: 2,
      rawpriceId: 191,
      title: "Rolling Die Patti",
      price: 670.0,
      different: 12.0,
      taxPercentage: 18.0,
      tax: 122.76,
    },
    productPacking: {
      id: 389,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3800,
        imgId: 1248,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190430-WA0010-6M.jpg",
        cropName: "20190430-WA0010-6M.jpg",
      },
    ],
    modifyDate: "2023-09-14T09:37:00.680+00:00",
    createDate: "2023-08-22T13:58:59.621+00:00",
    metalGram: 0.016,
    extraGram: 5.0e-4,
    readyGram: 0.011,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "2-tone Brass Punjabi Kada.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 188.62001,
    cashPrice: 219.9228,
    taxAll: 24.881603,
    taxInclude: 232.36362,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 625.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 390,
    p_adminid: 2,
    productCategory: { id: 390, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 390,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 5177,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5178,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 5179,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5180,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 5181,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5182,
        lbrId: 1184,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 5183,
        lbrId: 1167,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 5184,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 390, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 390,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 390,
      adminId: 2,
      rawpriceId: 199,
      title: "12 Gauge Brass Tube 2.6mm Price in Mumbai",
      price: 585.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 117.0,
    },
    productPacking: {
      id: 390,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2563,
        imgId: 880,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_173846.jpg",
        cropName: "20190813_173846.jpg",
      },
      {
        id: 2564,
        imgId: 910,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174321.jpg",
        cropName: "20190813_174321.jpg",
      },
      {
        id: 2565,
        imgId: 917,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174208.jpg",
        cropName: "20190813_174208.jpg",
      },
    ],
    modifyDate: "2023-09-05T13:57:37.983+00:00",
    createDate: "2023-08-22T14:16:32.364+00:00",
    metalGram: 0.022,
    extraGram: 5.0e-4,
    readyGram: 0.0105,
    golwireThaw: "6MMX2.4MM",
    hsnSacCode: null,
    itemCodeNumber: "12G-6M-22M-105R",
    prodManfDetails: "Metal 0.022 Gram & Pavala kada 0.0140 Gram",
    prodDescription: "2=6, 4=15, 6=15, 8=6 & 3=6, 5=15, 7=15, 9=6",
    cartDescription: "84 boxes of in 1 Carton",
    cartQuantity: 3,
    cartPackCharge: 500.0,
    makingCharge: 482.65,
    cashPrice: 566.3315,
    taxAll: 70.83301,
    taxInclude: 601.748,
    active: true,
    gstCash: true,
    moqkg: 133.0,
    moq: 252.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 391,
    p_adminid: 2,
    productCategory: {
      id: 391,
      adminId: 2,
      categoryId: 15,
      title: "PUNJABI KADA",
    },
    productParent: {
      id: 391,
      adminId: 2,
      parentId: 240,
      categoryId: 15,
      title: "9MM",
    },
    productChild: [
      {
        id: 2508,
        lbrId: 1713,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Solder",
      },
      {
        id: 2509,
        lbrId: 1719,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2510,
        lbrId: 1722,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2511,
        lbrId: 1730,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2512,
        lbrId: 1717,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Half Round",
      },
      {
        id: 2513,
        lbrId: 6740,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2514,
        lbrId: 6743,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2515,
        lbrId: 6746,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2516,
        lbrId: 6736,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Solder",
      },
      {
        id: 2517,
        lbrId: 6739,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Half Round",
      },
      {
        id: 2518,
        lbrId: 6758,
        price: 2.15,
        taxPercentage: 5.0,
        tax: 0.1075,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2519,
        lbrId: 6767,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 391, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 391,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 391,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 391,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1408,
        imgId: 1258,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160303_152445.jpg",
        cropName: "20160303_152445.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T14:17:51.447+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9MM",
    prodManfDetails: "9",
    prodDescription:
      "Latest Women's Fashion Jewelry Gold Plated Bangles Modern style 2-tone Punjabi kada bangles design.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 278.5,
    cashPrice: 321.61502,
    taxAll: 30.53,
    taxInclude: 336.88,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 392,
    p_adminid: 2,
    productCategory: {
      id: 392,
      adminId: 2,
      categoryId: 15,
      title: "PUNJABI KADA",
    },
    productParent: {
      id: 392,
      adminId: 2,
      parentId: 240,
      categoryId: 15,
      title: "9MM",
    },
    productChild: [
      {
        id: 2520,
        lbrId: 6775,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2521,
        lbrId: 6778,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2522,
        lbrId: 6771,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2523,
        lbrId: 6781,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2524,
        lbrId: 6793,
        price: 3.25,
        taxPercentage: 5.0,
        tax: 0.1625,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2525,
        lbrId: 6802,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "MFG. Transport\r\n",
      },
      {
        id: 2526,
        lbrId: 6774,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 2527,
        lbrId: 6736,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Solder",
      },
      {
        id: 2528,
        lbrId: 6743,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2529,
        lbrId: 6740,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 2530,
        lbrId: 6746,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2531,
        lbrId: 6739,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Half Round",
      },
      {
        id: 2532,
        lbrId: 6758,
        price: 2.15,
        taxPercentage: 5.0,
        tax: 0.1075,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2533,
        lbrId: 6767,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 392, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 392,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 392,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 392,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1409,
        imgId: 1254,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160303_151946.jpg",
        cropName: "20160303_151946.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T14:19:38.936+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9M",
    prodManfDetails: "9",
    prodDescription:
      "Top Quality New fashion Latest women 2-tone bangle bracelets Punjabi Kada.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 194.0,
    cashPrice: 222.87999,
    taxAll: 18.96,
    taxInclude: 232.35999,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 393,
    p_adminid: 2,
    productCategory: {
      id: 393,
      adminId: 2,
      categoryId: 15,
      title: "PUNJABI KADA",
    },
    productParent: {
      id: 393,
      adminId: 2,
      parentId: 241,
      categoryId: 15,
      title: "10MM",
    },
    productChild: [
      {
        id: 8670,
        lbrId: 6775,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8671,
        lbrId: 6802,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8672,
        lbrId: 6777,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 8673,
        lbrId: 6794,
        price: 5.0,
        taxPercentage: 5.0,
        tax: 0.25,
        title: "Plating Bright Silver",
      },
      {
        id: 8674,
        lbrId: 6805,
        price: 9.45,
        taxPercentage: 5.0,
        tax: 0.4725,
        title: "Pawala Labour ",
      },
      {
        id: 8675,
        lbrId: 6789,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8676,
        lbrId: 6788,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
    ],
    productSets: { id: 393, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 393,
      adminId: 2,
      boxId: 82,
      brandName: "Omega Baby ",
      boxDesc: "18 Sets in 1 Box ",
      pkgCharge: 18.0,
      box: "18",
      hsnCode: "4819",
      price: 8.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 393,
      adminId: 2,
      rawpriceId: 200,
      title: "13 Gauge Brass Casting 2.3mm Price in Rajkot",
      price: 540.0,
      different: 20.0,
      taxPercentage: 18.0,
      tax: 100.8,
    },
    productPacking: {
      id: 393,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4091,
        imgId: 1259,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211001_120816(10).jpg",
        cropName: "20211001_120816(10).jpg",
      },
    ],
    modifyDate: "2023-09-16T08:00:52.994+00:00",
    createDate: "2023-08-22T14:20:42.355+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.009,
    golwireThaw: "2.5MM ",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "Metal 0.00 Gram & Pavala kada 0.0105 Gram",
    prodDescription: "Looking for a bright silver Kada for a baby boy?",
    cartDescription: "120 boxes of in 1 Carton",
    cartQuantity: 2,
    cartPackCharge: 500.0,
    makingCharge: 491.12,
    cashPrice: 561.5773,
    taxAll: 42.6906,
    taxInclude: 582.9226,
    active: true,
    gstCash: true,
    moqkg: 49.7,
    moq: 240.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 5,
        adminId: 2,
        productId: 393,
        stockCategoryId: 15,
        stockParentId: 241,
        cutOrFullSize: "1",
        createDate: "2023-09-16T08:05:57.147+00:00",
        s1x8: "333",
        s1x9: "0",
        s1x10: "26",
        s1x11: "0",
        s1x12: "25",
        s1x13: "0",
        s1x14: "26",
        s1x15: "0",
        s2x0: "26",
        s2x1: "0",
        s2x2: "0",
        s2x3: "0",
        s2x4: "0",
        s2x5: "0",
        s2x6: "0",
        s2x7: "0",
        s2x8: "0",
        s2x9: "0",
        s2x10: "0",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 436.0,
      },
    ],
  },
  {
    id: 394,
    p_adminid: 2,
    productCategory: {
      id: 394,
      adminId: 2,
      categoryId: 15,
      title: "PUNJABI KADA",
    },
    productParent: {
      id: 394,
      adminId: 2,
      parentId: 243,
      categoryId: 15,
      title: "12MM",
    },
    productChild: [
      {
        id: 2541,
        lbrId: 6841,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 2542,
        lbrId: 6844,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Half Round &V",
      },
      {
        id: 2543,
        lbrId: 6845,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 2544,
        lbrId: 6848,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 2545,
        lbrId: 6851,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2546,
        lbrId: 6863,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 2547,
        lbrId: 6872,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 394, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 394,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 394,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 394,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1411,
        imgId: 1261,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0071(12).jpg",
        cropName: "20180213-WA0071(12).jpg",
      },
      {
        id: 1412,
        imgId: 1262,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0073(12).jpg",
        cropName: "20180213-WA0073(12).jpg",
      },
      {
        id: 1413,
        imgId: 1263,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0074(12).jpg",
        cropName: "20180213-WA0074(12).jpg",
      },
      {
        id: 1414,
        imgId: 1265,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0075(12).jpg",
        cropName: "20180213-WA0075(12).jpg",
      },
      {
        id: 1415,
        imgId: 1266,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180213-WA0076(12).jpg",
        cropName: "20180213-WA0076(12).jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-22T14:22:21.821+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "1",
    prodDescription:
      "Trendy 2-tone plated bangles designer Bangles Set For Women's jewellery.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 155.5,
    cashPrice: 179.5675,
    taxAll: 17.035,
    taxInclude: 188.085,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 395,
    p_adminid: 2,
    productCategory: {
      id: 395,
      adminId: 2,
      categoryId: 15,
      title: "PUNJABI KADA",
    },
    productParent: {
      id: 395,
      adminId: 2,
      parentId: 245,
      categoryId: 15,
      title: "14MM",
    },
    productChild: [
      {
        id: 7668,
        lbrId: 6943,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7669,
        lbrId: 6916,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7670,
        lbrId: 6918,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 7671,
        lbrId: 6922,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 7672,
        lbrId: 6930,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7673,
        lbrId: 6931,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7674,
        lbrId: 6935,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating Bright Silver",
      },
      {
        id: 7675,
        lbrId: 6946,
        price: 14.6,
        taxPercentage: 5.0,
        tax: 0.73,
        title: "Pawala Labour",
      },
    ],
    productSets: { id: 395, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 395,
      adminId: 2,
      boxId: 71,
      brandName: "Plastic Box No.7",
      boxDesc: "15 Sets In 1 Box",
      pkgCharge: 15.0,
      box: "15",
      hsnCode: "39231000",
      price: 16.7,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 395,
      adminId: 2,
      rawpriceId: 200,
      title: "13 Gauge Brass Casting 2.3mm Price in Rajkot",
      price: 540.0,
      different: 20.0,
      taxPercentage: 18.0,
      tax: 100.8,
    },
    productPacking: {
      id: 395,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3795,
        imgId: 1267,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211001_120129(14).jpg",
        cropName: "20211001_120129(14).jpg",
      },
    ],
    modifyDate: "2023-09-14T09:26:57.537+00:00",
    createDate: "2023-08-22T14:24:08.766+00:00",
    metalGram: 0.029,
    extraGram: 5.0e-4,
    readyGram: 0.0235,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "14MM",
    prodManfDetails: "Metal 0.00 Gram & Pavala kada 0.02790 Gram",
    prodDescription:
      "Silver-plated glowing durable bangles for women's latest fashion for all occasions.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 673.55,
    cashPrice: 775.50696,
    taxAll: 69.203995,
    taxInclude: 810.10895,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 230.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 396,
    p_adminid: 2,
    productCategory: {
      id: 396,
      adminId: 2,
      categoryId: 13,
      title: "FINGER RINGS",
    },
    productParent: {
      id: 396,
      adminId: 2,
      parentId: 197,
      categoryId: 13,
      title: "6MM",
    },
    productChild: [
      {
        id: 7393,
        lbrId: 4897,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7394,
        lbrId: 4905,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7395,
        lbrId: 4933,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7396,
        lbrId: 4936,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7397,
        lbrId: 4901,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Half Round",
      },
      {
        id: 7398,
        lbrId: 4922,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7399,
        lbrId: 4915,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7400,
        lbrId: 4903,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
    ],
    productSets: { id: 396, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 396,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 396,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 396,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3704,
        imgId: 1229,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_150515.jpg",
        cropName: "20160204_150515.jpg",
      },
      {
        id: 3705,
        imgId: 1230,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_150712.jpg",
        cropName: "20160204_150712.jpg",
      },
      {
        id: 3706,
        imgId: 1231,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_150859.jpg",
        cropName: "20160204_150859.jpg",
      },
      {
        id: 3707,
        imgId: 1232,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_150933.jpg",
        cropName: "20160204_150933.jpg",
      },
      {
        id: 3708,
        imgId: 1233,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_151031.jpg",
        cropName: "20160204_151031.jpg",
      },
      {
        id: 3709,
        imgId: 1234,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_151205.jpg",
        cropName: "20160204_151205.jpg",
      },
    ],
    modifyDate: "2023-09-12T13:52:38.143+00:00",
    createDate: "2023-08-22T14:26:17.237+00:00",
    metalGram: 0.007,
    extraGram: 5.0e-4,
    readyGram: 0.0035,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Hot Sale Simple Fashion Women finger ring Indian 3-tone plated for Indian traditional party finger ring.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 262.90002,
    cashPrice: 302.53104,
    taxAll: 26.682003,
    taxInclude: 315.87204,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 397,
    p_adminid: 2,
    productCategory: {
      id: 397,
      adminId: 2,
      categoryId: 13,
      title: "FINGER RINGS",
    },
    productParent: {
      id: 397,
      adminId: 2,
      parentId: 197,
      categoryId: 13,
      title: "6MM",
    },
    productChild: [
      {
        id: 7384,
        lbrId: 4897,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 7385,
        lbrId: 4905,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 7386,
        lbrId: 4936,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7387,
        lbrId: 4933,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7388,
        lbrId: 4901,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Half Round",
      },
      {
        id: 7389,
        lbrId: 4902,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 7390,
        lbrId: 4903,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 7391,
        lbrId: 4915,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7392,
        lbrId: 4922,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 397, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 397,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 397,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 397,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3701,
        imgId: 1237,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_151413.jpg",
        cropName: "20160204_151413.jpg",
      },
      {
        id: 3702,
        imgId: 1238,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_151257.jpg",
        cropName: "20160204_151257.jpg",
      },
      {
        id: 3703,
        imgId: 1239,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_151341.jpg",
        cropName: "20160204_151341.jpg",
      },
    ],
    modifyDate: "2023-09-12T13:51:43.078+00:00",
    createDate: "2023-08-22T14:27:33.932+00:00",
    metalGram: 0.007,
    extraGram: 5.0e-4,
    readyGram: 0.0035,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Wholesale fine jewellery flowers carved 3-tone plated wedding finger ring for women cheap price.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 267.90002,
    cashPrice: 308.15604,
    taxAll: 26.932003,
    taxInclude: 321.62204,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 398,
    p_adminid: 2,
    productCategory: { id: 398, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 398,
      adminId: 2,
      parentId: 164,
      categoryId: 12,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 2567,
        lbrId: 3411,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 2568,
        lbrId: 3417,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 2569,
        lbrId: 3416,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 2570,
        lbrId: 3425,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2571,
        lbrId: 3447,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 398, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 398,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 398,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 398,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1426,
        imgId: 1309,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143808.jpg",
        cropName: "20170308_143808.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-23T06:12:41.005+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "G",
    prodDescription:
      "Beautiful Three Tone plated Brass trendy Bangle Fashion Hoop Earrings for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 151.5,
    cashPrice: 178.73999,
    taxAll: 24.18,
    taxInclude: 190.82999,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 399,
    p_adminid: 2,
    productCategory: { id: 399, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 399,
      adminId: 2,
      parentId: 168,
      categoryId: 12,
      title: "4MM",
    },
    productChild: [
      {
        id: 2572,
        lbrId: 3591,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 2573,
        lbrId: 3597,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 2574,
        lbrId: 3598,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 2575,
        lbrId: 3606,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2576,
        lbrId: 3629,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2577,
        lbrId: 3632,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 399, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 399,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 399,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 399,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1427,
        imgId: 1310,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_155932.jpg",
        cropName: "20170308_155932.jpg",
      },
      {
        id: 1428,
        imgId: 1311,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143631.jpg",
        cropName: "20170308_143631.jpg",
      },
      {
        id: 1429,
        imgId: 1312,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143449.jpg",
        cropName: "20170308_143449.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-23T06:14:33.963+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Elegant three-tone plated trendy bangle fashionable hoop earrings for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 154.5,
    cashPrice: 182.11499,
    taxAll: 24.33,
    taxInclude: 194.28,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 400,
    p_adminid: 2,
    productCategory: { id: 400, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 400,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 8737,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 8738,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8739,
        lbrId: 3721,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "3tone",
      },
      {
        id: 8740,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8741,
        lbrId: 3687,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 8742,
        lbrId: 3689,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8743,
        lbrId: 3700,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8744,
        lbrId: 3708,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8745,
        lbrId: 3712,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Earring Die Cutting + Lock",
      },
      {
        id: 8746,
        lbrId: 3713,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Earring Solder",
      },
      {
        id: 8747,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
    ],
    productSets: { id: 400, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 400,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 400,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 400,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4124,
        imgId: 1276,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164118.jpg",
        cropName: "20160204_164118.jpg",
      },
      {
        id: 4125,
        imgId: 1277,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164232.jpg",
        cropName: "20160204_164232.jpg",
      },
      {
        id: 4126,
        imgId: 1278,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164323.jpg",
        cropName: "20160204_164323.jpg",
      },
      {
        id: 4127,
        imgId: 1279,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_171755.jpg",
        cropName: "20160204_171755.jpg",
      },
    ],
    modifyDate: "2023-09-20T06:38:48.365+00:00",
    createDate: "2023-08-23T06:16:22.246+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "U/V Earring",
    prodDescription:
      "3tone Fancy Earrings\nMaterial - Brass\nOccasion - any\nEarring Type - fancy\nPlating - 3tone",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.8,
    cashPrice: 603.57196,
    taxAll: 48.184006,
    taxInclude: 627.664,
    active: true,
    gstCash: true,
    moqkg: 300.0,
    moq: 1304.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 401,
    p_adminid: 2,
    productCategory: { id: 401, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 401,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 8321,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 8322,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 8323,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8324,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8325,
        lbrId: 3721,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "3tone",
      },
      {
        id: 8326,
        lbrId: 3700,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8327,
        lbrId: 3708,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8328,
        lbrId: 3712,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Earring Die Cutting + Lock",
      },
      {
        id: 8329,
        lbrId: 3713,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Earring Solder",
      },
      {
        id: 8330,
        lbrId: 3689,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8331,
        lbrId: 3687,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
    ],
    productSets: { id: 401, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 401,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 401,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 401,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4002,
        imgId: 1281,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_170045.jpg",
        cropName: "20160204_170045.jpg",
      },
      {
        id: 4003,
        imgId: 1282,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_165933.jpg",
        cropName: "20160204_165933.jpg",
      },
    ],
    modifyDate: "2023-09-14T13:58:26.100+00:00",
    createDate: "2023-08-23T06:17:14.891+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Fashion Jewelry Custom Three-Tone Plated Hoop Earrings for Women Gold Silver Plating Trending Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.8,
    cashPrice: 603.57196,
    taxAll: 48.184002,
    taxInclude: 627.664,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 435.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 402,
    p_adminid: 2,
    productCategory: { id: 402, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 402,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 8584,
        lbrId: 3683,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 8585,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 8586,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 8587,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8588,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8589,
        lbrId: 3687,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 8590,
        lbrId: 3689,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8591,
        lbrId: 3700,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8592,
        lbrId: 3708,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8593,
        lbrId: 3717,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Bright Silver",
      },
      {
        id: 8594,
        lbrId: 3712,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Earring Die Cutting + Lock",
      },
      {
        id: 8595,
        lbrId: 3713,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Earring Solder",
      },
    ],
    productSets: { id: 402, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 402,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 402,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 402,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4074,
        imgId: 1283,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164448.jpg",
        cropName: "20160204_164448.jpg",
      },
      {
        id: 4075,
        imgId: 1284,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164540.jpg",
        cropName: "20160204_164540.jpg",
      },
      {
        id: 4076,
        imgId: 1285,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164707.jpg",
        cropName: "20160204_164707.jpg",
      },
      {
        id: 4077,
        imgId: 1286,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164754.jpg",
        cropName: "20160204_164754.jpg",
      },
      {
        id: 4078,
        imgId: 1288,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164913.jpg",
        cropName: "20160204_164913.jpg",
      },
    ],
    modifyDate: "2023-09-15T13:21:41.285+00:00",
    createDate: "2023-08-23T06:18:51.970+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "3tone Fancy Earrings\nMaterial - Brass\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 436.8,
    cashPrice: 502.322,
    taxAll: 43.684002,
    taxInclude: 524.164,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 435.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 403,
    p_adminid: 2,
    productCategory: { id: 403, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 403,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 8298,
        lbrId: 3683,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 8299,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 8300,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 8301,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8302,
        lbrId: 3687,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 8303,
        lbrId: 3689,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8304,
        lbrId: 3700,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8305,
        lbrId: 3708,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8306,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8307,
        lbrId: 3717,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Bright Silver",
      },
      {
        id: 8308,
        lbrId: 3712,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Earring Die Cutting + Lock",
      },
      {
        id: 8309,
        lbrId: 3713,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Earring Solder",
      },
    ],
    productSets: { id: 403, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 403,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 403,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 403,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3996,
        imgId: 1287,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164849.jpg",
        cropName: "20160204_164849.jpg",
      },
      {
        id: 3997,
        imgId: 1289,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_164959.jpg",
        cropName: "20160204_164959.jpg",
      },
    ],
    modifyDate: "2023-09-14T13:57:07.780+00:00",
    createDate: "2023-08-23T06:20:14.261+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Fashion Jewelry Customize Logo Package Trendy Silver Tone Plated Hoop Earrings for Women Girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 436.8,
    cashPrice: 502.322,
    taxAll: 43.684002,
    taxInclude: 524.164,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 435.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 404,
    p_adminid: 2,
    productCategory: { id: 404, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 404,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 8332,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 8333,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 8334,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8335,
        lbrId: 3719,
        price: 7.5,
        taxPercentage: 5.0,
        tax: 0.375,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 8336,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8337,
        lbrId: 3687,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 8338,
        lbrId: 3689,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8339,
        lbrId: 3700,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8340,
        lbrId: 3708,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8341,
        lbrId: 3712,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Earring Die Cutting + Lock",
      },
      {
        id: 8342,
        lbrId: 3713,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Earring Solder",
      },
    ],
    productSets: { id: 404, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 404,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 404,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 404,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4004,
        imgId: 1291,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_165326.jpg",
        cropName: "20160204_165326.jpg",
      },
      {
        id: 4005,
        imgId: 1290,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_165153.jpg",
        cropName: "20160204_165153.jpg",
      },
    ],
    modifyDate: "2023-09-14T14:01:02.112+00:00",
    createDate: "2023-08-23T06:30:53.475+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "High-Quality Gold Micro Plated Hoop Earrings for Women Girls Fashion Jewelry New Designs.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 516.8,
    cashPrice: 592.32196,
    taxAll: 47.684006,
    taxInclude: 616.164,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 435.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 405,
    p_adminid: 2,
    productCategory: { id: 405, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 405,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 2612,
        lbrId: 3683,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 2613,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 2614,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 2615,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 2616,
        lbrId: 3699,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2617,
        lbrId: 3719,
        price: 7.5,
        taxPercentage: 5.0,
        tax: 0.375,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 2618,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 405, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 405,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 405,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 405,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1446,
        imgId: 1293,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_165544.jpg",
        cropName: "20160204_165544.jpg",
      },
      {
        id: 1447,
        imgId: 1292,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_165505.jpg",
        cropName: "20160204_165505.jpg",
      },
      {
        id: 1448,
        imgId: 1294,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_165623.jpg",
        cropName: "20160204_165623.jpg",
      },
      {
        id: 1449,
        imgId: 1295,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_165653.jpg",
        cropName: "20160204_165653.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-23T06:31:57.857+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6 MM Micro Plated Hoop Earrings jewellery high-quality luxury accessories for women and girls designs gift party trendy.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 325.5,
    cashPrice: 374.49,
    taxAll: 32.88,
    taxInclude: 390.93,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 406,
    p_adminid: 2,
    productCategory: { id: 406, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 406,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 2634,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 2635,
        lbrId: 3683,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 2636,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 2637,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 2638,
        lbrId: 3699,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2639,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 2640,
        lbrId: 3721,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "3tone",
      },
    ],
    productSets: { id: 406, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 406,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 406,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 406,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1458,
        imgId: 1296,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_172523.jpg",
        cropName: "20160204_172523.jpg",
      },
      {
        id: 1459,
        imgId: 1297,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_172700.jpg",
        cropName: "20160204_172700.jpg",
      },
      {
        id: 1460,
        imgId: 1298,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160204_172746.jpg",
        cropName: "20160204_172746.jpg",
      },
    ],
    modifyDate: "2023-08-23T06:34:54.032+00:00",
    createDate: "2023-08-23T06:32:59.054+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6 mm Fancy Three Tone Plated Bangle Hoop Earrings for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 335.5,
    cashPrice: 385.74,
    taxAll: 33.38,
    taxInclude: 402.43,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 407,
    p_adminid: 2,
    productCategory: { id: 407, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 407,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 2626,
        lbrId: 3683,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 2627,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 2628,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 2629,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 2630,
        lbrId: 3687,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 2631,
        lbrId: 3699,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2632,
        lbrId: 3721,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "3tone",
      },
      {
        id: 2633,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 407, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 407,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 407,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 407,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1453,
        imgId: 1299,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_140903.jpg",
        cropName: "20170308_140903.jpg",
      },
      {
        id: 1454,
        imgId: 1300,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_141130.jpg",
        cropName: "20170308_141130.jpg",
      },
      {
        id: 1455,
        imgId: 1301,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_141507.jpg",
        cropName: "20170308_141507.jpg",
      },
      {
        id: 1456,
        imgId: 1302,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_142417.jpg",
        cropName: "20170308_142417.jpg",
      },
      {
        id: 1457,
        imgId: 1303,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_142641.jpg",
        cropName: "20170308_142641.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-23T06:34:16.493+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Designer Three Tone Plated trendy Long bangle hoop jewellery Earrings for women to wear daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 341.5,
    cashPrice: 392.49,
    taxAll: 33.68,
    taxInclude: 409.33,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 408,
    p_adminid: 2,
    productCategory: { id: 408, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 408,
      adminId: 2,
      parentId: 170,
      categoryId: 12,
      title: "6MM",
    },
    productChild: [
      {
        id: 2641,
        lbrId: 3683,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 2642,
        lbrId: 3682,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 2643,
        lbrId: 3690,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 2644,
        lbrId: 3691,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 2645,
        lbrId: 3699,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2646,
        lbrId: 3721,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "3tone",
      },
      {
        id: 2647,
        lbrId: 3724,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 408, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 408,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 408,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 408,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1461,
        imgId: 1305,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_142857.jpg",
        cropName: "20170308_142857.jpg",
      },
      {
        id: 1462,
        imgId: 1306,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_142946.jpg",
        cropName: "20170308_142946.jpg",
      },
      {
        id: 1463,
        imgId: 1307,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143111.jpg",
        cropName: "20170308_143111.jpg",
      },
      {
        id: 1464,
        imgId: 1308,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143201.jpg",
        cropName: "20170308_143201.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-23T06:37:44.230+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Elegant 3-tone Plated Hoop Bangle fashion jewellery Earrings for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 335.5,
    cashPrice: 385.74,
    taxAll: 33.38,
    taxInclude: 402.43,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 409,
    p_adminid: 2,
    productCategory: { id: 409, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 409,
      adminId: 2,
      parentId: 172,
      categoryId: 12,
      title: "8MM",
    },
    productChild: [
      {
        id: 2648,
        lbrId: 3775,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 2649,
        lbrId: 3774,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 2650,
        lbrId: 3782,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 2651,
        lbrId: 3783,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 2652,
        lbrId: 3791,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2653,
        lbrId: 3813,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2654,
        lbrId: 3816,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 409, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 409,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 409,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 409,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1465,
        imgId: 1313,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143354.jpg",
        cropName: "20170308_143354.jpg",
      },
      {
        id: 1466,
        imgId: 1314,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143524.jpg",
        cropName: "20170308_143524.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-23T06:38:37.721+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Elegant Style 3-tone Plated Earring fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 154.5,
    cashPrice: 182.11499,
    taxAll: 24.33,
    taxInclude: 194.28,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 410,
    p_adminid: 2,
    productCategory: { id: 410, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 410,
      adminId: 2,
      parentId: 172,
      categoryId: 12,
      title: "8MM",
    },
    productChild: [
      {
        id: 2705,
        lbrId: 3775,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Solder",
      },
      {
        id: 2706,
        lbrId: 3774,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 2707,
        lbrId: 3782,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 2708,
        lbrId: 3783,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 2709,
        lbrId: 3791,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2710,
        lbrId: 3805,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Earring Solder",
      },
      {
        id: 2711,
        lbrId: 3813,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 2712,
        lbrId: 3816,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 410, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 410,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 410,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 410,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1498,
        imgId: 1315,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143713.jpg",
        cropName: "20170308_143713.jpg",
      },
      {
        id: 1499,
        imgId: 1314,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_143524.jpg",
        cropName: "20170308_143524.jpg",
      },
    ],
    modifyDate: "2023-08-24T05:17:47.414+00:00",
    createDate: "2023-08-23T06:39:46.953+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Elegant 3-tone Plated Earring fashion jewellery Earrings for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 154.5,
    cashPrice: 182.11499,
    taxAll: 24.33,
    taxInclude: 194.28,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 411,
    p_adminid: 2,
    productCategory: { id: 411, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 411,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 2760,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2761,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2762,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2763,
        lbrId: 548,
        price: 1.15,
        taxPercentage: 5.0,
        tax: 0.0575,
        title: "Kanas Meena",
      },
      {
        id: 2764,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2765,
        lbrId: 567,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated & Meena",
      },
    ],
    productSets: { id: 411, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 411,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 411,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 411,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1525,
        imgId: 1320,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_135845.jpg",
        cropName: "20171017_135845.jpg",
      },
      {
        id: 1526,
        imgId: 1318,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_173705.jpg",
        cropName: "20171013_173705.jpg",
      },
      {
        id: 1527,
        imgId: 1319,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_173607.jpg",
        cropName: "20171013_173607.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T07:17:33.904+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "gm",
    prodManfDetails: "g",
    prodDescription:
      " Gold Plated Bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 281.25,
    cashPrice: 329.6895,
    taxAll: 40.628998,
    taxInclude: 350.004,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 412,
    p_adminid: 2,
    productCategory: { id: 412, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 412,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 2766,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2767,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2768,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2769,
        lbrId: 553,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 2770,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 2771,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 412, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 412,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 412,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 412,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1528,
        imgId: 1326,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_163902.jpg",
        cropName: "20171013_163902.jpg",
      },
      {
        id: 1529,
        imgId: 1325,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_163559.jpg",
        cropName: "20171013_163559.jpg",
      },
      {
        id: 1530,
        imgId: 1324,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_163451.jpg",
        cropName: "20171013_163451.jpg",
      },
      {
        id: 1531,
        imgId: 1323,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_163204.jpg",
        cropName: "20171013_163204.jpg",
      },
      {
        id: 1532,
        imgId: 1322,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_162749.jpg",
        cropName: "20171013_162749.jpg",
      },
      {
        id: 1533,
        imgId: 1321,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_162635.jpg",
        cropName: "20171013_162635.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T07:19:18.998+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "G",
    prodDescription:
      "Style Gold Plated Imitation Bangle fashion jewellery women daily wear trendy bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 244.84999,
    cashPrice: 288.7395,
    taxAll: 38.809,
    taxInclude: 308.14398,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 413,
    p_adminid: 2,
    productCategory: { id: 413, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 413,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 3290,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3291,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3292,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3293,
        lbrId: 552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 3294,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3295,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 413, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 413,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 413,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 413,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1803,
        imgId: 1526,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_175312.jpg",
        cropName: "20190813_175312.jpg",
      },
    ],
    modifyDate: "2023-08-28T05:40:29.894+00:00",
    createDate: "2023-08-25T07:20:48.751+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "G",
    prodDescription:
      "Imitation Gold Plated Fashion Bangle jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 240.65,
    cashPrice: 284.0145,
    taxAll: 38.599,
    taxInclude: 303.314,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 414,
    p_adminid: 2,
    productCategory: { id: 414, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 414,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 2778,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 2779,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2780,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2781,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2782,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 2783,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 2784,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 414, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 414,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 414,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 414,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1535,
        imgId: 1329,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_190554.jpg",
        cropName: "20171013_190554.jpg",
      },
      {
        id: 1536,
        imgId: 1330,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_190700.jpg",
        cropName: "20171013_190700.jpg",
      },
      {
        id: 1537,
        imgId: 1328,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_190407.jpg",
        cropName: "20171013_190407.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T07:30:23.165+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Unique  Gold Plated Pola Bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 303.65,
    cashPrice: 355.1555,
    taxAll: 42.280994,
    taxInclude: 376.296,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 415,
    p_adminid: 2,
    productCategory: { id: 415, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 415,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 2785,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 2786,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2787,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2788,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2789,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 2790,
        lbrId: 814,
        price: 2.8,
        taxPercentage: 5.0,
        tax: 0.14,
        title: "Pola 2 Colour\r\n",
      },
      {
        id: 2791,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 415, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 415,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 415,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 415,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1538,
        imgId: 1333,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_144345.jpg",
        cropName: "20171017_144345.jpg",
      },
      {
        id: 1539,
        imgId: 1332,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_135415.jpg",
        cropName: "20171017_135415.jpg",
      },
      {
        id: 1540,
        imgId: 1331,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_134949.jpg",
        cropName: "20171017_134949.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T07:32:08.260+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Gold plated Red pola colour high polished women's hand bangle classic stunning most trending brand new hand bracelet modern.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 331.65,
    cashPrice: 386.6555,
    taxAll: 43.680996,
    taxInclude: 408.496,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 416,
    p_adminid: 2,
    productCategory: { id: 416, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 416,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 8343,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 8344,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8345,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 8346,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 8347,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8348,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 8349,
        lbrId: 887,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8350,
        lbrId: 876,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Kangaril/Dell Kachi",
      },
    ],
    productSets: { id: 416, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 416,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 416,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 416,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4006,
        imgId: 1334,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_145930.jpg",
        cropName: "20171017_145930.jpg",
      },
      {
        id: 4007,
        imgId: 1335,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_145847.jpg",
        cropName: "20171017_145847.jpg",
      },
      {
        id: 4008,
        imgId: 1336,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_145801.jpg",
        cropName: "20171017_145801.jpg",
      },
      {
        id: 4009,
        imgId: 1337,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_135554.jpg",
        cropName: "20171017_135554.jpg",
      },
      {
        id: 4010,
        imgId: 1338,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_145640.jpg",
        cropName: "20171017_145640.jpg",
      },
      {
        id: 4011,
        imgId: 1342,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_143220.jpg",
        cropName: "20171017_143220.jpg",
      },
    ],
    modifyDate: "2023-09-14T14:11:35.973+00:00",
    createDate: "2023-08-25T07:53:07.077+00:00",
    metalGram: 0.013,
    extraGram: 5.0e-4,
    readyGram: 0.0085,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "4 MM gold plated imitation high polish finished bangle fashion and fancy hand bracelets for women and girls super classic designs.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 449.45,
    cashPrice: 529.1355,
    taxAll: 69.481,
    taxInclude: 563.87604,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 192.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 417,
    p_adminid: 2,
    productCategory: { id: 417, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 417,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2798,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2799,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2800,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2801,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2802,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 417, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 417,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 417,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 417,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1547,
        imgId: 1339,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_140118.jpg",
        cropName: "20171017_140118.jpg",
      },
      {
        id: 1548,
        imgId: 1340,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_140248.jpg",
        cropName: "20171017_140248.jpg",
      },
      {
        id: 1549,
        imgId: 1341,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_143607.jpg",
        cropName: "20171017_143607.jpg",
      },
      {
        id: 1550,
        imgId: 1342,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_143220.jpg",
        cropName: "20171017_143220.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:10:52.653+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Gold finish high polish imitation bangle brand new latest modern stunning design for women and girls on all events gift.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.85,
    cashPrice: 296.7055,
    taxAll: 39.340996,
    taxInclude: 316.376,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 418,
    p_adminid: 2,
    productCategory: { id: 418, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 418,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2803,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2804,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2805,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2806,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2807,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 2808,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 418, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 418,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 418,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 418,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1551,
        imgId: 1344,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_173957.jpg",
        cropName: "20171013_173957.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:12:52.467+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Imitation gold plated bangle fashion jewellery classic and trendy new modern designs for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.05,
    cashPrice: 301.43048,
    taxAll: 39.550995,
    taxInclude: 321.206,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 419,
    p_adminid: 2,
    productCategory: { id: 419, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 419,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2809,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2810,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2811,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2812,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2813,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 2814,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 419, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 419,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 419,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 419,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1552,
        imgId: 1343,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_180405.jpg",
        cropName: "20171013_180405.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:14:59.574+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "4",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 405.05002,
    cashPrice: 476.94553,
    taxAll: 62.781002,
    taxInclude: 508.33603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 420,
    p_adminid: 2,
    productCategory: { id: 420, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 420,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2815,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2816,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2817,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2818,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2819,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 2820,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 420, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 420,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 420,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 420,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1553,
        imgId: 1345,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_164745.jpg",
        cropName: "20171013_164745.jpg",
      },
      {
        id: 1554,
        imgId: 1346,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_165017.jpg",
        cropName: "20171013_165017.jpg",
      },
      {
        id: 1555,
        imgId: 1347,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_165429.jpg",
        cropName: "20171013_165429.jpg",
      },
      {
        id: 1556,
        imgId: 1348,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_165532.jpg",
        cropName: "20171013_165532.jpg",
      },
      {
        id: 1557,
        imgId: 1349,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_165648.jpg",
        cropName: "20171013_165648.jpg",
      },
      {
        id: 1558,
        imgId: 1350,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_170041.jpg",
        cropName: "20171013_170041.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:17:03.210+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "This type of brass bangle is Perfect for weddings, anniversaries, festive wear, colleges, casual parties etc.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.05,
    cashPrice: 301.43048,
    taxAll: 39.550995,
    taxInclude: 321.206,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 421,
    p_adminid: 2,
    productCategory: { id: 421, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 421,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2821,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2822,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2823,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2824,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2825,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 2826,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 421, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 421,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 421,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 421,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1559,
        imgId: 1352,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113537.jpg",
        cropName: "20170721_113537.jpg",
      },
      {
        id: 1560,
        imgId: 1351,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113437.jpg",
        cropName: "20170721_113437.jpg",
      },
      {
        id: 1561,
        imgId: 1353,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113654.jpg",
        cropName: "20170721_113654.jpg",
      },
      {
        id: 1562,
        imgId: 1354,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114024.jpg",
        cropName: "20170721_114024.jpg",
      },
      {
        id: 1563,
        imgId: 1355,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114222.jpg",
        cropName: "20170721_114222.jpg",
      },
      {
        id: 1564,
        imgId: 1356,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114344.jpg",
        cropName: "20170721_114344.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:18:11.895+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Designer Cut 4 MM Gold Plated Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 405.05002,
    cashPrice: 476.94553,
    taxAll: 62.781002,
    taxInclude: 508.33603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 422,
    p_adminid: 2,
    productCategory: { id: 422, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 422,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2827,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2828,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2829,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2830,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 2831,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 422, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 422,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 422,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 422,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1565,
        imgId: 1357,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113736.jpg",
        cropName: "20170721_113736.jpg",
      },
      {
        id: 1566,
        imgId: 1358,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113911.jpg",
        cropName: "20170721_113911.jpg",
      },
      {
        id: 1567,
        imgId: 1359,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114108.jpg",
        cropName: "20170721_114108.jpg",
      },
      {
        id: 1568,
        imgId: 1360,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_113825.jpg",
        cropName: "20170721_113825.jpg",
      },
      {
        id: 1569,
        imgId: 1361,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114244.jpg",
        cropName: "20170721_114244.jpg",
      },
      {
        id: 1570,
        imgId: 1362,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170721_114420.jpg",
        cropName: "20170721_114420.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:29:39.073+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Trendy Style Imitation 4 MM Gold Plated Fashion Jewellery Bangle Women daily wear elegant bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 239.25,
    cashPrice: 282.5305,
    taxAll: 38.710995,
    taxInclude: 301.886,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 423,
    p_adminid: 2,
    productCategory: { id: 423, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 423,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2832,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2833,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2834,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2835,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2836,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 2837,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 423, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 423,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 423,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 423,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1571,
        imgId: 1363,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170714_104746.jpg",
        cropName: "20170714_104746.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:42:43.947+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Adjustable Gold Bangles for Women Gold Color Bracelets Bangles Indian bangles Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.05,
    cashPrice: 301.43048,
    taxAll: 39.550995,
    taxInclude: 321.206,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 424,
    p_adminid: 2,
    productCategory: { id: 424, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 424,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2838,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2839,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2840,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2841,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2842,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 2843,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 424, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 424,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 424,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 424,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1572,
        imgId: 1364,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170714_104943.jpg",
        cropName: "20170714_104943.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:44:32.844+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "wholesale Solid Gold Bangles Adjustable Alloy Charms Bangles wedding bracelet bangle for women cheap prices.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.05,
    cashPrice: 301.43048,
    taxAll: 39.550995,
    taxInclude: 321.206,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 425,
    p_adminid: 2,
    productCategory: { id: 425, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 425,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 2844,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2845,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2846,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 2847,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2848,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 2849,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 425, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 425,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 425,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 425,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1573,
        imgId: 1365,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171017_143447.jpg",
        cropName: "20171017_143447.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:45:44.963+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "wholesale fine jewellery flowers carved gold plated wedding bracelet bangle for women bangle the best price.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.05,
    cashPrice: 301.43048,
    taxAll: 39.550995,
    taxInclude: 321.206,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 426,
    p_adminid: 2,
    productCategory: { id: 426, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 426,
      adminId: 2,
      parentId: 113,
      categoryId: 11,
      title: "5/3MM",
    },
    productChild: [
      {
        id: 2850,
        lbrId: 1073,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2851,
        lbrId: 1076,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 2852,
        lbrId: 1067,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Solder",
      },
      {
        id: 2853,
        lbrId: 1087,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2854,
        lbrId: 1100,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 2855,
        lbrId: 1110,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 426, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 426,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 426,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 426,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1574,
        imgId: 1366,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_204853.jpg",
        cropName: "20160318_204853.jpg",
      },
      {
        id: 1575,
        imgId: 1367,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205002.jpg",
        cropName: "20160318_205002.jpg",
      },
      {
        id: 1576,
        imgId: 1368,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205058.jpg",
        cropName: "20160318_205058.jpg",
      },
      {
        id: 1577,
        imgId: 1369,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205207.jpg",
        cropName: "20160318_205207.jpg",
      },
      {
        id: 1578,
        imgId: 1370,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205329.jpg",
        cropName: "20160318_205329.jpg",
      },
      {
        id: 1579,
        imgId: 1371,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160318_205430.jpg",
        cropName: "20160318_205430.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T08:57:52.484+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5/3MM",
    prodManfDetails: "5",
    prodDescription:
      "wholesale luxury gold plated bangles for women's jewelry bracelet wedding gifts Party Bracelet Jewelry Wholesale.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 319.25,
    cashPrice: 375.62347,
    taxAll: 48.897003,
    taxInclude: 400.072,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 427,
    p_adminid: 2,
    productCategory: { id: 427, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 427,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 2861,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 2862,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2863,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2864,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2865,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 2866,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 427, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 427,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 427,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 427,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1586,
        imgId: 1381,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164940.jpg",
        cropName: "20160330_164940.jpg",
      },
      {
        id: 1587,
        imgId: 1380,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164853.jpg",
        cropName: "20160330_164853.jpg",
      },
      {
        id: 1588,
        imgId: 1379,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164806.jpg",
        cropName: "20160330_164806.jpg",
      },
      {
        id: 1589,
        imgId: 1378,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164706.jpg",
        cropName: "20160330_164706.jpg",
      },
      {
        id: 1590,
        imgId: 1377,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164622.jpg",
        cropName: "20160330_164622.jpg",
      },
      {
        id: 1591,
        imgId: 1376,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160330_164559.jpg",
        cropName: "20160330_164559.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T09:53:01.744+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Trendy Gold Plated Imitation Fashion jewellery Bangle women's daily wear bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 428,
    p_adminid: 2,
    productCategory: { id: 428, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 428,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6851,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6852,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6853,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 6854,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6855,
        lbrId: 990,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
    ],
    productSets: { id: 428, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 428,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 428,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 428,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3402,
        imgId: 1384,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_132918.jpg",
        cropName: "20160914_132918.jpg",
      },
      {
        id: 3403,
        imgId: 1382,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_132522.jpg",
        cropName: "20160914_132522.jpg",
      },
      {
        id: 3404,
        imgId: 1383,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_132816.jpg",
        cropName: "20160914_132816.jpg",
      },
      {
        id: 3405,
        imgId: 1385,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133023.jpg",
        cropName: "20160914_133023.jpg",
      },
      {
        id: 3406,
        imgId: 1386,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133111.jpg",
        cropName: "20160914_133111.jpg",
      },
      {
        id: 3407,
        imgId: 1387,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160914_133159.jpg",
        cropName: "20160914_133159.jpg",
      },
    ],
    modifyDate: "2023-09-11T12:56:34.284+00:00",
    createDate: "2023-08-25T09:55:20.979+00:00",
    metalGram: 0.0145,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Fashion Popular Classic Wholesale Gold Plated Jewellery Bangle For Gift.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 293.27,
    cashPrice: 345.72128,
    taxAll: 46.248596,
    taxInclude: 368.84558,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 287.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 429,
    p_adminid: 2,
    productCategory: { id: 429, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 429,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 2888,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 2889,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2890,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2891,
        lbrId: 986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2892,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 2893,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 429, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 429,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 429,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 429,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1613,
        imgId: 1394,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170302_123859.jpg",
        cropName: "20170302_123859.jpg",
      },
      {
        id: 1614,
        imgId: 1395,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170302_124039.jpg",
        cropName: "20170302_124039.jpg",
      },
      {
        id: 1615,
        imgId: 1396,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170302_124139.jpg",
        cropName: "20170302_124139.jpg",
      },
      {
        id: 1616,
        imgId: 1392,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170302_123618.jpg",
        cropName: "20170302_123618.jpg",
      },
      {
        id: 1617,
        imgId: 1391,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170302_123430.jpg",
        cropName: "20170302_123430.jpg",
      },
      {
        id: 1618,
        imgId: 1393,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170302_123735.jpg",
        cropName: "20170302_123735.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T10:33:45.246+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Modern High-Quality Accessories Gold Plated Trendy Jewellery For Women Gift Popularity Bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.85,
    cashPrice: 296.7055,
    taxAll: 39.340996,
    taxInclude: 316.376,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 430,
    p_adminid: 2,
    productCategory: { id: 430, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 430,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 2894,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 2895,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2896,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2897,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2898,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 2899,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 430, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 430,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 430,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 430,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1619,
        imgId: 1397,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_185704.jpg",
        cropName: "20171013_185704.jpg",
      },
      {
        id: 1620,
        imgId: 1398,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_185853.jpg",
        cropName: "20171013_185853.jpg",
      },
      {
        id: 1621,
        imgId: 1399,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_190005.jpg",
        cropName: "20171013_190005.jpg",
      },
      {
        id: 1622,
        imgId: 1400,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_190056.jpg",
        cropName: "20171013_190056.jpg",
      },
      {
        id: 1623,
        imgId: 1401,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_190148.jpg",
        cropName: "20171013_190148.jpg",
      },
      {
        id: 1624,
        imgId: 1402,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_190251.jpg",
        cropName: "20171013_190251.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T10:34:51.639+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Fashion bangle models brass bangle bracelet designer charms for Women's wife's wedding gifts bracelets jewellery.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 431,
    p_adminid: 2,
    productCategory: { id: 431, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 431,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 8716,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 8717,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8718,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 8719,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8720,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 8721,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 431, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 431,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 431,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 431,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4120,
        imgId: 1403,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160225_145623.jpg",
        cropName: "20160225_145623.jpg",
      },
    ],
    modifyDate: "2023-09-16T09:07:56.089+00:00",
    createDate: "2023-08-25T10:36:16.374+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Classic Design Unisex Bangle For Gift Anniversary Gold Plated Jewellery Bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 432,
    p_adminid: 2,
    productCategory: { id: 432, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 432,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 2912,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 2913,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2914,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2915,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2916,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 2917,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 432, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 432,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 432,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 432,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1628,
        imgId: 1404,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_141634.jpg",
        cropName: "20170418_141634.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T10:38:48.563+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Gold plated Indian handmade shape for women girls gift jewellery bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 433,
    p_adminid: 2,
    productCategory: { id: 433, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 433,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 2918,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 2919,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2920,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2921,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2922,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 2923,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 433, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 433,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 433,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 433,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1629,
        imgId: 1405,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170714_104624.jpg",
        cropName: "20170714_104624.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T10:39:50.625+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Thin Batch of Bangle Gold Plated Fashion Jewelry For Party Gift\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 434,
    p_adminid: 2,
    productCategory: { id: 434, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 434,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 2924,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 2925,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2926,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 2927,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 2928,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 2929,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 434, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 434,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 434,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 434,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1630,
        imgId: 1406,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170714_105052.jpg",
        cropName: "20170714_105052.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T10:46:49.504+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Imitation gold plated high polished women's bangle classic and trendy modern hand bracelet fashion and fancy brand new design.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 435,
    p_adminid: 2,
    productCategory: { id: 435, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 435,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 3739,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3740,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3741,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3742,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3743,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 3744,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 435, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 435,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 435,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 435,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1913,
        imgId: 1437,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/1110001001.jpeg",
        cropName: "1110001001.jpeg",
      },
    ],
    modifyDate: "2023-08-28T11:29:45.498+00:00",
    createDate: "2023-08-25T10:47:46.818+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "High Quality Elegant 5 MM Gold Plated Bangle fashion jewellery Women daily wear the trendy bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 437,
    p_adminid: 2,
    productCategory: { id: 437, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 437,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 2948,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 2949,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2950,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 2951,
        lbrId: 1185,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Cutting 1 Cut",
      },
      {
        id: 2952,
        lbrId: 1180,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Kanas Meena",
      },
      {
        id: 2953,
        lbrId: 1201,
        price: 2.05,
        taxPercentage: 5.0,
        tax: 0.1025,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 2954,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 437, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 437,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 437,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 437,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1635,
        imgId: 1414,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_184957.jpg",
        cropName: "20171013_184957.jpg",
      },
      {
        id: 1636,
        imgId: 1415,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_185055.jpg",
        cropName: "20171013_185055.jpg",
      },
      {
        id: 1637,
        imgId: 1416,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_185215.jpg",
        cropName: "20171013_185215.jpg",
      },
      {
        id: 1638,
        imgId: 1417,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_185308.jpg",
        cropName: "20171013_185308.jpg",
      },
      {
        id: 1639,
        imgId: 1418,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_185420.jpg",
        cropName: "20171013_185420.jpg",
      },
      {
        id: 1640,
        imgId: 1419,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_185534.jpg",
        cropName: "20171013_185534.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T13:56:33.982+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6mm",
    prodManfDetails: "6",
    prodDescription:
      "6 MM imitation gold plated finished bangle classic and luxury designs suitable for all occasions and events for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 295.85,
    cashPrice: 344.5015,
    taxAll: 38.133003,
    taxInclude: 363.568,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 438,
    p_adminid: 2,
    productCategory: { id: 438, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 438,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 2966,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 2967,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 2968,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 2969,
        lbrId: 1647,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Plating Gold Plated",
      },
      {
        id: 2970,
        lbrId: 1657,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 438, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 438,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 438,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 438,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1652,
        imgId: 1420,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_174221.jpg",
        cropName: "20171013_174221.jpg",
      },
      {
        id: 1653,
        imgId: 1421,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_174441.jpg",
        cropName: "20171013_174441.jpg",
      },
      {
        id: 1654,
        imgId: 1422,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_174914.jpg",
        cropName: "20171013_174914.jpg",
      },
      {
        id: 1655,
        imgId: 1423,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_175225.jpg",
        cropName: "20171013_175225.jpg",
      },
      {
        id: 1656,
        imgId: 1424,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_175634.jpg",
        cropName: "20171013_175634.jpg",
      },
      {
        id: 1657,
        imgId: 1425,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20171013_180034.jpg",
        cropName: "20171013_180034.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-25T14:06:16.417+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3mm",
    prodManfDetails: "8",
    prodDescription:
      "Trendy Gold Plated Diamond Cut Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 328.25,
    cashPrice: 385.7485,
    taxAll: 49.347004,
    taxInclude: 410.42203,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 439,
    p_adminid: 2,
    productCategory: { id: 439, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 439,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6710,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6711,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6712,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6713,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 6714,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6715,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 439, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 439,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 439,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 439,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3327,
        imgId: 1442,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_202950.jpg",
        cropName: "20220530_202950.jpg",
      },
      {
        id: 3328,
        imgId: 1443,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_203130.jpg",
        cropName: "20220530_203130.jpg",
      },
      {
        id: 3329,
        imgId: 1444,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_203546.jpg",
        cropName: "20220530_203546.jpg",
      },
      {
        id: 3330,
        imgId: 1445,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_203735.jpg",
        cropName: "20220530_203735.jpg",
      },
      {
        id: 3331,
        imgId: 1446,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_203949.jpg",
        cropName: "20220530_203949.jpg",
      },
    ],
    modifyDate: "2023-09-11T05:28:40.102+00:00",
    createDate: "2023-08-26T07:41:30.364+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "140T-175-105M-065",
    prodManfDetails: "LASIYA",
    prodDescription:
      "5 MM Three-tone gold plated bangle fashion jewellery also a stylish hand Bracelet for women and girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1134.23,
    cashPrice: 1306.9786,
    taxAll: 118.6514,
    taxInclude: 1366.3043,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 136.0,
    taxApply: true,
    cgst: 1.5,
    sgst: null,
    igst: null,
    stockSize: [],
  },
  {
    id: 440,
    p_adminid: 2,
    productCategory: { id: 440, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 440,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6716,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6717,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6718,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6719,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 6720,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6721,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 440, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 440,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 440,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 440,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3332,
        imgId: 1450,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_205747.jpg",
        cropName: "20220530_205747.jpg",
      },
      {
        id: 3333,
        imgId: 1447,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_204147.jpg",
        cropName: "20220530_204147.jpg",
      },
      {
        id: 3334,
        imgId: 1448,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_204339.jpg",
        cropName: "20220530_204339.jpg",
      },
      {
        id: 3335,
        imgId: 1449,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_205601.jpg",
        cropName: "20220530_205601.jpg",
      },
      {
        id: 3336,
        imgId: 1451,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_205932.jpg",
        cropName: "20220530_205932.jpg",
      },
    ],
    modifyDate: "2023-09-11T05:29:12.150+00:00",
    createDate: "2023-08-26T07:43:40.827+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "140T-175-105M-065",
    prodManfDetails: "LASIYA",
    prodDescription:
      "Gold plated three-tone colour high polished women hand bangle classic stunning most trending brand new hand bracelet modern.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1134.23,
    cashPrice: 1306.9786,
    taxAll: 118.6514,
    taxInclude: 1366.3043,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 136.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 441,
    p_adminid: 2,
    productCategory: { id: 441, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 441,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6722,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6723,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6724,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6725,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 6726,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6727,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 441, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 441,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 441,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 441,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3337,
        imgId: 1454,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210448.jpg",
        cropName: "20220530_210448.jpg",
      },
      {
        id: 3338,
        imgId: 1455,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210705.jpg",
        cropName: "20220530_210705.jpg",
      },
      {
        id: 3339,
        imgId: 1456,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210854.jpg",
        cropName: "20220530_210854.jpg",
      },
      {
        id: 3340,
        imgId: 1452,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210109.jpg",
        cropName: "20220530_210109.jpg",
      },
      {
        id: 3341,
        imgId: 1453,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210239.jpg",
        cropName: "20220530_210239.jpg",
      },
    ],
    modifyDate: "2023-09-11T05:29:41.407+00:00",
    createDate: "2023-08-26T07:47:01.436+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "140T-175-105M-065",
    prodManfDetails: "LASIYA",
    prodDescription:
      "Three-tone gold plated fashion bangle classic trendy fashionable imitation bangle for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1134.23,
    cashPrice: 1306.9786,
    taxAll: 118.6514,
    taxInclude: 1366.3043,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 136.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 442,
    p_adminid: 2,
    productCategory: { id: 442, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 442,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 8351,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 8352,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8353,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8354,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8355,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8356,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 442, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 442,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 442,
      adminId: 2,
      rawpriceId: 193,
      title: "Rolling Punching Patti/Lasiya",
      price: 610.0,
      different: 25.0,
      taxPercentage: 18.0,
      tax: 114.3,
    },
    productPacking: {
      id: 442,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4012,
        imgId: 1457,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211042.jpg",
        cropName: "20220530_211042.jpg",
      },
    ],
    modifyDate: "2023-09-14T14:29:21.691+00:00",
    createDate: "2023-08-26T07:48:47.824+00:00",
    metalGram: 0.011,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "140T-175-105M-065",
    prodManfDetails: "LASIYA",
    prodDescription:
      "5 MM 3-tone colour gold plated high polished women bangle fashion and fancy trending modern classic brand new latest design.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1168.95,
    cashPrice: 1348.2955,
    taxAll: 124.90101,
    taxInclude: 1410.746,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 130.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 443,
    p_adminid: 2,
    productCategory: { id: 443, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 443,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6734,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6735,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6736,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6737,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 6738,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6739,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 443, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 443,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 443,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 443,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3343,
        imgId: 1459,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_202104.jpg",
        cropName: "20220530_202104.jpg",
      },
      {
        id: 3344,
        imgId: 1460,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_202646.jpg",
        cropName: "20220530_202646.jpg",
      },
      {
        id: 3345,
        imgId: 1461,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_202817.jpg",
        cropName: "20220530_202817.jpg",
      },
      {
        id: 3346,
        imgId: 1462,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_203002.jpg",
        cropName: "20220530_203002.jpg",
      },
      {
        id: 3347,
        imgId: 1458,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_201917.jpg",
        cropName: "20220530_201917.jpg",
      },
    ],
    modifyDate: "2023-09-11T05:31:12.468+00:00",
    createDate: "2023-08-26T07:52:12.431+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "140T-175-105M-065",
    prodManfDetails: "LASIYA",
    prodDescription:
      "Latest Brass three tone Plated bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 186.39,
    cashPrice: 214.5391,
    taxAll: 19.0202,
    taxInclude: 224.0492,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 952.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 444,
    p_adminid: 2,
    productCategory: { id: 444, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 444,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6740,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6741,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6742,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6743,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 6744,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6745,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 444, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 444,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 444,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 444,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3348,
        imgId: 1466,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_204034.jpg",
        cropName: "20220530_204034.jpg",
      },
      {
        id: 3349,
        imgId: 1463,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_203144.jpg",
        cropName: "20220530_203144.jpg",
      },
      {
        id: 3350,
        imgId: 1464,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_203555.jpg",
        cropName: "20220530_203555.jpg",
      },
      {
        id: 3351,
        imgId: 1465,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_203745.jpg",
        cropName: "20220530_203745.jpg",
      },
      {
        id: 3352,
        imgId: 1468,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_204209.jpg",
        cropName: "20220530_204209.jpg",
      },
    ],
    modifyDate: "2023-09-11T05:31:51.857+00:00",
    createDate: "2023-08-26T07:55:16.449+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "140T-175-105M-065",
    prodManfDetails: "LASIYA",
    prodDescription:
      "Elegant 3-tone gold plated bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 186.39,
    cashPrice: 214.5391,
    taxAll: 19.0202,
    taxInclude: 224.0492,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 952.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 445,
    p_adminid: 2,
    productCategory: { id: 445, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 445,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6746,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6747,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6748,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 6749,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6750,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6751,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 445, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 445,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 445,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 445,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3353,
        imgId: 1469,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_204358.jpg",
        cropName: "20220530_204358.jpg",
      },
      {
        id: 3354,
        imgId: 1470,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_205036.jpg",
        cropName: "20220530_205036.jpg",
      },
      {
        id: 3355,
        imgId: 1471,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_205611.jpg",
        cropName: "20220530_205611.jpg",
      },
      {
        id: 3356,
        imgId: 1472,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_205757.jpg",
        cropName: "20220530_205757.jpg",
      },
      {
        id: 3357,
        imgId: 1474,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210119.jpg",
        cropName: "20220530_210119.jpg",
      },
    ],
    modifyDate: "2023-09-11T05:32:29.081+00:00",
    createDate: "2023-08-26T08:17:53.917+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "140T-175-105M-065",
    prodManfDetails: "LASIYA",
    prodDescription:
      "Brass Micron Finish Bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 186.39,
    cashPrice: 214.5391,
    taxAll: 19.0202,
    taxInclude: 224.0492,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 952.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 446,
    p_adminid: 2,
    productCategory: { id: 446, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 446,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 6752,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 6753,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6754,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 6755,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 6756,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6757,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 446, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 446,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 446,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 446,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3358,
        imgId: 1473,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210003.jpg",
        cropName: "20220530_210003.jpg",
      },
      {
        id: 3359,
        imgId: 1475,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210246.jpg",
        cropName: "20220530_210246.jpg",
      },
      {
        id: 3360,
        imgId: 1476,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210541.jpg",
        cropName: "20220530_210541.jpg",
      },
      {
        id: 3361,
        imgId: 1479,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211057.jpg",
        cropName: "20220530_211057.jpg",
      },
      {
        id: 3362,
        imgId: 1477,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210717.jpg",
        cropName: "20220530_210717.jpg",
      },
    ],
    modifyDate: "2023-09-11T05:33:05.685+00:00",
    createDate: "2023-08-26T08:25:59.048+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "V",
    prodManfDetails: "LASIYA",
    prodDescription:
      "5 MM three-tone gold plated fashion bangle classic trendy fashionable imitation bangle for women and girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 186.39,
    cashPrice: 214.5391,
    taxAll: 19.0202,
    taxInclude: 224.0492,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 952.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 447,
    p_adminid: 2,
    productCategory: { id: 448, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 448,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 9252,
        lbrId: 1374,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 9253,
        lbrId: 1388,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9254,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 9255,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 9256,
        lbrId: 1366,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 9257,
        lbrId: 1367,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 9258,
        lbrId: 1384,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 9259,
        lbrId: 1376,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 448, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 448,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 448,
      adminId: 2,
      rawpriceId: 199,
      title: "12 Gauge Brass Tube 2.6mm Price in Mumbai",
      price: 585.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 117.0,
    },
    productPacking: {
      id: 448,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4334,
        imgId: 997,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_173846.jpg",
        cropName: "20190813_173846.jpg",
      },
    ],
    modifyDate: "2023-12-01T09:23:42.475+00:00",
    createDate: "2023-08-26T14:17:04.151+00:00",
    metalGram: 0.026,
    extraGram: 5.0e-4,
    readyGram: 0.0125,
    golwireThaw: "7MMX2.4MM",
    hsnSacCode: null,
    itemCodeNumber: "12G-7M-26M-125G",
    prodManfDetails: "Metal 0.0256 Gram & Pavala kada 0.0165 Gram",
    prodDescription: "2=5, 4=13, 6=13, 8=5 & 3=5, 5=13, 7=13, 9=5",
    cartDescription: "72 boxes of in 1 Carton",
    cartQuantity: 7,
    cartPackCharge: 500.0,
    makingCharge: 553.44995,
    cashPrice: 650.0374,
    taxAll: 82.485,
    taxInclude: 691.2799,
    active: true,
    gstCash: true,
    moqkg: 314.5,
    moq: 504.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 18,
        adminId: 2,
        productId: 447,
        stockCategoryId: 11,
        stockParentId: 119,
        cutOrFullSize: "2",
        createDate: "2023-11-18T08:19:25.219+00:00",
        s1x8: "0",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "0",
        s2x3: "0",
        s2x4: "0",
        s2x5: "0",
        s2x6: "0",
        s2x7: "10",
        s2x8: "0",
        s2x9: "15",
        s2x10: "0",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 25.0,
      },
    ],
  },
  {
    id: 448,
    p_adminid: 2,
    productCategory: { id: 449, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 449,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 5169,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 5170,
        lbrId: 1565,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 5171,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 5172,
        lbrId: 1582,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 5173,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5174,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 5175,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5176,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 449, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 449,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 449,
      adminId: 2,
      rawpriceId: 199,
      title: "12 Gauge Brass Tube 2.6mm Price in Mumbai",
      price: 585.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 117.0,
    },
    productPacking: {
      id: 449,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2561,
        imgId: 1034,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_173927.jpg",
        cropName: "20190813_173927.jpg",
      },
      {
        id: 2562,
        imgId: 1036,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174349.jpg",
        cropName: "20190813_174349.jpg",
      },
    ],
    modifyDate: "2023-09-05T13:56:43.739+00:00",
    createDate: "2023-08-26T15:58:50.417+00:00",
    metalGram: 0.028,
    extraGram: 5.0e-4,
    readyGram: 0.0145,
    golwireThaw: "8MMX2.4MM",
    hsnSacCode: null,
    itemCodeNumber: "12G-8M-28M-145G",
    prodManfDetails: "Metal 0.0288 Gram & Pavala kada 0.0190 Gram",
    prodDescription: "2=5, 4=13, 6=13, 8=5 & 3=5, 5=13, 7=13, 9=5",
    cartDescription: "72 boxes of in 1 Carton",
    cartQuantity: 7,
    cartPackCharge: 500.0,
    makingCharge: 584.65,
    cashPrice: 687.1655,
    taxAll: 88.101006,
    taxInclude: 731.21606,
    active: true,
    gstCash: true,
    moqkg: 338.688,
    moq: 504.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 17,
        adminId: 2,
        productId: 448,
        stockCategoryId: 11,
        stockParentId: 123,
        cutOrFullSize: "3",
        createDate: "2023-11-18T08:12:00.595+00:00",
        s1x8: "0",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "0",
        s2x3: "0",
        s2x4: "0",
        s2x5: "0",
        s2x6: "0",
        s2x7: "33",
        s2x8: "0",
        s2x9: "11",
        s2x10: "0",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 44.0,
      },
    ],
  },
  {
    id: 449,
    p_adminid: 2,
    productCategory: { id: 450, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 450,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5185,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5186,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 5187,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5188,
        lbrId: 1873,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 5189,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5190,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5191,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5192,
        lbrId: 1858,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Guru",
      },
    ],
    productSets: { id: 450, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 450,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 450,
      adminId: 2,
      rawpriceId: 199,
      title: "12 Gauge Brass Tube 2.6mm Price in Mumbai",
      price: 585.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 117.0,
    },
    productPacking: {
      id: 450,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2566,
        imgId: 1140,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174008.jpg",
        cropName: "20190813_174008.jpg",
      },
      {
        id: 2567,
        imgId: 1141,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_1743210.jpg",
        cropName: "20190813_1743210.jpg",
      },
    ],
    modifyDate: "2023-09-05T14:02:14.530+00:00",
    createDate: "2023-08-26T16:15:32.993+00:00",
    metalGram: 0.036,
    extraGram: 5.0e-4,
    readyGram: 0.018,
    golwireThaw: "10MMX2.4MM",
    hsnSacCode: null,
    itemCodeNumber: "12G-10M-36M-18G",
    prodManfDetails: "Metal 0.0360 Gram & Pavala kada 0.0235 Gram",
    prodDescription: "2=6, 4=15, 6=15, 8=6 & 3=6, 5=15, 7=15, 9=6",
    cartDescription: "84 boxes of in 1 Carton",
    cartQuantity: 3,
    cartPackCharge: 500.0,
    makingCharge: 635.15,
    cashPrice: 745.95856,
    taxAll: 94.587,
    taxInclude: 793.252,
    active: true,
    gstCash: true,
    moqkg: 217.728,
    moq: 302.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 450,
    p_adminid: 2,
    productCategory: {
      id: 451,
      adminId: 2,
      categoryId: 15,
      title: "PUNJABI KADA",
    },
    productParent: {
      id: 451,
      adminId: 2,
      parentId: 240,
      categoryId: 15,
      title: "9MM",
    },
    productChild: [
      {
        id: 3249,
        lbrId: 6740,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 3250,
        lbrId: 6743,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3251,
        lbrId: 6736,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Solder",
      },
      {
        id: 3252,
        lbrId: 6749,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3253,
        lbrId: 6758,
        price: 2.15,
        taxPercentage: 5.0,
        tax: 0.1075,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 3254,
        lbrId: 6767,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 451, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 451,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 451,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 451,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1791,
        imgId: 1249,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/620160303_150707.jpg",
        cropName: "620160303_150707.jpg",
      },
      {
        id: 1792,
        imgId: 1250,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160303_150746.jpg",
        cropName: "20160303_150746.jpg",
      },
      {
        id: 1793,
        imgId: 1251,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160303_150828.jpg",
        cropName: "20160303_150828.jpg",
      },
      {
        id: 1794,
        imgId: 1252,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160303_150951.jpg",
        cropName: "20160303_150951.jpg",
      },
      {
        id: 1795,
        imgId: 1253,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160303_151152.jpg",
        cropName: "20160303_151152.jpg",
      },
      {
        id: 1796,
        imgId: 1257,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160303_152356.jpg",
        cropName: "20160303_152356.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:25:40.346+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9mm",
    prodManfDetails: "9",
    prodDescription:
      "2-tone plated Punjabi Kada fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 245.5,
    cashPrice: 284.49,
    taxAll: 28.880001,
    taxInclude: 298.93,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 451,
    p_adminid: 2,
    productCategory: { id: 452, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 452,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 3255,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 3256,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 3257,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3258,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 3259,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3260,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
    ],
    productSets: { id: 452, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 452,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 452,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 452,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1797,
        imgId: 1478,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_210915.jpg",
        cropName: "20220530_210915.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:27:26.816+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0065,
    golwireThaw: "140 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5mm",
    prodManfDetails: "5",
    prodDescription:
      "Elegant Style 5 MM Diamond Cut 3-tone Plated Bangle fashion jewellery set for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 186.39,
    cashPrice: 214.5391,
    taxAll: 19.0202,
    taxInclude: 224.0492,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 952.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 452,
    p_adminid: 2,
    productCategory: { id: 453, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 453,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 3261,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3262,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3263,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3264,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3265,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 3266,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 453, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 453,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 453,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 453,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1798,
        imgId: 1535,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161118_092947.jpg",
        cropName: "20161118_092947.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:28:28.104+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5mm",
    prodManfDetails: "5",
    prodDescription:
      "Gold Plated Bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 419.45,
    cashPrice: 493.1455,
    taxAll: 63.501003,
    taxInclude: 524.896,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 453,
    p_adminid: 2,
    productCategory: { id: 454, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 454,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 3267,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3268,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3269,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3270,
        lbrId: 992,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 3271,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3272,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 3273,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 454, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 454,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 454,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 454,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1799,
        imgId: 1536,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161118_093102.jpg",
        cropName: "20161118_093102.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:30:49.652+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5mm",
    prodManfDetails: "5",
    prodDescription:
      "New Imitation Jewelry Women's fashion Trendy Bridal Wedding Leaf Design Bangle Bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 293.84998,
    cashPrice: 343.95547,
    taxAll: 41.440994,
    taxInclude: 364.67596,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 454,
    p_adminid: 2,
    productCategory: { id: 455, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 455,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 3274,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3275,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3276,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3277,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3278,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 3279,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 455, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 455,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 455,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 455,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1800,
        imgId: 1537,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170418_141101.jpg",
        cropName: "20170418_141101.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:32:45.672+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription:
      "5 MM 3-Tone Plated Side Veni Bangle fashion trendy jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 455,
    p_adminid: 2,
    productCategory: { id: 456, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 456,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 3280,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3281,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3282,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3283,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3284,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 3285,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 456, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 456,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 456,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 456,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1801,
        imgId: 1538,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_162417.jpg",
        cropName: "20211006_162417.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:36:24.758+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5mm",
    prodManfDetails: "5",
    prodDescription:
      "High Quality Elegant Gold Plated Bangle fashion Jewellery Women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 264.45,
    cashPrice: 310.88052,
    taxAll: 39.970997,
    taxInclude: 330.86603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 456,
    p_adminid: 2,
    productCategory: { id: 457, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 457,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 3296,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3297,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3298,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3299,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3300,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3301,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 457, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 457,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 457,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 457,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1804,
        imgId: 1527,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_175327.jpg",
        cropName: "20190813_175327.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:42:11.286+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "G",
    prodDescription: "M",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 202.28998,
    cashPrice: 238.18408,
    taxAll: 31.330198,
    taxInclude: 253.84918,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 476.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 457,
    p_adminid: 2,
    productCategory: { id: 458, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 458,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 3302,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3303,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3304,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3305,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3306,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3307,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 458, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 458,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 458,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 458,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1805,
        imgId: 1528,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_175402.jpg",
        cropName: "20190813_175402.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:43:43.222+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "G",
    prodDescription:
      "Designer Light Weight with Matt Gold Bangles and Bracelets in all sizes Collection For Women And Girls.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 306.1,
    cashPrice: 360.44403,
    taxAll: 47.468,
    taxInclude: 384.178,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 286.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 458,
    p_adminid: 2,
    productCategory: { id: 459, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 459,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 3308,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3309,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3310,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3311,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3312,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3313,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 459, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 459,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 459,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 459,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1806,
        imgId: 1525,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_175240.jpg",
        cropName: "20190813_175240.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:45:08.347+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "G",
    prodDescription:
      "Latest Exclusive Designer Light Weight with Matt Gold Bangles and Bracelets in all sizes Collection For Women And Girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 310.25,
    cashPrice: 365.3815,
    taxAll: 48.213,
    taxInclude: 389.488,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 459,
    p_adminid: 2,
    productCategory: { id: 460, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 460,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 3314,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3315,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3316,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3317,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3318,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3319,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 460, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 460,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 460,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 460,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1807,
        imgId: 1524,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_175151.jpg",
        cropName: "20190813_175151.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:46:30.009+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "G",
    prodDescription:
      "Designer Gold plated 8pc Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 400.84998,
    cashPrice: 472.10345,
    taxAll: 62.337,
    taxInclude: 503.27197,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 460,
    p_adminid: 2,
    productCategory: { id: 461, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 461,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 3320,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3321,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3322,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3323,
        lbrId: 694,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3324,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3325,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 461, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 461,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 461,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 461,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1808,
        imgId: 1509,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/16_Gold_Bangles_set_4_51724.jpg",
        cropName: "16_Gold_Bangles_set_4_51724.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:50:17.598+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription:
      "Adjustable Gold Bangles for Women Gold Color Bracelets Bangles Indian bangles Jewellery.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 253.25,
    cashPrice: 298.1895,
    taxAll: 39.228996,
    taxInclude: 317.80402,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 461,
    p_adminid: 2,
    productCategory: { id: 462, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 462,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 3326,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3327,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3328,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3329,
        lbrId: 694,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3330,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3331,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 462, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 462,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 462,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 462,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1809,
        imgId: 1513,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174400.jpg",
        cropName: "20180127_174400.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:51:42.078+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription:
      "wholesale Solid Gold plated Bangles Adjustable Alloy Charms Bangles wedding bracelet bangle for women cheap prices.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 253.25,
    cashPrice: 298.1895,
    taxAll: 39.228996,
    taxInclude: 317.80402,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 462,
    p_adminid: 2,
    productCategory: { id: 463, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 463,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 3332,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3333,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3334,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3335,
        lbrId: 694,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3336,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3337,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 463, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 463,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 463,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 463,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1810,
        imgId: 1510,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174334.jpg",
        cropName: "20180127_174334.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T05:59:54.580+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription:
      "Hot Sale Simple Fashion Women Bangle Indian gold plated bangles for Indian traditional party bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 310.25,
    cashPrice: 365.3815,
    taxAll: 48.213,
    taxInclude: 389.488,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 463,
    p_adminid: 2,
    productCategory: { id: 464, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 464,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 3338,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3339,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3340,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3341,
        lbrId: 694,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3342,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3343,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 464, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 464,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 464,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 464,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1811,
        imgId: 1511,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174305.jpg",
        cropName: "20180127_174305.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:03:19.502+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription:
      "Fashion Jewelry Women's Gold Bangle Set gold plated wedding bracelet bangle for women's fancy bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 402.65002,
    cashPrice: 474.24554,
    taxAll: 62.661003,
    taxInclude: 505.57605,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 464,
    p_adminid: 2,
    productCategory: { id: 465, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 465,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 3344,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3345,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3346,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3347,
        lbrId: 694,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3348,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 465, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 465,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 465,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 465,
      adminId: 2,
      packingmaterialId: 79,
      type: "Crystal Dibbi",
      pkgdesc: "M5",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1812,
        imgId: 1512,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174203.jpg",
        cropName: "20180127_174203.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:05:42.413+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription:
      "Micro gold plated 12pc Trendy Pure Brass Antique Bangles Set jewellery with best price Wristband.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 576.05005,
    cashPrice: 679.10956,
    taxAll: 90.909004,
    taxInclude: 724.564,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 139.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 465,
    p_adminid: 2,
    productCategory: { id: 466, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 466,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 3349,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3350,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3351,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3352,
        lbrId: 694,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3353,
        lbrId: 705,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3354,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 466, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 466,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 466,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 466,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1813,
        imgId: 1523,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_184853.jpg",
        cropName: "20180127_184853.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:07:49.255+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription: "Gold-plated brass bangles artificial bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 310.25,
    cashPrice: 365.3815,
    taxAll: 48.213,
    taxInclude: 389.488,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 466,
    p_adminid: 2,
    productCategory: { id: 467, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 467,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3355,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3356,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3357,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3358,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3359,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3360,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 467, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 467,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 467,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 467,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1814,
        imgId: 1493,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2-6-4-777.jpeg",
        cropName: "2-6-4-777.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:10:51.430+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Waterproof Jewelry Gold Plated Bracelet Bangle Women Popular Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.84999,
    cashPrice: 296.7895,
    taxAll: 39.509,
    taxInclude: 316.544,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 467,
    p_adminid: 2,
    productCategory: { id: 468, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 468,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3361,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3362,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3363,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3364,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3365,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3366,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 3367,
        lbrId: 774,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
    ],
    productSets: { id: 468, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 468,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 468,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 468,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1815,
        imgId: 1514,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174840.jpg",
        cropName: "20180127_174840.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:13:58.750+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Top Quality New fashion Latest women gold plated brass bangle bracelets.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 258.84998,
    cashPrice: 304.6645,
    taxAll: 39.858997,
    taxInclude: 324.594,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 468,
    p_adminid: 2,
    productCategory: { id: 469, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 469,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3368,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3369,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3370,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3371,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3372,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3373,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 469, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 469,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 469,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 469,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1816,
        imgId: 1515,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_174935.jpg",
        cropName: "20180127_174935.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:15:04.894+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "33",
    prodDescription:
      "Latest Women's Fashion Jewellery Gold Plated Bangles modern style gold bangles design.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 253.25,
    cashPrice: 298.4555,
    taxAll: 39.760998,
    taxInclude: 318.336,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 469,
    p_adminid: 2,
    productCategory: { id: 470, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 470,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3374,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3375,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3376,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3377,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3378,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3379,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 3380,
        lbrId: 774,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
    ],
    productSets: { id: 470, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 470,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 470,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 470,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1817,
        imgId: 1516,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_175320.jpg",
        cropName: "20180127_175320.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:17:42.437+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Designer Gold plated 8pc Trendy Pure Brass Antique Bangles Set.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 410.45,
    cashPrice: 483.2035,
    taxAll: 63.417,
    taxInclude: 514.912,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 470,
    p_adminid: 2,
    productCategory: { id: 471, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 471,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3381,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3382,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3383,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3384,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3385,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3386,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 471, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 471,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 471,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 471,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1818,
        imgId: 1517,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_175445.jpg",
        cropName: "20180127_175445.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:19:57.330+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Appealing Unique Design Gold Plated High Polished Zigzag  Bangle Bracelet For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 398.45,
    cashPrice: 469.70352,
    taxAll: 62.817,
    taxInclude: 501.112,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 471,
    p_adminid: 2,
    productCategory: { id: 472, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 472,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3387,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3388,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3389,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3390,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3391,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3392,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 472, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 472,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 472,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 472,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1819,
        imgId: 1518,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_180219.jpg",
        cropName: "20180127_180219.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:20:43.093+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription: "3",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.84999,
    cashPrice: 296.7895,
    taxAll: 39.509,
    taxInclude: 316.544,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 472,
    p_adminid: 2,
    productCategory: { id: 473, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 473,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3393,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3394,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3395,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3396,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3397,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3398,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 473, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 473,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 473,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 473,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1820,
        imgId: 1519,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_180147.jpg",
        cropName: "20180127_180147.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:25:53.439+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Most highly trendy and classic designs for women's top fashion jewellery and bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 308.45,
    cashPrice: 363.5815,
    taxAll: 48.573,
    taxInclude: 387.868,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 473,
    p_adminid: 2,
    productCategory: { id: 474, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 474,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3399,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3400,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3401,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3402,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3403,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3404,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 474, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 474,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 474,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 474,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1821,
        imgId: 1529,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190329_120400.jpg",
        cropName: "20190329_120400.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:26:42.278+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "3 MM Finish Gold Plated Bangle fashion jewellery for women and girls to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 251.84999,
    cashPrice: 296.7895,
    taxAll: 39.509,
    taxInclude: 316.544,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 474,
    p_adminid: 2,
    productCategory: { id: 475, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 475,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 3405,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 3406,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3407,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3408,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3409,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 3410,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 475, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 475,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 475,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 475,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1822,
        imgId: 1530,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20180213-WA0007.jpg",
        cropName: "IMG-20180213-WA0007.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:27:45.260+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription: "South Indian gold-plated brass bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 398.45,
    cashPrice: 469.70352,
    taxAll: 62.817,
    taxInclude: 501.112,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 475,
    p_adminid: 2,
    productCategory: { id: 476, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 476,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3411,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3412,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3413,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3414,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3415,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 3416,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 476, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 476,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 476,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 476,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1823,
        imgId: 1481,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211512.jpg",
        cropName: "20220530_211512.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:31:17.333+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Simple New Design Brass Bangle.\n                                                                                                                      ",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 109.5,
    cashPrice: 127.8175,
    taxAll: 14.735,
    taxInclude: 135.185,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 476,
    p_adminid: 2,
    productCategory: { id: 477, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 477,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3417,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3418,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3419,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3420,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3421,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 3422,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 477, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 477,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 477,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 477,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1824,
        imgId: 1482,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211505.jpg",
        cropName: "20220530_211505.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:32:24.794+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Indian Gold plated Tri colour bangles set for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 309.5,
    cashPrice: 356.49002,
    taxAll: 32.08,
    taxInclude: 372.53003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 477,
    p_adminid: 2,
    productCategory: { id: 478, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 478,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3423,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3424,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3425,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3426,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3427,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 3428,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 478, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 478,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 478,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 478,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1825,
        imgId: 1483,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211454.jpg",
        cropName: "20220530_211454.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:34:04.573+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "4",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 265.0,
    cashPrice: 310.5875,
    taxAll: 38.175,
    taxInclude: 329.675,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 478,
    p_adminid: 2,
    productCategory: { id: 479, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 479,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3429,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3430,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3431,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3432,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3433,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 3434,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 479, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 479,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 479,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 479,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1826,
        imgId: 1484,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211448.jpg",
        cropName: "20220530_211448.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:35:26.398+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "festival high trendy looks durability 3-tone gold plated sterling bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 346.5,
    cashPrice: 406.435,
    taxAll: 50.57,
    taxInclude: 431.72,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 479,
    p_adminid: 2,
    productCategory: { id: 480, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 480,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3435,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3436,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3437,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3438,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3439,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 3440,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 480, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 480,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 480,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 480,
      adminId: 2,
      packingmaterialId: 111,
      type: "Polythene PP",
      pkgdesc: "5 Pcs",
      hsncode: "39202020",
      price: 1.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1827,
        imgId: 1485,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211437.jpg",
        cropName: "20220530_211437.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:36:56.410+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Stylish Latest Design 3-tone Gold Plated Bangle For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 428.0,
    cashPrice: 502.2825,
    taxAll: 62.965,
    taxInclude: 533.765,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 200.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 480,
    p_adminid: 2,
    productCategory: { id: 481, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 481,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3441,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3442,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3443,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3444,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3445,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3446,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 481, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 481,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 481,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 481,
      adminId: 2,
      packingmaterialId: 112,
      type: "Polythene PP",
      pkgdesc: "6 Pcs",
      hsncode: "39202020",
      price: 1.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1828,
        imgId: 1486,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211424.jpg",
        cropName: "20220530_211424.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:38:12.386+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "4 mm 3-tone Designer imitation bangle fashion and fancy jewellery for women on every occasion.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 887.5,
    cashPrice: 1023.38,
    taxAll: 94.26,
    taxInclude: 1070.51,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 167.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 481,
    p_adminid: 2,
    productCategory: { id: 482, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 482,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3447,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3448,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3449,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3450,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3451,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3452,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 482, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 482,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 482,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 482,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1829,
        imgId: 1487,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211341.jpg",
        cropName: "20220530_211341.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:39:29.548+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Classic 3Tone 7 Piece Set Bangle Bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1037.0,
    cashPrice: 1196.0525,
    taxAll: 110.705,
    taxInclude: 1251.4049,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 482,
    p_adminid: 2,
    productCategory: { id: 483, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 483,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3453,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3454,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3455,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3456,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3457,
        lbrId: 905,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Plating Rose Gold\r\n",
      },
      {
        id: 3458,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 483, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 483,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 483,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 483,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1830,
        imgId: 1496,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2-01242342.jpg",
        cropName: "2-01242342.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:40:48.600+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "festival high trendy looks durability Micron plated bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 578.5,
    cashPrice: 667.435,
    taxAll: 62.17,
    taxInclude: 698.51996,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 483,
    p_adminid: 2,
    productCategory: { id: 484, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 484,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3459,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3460,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3461,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3462,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3463,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3464,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 484, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 484,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 484,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 484,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1831,
        imgId: 1501,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/3-489486984.jpg",
        cropName: "3-489486984.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:43:23.118+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Manufacture three-tone plated Jewelry bangles for women for Export.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1037.0,
    cashPrice: 1196.0525,
    taxAll: 110.705,
    taxInclude: 1251.4049,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 484,
    p_adminid: 2,
    productCategory: { id: 485, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 485,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3465,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3466,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3467,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3468,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3469,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3470,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 485, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 485,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 485,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 485,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1832,
        imgId: 1506,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/19145805_1286852448126622_6457765939368118755_n.jpg",
        cropName: "19145805_1286852448126622_6457765939368118755_n.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:44:34.544+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Indian Gold plated Tri colour bangles set for women manufacture.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 454.0,
    cashPrice: 523.2125,
    taxAll: 47.625,
    taxInclude: 547.025,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 485,
    p_adminid: 2,
    productCategory: { id: 486, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 486,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3471,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3472,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 3473,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 3474,
        lbrId: 900,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3475,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 486, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 486,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 486,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 486,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1833,
        imgId: 1507,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/19260545_1286852364793297_2576880881480684361_n.jpg",
        cropName: "19260545_1286852364793297_2576880881480684361_n.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:45:48.006+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "South Indian gold plated bangle meenakari brass bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 308.5,
    cashPrice: 359.525,
    taxAll: 40.35,
    taxInclude: 379.7,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 486,
    p_adminid: 2,
    productCategory: { id: 487, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 487,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3476,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3477,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3478,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3479,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3480,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 3481,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 3482,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 487, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 487,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 487,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 487,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1834,
        imgId: 1520,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_175857.jpg",
        cropName: "20180127_175857.jpg",
      },
      {
        id: 1835,
        imgId: 1521,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_175739.jpg",
        cropName: "20180127_175739.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:46:59.728+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "New design gold plated bangles for women zigzag cut bangles fashion jewellery bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 245.2,
    cashPrice: 288.064,
    taxAll: 36.688,
    taxInclude: 306.408,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 487,
    p_adminid: 2,
    productCategory: { id: 488, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 488,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3483,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3484,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3485,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3486,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3487,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 3488,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 488, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 488,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 488,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 488,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1836,
        imgId: 1522,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20180127_180404.jpg",
        cropName: "20180127_180404.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:48:20.458+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "New products jewellery accessories personalized bangle wholesale custom gold plated women's bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.05,
    cashPrice: 301.43048,
    taxAll: 39.550995,
    taxInclude: 321.206,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 357.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 488,
    p_adminid: 2,
    productCategory: { id: 489, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 489,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 5571,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 5572,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5573,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5574,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 5575,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5576,
        lbrId: 885,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 489, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 489,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 489,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 489,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2718,
        imgId: 1531,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211006_151512.jpg",
        cropName: "20211006_151512.jpg",
      },
    ],
    modifyDate: "2023-09-08T10:46:00.257+00:00",
    createDate: "2023-08-28T06:49:50.876+00:00",
    metalGram: 0.009,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "New Hot Sale wholesale gold plated fashion bangle for women brass bangle ladies bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 301.31,
    cashPrice: 353.80692,
    taxAll: 44.7318,
    taxInclude: 376.17282,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 489,
    p_adminid: 2,
    productCategory: { id: 490, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 490,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6665,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 6666,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 6667,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6668,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 6669,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 6670,
        lbrId: 870,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Half Round",
      },
      {
        id: 6671,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6672,
        lbrId: 877,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 6673,
        lbrId: 889,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
    ],
    productSets: { id: 490, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 490,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 490,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 490,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3307,
        imgId: 1532,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20150703-WA0044.jpg",
        cropName: "IMG-20150703-WA0044.jpg",
      },
      {
        id: 3308,
        imgId: 1533,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20150703-WA0045.jpg",
        cropName: "IMG-20150703-WA0045.jpg",
      },
      {
        id: 3309,
        imgId: 1534,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20150703-WA0046.jpg",
        cropName: "IMG-20150703-WA0046.jpg",
      },
    ],
    modifyDate: "2023-09-09T14:04:46.806+00:00",
    createDate: "2023-08-28T06:51:07.681+00:00",
    metalGram: 0.008,
    extraGram: 5.0e-4,
    readyGram: 0.005,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Indian manufacture Gold plated Tri colour bangles set for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 609.3,
    cashPrice: 698.88696,
    taxAll: 57.314003,
    taxInclude: 727.544,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 312.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 490,
    p_adminid: 2,
    productCategory: { id: 491, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 491,
      adminId: 2,
      parentId: 113,
      categoryId: 11,
      title: "5/3MM",
    },
    productChild: [
      {
        id: 3501,
        lbrId: 1067,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Solder",
      },
      {
        id: 3502,
        lbrId: 1087,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3503,
        lbrId: 1073,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3504,
        lbrId: 1076,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3505,
        lbrId: 1100,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 3506,
        lbrId: 1110,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 491, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 491,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 491,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 491,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1841,
        imgId: 1494,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2-6-611353453.jpeg",
        cropName: "2-6-611353453.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:55:02.934+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5/3MM",
    prodManfDetails: "5",
    prodDescription:
      "Stylish Latest Design Gold Plated Bangle For Women and Girls for any Function.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 319.25,
    cashPrice: 375.62347,
    taxAll: 48.897003,
    taxInclude: 400.072,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 491,
    p_adminid: 2,
    productCategory: { id: 492, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 492,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3507,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3508,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3509,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3510,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3511,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 3512,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 492, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 492,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 492,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 492,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1842,
        imgId: 1492,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/1-223434464.jpg",
        cropName: "1-223434464.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:57:49.309+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "South Indian gold-plated bangle brass bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 238.25,
    cashPrice: 279.7015,
    taxAll: 35.253002,
    taxInclude: 297.328,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 492,
    p_adminid: 2,
    productCategory: { id: 493, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 493,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3513,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3514,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3515,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3516,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3517,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 3518,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 493, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 493,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 493,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 493,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1843,
        imgId: 1497,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2x6007700700300300.jpg",
        cropName: "2x6007700700300300.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T06:59:51.896+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Trendy Fashion Jewelry artificial bangles and bracelets Latest gold plated women's bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 158.75,
    cashPrice: 186.3715,
    taxAll: 23.492998,
    taxInclude: 198.118,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 493,
    p_adminid: 2,
    productCategory: { id: 494, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 494,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 7743,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 7744,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7745,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7746,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 7747,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 7748,
        lbrId: 1179,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Diamond Dell",
      },
      {
        id: 7749,
        lbrId: 1187,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7750,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 494, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 494,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 494,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 494,
      adminId: 2,
      packingmaterialId: 112,
      type: "Polythene PP",
      pkgdesc: "6 Pcs",
      hsncode: "39202020",
      price: 1.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3813,
        imgId: 1500,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/3-500x500.jpg",
        cropName: "3-500x500.jpg",
      },
    ],
    modifyDate: "2023-09-14T10:50:43.748+00:00",
    createDate: "2023-08-28T07:02:58.157+00:00",
    metalGram: 0.018,
    extraGram: 5.0e-4,
    readyGram: 0.014,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1441.3,
    cashPrice: 1672.2621,
    taxAll: 173.66397,
    taxInclude: 1759.094,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 93.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 494,
    p_adminid: 2,
    productCategory: {
      id: 495,
      adminId: 2,
      categoryId: 15,
      title: "PUNJABI KADA",
    },
    productParent: {
      id: 495,
      adminId: 2,
      parentId: 241,
      categoryId: 15,
      title: "10MM",
    },
    productChild: [
      {
        id: 8722,
        lbrId: 6775,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8723,
        lbrId: 6777,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 8724,
        lbrId: 6789,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8725,
        lbrId: 6788,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8726,
        lbrId: 6794,
        price: 5.0,
        taxPercentage: 5.0,
        tax: 0.25,
        title: "Plating Bright Silver",
      },
      {
        id: 8727,
        lbrId: 6802,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8728,
        lbrId: 6805,
        price: 9.45,
        taxPercentage: 5.0,
        tax: 0.4725,
        title: "Pawala Labour ",
      },
    ],
    productSets: { id: 495, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 495,
      adminId: 2,
      boxId: 83,
      brandName: "H5 - My Bangles 24",
      boxDesc: "24 Sets In 1 Box",
      pkgCharge: 24.0,
      box: "24",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 495,
      adminId: 2,
      rawpriceId: 200,
      title: "13 Gauge Brass Casting 2.3mm Price in Rajkot",
      price: 540.0,
      different: 20.0,
      taxPercentage: 18.0,
      tax: 100.8,
    },
    productPacking: {
      id: 495,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4121,
        imgId: 1259,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20211001_120816(10).jpg",
        cropName: "20211001_120816(10).jpg",
      },
    ],
    modifyDate: "2023-09-16T12:51:45.502+00:00",
    createDate: "2023-08-28T07:06:04.048+00:00",
    metalGram: 0.0175,
    extraGram: 5.0e-4,
    readyGram: 0.014,
    golwireThaw: "2.5MM ",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "Metal 0.00 Gram & Pavala kada 0.0165 Gram",
    prodDescription: "Looking for a bright silver Kada for a gents?",
    cartDescription: "84 boxes of in 1 Carton",
    cartQuantity: 2,
    cartPackCharge: 500.0,
    makingCharge: 736.44995,
    cashPrice: 845.9015,
    taxAll: 71.61301,
    taxInclude: 881.708,
    active: true,
    gstCash: true,
    moqkg: 70.056,
    moq: 167.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 6,
        adminId: 2,
        productId: 494,
        stockCategoryId: 15,
        stockParentId: 241,
        cutOrFullSize: "1",
        createDate: "2023-09-16T08:08:53.556+00:00",
        s1x8: "111",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "0",
        s2x3: "0",
        s2x4: "20",
        s2x5: "0",
        s2x6: "20",
        s2x7: "0",
        s2x8: "20",
        s2x9: "0",
        s2x10: "20",
        s2x11: "0",
        s2x12: "20",
        s2x13: "0",
        s2x14: "23",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 234.0,
      },
    ],
  },
  {
    id: 495,
    p_adminid: 2,
    productCategory: { id: 496, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 496,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3531,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 3532,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 3533,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
      {
        id: 3534,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 3535,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3536,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3537,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 496, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 496,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 496,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 496,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1846,
        imgId: 1508,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8mm T Bangles15 Gms 8012.jpg",
        cropName: "8mm T Bangles15 Gms 8012.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T07:07:32.400+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1180.5,
    cashPrice: 1357.49,
    taxAll: 117.880005,
    taxInclude: 1416.43,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 496,
    p_adminid: 2,
    productCategory: { id: 497, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 497,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 3538,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 3539,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 3540,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3541,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3542,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 3543,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 497, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 497,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 497,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 497,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1847,
        imgId: 1491,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/0-4-ccf-1983-c-650.jpeg",
        cropName: "0-4-ccf-1983-c-650.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T07:12:19.857+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 245.45001,
    cashPrice: 287.8015,
    taxAll: 35.613003,
    taxInclude: 305.60803,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 497,
    p_adminid: 2,
    productCategory: { id: 498, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 498,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 3544,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 3545,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3546,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 3547,
        lbrId: 1636,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3548,
        lbrId: 1647,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Plating Gold Plated",
      },
      {
        id: 3549,
        lbrId: 1657,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 498, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 498,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 498,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 498,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1848,
        imgId: 1495,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2-6-611353453.jpeg",
        cropName: "2-6-611353453.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T07:16:40.966+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3MM",
    prodManfDetails: "8",
    prodDescription: "3",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 349.85,
    cashPrice: 410.04852,
    taxAll: 50.427002,
    taxInclude: 435.26202,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 498,
    p_adminid: 2,
    productCategory: { id: 499, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 499,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 8825,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 8826,
        lbrId: 1167,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 8827,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8828,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8829,
        lbrId: 1184,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 8830,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8831,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8832,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 499, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 499,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 499,
      adminId: 2,
      rawpriceId: 199,
      title: "12 Gauge Brass Tube 2.6mm Price in Mumbai",
      price: 585.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 117.0,
    },
    productPacking: {
      id: 499,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4152,
        imgId: 880,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_173846.jpg",
        cropName: "20190813_173846.jpg",
      },
      {
        id: 4153,
        imgId: 910,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174321.jpg",
        cropName: "20190813_174321.jpg",
      },
    ],
    modifyDate: "2023-09-21T12:35:40.401+00:00",
    createDate: "2023-08-28T07:25:09.057+00:00",
    metalGram: 0.02,
    extraGram: 5.0e-4,
    readyGram: 0.0085,
    golwireThaw: "6MMX2.2MM",
    hsnSacCode: null,
    itemCodeNumber: "12G-6M-20M-075G",
    prodManfDetails: "Metal 0.020 Gram & Pavala kada 0.0120 Gram",
    prodDescription: "",
    cartDescription: "84 boxes of in 1 Carton",
    cartQuantity: 6,
    cartPackCharge: 500.0,
    makingCharge: 451.45,
    cashPrice: 529.2035,
    taxAll: 65.216995,
    taxInclude: 561.812,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 499,
    p_adminid: 2,
    productCategory: { id: 500, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 500,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 9014,
        lbrId: 1367,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 9015,
        lbrId: 1366,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 9016,
        lbrId: 1384,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 9017,
        lbrId: 1376,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 9018,
        lbrId: 1374,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 9019,
        lbrId: 1388,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9020,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 9021,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 9022,
        lbrId: 1382,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
    ],
    productSets: { id: 500, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 500,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 500,
      adminId: 2,
      rawpriceId: 199,
      title: "12 Gauge Brass Tube 2.6mm Price in Mumbai",
      price: 585.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 117.0,
    },
    productPacking: {
      id: 500,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4227,
        imgId: 999,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174321.jpg",
        cropName: "20190813_174321.jpg",
      },
    ],
    modifyDate: "2023-10-13T10:44:37.702+00:00",
    createDate: "2023-08-28T07:34:00.528+00:00",
    metalGram: 0.0235,
    extraGram: 5.0e-4,
    readyGram: 0.01,
    golwireThaw: "7MMX2.2MM",
    hsnSacCode: null,
    itemCodeNumber: "12G-7M-235M-10G",
    prodManfDetails: "Metal 0.0233 Gram & Pavala kada 0.0140 Gram",
    prodDescription: "",
    cartDescription: "72 boxes of in 1 Carton",
    cartQuantity: 2,
    cartPackCharge: 500.0,
    makingCharge: 526.44995,
    cashPrice: 617.12744,
    taxAll: 76.065,
    taxInclude: 655.16,
    active: true,
    gstCash: true,
    moqkg: 220.0,
    moq: 390.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 500,
    p_adminid: 2,
    productCategory: { id: 501, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 501,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 8833,
        lbrId: 1565,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 8834,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 8835,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 8836,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 8837,
        lbrId: 1582,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 8838,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8839,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 8840,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 501, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 501,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 501,
      adminId: 2,
      rawpriceId: 199,
      title: "12 Gauge Brass Tube 2.6mm Price in Mumbai",
      price: 585.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 117.0,
    },
    productPacking: {
      id: 501,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4154,
        imgId: 1034,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_173927.jpg",
        cropName: "20190813_173927.jpg",
      },
      {
        id: 4155,
        imgId: 1036,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174349.jpg",
        cropName: "20190813_174349.jpg",
      },
    ],
    modifyDate: "2023-09-21T12:38:46.927+00:00",
    createDate: "2023-08-28T07:37:39.532+00:00",
    metalGram: 0.026,
    extraGram: 5.0e-4,
    readyGram: 0.0115,
    golwireThaw: "7MMX2.2MM",
    hsnSacCode: null,
    itemCodeNumber: "12G-8M-26M-115G",
    prodManfDetails: "Metal 0.0262 Gram & Pavala kada 0.016 Gram",
    prodDescription: "",
    cartDescription: "84 boxes of in 1 Carton",
    cartQuantity: 6,
    cartPackCharge: 500.0,
    makingCharge: 553.44995,
    cashPrice: 650.0374,
    taxAll: 82.485,
    taxInclude: 691.2799,
    active: true,
    gstCash: true,
    moqkg: 210.0,
    moq: 337.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 501,
    p_adminid: 2,
    productCategory: { id: 502, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 502,
      adminId: 2,
      parentId: 126,
      categoryId: 11,
      title: "9MM",
    },
    productChild: [
      {
        id: 8841,
        lbrId: 1712,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Guru",
      },
      {
        id: 8842,
        lbrId: 1711,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 8843,
        lbrId: 1719,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8844,
        lbrId: 1721,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 8845,
        lbrId: 1729,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 8846,
        lbrId: 1733,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8847,
        lbrId: 1743,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 8848,
        lbrId: 1753,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 502, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 502,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 502,
      adminId: 2,
      rawpriceId: 199,
      title: "12 Gauge Brass Tube 2.6mm Price in Mumbai",
      price: 585.0,
      different: 65.0,
      taxPercentage: 18.0,
      tax: 117.0,
    },
    productPacking: {
      id: 502,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4156,
        imgId: 1095,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_174008.jpg",
        cropName: "20190813_174008.jpg",
      },
      {
        id: 4157,
        imgId: 1097,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190813_1743210.jpg",
        cropName: "20190813_1743210.jpg",
      },
    ],
    modifyDate: "2023-09-21T12:46:21.675+00:00",
    createDate: "2023-08-28T07:44:51.108+00:00",
    metalGram: 0.0295,
    extraGram: 5.0e-4,
    readyGram: 0.0135,
    golwireThaw: "9MMX2.2MM",
    hsnSacCode: null,
    itemCodeNumber: "12G-9M-295M-115G",
    prodManfDetails: "Metal 0.0295 Gram & Pavala kada 0.01825 Gram",
    prodDescription: "2=6, 4=15, 6=15, 8=6 & 3=6, 5=15, 7=15, 9=6",
    cartDescription: "84 boxes of in 1 Carton",
    cartQuantity: 3,
    cartPackCharge: 500.0,
    makingCharge: 550.65,
    cashPrice: 645.4035,
    taxAll: 79.377,
    taxInclude: 685.09204,
    active: true,
    gstCash: true,
    moqkg: 178.416,
    moq: 302.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 502,
    p_adminid: 2,
    productCategory: { id: 503, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 503,
      adminId: 2,
      parentId: 124,
      categoryId: 11,
      title: "8/3MM",
    },
    productChild: [
      {
        id: 3628,
        lbrId: 1616,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Solder",
      },
      {
        id: 3629,
        lbrId: 1623,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3630,
        lbrId: 1626,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 3631,
        lbrId: 1636,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3632,
        lbrId: 1647,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Plating Gold Plated",
      },
      {
        id: 3633,
        lbrId: 1657,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 503, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 503,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 503,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 503,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1868,
        imgId: 1504,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6-500x50053.jpg",
        cropName: "6-500x50053.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T09:01:22.600+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8/3MM",
    prodManfDetails: "8",
    prodDescription:
      "Beautiful New Design Gold Platted Punching brass Bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 349.85,
    cashPrice: 410.04852,
    taxAll: 50.427002,
    taxInclude: 435.26202,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 503,
    p_adminid: 2,
    productCategory: { id: 504, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 504,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 3634,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 3635,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 3636,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3637,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 3638,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 3639,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 504, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 504,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 504,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 504,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1869,
        imgId: 1488,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211207.jpg",
        cropName: "20220530_211207.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T09:08:12.655+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 253.0,
    cashPrice: 289.25497,
    taxAll: 21.91,
    taxInclude: 300.21,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 504,
    p_adminid: 2,
    productCategory: { id: 505, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 505,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 3640,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 3641,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 3642,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 3643,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3644,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 3645,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3646,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 505, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 505,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 505,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 505,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1870,
        imgId: 1489,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211159.jpg",
        cropName: "20220530_211159.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T09:10:37.279+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10",
    prodManfDetails: "M",
    prodDescription: "M",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 489.5,
    cashPrice: 558.99,
    taxAll: 41.08,
    taxInclude: 579.53,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 505,
    p_adminid: 2,
    productCategory: { id: 506, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 506,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 3647,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 3648,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3649,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 3650,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 3651,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3652,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 506, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 506,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 506,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 506,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1871,
        imgId: 1490,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220530_211147.jpg",
        cropName: "20220530_211147.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T09:12:03.652+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription:
      "3-Tone Plated Semanario Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 920.5,
    cashPrice: 1052.1849,
    taxAll: 79.270004,
    taxInclude: 1091.82,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 506,
    p_adminid: 2,
    productCategory: { id: 507, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 507,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 3653,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 3654,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 3655,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 3656,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3657,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 507, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 507,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 507,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 507,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1872,
        imgId: 1498,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/3tone-10000001.jpg",
        cropName: "3tone-10000001.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T09:15:08.917+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "1",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 458.5,
    cashPrice: 524.115,
    taxAll: 39.53,
    taxInclude: 543.88,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 507,
    p_adminid: 2,
    productCategory: { id: 508, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 508,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 3658,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 3659,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 3660,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 3661,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3662,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3663,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 508, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 508,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 508,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 508,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1873,
        imgId: 1499,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/3tone-1011212215.jpg",
        cropName: "3tone-1011212215.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T09:16:46.999+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "1OMM",
    prodManfDetails: "1",
    prodDescription: "0",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 470.5,
    cashPrice: 537.615,
    taxAll: 40.13,
    taxInclude: 557.68,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 508,
    p_adminid: 2,
    productCategory: { id: 509, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 509,
      adminId: 2,
      parentId: 151,
      categoryId: 11,
      title: "20MM",
    },
    productChild: [
      {
        id: 3664,
        lbrId: 2835,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 3665,
        lbrId: 2841,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Buffing",
      },
      {
        id: 3666,
        lbrId: 2842,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 3667,
        lbrId: 2872,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3668,
        lbrId: 2853,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3669,
        lbrId: 2875,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 509, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 509,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 509,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 509,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1874,
        imgId: 1480,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220610_191836.jpg",
        cropName: "20220610_191836.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T09:20:30.532+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "20MM",
    prodManfDetails: "2",
    prodDescription:
      "Unique Three-Tone Plated Micron Finish 20 mm Fashion Broad Bangle with stunning and engaging designs.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 353.5,
    cashPrice: 402.3175,
    taxAll: 26.935,
    taxInclude: 415.785,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 509,
    p_adminid: 2,
    productCategory: { id: 510, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 510,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3670,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3671,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3672,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3673,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3674,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3675,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 510, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 510,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 510,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 510,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1875,
        imgId: 1543,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_175343.jpg",
        cropName: "20170307_175343.jpg",
      },
      {
        id: 1876,
        imgId: 1544,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_191420.jpg",
        cropName: "20170307_191420.jpg",
      },
      {
        id: 1877,
        imgId: 1540,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_153103.jpg",
        cropName: "20170307_153103.jpg",
      },
      {
        id: 1878,
        imgId: 1541,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_161506.jpg",
        cropName: "20170307_161506.jpg",
      },
      {
        id: 1879,
        imgId: 1542,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_175202.jpg",
        cropName: "20170307_175202.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:40:49.551+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "4",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1016.0,
    cashPrice: 1172.4275,
    taxAll: 109.655,
    taxInclude: 1227.255,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 510,
    p_adminid: 2,
    productCategory: { id: 511, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 511,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3676,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3677,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3678,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3679,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3680,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3681,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 511, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 511,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 511,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 511,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1880,
        imgId: 1545,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_194304.jpg",
        cropName: "20170307_194304.jpg",
      },
      {
        id: 1881,
        imgId: 1546,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_112533.jpg",
        cropName: "20170308_112533.jpg",
      },
      {
        id: 1882,
        imgId: 1547,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_124921.jpg",
        cropName: "20170308_124921.jpg",
      },
      {
        id: 1883,
        imgId: 1548,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_122448.jpg",
        cropName: "20170308_122448.jpg",
      },
      {
        id: 1884,
        imgId: 1549,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_131752.jpg",
        cropName: "20170308_131752.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:42:26.860+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "4",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1016.0,
    cashPrice: 1172.4275,
    taxAll: 109.655,
    taxInclude: 1227.255,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 511,
    p_adminid: 2,
    productCategory: { id: 512, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 512,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3682,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3683,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3684,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3685,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3686,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3687,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 512, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 512,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 512,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 512,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1885,
        imgId: 1550,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_153138.jpg",
        cropName: "20170307_153138.jpg",
      },
      {
        id: 1886,
        imgId: 1551,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_161528.jpg",
        cropName: "20170307_161528.jpg",
      },
      {
        id: 1887,
        imgId: 1552,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_175224.jpg",
        cropName: "20170307_175224.jpg",
      },
      {
        id: 1888,
        imgId: 1553,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_175431.jpg",
        cropName: "20170307_175431.jpg",
      },
      {
        id: 1889,
        imgId: 1554,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_191519.jpg",
        cropName: "20170307_191519.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:44:28.584+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "4",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 586.5,
    cashPrice: 676.435,
    taxAll: 62.57,
    taxInclude: 707.72003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 512,
    p_adminid: 2,
    productCategory: { id: 513, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 513,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 3688,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3689,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3690,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3691,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 3692,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3693,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 3694,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3695,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3696,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 513, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 513,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 513,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 513,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1890,
        imgId: 1556,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_112606.jpg",
        cropName: "20170308_112606.jpg",
      },
      {
        id: 1891,
        imgId: 1555,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_194319.jpg",
        cropName: "20170307_194319.jpg",
      },
      {
        id: 1892,
        imgId: 1557,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_122458.jpg",
        cropName: "20170308_122458.jpg",
      },
      {
        id: 1893,
        imgId: 1558,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_125000.jpg",
        cropName: "20170308_125000.jpg",
      },
      {
        id: 1894,
        imgId: 1559,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_131807.jpg",
        cropName: "20170308_131807.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:46:15.411+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "4",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 622.5,
    cashPrice: 716.935,
    taxAll: 64.369995,
    taxInclude: 749.12,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 513,
    p_adminid: 2,
    productCategory: { id: 514, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 514,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3697,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3698,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3699,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3700,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3701,
        lbrId: 1201,
        price: 2.05,
        taxPercentage: 5.0,
        tax: 0.1025,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3702,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 514, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 514,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 514,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 514,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1895,
        imgId: 1560,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6413.jpg",
        cropName: "6413.jpg",
      },
      {
        id: 1896,
        imgId: 1561,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6414.jpg",
        cropName: "6414.jpg",
      },
      {
        id: 1897,
        imgId: 1562,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6415.jpg",
        cropName: "6415.jpg",
      },
      {
        id: 1898,
        imgId: 1563,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6417.jpg",
        cropName: "6417.jpg",
      },
      {
        id: 1899,
        imgId: 1564,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6418.jpg",
        cropName: "6418.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:48:21.292+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 129.0,
    cashPrice: 149.75499,
    taxAll: 15.71,
    taxInclude: 157.61,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 514,
    p_adminid: 2,
    productCategory: { id: 515, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 515,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3703,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3704,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3705,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3706,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3707,
        lbrId: 1201,
        price: 2.05,
        taxPercentage: 5.0,
        tax: 0.1025,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3708,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 515, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 515,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 515,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 515,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1900,
        imgId: 1565,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6419.jpg",
        cropName: "6419.jpg",
      },
      {
        id: 1901,
        imgId: 1566,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6420.jpg",
        cropName: "6420.jpg",
      },
      {
        id: 1902,
        imgId: 1567,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6421.jpg",
        cropName: "6421.jpg",
      },
      {
        id: 1903,
        imgId: 1568,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6422.jpg",
        cropName: "6422.jpg",
      },
      {
        id: 1904,
        imgId: 1569,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6423.jpg",
        cropName: "6423.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:50:36.393+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 129.0,
    cashPrice: 149.75499,
    taxAll: 15.71,
    taxInclude: 157.61,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 515,
    p_adminid: 2,
    productCategory: { id: 516, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 516,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3709,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3710,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3711,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3712,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3713,
        lbrId: 1201,
        price: 2.05,
        taxPercentage: 5.0,
        tax: 0.1025,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3714,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 516, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 516,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 516,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 516,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1905,
        imgId: 1570,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6424.jpg",
        cropName: "6424.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:52:07.762+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 129.0,
    cashPrice: 149.75499,
    taxAll: 15.71,
    taxInclude: 157.61,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 516,
    p_adminid: 2,
    productCategory: { id: 517, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 517,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3715,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3716,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3717,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3718,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3719,
        lbrId: 1201,
        price: 2.05,
        taxPercentage: 5.0,
        tax: 0.1025,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3720,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 517, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 517,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 517,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 517,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1906,
        imgId: 1573,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6429.jpg",
        cropName: "6429.jpg",
      },
      {
        id: 1907,
        imgId: 1572,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6428.jpg",
        cropName: "6428.jpg",
      },
      {
        id: 1908,
        imgId: 1571,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6427.jpg",
        cropName: "6427.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:53:38.294+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 129.0,
    cashPrice: 149.75499,
    taxAll: 15.71,
    taxInclude: 157.61,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 517,
    p_adminid: 2,
    productCategory: { id: 518, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 518,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3721,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3722,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3723,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3724,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3725,
        lbrId: 1201,
        price: 2.05,
        taxPercentage: 5.0,
        tax: 0.1025,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3726,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 518, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 518,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 518,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 518,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1909,
        imgId: 1574,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317-WA0015.jpg",
        cropName: "20160317-WA0015.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:56:53.109+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 129.0,
    cashPrice: 149.75499,
    taxAll: 15.71,
    taxInclude: 157.61,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 518,
    p_adminid: 2,
    productCategory: { id: 519, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 519,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3727,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3728,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3729,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3730,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3731,
        lbrId: 1201,
        price: 2.05,
        taxPercentage: 5.0,
        tax: 0.1025,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3732,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 519, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 519,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 519,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 519,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1910,
        imgId: 1575,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317-WA0016.jpg",
        cropName: "20160317-WA0016.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:58:06.502+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 129.0,
    cashPrice: 149.75499,
    taxAll: 15.71,
    taxInclude: 157.61,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 519,
    p_adminid: 2,
    productCategory: { id: 520, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 520,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 3733,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 3734,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 3735,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 3736,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3737,
        lbrId: 1201,
        price: 2.05,
        taxPercentage: 5.0,
        tax: 0.1025,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3738,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 520, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 520,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 520,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 520,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1911,
        imgId: 1576,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317-WA0026.jpg",
        cropName: "20160317-WA0026.jpg",
      },
      {
        id: 1912,
        imgId: 1577,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317-WA0031.jpg",
        cropName: "20160317-WA0031.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T10:59:13.570+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "6",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 129.0,
    cashPrice: 149.75499,
    taxAll: 15.71,
    taxInclude: 157.61,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 520,
    p_adminid: 2,
    productCategory: { id: 521, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 521,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 3745,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 3746,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 3747,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3748,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3749,
        lbrId: 1599,
        price: 2.2,
        taxPercentage: 5.0,
        tax: 0.11,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3750,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 521, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 521,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 521,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 521,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1914,
        imgId: 1578,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8401.jpg",
        cropName: "8401.jpg",
      },
      {
        id: 1915,
        imgId: 1579,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8403.jpg",
        cropName: "8403.jpg",
      },
      {
        id: 1916,
        imgId: 1580,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8404.jpg",
        cropName: "8404.jpg",
      },
      {
        id: 1917,
        imgId: 1581,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8405.jpg",
        cropName: "8405.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T12:01:16.495+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 132.0,
    cashPrice: 153.12999,
    taxAll: 15.86,
    taxInclude: 161.06,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 521,
    p_adminid: 2,
    productCategory: { id: 522, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 522,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 3751,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 3752,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 3753,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3754,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3755,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 3756,
        lbrId: 1599,
        price: 2.2,
        taxPercentage: 5.0,
        tax: 0.11,
        title: "Plating Gold Plated & Meena",
      },
    ],
    productSets: { id: 522, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 522,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 522,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 522,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1918,
        imgId: 1582,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8406.jpg",
        cropName: "8406.jpg",
      },
      {
        id: 1919,
        imgId: 1583,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8407.jpg",
        cropName: "8407.jpg",
      },
      {
        id: 1920,
        imgId: 1584,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8408.jpg",
        cropName: "8408.jpg",
      },
      {
        id: 1921,
        imgId: 1585,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8409.jpg",
        cropName: "8409.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T12:02:32.100+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 132.0,
    cashPrice: 153.12999,
    taxAll: 15.86,
    taxInclude: 161.06,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 522,
    p_adminid: 2,
    productCategory: { id: 523, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 523,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 3757,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 3758,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 3759,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 3760,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3761,
        lbrId: 1599,
        price: 2.2,
        taxPercentage: 5.0,
        tax: 0.11,
        title: "Plating Gold Plated & Meena",
      },
      {
        id: 3762,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 523, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 523,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 523,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 523,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1922,
        imgId: 1586,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8411.jpg",
        cropName: "8411.jpg",
      },
      {
        id: 1923,
        imgId: 1587,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8412.jpg",
        cropName: "8412.jpg",
      },
      {
        id: 1924,
        imgId: 1588,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317-WA0026.jpg",
        cropName: "20160317-WA0026.jpg",
      },
      {
        id: 1925,
        imgId: 1589,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160317-WA0031.jpg",
        cropName: "20160317-WA0031.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-28T12:03:38.825+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "8",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 132.0,
    cashPrice: 153.12999,
    taxAll: 15.86,
    taxInclude: 161.06,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 523,
    p_adminid: 2,
    productCategory: { id: 524, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 524,
      adminId: 2,
      parentId: 131,
      categoryId: 11,
      title: "11MM",
    },
    productChild: [
      {
        id: 3854,
        lbrId: 1948,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 3855,
        lbrId: 1953,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 3856,
        lbrId: 1956,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 3857,
        lbrId: 1966,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3858,
        lbrId: 1983,
        price: 14.5,
        taxPercentage: 5.0,
        tax: 0.725,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3859,
        lbrId: 1986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 524, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 524,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 524,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 524,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1977,
        imgId: 1591,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161023_134410.jpg",
        cropName: "20161023_134410.jpg",
      },
      {
        id: 1978,
        imgId: 1590,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161023_133722.jpg",
        cropName: "20161023_133722.jpg",
      },
      {
        id: 1979,
        imgId: 1592,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161023_135012.jpg",
        cropName: "20161023_135012.jpg",
      },
      {
        id: 1980,
        imgId: 1593,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161023_135755.jpg",
        cropName: "20161023_135755.jpg",
      },
      {
        id: 1981,
        imgId: 1594,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161023_140154.jpg",
        cropName: "20161023_140154.jpg",
      },
      {
        id: 1982,
        imgId: 1595,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161023_161859.jpg",
        cropName: "20161023_161859.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T08:46:28.083+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: " 11MM",
    prodManfDetails: "1",
    prodDescription:
      "11 MM Three Tone Plated Side Veni Bangle women daily wear fashion jewellery bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 484.5,
    cashPrice: 553.365,
    taxAll: 40.83,
    taxInclude: 573.78,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 524,
    p_adminid: 2,
    productCategory: { id: 525, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 525,
      adminId: 2,
      parentId: 131,
      categoryId: 11,
      title: "11MM",
    },
    productChild: [
      {
        id: 3860,
        lbrId: 1948,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 3861,
        lbrId: 1956,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 3862,
        lbrId: 1953,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 3863,
        lbrId: 1966,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3864,
        lbrId: 1983,
        price: 14.5,
        taxPercentage: 5.0,
        tax: 0.725,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3865,
        lbrId: 1986,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 525, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 525,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 525,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 525,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1983,
        imgId: 1597,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_143903.jpg",
        cropName: "20170307_143903.jpg",
      },
      {
        id: 1984,
        imgId: 1596,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_143144.jpg",
        cropName: "20170307_143144.jpg",
      },
      {
        id: 1985,
        imgId: 1598,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_143531.jpg",
        cropName: "20170307_143531.jpg",
      },
      {
        id: 1986,
        imgId: 1599,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_144123.jpg",
        cropName: "20170307_144123.jpg",
      },
      {
        id: 1987,
        imgId: 1600,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_144242.jpg",
        cropName: "20170307_144242.jpg",
      },
      {
        id: 1988,
        imgId: 1601,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_144415.jpg",
        cropName: "20170307_144415.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T08:47:50.835+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11MM",
    prodManfDetails: "1",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 948.5,
    cashPrice: 1083.6849,
    taxAll: 80.67,
    taxInclude: 1124.02,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 525,
    p_adminid: 2,
    productCategory: { id: 526, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 526,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3866,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3867,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3868,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3869,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3870,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3871,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 526, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 526,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 526,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 526,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1989,
        imgId: 1604,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_145052.jpg",
        cropName: "20170307_145052.jpg",
      },
      {
        id: 1990,
        imgId: 1606,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_145851.jpg",
        cropName: "20170307_145851.jpg",
      },
      {
        id: 1991,
        imgId: 1608,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_150604.jpg",
        cropName: "20170307_150604.jpg",
      },
      {
        id: 1992,
        imgId: 1610,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_151032.jpg",
        cropName: "20170307_151032.jpg",
      },
      {
        id: 1993,
        imgId: 1612,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_151644.jpg",
        cropName: "20170307_151644.jpg",
      },
      {
        id: 1994,
        imgId: 1614,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_152253.jpg",
        cropName: "20170307_152253.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T08:50:45.368+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11MM",
    prodManfDetails: "11",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 526,
    p_adminid: 2,
    productCategory: { id: 527, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 527,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3872,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3873,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3874,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3875,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3876,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3877,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 527, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 527,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 527,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 527,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 1995,
        imgId: 1605,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_145416.jpg",
        cropName: "20170307_145416.jpg",
      },
      {
        id: 1996,
        imgId: 1607,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_150414.jpg",
        cropName: "20170307_150414.jpg",
      },
      {
        id: 1997,
        imgId: 1609,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_150800.jpg",
        cropName: "20170307_150800.jpg",
      },
      {
        id: 1998,
        imgId: 1611,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_151208.jpg",
        cropName: "20170307_151208.jpg",
      },
      {
        id: 1999,
        imgId: 1613,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_151913.jpg",
        cropName: "20170307_151913.jpg",
      },
      {
        id: 2000,
        imgId: 1615,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_152512.jpg",
        cropName: "20170307_152512.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T08:54:29.008+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 527,
    p_adminid: 2,
    productCategory: { id: 528, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 528,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3878,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3879,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3880,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3881,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3882,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3883,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 528, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 528,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 528,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 528,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2001,
        imgId: 1616,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_155951.jpg",
        cropName: "20170307_155951.jpg",
      },
      {
        id: 2002,
        imgId: 1619,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_160340.jpg",
        cropName: "20170307_160340.jpg",
      },
      {
        id: 2003,
        imgId: 1620,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_160441.jpg",
        cropName: "20170307_160441.jpg",
      },
      {
        id: 2004,
        imgId: 1623,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_160802.jpg",
        cropName: "20170307_160802.jpg",
      },
      {
        id: 2005,
        imgId: 1626,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_161304.jpg",
        cropName: "20170307_161304.jpg",
      },
      {
        id: 2006,
        imgId: 1624,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_160907.jpg",
        cropName: "20170307_160907.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T08:57:19.156+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 528,
    p_adminid: 2,
    productCategory: { id: 529, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 529,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3884,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3885,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3886,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3887,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3888,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3889,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 529, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 529,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 529,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 529,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2007,
        imgId: 1621,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_160545.jpg",
        cropName: "20170307_160545.jpg",
      },
      {
        id: 2008,
        imgId: 1617,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_160049.jpg",
        cropName: "20170307_160049.jpg",
      },
      {
        id: 2009,
        imgId: 1618,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_160241.jpg",
        cropName: "20170307_160241.jpg",
      },
      {
        id: 2010,
        imgId: 1622,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_160711.jpg",
        cropName: "20170307_160711.jpg",
      },
      {
        id: 2011,
        imgId: 1625,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_161157.jpg",
        cropName: "20170307_161157.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T08:59:37.771+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 529,
    p_adminid: 2,
    productCategory: { id: 530, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 530,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3925,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3926,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3927,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3928,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3929,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3930,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 530, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 530,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 530,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 530,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2025,
        imgId: 1633,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_170515.jpg",
        cropName: "20170307_170515.jpg",
      },
      {
        id: 2026,
        imgId: 1627,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_163620.jpg",
        cropName: "20170307_163620.jpg",
      },
      {
        id: 2027,
        imgId: 1628,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_170129.jpg",
        cropName: "20170307_170129.jpg",
      },
      {
        id: 2028,
        imgId: 1631,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_170429.jpg",
        cropName: "20170307_170429.jpg",
      },
      {
        id: 2029,
        imgId: 1635,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_171041.jpg",
        cropName: "20170307_171041.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:20:07.253+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 530,
    p_adminid: 2,
    productCategory: { id: 531, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 531,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3931,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3932,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3933,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3934,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3935,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3936,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 531, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 531,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 531,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 531,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2030,
        imgId: 1638,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_171543.jpg",
        cropName: "20170307_171543.jpg",
      },
      {
        id: 2031,
        imgId: 1637,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_171711.jpg",
        cropName: "20170307_171711.jpg",
      },
      {
        id: 2032,
        imgId: 1636,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_171131.jpg",
        cropName: "20170307_171131.jpg",
      },
      {
        id: 2033,
        imgId: 1634,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_170833.jpg",
        cropName: "20170307_170833.jpg",
      },
      {
        id: 2034,
        imgId: 1632,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_170621.jpg",
        cropName: "20170307_170621.jpg",
      },
      {
        id: 2035,
        imgId: 1630,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_170344.jpg",
        cropName: "20170307_170344.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:22:16.192+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 531,
    p_adminid: 2,
    productCategory: { id: 532, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 532,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3941,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3942,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3943,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3944,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3945,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3946,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 532, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 532,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 532,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 532,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2038,
        imgId: 1639,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_180130.jpg",
        cropName: "20170307_180130.jpg",
      },
      {
        id: 2039,
        imgId: 1642,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_180714.jpg",
        cropName: "20170307_180714.jpg",
      },
      {
        id: 2040,
        imgId: 1643,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_180956.jpg",
        cropName: "20170307_180956.jpg",
      },
      {
        id: 2041,
        imgId: 1646,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_181442.jpg",
        cropName: "20170307_181442.jpg",
      },
      {
        id: 2042,
        imgId: 1648,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_181745.jpg",
        cropName: "20170307_181745.jpg",
      },
      {
        id: 2043,
        imgId: 1649,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_181952.jpg",
        cropName: "20170307_181952.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:23:41.927+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 532,
    p_adminid: 2,
    productCategory: { id: 533, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 533,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3947,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3948,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3949,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3950,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3951,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3952,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 533, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 533,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 533,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 533,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2044,
        imgId: 1640,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_180439.jpg",
        cropName: "20170307_180439.jpg",
      },
      {
        id: 2045,
        imgId: 1641,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_180610.jpg",
        cropName: "20170307_180610.jpg",
      },
      {
        id: 2046,
        imgId: 1644,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_181035.jpg",
        cropName: "20170307_181035.jpg",
      },
      {
        id: 2047,
        imgId: 1645,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_181217.jpg",
        cropName: "20170307_181217.jpg",
      },
      {
        id: 2048,
        imgId: 1647,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_181619.jpg",
        cropName: "20170307_181619.jpg",
      },
      {
        id: 2049,
        imgId: 1650,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_182512.jpg",
        cropName: "20170307_182512.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:25:22.839+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 533,
    p_adminid: 2,
    productCategory: { id: 534, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 534,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3953,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3954,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3955,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3956,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3957,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3958,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 534, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 534,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 534,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 534,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2050,
        imgId: 1653,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_192323.jpg",
        cropName: "20170307_192323.jpg",
      },
      {
        id: 2051,
        imgId: 1651,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_192120.jpg",
        cropName: "20170307_192120.jpg",
      },
      {
        id: 2052,
        imgId: 1656,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_193054.jpg",
        cropName: "20170307_193054.jpg",
      },
      {
        id: 2053,
        imgId: 1657,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_193241.jpg",
        cropName: "20170307_193241.jpg",
      },
      {
        id: 2054,
        imgId: 1660,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_193534.jpg",
        cropName: "20170307_193534.jpg",
      },
      {
        id: 2055,
        imgId: 1662,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_194004.jpg",
        cropName: "20170307_194004.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:27:47.458+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 534,
    p_adminid: 2,
    productCategory: { id: 535, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 535,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3959,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3960,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3961,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3962,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3963,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3964,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 535, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 535,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 535,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 535,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2056,
        imgId: 1658,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_193326.jpg",
        cropName: "20170307_193326.jpg",
      },
      {
        id: 2057,
        imgId: 1654,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_192625.jpg",
        cropName: "20170307_192625.jpg",
      },
      {
        id: 2058,
        imgId: 1652,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_192203.jpg",
        cropName: "20170307_192203.jpg",
      },
      {
        id: 2059,
        imgId: 1655,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_192856.jpg",
        cropName: "20170307_192856.jpg",
      },
      {
        id: 2060,
        imgId: 1659,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_193458.jpg",
        cropName: "20170307_193458.jpg",
      },
      {
        id: 2061,
        imgId: 1661,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_193756.jpg",
        cropName: "20170307_193756.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:30:05.690+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 535,
    p_adminid: 2,
    productCategory: { id: 536, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 536,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3965,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3966,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3967,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3968,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3969,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3970,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 536, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 536,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 536,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 536,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2062,
        imgId: 1663,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_195253.jpg",
        cropName: "20170307_195253.jpg",
      },
      {
        id: 2063,
        imgId: 1666,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_195721.jpg",
        cropName: "20170307_195721.jpg",
      },
      {
        id: 2064,
        imgId: 1668,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_195840.jpg",
        cropName: "20170307_195840.jpg",
      },
      {
        id: 2065,
        imgId: 1669,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_200341.jpg",
        cropName: "20170307_200341.jpg",
      },
      {
        id: 2066,
        imgId: 1670,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_200836.jpg",
        cropName: "20170307_200836.jpg",
      },
      {
        id: 2067,
        imgId: 1673,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_201341.jpg",
        cropName: "20170307_201341.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:33:00.771+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 536,
    p_adminid: 2,
    productCategory: { id: 537, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 537,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3971,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3972,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3973,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3974,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3975,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3976,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 537, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 537,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 537,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 537,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2068,
        imgId: 1674,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_201454.jpg",
        cropName: "20170307_201454.jpg",
      },
      {
        id: 2069,
        imgId: 1664,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_195449.jpg",
        cropName: "20170307_195449.jpg",
      },
      {
        id: 2070,
        imgId: 1665,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_195634.jpg",
        cropName: "20170307_195634.jpg",
      },
      {
        id: 2071,
        imgId: 1667,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_200116.jpg",
        cropName: "20170307_200116.jpg",
      },
      {
        id: 2072,
        imgId: 1671,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_201029.jpg",
        cropName: "20170307_201029.jpg",
      },
      {
        id: 2073,
        imgId: 1672,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170307_201239.jpg",
        cropName: "20170307_201239.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:35:46.359+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4 MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 537,
    p_adminid: 2,
    productCategory: { id: 538, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 538,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3977,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3978,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3979,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3980,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3981,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3982,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 538, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 538,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 538,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 538,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2074,
        imgId: 1679,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_121106.jpg",
        cropName: "20170308_121106.jpg",
      },
      {
        id: 2075,
        imgId: 1678,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_120520.jpg",
        cropName: "20170308_120520.jpg",
      },
      {
        id: 2076,
        imgId: 1675,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_120204.jpg",
        cropName: "20170308_120204.jpg",
      },
      {
        id: 2077,
        imgId: 1681,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_121411.jpg",
        cropName: "20170308_121411.jpg",
      },
      {
        id: 2078,
        imgId: 1683,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_121746.jpg",
        cropName: "20170308_121746.jpg",
      },
      {
        id: 2079,
        imgId: 1686,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_122142.jpg",
        cropName: "20170308_122142.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:39:22.737+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 538,
    p_adminid: 2,
    productCategory: { id: 539, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 539,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3983,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3984,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3985,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3986,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3987,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3988,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 539, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 539,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 539,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 539,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2080,
        imgId: 1677,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_120419.jpg",
        cropName: "20170308_120419.jpg",
      },
      {
        id: 2081,
        imgId: 1676,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_120309.jpg",
        cropName: "20170308_120309.jpg",
      },
      {
        id: 2082,
        imgId: 1680,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_121219.jpg",
        cropName: "20170308_121219.jpg",
      },
      {
        id: 2083,
        imgId: 1682,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_121516.jpg",
        cropName: "20170308_121516.jpg",
      },
      {
        id: 2084,
        imgId: 1684,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_122018.jpg",
        cropName: "20170308_122018.jpg",
      },
      {
        id: 2085,
        imgId: 1685,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_121854.jpg",
        cropName: "20170308_121854.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:41:21.089+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 539,
    p_adminid: 2,
    productCategory: { id: 540, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 540,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3989,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3990,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3991,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3992,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3993,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 3994,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 540, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 540,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 540,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 540,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2086,
        imgId: 1691,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_123727.jpg",
        cropName: "20170308_123727.jpg",
      },
      {
        id: 2087,
        imgId: 1688,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_123330.jpg",
        cropName: "20170308_123330.jpg",
      },
      {
        id: 2088,
        imgId: 1689,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_123505.jpg",
        cropName: "20170308_123505.jpg",
      },
      {
        id: 2089,
        imgId: 1693,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_123957.jpg",
        cropName: "20170308_123957.jpg",
      },
      {
        id: 2090,
        imgId: 1696,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_124346.jpg",
        cropName: "20170308_124346.jpg",
      },
      {
        id: 2091,
        imgId: 1698,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_124547.jpg",
        cropName: "20170308_124547.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:44:12.084+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 540,
    p_adminid: 2,
    productCategory: { id: 541, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 541,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 3995,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 3996,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 3997,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 3998,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 3999,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4000,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 541, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 541,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 541,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 541,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2092,
        imgId: 1697,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_124447.jpg",
        cropName: "20170308_124447.jpg",
      },
      {
        id: 2093,
        imgId: 1694,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_124109.jpg",
        cropName: "20170308_124109.jpg",
      },
      {
        id: 2094,
        imgId: 1695,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_124238.jpg",
        cropName: "20170308_124238.jpg",
      },
      {
        id: 2095,
        imgId: 1692,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_123842.jpg",
        cropName: "20170308_123842.jpg",
      },
      {
        id: 2096,
        imgId: 1690,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_123602.jpg",
        cropName: "20170308_123602.jpg",
      },
      {
        id: 2097,
        imgId: 1687,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_123204.jpg",
        cropName: "20170308_123204.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:46:39.523+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 541,
    p_adminid: 2,
    productCategory: { id: 542, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 542,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 4001,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 4002,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4003,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 4004,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4005,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4006,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 542, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 542,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 542,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 542,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2098,
        imgId: 1711,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_131640.jpg",
        cropName: "20170308_131640.jpg",
      },
      {
        id: 2099,
        imgId: 1699,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_125950.jpg",
        cropName: "20170308_125950.jpg",
      },
      {
        id: 2100,
        imgId: 1702,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_130403.jpg",
        cropName: "20170308_130403.jpg",
      },
      {
        id: 2101,
        imgId: 1703,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_130530.jpg",
        cropName: "20170308_130530.jpg",
      },
      {
        id: 2102,
        imgId: 1706,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_130801.jpg",
        cropName: "20170308_130801.jpg",
      },
      {
        id: 2103,
        imgId: 1707,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_130958.jpg",
        cropName: "20170308_130958.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:50:15.815+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 542,
    p_adminid: 2,
    productCategory: { id: 543, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 543,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 4007,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 4008,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4009,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 4010,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4011,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4012,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 543, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 543,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 543,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 543,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2104,
        imgId: 1708,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_131136.jpg",
        cropName: "20170308_131136.jpg",
      },
      {
        id: 2105,
        imgId: 1710,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_131525.jpg",
        cropName: "20170308_131525.jpg",
      },
      {
        id: 2106,
        imgId: 1705,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_130617.jpg",
        cropName: "20170308_130617.jpg",
      },
      {
        id: 2107,
        imgId: 1704,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_130706.jpg",
        cropName: "20170308_130706.jpg",
      },
      {
        id: 2108,
        imgId: 1701,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_130306.jpg",
        cropName: "20170308_130306.jpg",
      },
      {
        id: 2109,
        imgId: 1700,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_130056.jpg",
        cropName: "20170308_130056.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:51:55.309+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 543,
    p_adminid: 2,
    productCategory: { id: 544, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 544,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 4013,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 4014,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4015,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 4016,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4017,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4018,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 544, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 544,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 544,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 544,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2110,
        imgId: 1723,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_134429.jpg",
        cropName: "20170308_134429.jpg",
      },
      {
        id: 2111,
        imgId: 1721,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_134209.jpg",
        cropName: "20170308_134209.jpg",
      },
      {
        id: 2112,
        imgId: 1718,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_133909.jpg",
        cropName: "20170308_133909.jpg",
      },
      {
        id: 2113,
        imgId: 1712,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_133220.jpg",
        cropName: "20170308_133220.jpg",
      },
      {
        id: 2114,
        imgId: 1715,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_133529.jpg",
        cropName: "20170308_133529.jpg",
      },
      {
        id: 2115,
        imgId: 1716,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_133652.jpg",
        cropName: "20170308_133652.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:53:39.765+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 544,
    p_adminid: 2,
    productCategory: { id: 545, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 545,
      adminId: 2,
      parentId: 133,
      categoryId: 11,
      title: "11/4MM",
    },
    productChild: [
      {
        id: 4019,
        lbrId: 2037,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 4020,
        lbrId: 2043,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4021,
        lbrId: 2044,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 4022,
        lbrId: 2055,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4023,
        lbrId: 2073,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4024,
        lbrId: 2076,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 545, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 545,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 545,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 545,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2116,
        imgId: 1714,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_133355.jpg",
        cropName: "20170308_133355.jpg",
      },
      {
        id: 2117,
        imgId: 1713,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_133259.jpg",
        cropName: "20170308_133259.jpg",
      },
      {
        id: 2118,
        imgId: 1717,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_133755.jpg",
        cropName: "20170308_133755.jpg",
      },
      {
        id: 2119,
        imgId: 1719,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_133955.jpg",
        cropName: "20170308_133955.jpg",
      },
      {
        id: 2120,
        imgId: 1720,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_134059.jpg",
        cropName: "20170308_134059.jpg",
      },
      {
        id: 2121,
        imgId: 1722,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170308_134344.jpg",
        cropName: "20170308_134344.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-08-29T09:55:24.564+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "11/4MM",
    prodManfDetails: "11/4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 526.0,
    cashPrice: 621.1775,
    taxAll: 85.155,
    taxInclude: 663.755,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 546,
    p_adminid: 2,
    productCategory: { id: 547, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 547,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4159,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4160,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4161,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4162,
        lbrId: 573,
        price: 4.5,
        taxPercentage: 5.0,
        tax: 0.225,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4163,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4164,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
    ],
    productSets: { id: 547, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 547,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 547,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 547,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2238,
        imgId: 1770,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1147.jpg",
        cropName: "20220430_1147.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:11:03.249+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GMQ",
    prodManfDetails: "GM",
    prodDescription:
      "Unique design 3-tone bangles for women with an attractive look and the latest design.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 879.5,
    cashPrice: 1018.865,
    taxAll: 102.83,
    taxInclude: 1070.28,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 547,
    p_adminid: 2,
    productCategory: { id: 548, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 548,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4165,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4166,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4167,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4168,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4169,
        lbrId: 573,
        price: 4.5,
        taxPercentage: 5.0,
        tax: 0.225,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4170,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 548, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 548,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 548,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 548,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2239,
        imgId: 1771,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1150.jpg",
        cropName: "20220430_1150.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:11:50.314+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "GM",
    prodDescription:
      "Set of 3 Silver/Rose Gold/Gold-Tone Bangle Bracelets Tri-color for Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 386.5,
    cashPrice: 447.275,
    taxAll: 44.25,
    taxInclude: 469.4,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 548,
    p_adminid: 2,
    productCategory: { id: 549, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 549,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4171,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4172,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4173,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4174,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4175,
        lbrId: 573,
        price: 4.5,
        taxPercentage: 5.0,
        tax: 0.225,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4176,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 549, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 549,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 549,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 549,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2240,
        imgId: 1772,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1153.jpg",
        cropName: "20220430_1153.jpg",
      },
      {
        id: 2241,
        imgId: 1773,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1154.jpg",
        cropName: "20220430_1154.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:14:14.403+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "GM",
    prodDescription: "Zig Zag 3-tone brass bangles bracelets.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 508.5,
    cashPrice: 588.685,
    taxAll: 58.67,
    taxInclude: 618.01996,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 549,
    p_adminid: 2,
    productCategory: { id: 550, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 550,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4177,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4178,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4179,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4180,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4181,
        lbrId: 573,
        price: 4.5,
        taxPercentage: 5.0,
        tax: 0.225,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4182,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 550, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 550,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 550,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 550,
      adminId: 2,
      packingmaterialId: 118,
      type: "Polythene PP",
      pkgdesc: "12 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2242,
        imgId: 1774,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1190.jpg",
        cropName: "20220430_1190.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:15:33.629+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "GM",
    prodDescription: "Classic 3Tone 12 Piece Set Bangle Bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1462.0,
    cashPrice: 1693.19,
    taxAll: 169.98,
    taxInclude: 1778.1799,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 83.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 550,
    p_adminid: 2,
    productCategory: { id: 551, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 551,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4183,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4184,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4185,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4186,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4187,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4188,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 551, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 551,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 551,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 551,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2243,
        imgId: 1821,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/aca29ee47233093d6a873468003c61c5.jpg",
        cropName: "aca29ee47233093d6a873468003c61c5.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:16:43.790+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "GM",
    prodDescription:
      "Smooth Fashion Wedding Bangle 8 pcs/set Silver Gold and Rose Gold Color Simple Wiring Bracelet Wristband Wire Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 400.85,
    cashPrice: 472.1035,
    taxAll: 62.337,
    taxInclude: 503.272,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 551,
    p_adminid: 2,
    productCategory: { id: 552, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 552,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4189,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4190,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4191,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4192,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4193,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4194,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 552, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 552,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 552,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 552,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2244,
        imgId: 1831,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/5934691152954.jpg",
        cropName: "5934691152954.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:18:14.350+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "GM",
    prodDescription: "Antique Look Styles Bangle For Women And Girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 400.85,
    cashPrice: 472.1035,
    taxAll: 62.337,
    taxInclude: 503.272,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 552,
    p_adminid: 2,
    productCategory: { id: 553, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 553,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 4195,
        lbrId: 677,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 4196,
        lbrId: 683,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4197,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4198,
        lbrId: 694,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4199,
        lbrId: 712,
        price: 4.0,
        taxPercentage: 5.0,
        tax: 0.2,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4200,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 553, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 553,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 553,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 553,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2245,
        imgId: 1725,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1001.jpg",
        cropName: "20220430_1001.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:24:06.061+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription:
      "New products jewellery accessories personalized 3-tone bangle wholesale custom gold plated women's bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 823.5,
    cashPrice: 955.865,
    taxAll: 100.03,
    taxInclude: 1005.88,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 553,
    p_adminid: 2,
    productCategory: { id: 554, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 554,
      adminId: 2,
      parentId: 105,
      categoryId: 11,
      title: "2MM",
    },
    productChild: [
      {
        id: 4201,
        lbrId: 678,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4202,
        lbrId: 684,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4203,
        lbrId: 682,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4204,
        lbrId: 694,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4205,
        lbrId: 707,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 4206,
        lbrId: 715,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 554, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 554,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 554,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 554,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2246,
        imgId: 1777,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/img_9139_1.jpg",
        cropName: "img_9139_1.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:25:16.397+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "2MM",
    prodManfDetails: "2",
    prodDescription:
      "New products jewellery accessories personalized 2-tone bangle wholesale custom 18k gold plated women's bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 482.05,
    cashPrice: 564.7595,
    taxAll: 69.009,
    taxInclude: 599.26404,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 554,
    p_adminid: 2,
    productCategory: { id: 555, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 555,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4207,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4208,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 4209,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4210,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4211,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4212,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 555, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 555,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 555,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 555,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2247,
        imgId: 1728,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1001.jpg",
        cropName: "20220430_1001.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:29:08.897+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Indian Manufacturer and Export Gold Plated Brass Bangles 3 Tone Top Quality Latest Designed Fancy Bangles For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1037.0,
    cashPrice: 1196.0525,
    taxAll: 110.705,
    taxInclude: 1251.4049,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 555,
    p_adminid: 2,
    productCategory: { id: 556, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 556,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4213,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4214,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4215,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 4216,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4217,
        lbrId: 904,
        price: 6.0,
        taxPercentage: 5.0,
        tax: 0.3,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 4218,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 556, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 556,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 556,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 556,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2248,
        imgId: 1729,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1006.jpg",
        cropName: "20220430_1006.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:30:42.397+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Micron plated High finish plated bangle fashion jewellery women daily wear trendy bracelet.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1002.0,
    cashPrice: 1156.6775,
    taxAll: 108.955,
    taxInclude: 1211.1549,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 556,
    p_adminid: 2,
    productCategory: { id: 557, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 557,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4219,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4220,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 4221,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4222,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4223,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4224,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 557, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 557,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 557,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 557,
      adminId: 2,
      packingmaterialId: 111,
      type: "Polythene PP",
      pkgdesc: "5 Pcs",
      hsncode: "39202020",
      price: 1.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2249,
        imgId: 1730,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1015.jpg",
        cropName: "20220430_1015.jpg",
      },
      {
        id: 2250,
        imgId: 1731,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1016.jpg",
        cropName: "20220430_1016.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:40:20.246+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "High finish three tone plated bangle fashion jewellery women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 743.0,
    cashPrice: 856.6575,
    taxAll: 78.715,
    taxInclude: 896.015,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 200.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 557,
    p_adminid: 2,
    productCategory: { id: 558, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 558,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4225,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4226,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4227,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 4228,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4229,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4230,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 558, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 558,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 558,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 558,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2251,
        imgId: 1732,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1186.jpg",
        cropName: "20220430_1186.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:43:28.199+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Micron finish three-tone Plated latest fashion Bangle jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1037.0,
    cashPrice: 1196.0525,
    taxAll: 110.705,
    taxInclude: 1251.4049,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 558,
    p_adminid: 2,
    productCategory: { id: 559, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 559,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4231,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4232,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4233,
        lbrId: 873,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Buffing",
      },
      {
        id: 4234,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4235,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4236,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 559, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 559,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 559,
      adminId: 2,
      rawpriceId: 190,
      title: "Rolling Dell Patti/Lasiya",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 559,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2252,
        imgId: 1733,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1020.jpg",
        cropName: "20220430_1020.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:44:32.839+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "3-Tone Plated 4MM  bangle fashion trendy jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1039.1,
    cashPrice: 1198.5515,
    taxAll: 111.08299,
    taxInclude: 1254.093,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 559,
    p_adminid: 2,
    productCategory: { id: 560, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 560,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4237,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4238,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4239,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4240,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4241,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4242,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 560, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 560,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 560,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 560,
      adminId: 2,
      packingmaterialId: 111,
      type: "Polythene PP",
      pkgdesc: "5 Pcs",
      hsncode: "39202020",
      price: 1.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2253,
        imgId: 1785,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20171109-WA0019.jpg",
        cropName: "IMG-20171109-WA0019.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:46:51.455+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "3 Colors Smooth Fashion Wedding Bangle 5 pcs/set Silver Gold and Rose Gold Color Simple Wiring Bracelet Wristband Wire Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 735.5,
    cashPrice: 848.22,
    taxAll: 78.34,
    taxInclude: 887.39,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 200.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 560,
    p_adminid: 2,
    productCategory: { id: 561, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 561,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4243,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4244,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4245,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4246,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4247,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 4248,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 561, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 561,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 561,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 561,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2254,
        imgId: 1791,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/alloy-bangles-500x500.jpg",
        cropName: "alloy-bangles-500x500.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:51:41.945+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Beautiful 4 MM Zig Zag Gold plated Brass trendy Bangle Fashion for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 405.05002,
    cashPrice: 476.94553,
    taxAll: 62.781002,
    taxInclude: 508.33603,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 561,
    p_adminid: 2,
    productCategory: { id: 562, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 562,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4249,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4250,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4251,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4252,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4253,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4254,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 562, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 562,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 562,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 562,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2255,
        imgId: 1793,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/ethlyn-6-unids-lote-dubai-partido-oro-color (12).jpg",
        cropName: "ethlyn-6-unids-lote-dubai-partido-oro-color (12).jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:53:12.495+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Simply Unique 4MM Gold Plated Bangle which has tiny and intricate designs that enhance its appearance.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 312.05002,
    cashPrice: 367.4065,
    taxAll: 48.303,
    taxInclude: 391.558,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 562,
    p_adminid: 2,
    productCategory: { id: 563, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 563,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4255,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4256,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4257,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4258,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4259,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 4260,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 563, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 563,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 563,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 563,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2256,
        imgId: 1840,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/53600198812521.jpg",
        cropName: "53600198812521.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:54:40.083+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Attractive & Fancy Design Golden colour bangles for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 312.05002,
    cashPrice: 367.4065,
    taxAll: 48.303,
    taxInclude: 391.558,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 563,
    p_adminid: 2,
    productCategory: { id: 564, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 564,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4261,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4262,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4263,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4264,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4265,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 4266,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 564, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 564,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 564,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 564,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2257,
        imgId: 1842,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/607904960601529.jpg",
        cropName: "607904960601529.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:56:07.439+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "Indian style forming bangle for women and girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 434.05002,
    cashPrice: 510.91553,
    taxAll: 66.921005,
    taxInclude: 544.37604,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 564,
    p_adminid: 2,
    productCategory: { id: 565, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 565,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4267,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4268,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4269,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4270,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4271,
        lbrId: 901,
        price: 1.85,
        taxPercentage: 5.0,
        tax: 0.0925,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 4272,
        lbrId: 881,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Chakri Full",
      },
      {
        id: 4273,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 565, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 565,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 565,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 565,
      adminId: 2,
      packingmaterialId: 78,
      type: "Crystal Dibbi",
      pkgdesc: "M4",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2258,
        imgId: 1843,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/Ethnic-Indian-8-PCS-Two-tone-Bangles-Traditional.jpg",
        cropName: "Ethnic-Indian-8-PCS-Two-tone-Bangles-Traditional.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T13:57:43.231+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Gorgeous Designer Set of Simple 2-tone Bangle Bracelet for Women and Girls on Party Gifts.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 484.25,
    cashPrice: 566.0455,
    taxAll: 66.741005,
    taxInclude: 599.416,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 565,
    p_adminid: 2,
    productCategory: { id: 566, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 566,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4274,
        lbrId: 967,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4275,
        lbrId: 976,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4276,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4277,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4278,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4279,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 566, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 566,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 566,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 566,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2259,
        imgId: 1734,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1021.jpg",
        cropName: "20220430_1021.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T14:02:22.826+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "3 Colors Fashion Wedding Bangle 3pcs/set Silver Gold and Rose Gold Color Simple Wiring Bracelet Wristband Wire Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 467.5,
    cashPrice: 538.4,
    taxAll: 48.3,
    taxInclude: 562.55,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 566,
    p_adminid: 2,
    productCategory: { id: 567, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 567,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4280,
        lbrId: 967,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4281,
        lbrId: 973,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Half Round",
      },
      {
        id: 4282,
        lbrId: 976,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4283,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4284,
        lbrId: 1008,
        price: 7.0,
        taxPercentage: 5.0,
        tax: 0.35,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4285,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 567, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 567,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 567,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 567,
      adminId: 2,
      packingmaterialId: 111,
      type: "Polythene PP",
      pkgdesc: "5 Pcs",
      hsncode: "39202020",
      price: 1.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2260,
        imgId: 1749,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1181.jpg",
        cropName: "20220430_1181.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T14:04:09.249+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Classic and different look 3Tone 7 Piece Set Bangle Bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 750.5,
    cashPrice: 865.095,
    taxAll: 79.09,
    taxInclude: 904.64,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 200.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 567,
    p_adminid: 2,
    productCategory: { id: 568, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 568,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4286,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 4287,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4288,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4289,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4290,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4291,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 568, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 568,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 568,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 568,
      adminId: 2,
      packingmaterialId: 76,
      type: "Crystal Dibbi",
      pkgdesc: "M2",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2261,
        imgId: 1823,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/br1399-latest-college-girl-jewelry-wear-collection-one-gram-bangle-jewelry-in-gold-for-marriage-wear-use-buy-online-350-1-425x500.jpg",
        cropName:
          "br1399-latest-college-girl-jewelry-wear-collection-one-gram-bangle-jewelry-in-gold-for-marriage-wear-use-buy-online-350-1-425x500.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T14:05:38.025+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Fashion gold Plated filled high-quality Bracelets Bangles for women's wedding mom gift jewellery bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 256.45,
    cashPrice: 301.3655,
    taxAll: 38.541,
    taxInclude: 320.636,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 568,
    p_adminid: 2,
    productCategory: { id: 569, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 569,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4292,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4293,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4294,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4295,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4296,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4297,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 569, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 569,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 569,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 569,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2262,
        imgId: 1735,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1025.jpg",
        cropName: "20220430_1025.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T14:08:12.350+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Three Tone Plated Semanario Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1159.5,
    cashPrice: 1333.865,
    taxAll: 116.83,
    taxInclude: 1392.2799,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 569,
    p_adminid: 2,
    productCategory: { id: 570, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 570,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4298,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4299,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4300,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4301,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4302,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4303,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 570, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 570,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 570,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 570,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2263,
        imgId: 1736,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1045.jpg",
        cropName: "20220430_1045.jpg",
      },
      {
        id: 2264,
        imgId: 1737,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1044.jpg",
        cropName: "20220430_1044.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T14:11:23.797+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Set of 7 pcs Silver/Rose Gold/Gold-Tone Eaching Brass Bangle Bracelets Tri-color for Women and Girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1159.5,
    cashPrice: 1333.865,
    taxAll: 116.83,
    taxInclude: 1392.2799,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 570,
    p_adminid: 2,
    productCategory: { id: 571, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 571,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4304,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4305,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4306,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4307,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4308,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4309,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 571, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 571,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 571,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 571,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2265,
        imgId: 1738,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1057.jpg",
        cropName: "20220430_1057.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T14:12:55.056+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Set of 7 pcs Silver/Rose Gold/Gold-Tone Brass Bangle Bracelets Tri-color for Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 668.5,
    cashPrice: 768.685,
    taxAll: 66.67,
    taxInclude: 802.01996,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 571,
    p_adminid: 2,
    productCategory: { id: 572, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 572,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4310,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4311,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4312,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4313,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4314,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4315,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 572, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 572,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 572,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 572,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2266,
        imgId: 1739,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1058.jpg",
        cropName: "20220430_1058.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-01T14:14:12.317+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Elegant 6 MM Three Tone Plated Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 668.5,
    cashPrice: 768.685,
    taxAll: 66.67,
    taxInclude: 802.01996,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 576,
    p_adminid: 2,
    productCategory: { id: 577, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 577,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4370,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4371,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4372,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4373,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4374,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4375,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 577, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 577,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 577,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 577,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2281,
        imgId: 1855,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8435838.jpeg",
        cropName: "8435838.jpeg",
      },
      {
        id: 2282,
        imgId: 1856,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/34785347.jpeg",
        cropName: "34785347.jpeg",
      },
      {
        id: 2283,
        imgId: 1857,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/43753487.jpeg",
        cropName: "43753487.jpeg",
      },
      {
        id: 2284,
        imgId: 1858,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8438753485.jpeg",
        cropName: "8438753485.jpeg",
      },
      {
        id: 2285,
        imgId: 1859,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/85764864574.jpeg",
        cropName: "85764864574.jpeg",
      },
      {
        id: 2286,
        imgId: 1882,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901 60921.jpeg",
        cropName: "20230901 60921.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T09:31:50.717+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "GM",
    prodDescription:
      "Wholesale bangles Gold Plated Bangles Indian Wedding women Bangles at discount price.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 399.65002,
    cashPrice: 470.67554,
    taxAll: 62.121002,
    taxInclude: 501.73602,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 577,
    p_adminid: 2,
    productCategory: { id: 578, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 578,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4376,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4377,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4378,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4379,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4380,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4381,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 578, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 578,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 578,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 578,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2287,
        imgId: 1869,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611595.jpeg",
        cropName: "20230901611595.jpeg",
      },
      {
        id: 2288,
        imgId: 1863,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161158.jpeg",
        cropName: "2023090161158.jpeg",
      },
      {
        id: 2289,
        imgId: 1864,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161200.jpeg",
        cropName: "2023090161200.jpeg",
      },
      {
        id: 2290,
        imgId: 1865,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161201.jpeg",
        cropName: "2023090161201.jpeg",
      },
      {
        id: 2291,
        imgId: 1870,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611596.jpeg",
        cropName: "20230901611596.jpeg",
      },
      {
        id: 2292,
        imgId: 1871,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901612011.jpeg",
        cropName: "20230901612011.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T09:34:59.433+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "GM",
    prodManfDetails: "GM",
    prodDescription:
      "New Design Irregular Opening Gold Plated Brass Bangles Bracelet Women's Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 399.65002,
    cashPrice: 470.67554,
    taxAll: 62.121002,
    taxInclude: 501.73602,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 578,
    p_adminid: 2,
    productCategory: { id: 579, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 579,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4382,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4383,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4384,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4385,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4386,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4387,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 579, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 579,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 579,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 579,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2293,
        imgId: 1740,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1056.jpg",
        cropName: "20220430_1056.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:29:06.956+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Women's Brass Bracelet Bangle Special Design Bracelet Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 668.5,
    cashPrice: 768.685,
    taxAll: 66.67,
    taxInclude: 802.01996,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 579,
    p_adminid: 2,
    productCategory: { id: 580, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 580,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4388,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4389,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4390,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4391,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4392,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4393,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 580, setsId: 30, setDesc: "5 Pcs in 1 Set", pcs: 5 },
    productBox: {
      id: 580,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 580,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 580,
      adminId: 2,
      packingmaterialId: 111,
      type: "Polythene PP",
      pkgdesc: "5 Pcs",
      hsncode: "39202020",
      price: 1.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2294,
        imgId: 1743,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1083.jpg",
        cropName: "20220430_1083.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:30:05.900+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6MM Three Tone Plated Gold finished bangle design classic fashionable modern design for women and girls.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 830.5,
    cashPrice: 955.095,
    taxAll: 83.09,
    taxInclude: 996.64,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 200.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 580,
    p_adminid: 2,
    productCategory: { id: 581, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 581,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4394,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4395,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4396,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4397,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4398,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4399,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 581, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 581,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 581,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 581,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2295,
        imgId: 1744,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1089.jpg",
        cropName: "20220430_1089.jpg",
      },
      {
        id: 2296,
        imgId: 1745,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1090.jpg",
        cropName: "20220430_1090.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:31:49.676+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6 MM Three Tone Plated Side Veni Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 344.5,
    cashPrice: 395.86502,
    taxAll: 33.83,
    taxInclude: 412.78003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 581,
    p_adminid: 2,
    productCategory: { id: 582, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 582,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4400,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4401,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4402,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4403,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4404,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4405,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 582, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 582,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 582,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 582,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2297,
        imgId: 1746,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1180.jpg",
        cropName: "20220430_1180.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:33:00.552+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Trendy Gold Plated 6MM Side veni Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 344.5,
    cashPrice: 395.86502,
    taxAll: 33.83,
    taxInclude: 412.78003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 582,
    p_adminid: 2,
    productCategory: { id: 583, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 583,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4406,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4407,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4408,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4409,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4410,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 583, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 583,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 583,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 583,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2298,
        imgId: 1747,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1191.jpg",
        cropName: "20220430_1191.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:35:01.822+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "",
    prodDescription:
      "High Quality Elegant 6 MM 3-tone Gold Plated Bangle fashion jewellery Women daily wear trendy bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 502.0,
    cashPrice: 577.2125,
    taxAll: 50.025,
    taxInclude: 602.22504,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 583,
    p_adminid: 2,
    productCategory: { id: 584, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 584,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4411,
        lbrId: 907,
        price: 5.5,
        taxPercentage: 5.0,
        tax: 0.275,
        title: "Plating Tri Color",
      },
      {
        id: 4412,
        lbrId: 906,
        price: 6.5,
        taxPercentage: 5.0,
        tax: 0.325,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4413,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4414,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4415,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4416,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4417,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4418,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 584, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 584,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 584,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 584,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2299,
        imgId: 1776,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/a109a1266bd06bf697ecfe7a1dd10a42.jpg",
        cropName: "a109a1266bd06bf697ecfe7a1dd10a42.jpg",
      },
      {
        id: 2300,
        imgId: 1805,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/28e63a9e7ff14b8c6955dd366a9d7491.jpg",
        cropName: "28e63a9e7ff14b8c6955dd366a9d7491.jpg",
      },
      {
        id: 2301,
        imgId: 1804,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8984374025792508.jpg",
        cropName: "8984374025792508.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:40:53.779+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Punching Jewelry Set Gold Plated Adjustable Brass Bangle Women Bracelet Gifts to Girlfriend.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 531.85,
    cashPrice: 610.1315,
    taxAll: 50.193,
    taxInclude: 635.22797,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 584,
    p_adminid: 2,
    productCategory: { id: 585, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 585,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4419,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4420,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4421,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4422,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4423,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4424,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 585, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 585,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 585,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 585,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2302,
        imgId: 1790,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/a-labilir-dubai-alt-n-bilezik-64-10mm-geni11.jpg",
        cropName: "a-labilir-dubai-alt-n-bilezik-64-10mm-geni11.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:42:27.089+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Designer Gold plated 4pc Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 240.25,
    cashPrice: 282.08148,
    taxAll: 35.613003,
    taxInclude: 299.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 585,
    p_adminid: 2,
    productCategory: { id: 586, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 586,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4425,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4426,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4427,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4428,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4429,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4430,
        lbrId: 1172,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Half Round",
      },
    ],
    productSets: { id: 586, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 586,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 586,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 586,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2303,
        imgId: 1789,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/151661990307638015-gold-plated-bangle.jpg",
        cropName: "151661990307638015-gold-plated-bangle.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:44:51.525+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Bangles Designer Gold plated Trendy Pure Brass Broad Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 139.65,
    cashPrice: 163.82098,
    taxAll: 20.411999,
    taxInclude: 174.027,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 586,
    p_adminid: 2,
    productCategory: { id: 587, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 587,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4431,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4432,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4433,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4434,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4435,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4436,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 587, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 587,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 587,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 587,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2304,
        imgId: 1794,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/forming-look-bangles-500x500.jpg",
        cropName: "forming-look-bangles-500x500.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-02T14:46:11.600+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Bracelets bangles Designer Gold plated Trendy Pure Brass Indian Bangles Set jewellery for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 240.25,
    cashPrice: 282.08148,
    taxAll: 35.613003,
    taxInclude: 299.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 587,
    p_adminid: 2,
    productCategory: { id: 588, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 588,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4437,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4438,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4439,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4440,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4441,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4442,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 588, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 588,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 588,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 588,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2305,
        imgId: 1795,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/4770092049371.jpg",
        cropName: "4770092049371.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:26:02.944+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Gold plated 4pc Trendy Pure Brass Antique Bangles Set jewellery with best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 239.05,
    cashPrice: 280.7315,
    taxAll: 35.553,
    taxInclude: 298.50803,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 588,
    p_adminid: 2,
    productCategory: { id: 589, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 589,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4443,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4444,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4445,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4446,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4447,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4448,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 589, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 589,
      adminId: 2,
      boxId: 76,
      brandName: "H5 - My Bangles 24",
      boxDesc: "12 Sets In 1 Box",
      pkgCharge: 12.0,
      box: "12",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 589,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 589,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2306,
        imgId: 1796,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/3705364294579796.jpg",
        cropName: "3705364294579796.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:27:20.293+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Manufacture, Wholesale and Exporter women's brass gold women bracelet bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 466.44998,
    cashPrice: 547.5995,
    taxAll: 69.009,
    taxInclude: 582.104,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 589,
    p_adminid: 2,
    productCategory: { id: 590, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 590,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4449,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4450,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4451,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4452,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4453,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4454,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 590, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 590,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 590,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 590,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2307,
        imgId: 1797,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/5c20693eab8b0f6487d29a2f9b9aa286.jpg",
        cropName: "5c20693eab8b0f6487d29a2f9b9aa286.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:32:03.848+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Personalized custom bracelet customized name jewellery bangles gold plated jewellery manufacture.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 239.05,
    cashPrice: 280.7315,
    taxAll: 35.553,
    taxInclude: 298.50803,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 590,
    p_adminid: 2,
    productCategory: { id: 591, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 591,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4455,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4456,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4457,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4458,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4459,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4460,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 591, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 591,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 591,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 591,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2308,
        imgId: 1799,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/79214747292541403.jpg",
        cropName: "79214747292541403.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:33:26.105+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "6mm Wide Textured Gold Plated Bangles different design Pattern Bangle Jewelry Women's.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 236.65001,
    cashPrice: 278.0315,
    taxAll: 35.433002,
    taxInclude: 295.74802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 591,
    p_adminid: 2,
    productCategory: { id: 592, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 592,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4461,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4462,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4463,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4464,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4465,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4466,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 592, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 592,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 592,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 592,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2309,
        imgId: 1806,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/43239271812856010144.jpg",
        cropName: "43239271812856010144.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:34:39.366+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "fashion gold plated women different design simple bracelets bangles brass bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 236.65,
    cashPrice: 278.0315,
    taxAll: 35.433002,
    taxInclude: 295.74802,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 592,
    p_adminid: 2,
    productCategory: { id: 593, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 593,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4467,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4468,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4469,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4470,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4471,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4472,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 593, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 593,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 593,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 593,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2310,
        imgId: 1815,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/980616575606377.jpg",
        cropName: "980616575606377.jpg",
      },
      {
        id: 2311,
        imgId: 1814,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/774e5fc798490437ee6f19a90ecf3edd.jpg",
        cropName: "774e5fc798490437ee6f19a90ecf3edd.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:36:17.894+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Hot Sale Simple Fashion Women Bangle Indian gold plated bangles for Indian traditional party bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 240.25,
    cashPrice: 282.08148,
    taxAll: 35.613003,
    taxInclude: 299.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 593,
    p_adminid: 2,
    productCategory: { id: 594, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 594,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4473,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4474,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4475,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4476,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4477,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4478,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 594, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 594,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 594,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 594,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2312,
        imgId: 1817,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/96720915621939896.jpg",
        cropName: "96720915621939896.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:37:53.965+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "Personality Creative Gold Plated Bracelet Brass Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 392.65,
    cashPrice: 460.8935,
    taxAll: 57.957,
    taxInclude: 489.87198,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 594,
    p_adminid: 2,
    productCategory: { id: 595, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 595,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4479,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4480,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4481,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4482,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4483,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4484,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 595, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 595,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 595,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 595,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2313,
        imgId: 1834,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6088056515132.jpg",
        cropName: "6088056515132.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:39:18.356+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "",
    prodDescription:
      "Gold Plated Bangles Design & Bracelets Online Best Price.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 240.25,
    cashPrice: 282.08148,
    taxAll: 35.613003,
    taxInclude: 299.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 595,
    p_adminid: 2,
    productCategory: { id: 596, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 596,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4491,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4492,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4493,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4494,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4495,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4496,
        lbrId: 1205,
        price: 7.5,
        taxPercentage: 5.0,
        tax: 0.375,
        title: "Plating Micro Gold\r\n",
      },
    ],
    productSets: { id: 596, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 596,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 596,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 596,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2315,
        imgId: 1819,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/988543893025152859.jpg",
        cropName: "988543893025152859.jpg",
      },
    ],
    modifyDate: "2023-09-04T05:56:10.615+00:00",
    createDate: "2023-09-04T05:55:21.206+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Gold Plated Bracelets for Women Wedding Gold Plated Jewellery High-Quality Gold Plated Bracelets brass bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 334.5,
    cashPrice: 384.61502,
    taxAll: 33.33,
    taxInclude: 401.28003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 596,
    p_adminid: 2,
    productCategory: { id: 597, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 597,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4497,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4498,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4499,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4500,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4501,
        lbrId: 1205,
        price: 7.5,
        taxPercentage: 5.0,
        tax: 0.375,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 4502,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4503,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 597, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 597,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 597,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 597,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2316,
        imgId: 1839,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/e0750b327747829915a872ba076c5a45.jpg",
        cropName: "e0750b327747829915a872ba076c5a45.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T05:57:11.534+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription: "Glossy gold plated Brass bracelet Open Bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 652.5,
    cashPrice: 750.685,
    taxAll: 65.869995,
    taxInclude: 783.62,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 597,
    p_adminid: 2,
    productCategory: { id: 598, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 598,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4504,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4505,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4506,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4507,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4508,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4509,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 598, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 598,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 598,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 598,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2317,
        imgId: 1845,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/6494071314946672144.jpg",
        cropName: "6494071314946672144.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:09:20.970+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Gold Plated Jewellery High-Quality Integrated Classic brass bangles For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 339.25,
    cashPrice: 398.25348,
    taxAll: 50.157,
    taxInclude: 423.332,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 598,
    p_adminid: 2,
    productCategory: { id: 599, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 599,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4510,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4511,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4512,
        lbrId: 1177,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Dell",
      },
      {
        id: 4513,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4514,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4515,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 599, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 599,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 599,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 599,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2318,
        imgId: 1847,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/393266316688986.jpg",
        cropName: "393266316688986.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:17:51.260+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "New Fashion Gold Color Wedding Bangles for Women Bride Can OPen Bracelets Indian/ Ethiopian/ France/ African/ Dubai Jewelry gifts.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 220.65,
    cashPrice: 258.94348,
    taxAll: 32.456997,
    taxInclude: 275.172,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 599,
    p_adminid: 2,
    productCategory: { id: 600, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 600,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4516,
        lbrId: 1168,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Solder",
      },
      {
        id: 4517,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4518,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4519,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4520,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4521,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4522,
        lbrId: 1207,
        price: 8.0,
        taxPercentage: 5.0,
        tax: 0.4,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 600, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 600,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 600,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 600,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2319,
        imgId: 1849,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/il_794xN.2243644182_8o4k.webp",
        cropName: "il_794xN.2243644182_8o4k.webp",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:24:54.014+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "European and American Bangle Charms Wholesale Three Colors Zirconia Jewelry Set Bracelets & Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 524.5,
    cashPrice: 602.525,
    taxAll: 51.15,
    taxInclude: 628.10004,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 600,
    p_adminid: 2,
    productCategory: { id: 601, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 601,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4523,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4524,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4525,
        lbrId: 1175,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Buffing",
      },
      {
        id: 4526,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4527,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4528,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 601, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 601,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 601,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 601,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2320,
        imgId: 1850,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/image_zoom.jpeg",
        cropName: "image_zoom.jpeg",
      },
      {
        id: 2321,
        imgId: 1851,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG_4605_zoom.jpg",
        cropName: "IMG_4605_zoom.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:26:33.699+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Designer Gold plated 4pc Trendy side veny Pure Brass Antique Bangles Set Dubai jewellery for women with best-price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 366.5,
    cashPrice: 428.935,
    taxAll: 51.57,
    taxInclude: 454.72,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 601,
    p_adminid: 2,
    productCategory: { id: 602, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 602,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4541,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4542,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4543,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4544,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4545,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4546,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 602, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 602,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 602,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 602,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2334,
        imgId: 1942,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-312.jpg",
        cropName: "20151222-312.jpg",
      },
      {
        id: 2335,
        imgId: 1944,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-621.jpg",
        cropName: "201512222-621.jpg",
      },
      {
        id: 2336,
        imgId: 1945,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-626.jpg",
        cropName: "20151222-626.jpg",
      },
      {
        id: 2337,
        imgId: 1947,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-630.jpg",
        cropName: "201512222-630.jpg",
      },
      {
        id: 2338,
        imgId: 1943,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-555.jpg",
        cropName: "20151222-555.jpg",
      },
      {
        id: 2339,
        imgId: 1948,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-662.jpg",
        cropName: "20151222-662.jpg",
      },
    ],
    modifyDate: "2023-09-04T06:33:56.881+00:00",
    createDate: "2023-09-04T06:31:06.712+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 358.5,
    cashPrice: 419.935,
    taxAll: 51.17,
    taxInclude: 445.52002,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 602,
    p_adminid: 2,
    productCategory: { id: 603, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 603,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4535,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4536,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4537,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4538,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4539,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4540,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 603, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 603,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 603,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 603,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2328,
        imgId: 1949,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-669.jpg",
        cropName: "20151222-669.jpg",
      },
      {
        id: 2329,
        imgId: 1950,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-675.jpg",
        cropName: "20151222-675.jpg",
      },
      {
        id: 2330,
        imgId: 1951,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-680.jpg",
        cropName: "201512222-680.jpg",
      },
      {
        id: 2331,
        imgId: 1952,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-682.jpg",
        cropName: "201512222-682.jpg",
      },
      {
        id: 2332,
        imgId: 1953,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-691.jpg",
        cropName: "20151222-691.jpg",
      },
      {
        id: 2333,
        imgId: 1954,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-692.jpg",
        cropName: "20151222-692.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:32:35.127+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 358.5,
    cashPrice: 419.935,
    taxAll: 51.17,
    taxInclude: 445.52002,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 603,
    p_adminid: 2,
    productCategory: { id: 604, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 604,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4547,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4548,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4549,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4550,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4551,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4552,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 604, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 604,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 604,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 604,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2340,
        imgId: 1946,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-627.jpg",
        cropName: "20151222-627.jpg",
      },
      {
        id: 2341,
        imgId: 1957,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-778.jpg",
        cropName: "20151222-778.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:35:05.450+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6 MM",
    prodManfDetails: "6",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 189.5,
    cashPrice: 221.48999,
    taxAll: 26.080002,
    taxInclude: 234.53,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 604,
    p_adminid: 2,
    productCategory: { id: 605, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 605,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4553,
        lbrId: 3323,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Pipe Cutting",
      },
      {
        id: 4554,
        lbrId: 3330,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Micro Dell",
      },
      {
        id: 4555,
        lbrId: 3328,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 4556,
        lbrId: 3341,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4557,
        lbrId: 3354,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating Gold Plated",
      },
      {
        id: 4558,
        lbrId: 3364,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 605, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 605,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 605,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 605,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2342,
        imgId: 1956,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-694.jpg",
        cropName: "20151222-694.jpg",
      },
      {
        id: 2343,
        imgId: 1955,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-693.jpg",
        cropName: "20151222-693.jpg",
      },
      {
        id: 2344,
        imgId: 1958,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1104.jpg",
        cropName: "20151222-1104.jpg",
      },
      {
        id: 2345,
        imgId: 1959,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1106.jpg",
        cropName: "20151222-1106.jpg",
      },
      {
        id: 2346,
        imgId: 1960,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1107.jpg",
        cropName: "20151222-1107.jpg",
      },
      {
        id: 2347,
        imgId: 1962,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1112.jpg",
        cropName: "20151222-1112.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:37:24.485+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 306.5,
    cashPrice: 361.435,
    taxAll: 48.57,
    taxInclude: 385.72,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 605,
    p_adminid: 2,
    productCategory: { id: 606, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 606,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4559,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4560,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4561,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4562,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4563,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4564,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 606, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 606,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 606,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 606,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2348,
        imgId: 1961,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1111.jpg",
        cropName: "20151222-1111.jpg",
      },
      {
        id: 2349,
        imgId: 1965,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1118.jpg",
        cropName: "20151222-1118.jpg",
      },
      {
        id: 2350,
        imgId: 1973,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1132.jpg",
        cropName: "20151222-1132.jpg",
      },
      {
        id: 2351,
        imgId: 1966,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1119.jpg",
        cropName: "20151222-1119.jpg",
      },
      {
        id: 2352,
        imgId: 1972,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1131.jpg",
        cropName: "20151222-1131.jpg",
      },
      {
        id: 2353,
        imgId: 1967,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-1120.jpg",
        cropName: "201512222-1120.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:41:12.282+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 358.5,
    cashPrice: 419.935,
    taxAll: 51.17,
    taxInclude: 445.52002,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 606,
    p_adminid: 2,
    productCategory: { id: 607, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 607,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 8703,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 8704,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8705,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8706,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8707,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 8708,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 607, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 607,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 607,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 607,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4113,
        imgId: 1979,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160715-503.jpg",
        cropName: "20160715-503.jpg",
      },
      {
        id: 4114,
        imgId: 1978,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1139.jpg",
        cropName: "20151222-1139.jpg",
      },
      {
        id: 4115,
        imgId: 1977,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1137.jpg",
        cropName: "20151222-1137.jpg",
      },
      {
        id: 4116,
        imgId: 1975,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1134.jpg",
        cropName: "20151222-1134.jpg",
      },
      {
        id: 4117,
        imgId: 1974,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1133.jpg",
        cropName: "20151222-1133.jpg",
      },
    ],
    modifyDate: "2023-09-16T08:52:53.245+00:00",
    createDate: "2023-09-04T06:43:21.917+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 358.5,
    cashPrice: 419.935,
    taxAll: 51.17,
    taxInclude: 445.52002,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 607,
    p_adminid: 2,
    productCategory: { id: 608, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 608,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4571,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4572,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4573,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4574,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4575,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4576,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 608, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 608,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 608,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 608,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2360,
        imgId: 1970,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1124.jpg",
        cropName: "20151222-1124.jpg",
      },
      {
        id: 2361,
        imgId: 1971,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1128.jpg",
        cropName: "20151222-1128.jpg",
      },
      {
        id: 2362,
        imgId: 1968,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-1121.jpg",
        cropName: "201512222-1121.jpg",
      },
      {
        id: 2363,
        imgId: 1969,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1123.jpg",
        cropName: "20151222-1123.jpg",
      },
      {
        id: 2364,
        imgId: 1963,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1116.jpg",
        cropName: "20151222-1116.jpg",
      },
      {
        id: 2365,
        imgId: 1962,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1112.jpg",
        cropName: "20151222-1112.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:45:27.470+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 358.5,
    cashPrice: 419.935,
    taxAll: 51.17,
    taxInclude: 445.52002,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 608,
    p_adminid: 2,
    productCategory: { id: 609, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 609,
      adminId: 2,
      parentId: 115,
      categoryId: 11,
      title: "6MM",
    },
    productChild: [
      {
        id: 4577,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4578,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4579,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4580,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4581,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4582,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 609, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 609,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 609,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 609,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2366,
        imgId: 1976,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1136.jpg",
        cropName: "20151222-1136.jpg",
      },
      {
        id: 2367,
        imgId: 1964,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1117.jpg",
        cropName: "20151222-1117.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:46:38.536+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 358.5,
    cashPrice: 419.935,
    taxAll: 51.17,
    taxInclude: 445.52002,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 609,
    p_adminid: 2,
    productCategory: { id: 610, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 610,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 4589,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 4590,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4591,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4592,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4593,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4594,
        lbrId: 576,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 610, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 610,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 610,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 610,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2373,
        imgId: 1866,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611494.jpeg",
        cropName: "20230901611494.jpeg",
      },
      {
        id: 2374,
        imgId: 1860,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161149.jpeg",
        cropName: "2023090161149.jpeg",
      },
      {
        id: 2375,
        imgId: 1861,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161150.jpeg",
        cropName: "2023090161150.jpeg",
      },
      {
        id: 2376,
        imgId: 1862,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161151.jpeg",
        cropName: "2023090161151.jpeg",
      },
      {
        id: 2377,
        imgId: 1868,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611515.jpeg",
        cropName: "20230901611515.jpeg",
      },
      {
        id: 2378,
        imgId: 1867,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611505.jpeg",
        cropName: "20230901611505.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T06:56:22.520+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "6MM",
    prodManfDetails: "6",
    prodDescription:
      "Hot Sale Simple Fashion Women golwire Bangle Indian gold plated brass bangles for Indian traditional party bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 399.65002,
    cashPrice: 470.67554,
    taxAll: 62.121002,
    taxInclude: 501.73602,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 610,
    p_adminid: 2,
    productCategory: { id: 611, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 611,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 4595,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 4596,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4597,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4598,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4599,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4600,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 611, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 611,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 611,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 611,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2379,
        imgId: 1880,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611446.jpeg",
        cropName: "20230901611446.jpeg",
      },
      {
        id: 2380,
        imgId: 1881,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611452.jpeg",
        cropName: "20230901611452.jpeg",
      },
      {
        id: 2381,
        imgId: 1872,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161141.jpeg",
        cropName: "2023090161141.jpeg",
      },
      {
        id: 2382,
        imgId: 1873,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161142.jpeg",
        cropName: "2023090161142.jpeg",
      },
      {
        id: 2383,
        imgId: 1874,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161143.jpeg",
        cropName: "2023090161143.jpeg",
      },
      {
        id: 2384,
        imgId: 1875,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161144.jpeg",
        cropName: "2023090161144.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T07:04:08.873+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Trendy 3 MM Gold Plated Textured Brass Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 402.25,
    cashPrice: 474.2255,
    taxAll: 63.501003,
    taxInclude: 505.976,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 611,
    p_adminid: 2,
    productCategory: { id: 612, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 612,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 4601,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 4602,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4603,
        lbrId: 778,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 4604,
        lbrId: 788,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4605,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 4606,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 612, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 612,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 612,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 612,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2385,
        imgId: 1888,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161229.jpeg",
        cropName: "2023090161229.jpeg",
      },
      {
        id: 2386,
        imgId: 1887,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161228.jpeg",
        cropName: "2023090161228.jpeg",
      },
      {
        id: 2387,
        imgId: 1883,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161221.jpeg",
        cropName: "2023090161221.jpeg",
      },
      {
        id: 2388,
        imgId: 1884,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161222.jpeg",
        cropName: "2023090161222.jpeg",
      },
      {
        id: 2389,
        imgId: 1885,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161224.jpeg",
        cropName: "2023090161224.jpeg",
      },
      {
        id: 2390,
        imgId: 1886,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161226.jpeg",
        cropName: "2023090161226.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T07:07:07.988+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription:
      "Classic Gold Plated  Bracelet Brass Plain Bangles Charm Bracelets.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 402.25,
    cashPrice: 474.2255,
    taxAll: 63.501003,
    taxInclude: 505.976,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 208.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 612,
    p_adminid: 2,
    productCategory: { id: 613, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 613,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4607,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4608,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4609,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4610,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4611,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 4612,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 613, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 613,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 613,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 613,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2391,
        imgId: 1920,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-650.jpg",
        cropName: "20151222-650.jpg",
      },
      {
        id: 2392,
        imgId: 1915,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-615.jpg",
        cropName: "20151222-615.jpg",
      },
      {
        id: 2393,
        imgId: 1910,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-605.jpg",
        cropName: "201512222-605.jpg",
      },
      {
        id: 2394,
        imgId: 1911,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2015122212-608.jpg",
        cropName: "2015122212-608.jpg",
      },
      {
        id: 2395,
        imgId: 1912,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-607.jpg",
        cropName: "20151222-607.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:05:01.469+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 181.5,
    cashPrice: 212.48999,
    taxAll: 25.68,
    taxInclude: 225.32999,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 613,
    p_adminid: 2,
    productCategory: { id: 614, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 614,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4613,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4614,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4615,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4616,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4617,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 4618,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 614, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 614,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 614,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 614,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2396,
        imgId: 1913,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-609.jpg",
        cropName: "201512222-609.jpg",
      },
      {
        id: 2397,
        imgId: 1907,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-428.jpg",
        cropName: "20151222-428.jpg",
      },
      {
        id: 2398,
        imgId: 1908,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-432.jpg",
        cropName: "20151222-432.jpg",
      },
      {
        id: 2399,
        imgId: 1909,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-434.jpg",
        cropName: "20151222-434.jpg",
      },
      {
        id: 2400,
        imgId: 1914,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-612.jpg",
        cropName: "20151222-612.jpg",
      },
      {
        id: 2401,
        imgId: 1916,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-616.jpg",
        cropName: "20151222-616.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:07:17.819+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 342.5,
    cashPrice: 401.935,
    taxAll: 50.37,
    taxInclude: 427.12,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 614,
    p_adminid: 2,
    productCategory: { id: 615, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 615,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4619,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4620,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4621,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4622,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4623,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 4624,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 615, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 615,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 615,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 615,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2402,
        imgId: 1921,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-664.jpg",
        cropName: "20151222-664.jpg",
      },
      {
        id: 2403,
        imgId: 1918,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-620.jpg",
        cropName: "20151222-620.jpg",
      },
      {
        id: 2404,
        imgId: 1917,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-618.jpg",
        cropName: "20151222-618.jpg",
      },
      {
        id: 2405,
        imgId: 1919,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-641.jpg",
        cropName: "20151222-641.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:10:02.740+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 340.5,
    cashPrice: 399.555,
    taxAll: 50.01,
    taxInclude: 424.56,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 615,
    p_adminid: 2,
    productCategory: { id: 616, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 616,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 4625,
        lbrId: 865,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4626,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4627,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 4628,
        lbrId: 886,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4629,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 4630,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 616, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 616,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 616,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 616,
      adminId: 2,
      packingmaterialId: 114,
      type: "Polythene PP",
      pkgdesc: "8 Pcs 3MM 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2406,
        imgId: 1922,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-684.jpg",
        cropName: "20151222-684.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:11:17.735+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 654.5,
    cashPrice: 768.925,
    taxAll: 97.95,
    taxInclude: 817.9,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 125.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 616,
    p_adminid: 2,
    productCategory: { id: 617, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 617,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4631,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 4632,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4633,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4634,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4635,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4636,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 617, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 617,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 617,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 617,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2407,
        imgId: 1894,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611483.jpeg",
        cropName: "20230901611483.jpeg",
      },
      {
        id: 2408,
        imgId: 1889,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161146.jpeg",
        cropName: "2023090161146.jpeg",
      },
      {
        id: 2409,
        imgId: 1890,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161145.jpeg",
        cropName: "2023090161145.jpeg",
      },
      {
        id: 2410,
        imgId: 1891,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161147.jpeg",
        cropName: "2023090161147.jpeg",
      },
      {
        id: 2411,
        imgId: 1892,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161148.jpeg",
        cropName: "2023090161148.jpeg",
      },
      {
        id: 2412,
        imgId: 1893,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611472.jpeg",
        cropName: "20230901611472.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:13:23.826+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Manufacturer fashion jewellery open adjustable design brass bangle yellow gold plated bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 226.85,
    cashPrice: 266.68152,
    taxAll: 34.293,
    taxInclude: 283.828,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 617,
    p_adminid: 2,
    productCategory: { id: 618, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 618,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4637,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 4638,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4639,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4640,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4641,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4642,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 618, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 618,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 618,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 618,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2413,
        imgId: 1899,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161237.jpeg",
        cropName: "2023090161237.jpeg",
      },
      {
        id: 2414,
        imgId: 1900,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611525.jpeg",
        cropName: "20230901611525.jpeg",
      },
      {
        id: 2415,
        imgId: 1895,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161155.jpeg",
        cropName: "2023090161155.jpeg",
      },
      {
        id: 2416,
        imgId: 1896,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161230.jpeg",
        cropName: "2023090161230.jpeg",
      },
      {
        id: 2417,
        imgId: 1897,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161232.jpeg",
        cropName: "2023090161232.jpeg",
      },
      {
        id: 2418,
        imgId: 1898,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161234.jpeg",
        cropName: "2023090161234.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:14:44.136+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "High-Quality Bracelet for Women Easy Wear Gold Plated Brass Bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 226.85,
    cashPrice: 266.68152,
    taxAll: 34.293,
    taxInclude: 283.828,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 618,
    p_adminid: 2,
    productCategory: { id: 619, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 619,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4643,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 4644,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4645,
        lbrId: 978,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4646,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4647,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4648,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 619, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 619,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 619,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 619,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2419,
        imgId: 1905,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611575.jpeg",
        cropName: "20230901611575.jpeg",
      },
      {
        id: 2420,
        imgId: 1901,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611565.jpeg",
        cropName: "20230901611565.jpeg",
      },
      {
        id: 2421,
        imgId: 1902,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161157.jpeg",
        cropName: "2023090161157.jpeg",
      },
      {
        id: 2422,
        imgId: 1903,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023090161156.jpeg",
        cropName: "2023090161156.jpeg",
      },
      {
        id: 2423,
        imgId: 1904,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611555.jpeg",
        cropName: "20230901611555.jpeg",
      },
      {
        id: 2424,
        imgId: 1906,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230901611585.jpeg",
        cropName: "20230901611585.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:16:03.889+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Fantastic Design Brass bangle latest Superior Quality gold plated brass fashionable design customized size best price.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 226.85,
    cashPrice: 266.68152,
    taxAll: 34.293,
    taxInclude: 283.828,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 619,
    p_adminid: 2,
    productCategory: { id: 620, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 620,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4649,
        lbrId: 967,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4650,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4651,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4652,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4653,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4654,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 620, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 620,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 620,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 620,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2425,
        imgId: 1923,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-315.jpg",
        cropName: "20151222-315.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:25:32.914+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 185.5,
    cashPrice: 216.99,
    taxAll: 25.880001,
    taxInclude: 229.93001,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 620,
    p_adminid: 2,
    productCategory: { id: 621, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 621,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4655,
        lbrId: 967,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4656,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4657,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4658,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4659,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4660,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 621, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 621,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 621,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 621,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2426,
        imgId: 1933,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-665.jpg",
        cropName: "20151222-665.jpg",
      },
      {
        id: 2427,
        imgId: 1926,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-501.jpg",
        cropName: "20151222-501.jpg",
      },
      {
        id: 2428,
        imgId: 1925,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-431.jpg",
        cropName: "201512222-431.jpg",
      },
      {
        id: 2429,
        imgId: 1924,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-411.jpg",
        cropName: "20151222-411.jpg",
      },
      {
        id: 2430,
        imgId: 1931,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-663.jpg",
        cropName: "201512222-663.jpg",
      },
      {
        id: 2431,
        imgId: 1935,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-800.jpg",
        cropName: "20151222-800.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:27:14.594+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 350.5,
    cashPrice: 410.935,
    taxAll: 50.77,
    taxInclude: 436.31998,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 621,
    p_adminid: 2,
    productCategory: { id: 622, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 622,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4661,
        lbrId: 1166,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4662,
        lbrId: 1174,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4663,
        lbrId: 1176,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4664,
        lbrId: 1188,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4665,
        lbrId: 1200,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
      {
        id: 4666,
        lbrId: 1210,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4667,
        lbrId: 967,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4668,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4669,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4670,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4671,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4672,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 622, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 622,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 622,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 622,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2432,
        imgId: 1934,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-696.jpg",
        cropName: "20151222-696.jpg",
      },
      {
        id: 2433,
        imgId: 1936,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1105.jpg",
        cropName: "20151222-1105.jpg",
      },
      {
        id: 2434,
        imgId: 1937,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1113.jpg",
        cropName: "20151222-1113.jpg",
      },
      {
        id: 2435,
        imgId: 1938,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1114.jpg",
        cropName: "20151222-1114.jpg",
      },
      {
        id: 2436,
        imgId: 1939,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1129.jpg",
        cropName: "20151222-1129.jpg",
      },
      {
        id: 2437,
        imgId: 1940,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160715-1132.jpg",
        cropName: "20160715-1132.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:28:59.315+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 432.5,
    cashPrice: 503.185,
    taxAll: 54.87,
    taxInclude: 530.62,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 622,
    p_adminid: 2,
    productCategory: { id: 623, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 623,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4673,
        lbrId: 967,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4674,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4675,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4676,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4677,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4678,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 623, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 623,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 623,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 623,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2438,
        imgId: 2021,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-565.jpg",
        cropName: "201512222-565.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:29:49.557+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 350.5,
    cashPrice: 410.935,
    taxAll: 50.77,
    taxInclude: 436.31998,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 623,
    p_adminid: 2,
    productCategory: { id: 624, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 624,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 4679,
        lbrId: 967,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Pipe Cutting",
      },
      {
        id: 4680,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 4681,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 4682,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4683,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4684,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 4685,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 624, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 624,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 624,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 624,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2439,
        imgId: 1927,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-511.jpg",
        cropName: "20151222-511.jpg",
      },
      {
        id: 2440,
        imgId: 1928,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-519.jpg",
        cropName: "20151222-519.jpg",
      },
      {
        id: 2441,
        imgId: 1929,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-528.jpg",
        cropName: "20151222-528.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T08:31:37.579+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "5MM",
    prodManfDetails: "5",
    prodDescription:
      "Bangles Designer Gold plated bangles Trendy Pure Brass Antique Bangles Set Dubai jewellery for women with the best price Wristband.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 370.5,
    cashPrice: 433.435,
    taxAll: 51.77,
    taxInclude: 459.31998,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 624,
    p_adminid: 2,
    productCategory: { id: 625, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 625,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 4686,
        lbrId: 1366,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4687,
        lbrId: 1375,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4688,
        lbrId: 1376,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4689,
        lbrId: 1388,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4690,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4691,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 625, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 625,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 625,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 625,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2442,
        imgId: 1808,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/29-3-600x600.jpg",
        cropName: "29-3-600x600.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T09:13:01.224+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7MM",
    prodManfDetails: "7",
    prodDescription:
      "Charming Beautiful Design Gold Plated Bangle For Lady's Jewelry Gift/Party.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 114.5,
    cashPrice: 133.44249,
    taxAll: 14.985,
    taxInclude: 140.935,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 625,
    p_adminid: 2,
    productCategory: { id: 626, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 626,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 4692,
        lbrId: 1366,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4693,
        lbrId: 1375,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4694,
        lbrId: 1376,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4695,
        lbrId: 1388,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4696,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4697,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 626, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 626,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 626,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 626,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2443,
        imgId: 1825,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/51F1KPVDN1A00_1.jpg",
        cropName: "51F1KPVDN1A00_1.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T09:15:14.541+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7MM",
    prodManfDetails: "7",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 114.5,
    cashPrice: 133.44249,
    taxAll: 14.985,
    taxInclude: 140.935,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 626,
    p_adminid: 2,
    productCategory: { id: 627, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 627,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 4698,
        lbrId: 1366,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4699,
        lbrId: 1367,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 4700,
        lbrId: 1374,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4701,
        lbrId: 1376,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4702,
        lbrId: 1384,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 4703,
        lbrId: 1388,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4704,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4705,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 627, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 627,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 627,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 627,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2444,
        imgId: 1827,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/92ec19b24d4410f593281bec928d3c14.jpg",
        cropName: "92ec19b24d4410f593281bec928d3c14.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T09:20:17.033+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7MM",
    prodManfDetails: "7",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 140.0,
    cashPrice: 162.13,
    taxAll: 16.26,
    taxInclude: 170.26,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 627,
    p_adminid: 2,
    productCategory: { id: 628, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 628,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 4706,
        lbrId: 1366,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4707,
        lbrId: 1374,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4708,
        lbrId: 1376,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4709,
        lbrId: 1367,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 4710,
        lbrId: 1384,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 4711,
        lbrId: 1388,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4712,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4713,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 628, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 628,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 628,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 628,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2445,
        imgId: 1838,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/e2f3f46f9b32c2908589b1bb28bc8bb9.jpg",
        cropName: "e2f3f46f9b32c2908589b1bb28bc8bb9.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T09:21:52.733+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7MM",
    prodManfDetails: "7",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 135.0,
    cashPrice: 156.18,
    taxAll: 15.360001,
    taxInclude: 163.86,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 628,
    p_adminid: 2,
    productCategory: { id: 629, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 629,
      adminId: 2,
      parentId: 119,
      categoryId: 11,
      title: "7MM",
    },
    productChild: [
      {
        id: 4714,
        lbrId: 1366,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4715,
        lbrId: 1376,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4716,
        lbrId: 1374,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4717,
        lbrId: 1388,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4718,
        lbrId: 1400,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4719,
        lbrId: 1410,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 629, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 629,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 629,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 629,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2446,
        imgId: 1852,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/LdEz89B9WwHWMHHL8x30zoSTfyvNbxE3GNcUioDl.jpeg",
        cropName: "LdEz89B9WwHWMHHL8x30zoSTfyvNbxE3GNcUioDl.jpeg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T09:22:55.417+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7MM",
    prodManfDetails: "7",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 116.0,
    cashPrice: 135.13,
    taxAll: 15.059999,
    taxInclude: 142.66,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 629,
    p_adminid: 2,
    productCategory: { id: 630, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 630,
      adminId: 2,
      parentId: 122,
      categoryId: 11,
      title: "7.8MM",
    },
    productChild: [
      {
        id: 4760,
        lbrId: 1518,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Cutting",
      },
      {
        id: 4761,
        lbrId: 1525,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Dell",
      },
      {
        id: 4762,
        lbrId: 1523,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Acid Double & Putas",
      },
      {
        id: 4763,
        lbrId: 1536,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4764,
        lbrId: 1555,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4765,
        lbrId: 1558,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 630, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 630,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 630,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 630,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2457,
        imgId: 1750,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1100.jpg",
        cropName: "20220430_1100.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T11:14:43.114+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "7.8mm",
    prodManfDetails: "7",
    prodDescription:
      "Fancy Style 7.8 MM Three Tone Plated die cutting Bangle fashion jewellery for women to use daily.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 125.05,
    cashPrice: 147.68451,
    taxAll: 20.259,
    taxInclude: 157.81401,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 630,
    p_adminid: 2,
    productCategory: { id: 631, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 631,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4766,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4767,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4768,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4769,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4770,
        lbrId: 1605,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4771,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 631, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 631,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 631,
      adminId: 2,
      rawpriceId: 187,
      title: "Golwire Plus 100 Thaw",
      price: 610.0,
      different: 0.0,
      taxPercentage: 18.0,
      tax: 109.8,
    },
    productPacking: {
      id: 631,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2458,
        imgId: 1751,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1108.jpg",
        cropName: "20220430_1108.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T11:20:20.247+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "We are Manufacture and Export Fancy Style 3-Tone Plated brass Bangle fashion jewellery for women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 601.0,
    cashPrice: 688.49,
    taxAll: 54.78,
    taxInclude: 715.88,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 631,
    p_adminid: 2,
    productCategory: { id: 632, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 632,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4772,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4773,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4774,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4775,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4776,
        lbrId: 1605,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4777,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 632, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 632,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 632,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 632,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2459,
        imgId: 1752,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1119.jpg",
        cropName: "20220430_1119.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T11:26:25.858+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "6 MM 3-tone Gold plated imitation classic and super trendy modern latest design for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 408.5,
    cashPrice: 467.865,
    taxAll: 37.03,
    taxInclude: 486.38,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 632,
    p_adminid: 2,
    productCategory: { id: 633, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 633,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4778,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4779,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4780,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4781,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4782,
        lbrId: 1605,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4783,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 633, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 633,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 633,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 633,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2460,
        imgId: 1769,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/286.jpg",
        cropName: "286.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T11:28:07.720+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "8 MM 3-tone Gold plated imitation classic and super trendy modern latest design for women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 408.5,
    cashPrice: 467.865,
    taxAll: 37.03,
    taxInclude: 486.38,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 633,
    p_adminid: 2,
    productCategory: { id: 634, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 634,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4784,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4785,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4786,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4787,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4788,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4789,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 634, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 634,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 634,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 634,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2461,
        imgId: 1786,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_223005_Richtone(HDR).jpg",
        cropName: "20190821_223005_Richtone(HDR).jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T11:31:38.213+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Designer Brass Bangle Latest Brass Bangle Bangles Plain Brass Bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 222.65,
    cashPrice: 261.19348,
    taxAll: 32.557,
    taxInclude: 277.472,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 634,
    p_adminid: 2,
    productCategory: { id: 635, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 635,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4790,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4791,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4792,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4793,
        lbrId: 1582,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 4794,
        lbrId: 1565,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 4795,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4796,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 635, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 635,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 635,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 635,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2462,
        imgId: 1788,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160809123813_Treasure_fk_.jpg",
        cropName: "20160809123813_Treasure_fk_.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:01:01.084+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 241.5,
    cashPrice: 279.99,
    taxAll: 28.68,
    taxInclude: 294.33,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 635,
    p_adminid: 2,
    productCategory: { id: 636, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 636,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4797,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4798,
        lbrId: 1565,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Guru",
      },
      {
        id: 4799,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4800,
        lbrId: 1582,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Hole",
      },
      {
        id: 4801,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4802,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4803,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 636, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 636,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 636,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 636,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2463,
        imgId: 1809,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/29-3-600x600.jpg",
        cropName: "29-3-600x600.jpg",
      },
      {
        id: 2464,
        imgId: 1828,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/92ec19b24d4410f593281bec928d3c14.jpg",
        cropName: "92ec19b24d4410f593281bec928d3c14.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:02:34.911+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 138.5,
    cashPrice: 160.4425,
    taxAll: 16.185,
    taxInclude: 168.535,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 636,
    p_adminid: 2,
    productCategory: { id: 637, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 637,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4804,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4805,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4806,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4807,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4808,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4809,
        lbrId: 1603,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 4810,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 637, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 637,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 637,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 637,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2465,
        imgId: 1800,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/958746006.jpg",
        cropName: "958746006.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:08:58.231+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Trendy 2 pcs Gold Plated Openable Bangles Women  Bride Bangles Jewellery.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 441.65,
    cashPrice: 507.5685,
    taxAll: 43.506996,
    taxInclude: 529.322,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 637,
    p_adminid: 2,
    productCategory: { id: 638, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 638,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4811,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4812,
        lbrId: 1570,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 4813,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4814,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4815,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4816,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4817,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 638, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 638,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 638,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 638,
      adminId: 2,
      packingmaterialId: 88,
      type: "Crystal Dibbi",
      pkgdesc: "H6",
      hsncode: "39231000",
      price: 4.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2466,
        imgId: 1801,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8b9c9bb91a0a769482df58cbb1bea2b3.jpg",
        cropName: "8b9c9bb91a0a769482df58cbb1bea2b3.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:10:38.979+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Golden Bracelets for Women Dubai Bracelet bangles Middle East African Bride Wedding Jewelry Gifts.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 372.85,
    cashPrice: 436.2485,
    taxAll: 52.227,
    taxInclude: 462.362,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 638,
    p_adminid: 2,
    productCategory: { id: 639, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 639,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4818,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4819,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4820,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4821,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4822,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4823,
        lbrId: 1605,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 639, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 639,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 639,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 639,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2467,
        imgId: 1802,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/7c4990d3d500938dffac992287928e27.jpg",
        cropName: "7c4990d3d500938dffac992287928e27.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:12:40.126+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Brass Bangles 3 Tone Colour Plated Gold Rhodium And Rose Gold Open Type Broad High-Quality Bangle 2 Piece Set.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 404.5,
    cashPrice: 463.36502,
    taxAll: 36.83,
    taxInclude: 481.78003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 639,
    p_adminid: 2,
    productCategory: { id: 640, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 640,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4824,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4825,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4826,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4827,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4828,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4829,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 640, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 640,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 640,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 640,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2468,
        imgId: 1803,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/682119620040704289.jpg",
        cropName: "682119620040704289.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:14:30.622+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Gold plated angle minimalist Manufacture charms bangle gold Plated fashion jewellery bracelets bangles.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 247.45001,
    cashPrice: 290.18152,
    taxAll: 35.973003,
    taxInclude: 308.168,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 640,
    p_adminid: 2,
    productCategory: { id: 641, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 641,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4830,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4831,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4832,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4833,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4834,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4835,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 641, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 641,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 641,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 641,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2469,
        imgId: 1811,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/46dc5875e5ceb6fc47badd496e3eef4e.jpg",
        cropName: "46dc5875e5ceb6fc47badd496e3eef4e.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:16:51.743+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Gold plated Bangles Women Adjustable Bangle Wedding Engagement Jewelry Turkish Gold plated brass Bangles\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 139.85,
    cashPrice: 164.0095,
    taxAll: 20.349,
    taxInclude: 174.184,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 641,
    p_adminid: 2,
    productCategory: { id: 642, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 642,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4836,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4837,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4838,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4839,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4840,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4841,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 642, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 642,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 642,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 642,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2470,
        imgId: 1837,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/d1f3339be2290267f5087f023ad3f160.jpg",
        cropName: "d1f3339be2290267f5087f023ad3f160.jpg",
      },
      {
        id: 2471,
        imgId: 1836,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/c46c6febcf810c9c6b87d98728257104.jpg",
        cropName: "c46c6febcf810c9c6b87d98728257104.jpg",
      },
      {
        id: 2472,
        imgId: 1812,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/46f9d13ec21e8a226e02745d7669150e.jpg",
        cropName: "46f9d13ec21e8a226e02745d7669150e.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:18:52.900+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "This Bangle is also known as a Gold plate Filled Gram Punching Gold Plated Bangle.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 160.15001,
    cashPrice: 187.76451,
    taxAll: 23.199,
    taxInclude: 199.36401,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 642,
    p_adminid: 2,
    productCategory: { id: 643, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 643,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4842,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4843,
        lbrId: 1568,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 4844,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4845,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4846,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4847,
        lbrId: 1603,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 4848,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 643, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 643,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 643,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 643,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2473,
        imgId: 1816,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2456281bc1d3fef08905a28ba57e8ec7.jpg",
        cropName: "2456281bc1d3fef08905a28ba57e8ec7.jpg",
      },
      {
        id: 2474,
        imgId: 1846,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/931185859488054259.jpg",
        cropName: "931185859488054259.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:20:29.195+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Wholesale Manufacturer Jewelry Traditional Gold Tone Bracelet Bangle Bridal Wedding Fashion Jewelry For Women Fashion Jewelry.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 222.0,
    cashPrice: 254.38,
    taxAll: 20.36,
    taxInclude: 264.56,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 643,
    p_adminid: 2,
    productCategory: { id: 644, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 644,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4849,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4850,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4851,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4852,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4853,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4854,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 644, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 644,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 644,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 644,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2475,
        imgId: 1818,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/807123713144153.jpg",
        cropName: "807123713144153.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:22:25.608+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Bridal Bangle Ethnic Designer Gold Tone Indian Manufacturer Wholesale Jewellery Wedding Bangle Set For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 100.3,
    cashPrice: 117.635,
    taxAll: 14.610001,
    taxInclude: 124.94,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1429.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 644,
    p_adminid: 2,
    productCategory: { id: 645, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 645,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4855,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4856,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4857,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4858,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4859,
        lbrId: 1603,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 4860,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 645, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 645,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 645,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 645,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2476,
        imgId: 1820,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/988543893025152859.jpg",
        cropName: "988543893025152859.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:23:35.620+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Wholesale Manufacturer Jewelry Traditional Gold Tone Bracelet Bangle Bridal Wedding Fashion Jewelry For Women.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 408.5,
    cashPrice: 467.865,
    taxAll: 37.03,
    taxInclude: 486.38,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 645,
    p_adminid: 2,
    productCategory: { id: 646, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 646,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4861,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4862,
        lbrId: 1570,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 4863,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4864,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4865,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4866,
        lbrId: 1600,
        price: 2.6,
        taxPercentage: 5.0,
        tax: 0.13,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 4867,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 646, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 646,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 646,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 646,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2477,
        imgId: 1826,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/51F1KPVDN1A00_1.jpg",
        cropName: "51F1KPVDN1A00_1.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:24:55.277+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 143.5,
    cashPrice: 166.0675,
    taxAll: 16.435,
    taxInclude: 174.285,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 646,
    p_adminid: 2,
    productCategory: { id: 647, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 647,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4868,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4869,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4870,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4871,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4872,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4873,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 647, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 647,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 647,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 647,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2478,
        imgId: 1830,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/412baa603eda9d55157de37569a3e54f.jpg",
        cropName: "412baa603eda9d55157de37569a3e54f.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:25:57.262+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Bangle Set Trendy Designer Gold Plated Bridal Wedding Bangle Indian Manufacturer Wholesale Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 242.45001,
    cashPrice: 284.2315,
    taxAll: 35.073,
    taxInclude: 301.768,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 647,
    p_adminid: 2,
    productCategory: { id: 648, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 648,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4874,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4875,
        lbrId: 1570,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 4876,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4877,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4878,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4879,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 4880,
        lbrId: 1604,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating Rose Gold\r\n",
      },
    ],
    productSets: { id: 648, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 648,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 648,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 648,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2479,
        imgId: 1835,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/60431502553254444.jpg",
        cropName: "60431502553254444.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:27:28.099+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "This exquisite and elegant Indian jewellery, Indian bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 227.5,
    cashPrice: 260.5675,
    taxAll: 20.635,
    taxInclude: 270.885,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 648,
    p_adminid: 2,
    productCategory: { id: 649, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 649,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4881,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4882,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4883,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4884,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4885,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4886,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 649, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 649,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 649,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 649,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2480,
        imgId: 1841,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/7781837980248255.jpg",
        cropName: "7781837980248255.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:37:29.908+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Bangle Set Indian Manufacture Jewellery Gold Plated Bangle Ethnic Indian Wholesale Bridal Bangle Set For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 247.45001,
    cashPrice: 290.18152,
    taxAll: 35.973003,
    taxInclude: 308.168,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 649,
    p_adminid: 2,
    productCategory: { id: 650, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 650,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4887,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4888,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4889,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4890,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4891,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4892,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 650, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 650,
      adminId: 2,
      boxId: 79,
      brandName: "H4 - My Bangles 24",
      boxDesc: "7 Sets In 1 Box",
      pkgCharge: 7.0,
      box: "7",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 650,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 650,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2481,
        imgId: 1844,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/f9f936639fb3c4bac0fd3b59e5a43863.jpg",
        cropName: "f9f936639fb3c4bac0fd3b59e5a43863.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:38:50.999+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Bangle Set Traditional Trendy Gold Tone Textured Engraved Bangle Bridal Wedding Bangle Indian Manufacturer Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 160.15001,
    cashPrice: 187.76451,
    taxAll: 23.199,
    taxInclude: 199.36401,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 714.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 650,
    p_adminid: 2,
    productCategory: { id: 651, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 651,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4893,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4894,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4895,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4896,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4897,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4898,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 651, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 651,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 651,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 651,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2482,
        imgId: 1848,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/393266316688986.jpg",
        cropName: "393266316688986.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:40:31.159+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Indian Bangle Set Manufacture Jewellery Gold Plated Bangle Ethnic Indian Wholesale Bridal Bangle Set For Women.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 222.65,
    cashPrice: 261.19348,
    taxAll: 32.557,
    taxInclude: 277.472,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 651,
    p_adminid: 2,
    productCategory: { id: 652, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 652,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4899,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4900,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4901,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4902,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4903,
        lbrId: 1605,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4904,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 652, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 652,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 652,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 652,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2483,
        imgId: 1853,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/3t54545-500x500.jpg",
        cropName: "3t54545-500x500.jpg",
      },
      {
        id: 2484,
        imgId: 1854,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/3345465005.jpg",
        cropName: "3345465005.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:43:01.870+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription:
      "Ladies Simple Modern Style Brass Bangle Stylish Bangles Personality Bangle Jewelry.\n",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 408.5,
    cashPrice: 467.865,
    taxAll: 37.03,
    taxInclude: 486.38,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 652,
    p_adminid: 2,
    productCategory: { id: 653, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 653,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4905,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4906,
        lbrId: 1573,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4907,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 4908,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4909,
        lbrId: 1603,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 4910,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 653, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 653,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 653,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 653,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2485,
        imgId: 1980,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-402.jpg",
        cropName: "20151222-402.jpg",
      },
      {
        id: 2486,
        imgId: 1981,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-448.jpg",
        cropName: "20151222-448.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:44:07.977+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 796.5,
    cashPrice: 912.685,
    taxAll: 73.07,
    taxInclude: 949.22003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 653,
    p_adminid: 2,
    productCategory: { id: 654, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 654,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4911,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4912,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4913,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4914,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4915,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4916,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 654, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 654,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 654,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 654,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2487,
        imgId: 2001,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1109.jpg",
        cropName: "20151222-1109.jpg",
      },
      {
        id: 2488,
        imgId: 2002,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1110.jpg",
        cropName: "20151222-1110.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:45:02.214+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 364.5,
    cashPrice: 426.685,
    taxAll: 51.47,
    taxInclude: 452.42,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 654,
    p_adminid: 2,
    productCategory: { id: 655, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 655,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4917,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4918,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4919,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4920,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4921,
        lbrId: 1603,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 4922,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 655, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 655,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 655,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 655,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2489,
        imgId: 1982,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-509.jpg",
        cropName: "201512222-509.jpg",
      },
      {
        id: 2490,
        imgId: 1983,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-512.jpg",
        cropName: "201512222-512.jpg",
      },
      {
        id: 2491,
        imgId: 1984,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-514.jpg",
        cropName: "20151222-514.jpg",
      },
      {
        id: 2492,
        imgId: 1985,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-516.jpg",
        cropName: "20151222-516.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:46:11.400+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 400.5,
    cashPrice: 458.865,
    taxAll: 36.63,
    taxInclude: 477.18,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 655,
    p_adminid: 2,
    productCategory: { id: 656, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 656,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4923,
        lbrId: 1564,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Pipe Cutting",
      },
      {
        id: 4924,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4925,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4926,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4927,
        lbrId: 1603,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 4928,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 656, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 656,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 656,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 656,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2493,
        imgId: 1986,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-594.jpg",
        cropName: "20151222-594.jpg",
      },
      {
        id: 2494,
        imgId: 1991,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-672.jpg",
        cropName: "201512222-672.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T13:49:22.426+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 400.5,
    cashPrice: 458.865,
    taxAll: 36.63,
    taxInclude: 477.18,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 656,
    p_adminid: 2,
    productCategory: { id: 657, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 657,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4929,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4930,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4931,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4932,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4933,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4934,
        lbrId: 1605,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 657, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 657,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 657,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 657,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2495,
        imgId: 1990,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-670.jpg",
        cropName: "201512222-670.jpg",
      },
      {
        id: 2496,
        imgId: 1993,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-677.jpg",
        cropName: "20151222-677.jpg",
      },
      {
        id: 2497,
        imgId: 1987,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-622.jpg",
        cropName: "20151222-622.jpg",
      },
      {
        id: 2498,
        imgId: 1988,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-624.jpg",
        cropName: "20151222-624.jpg",
      },
      {
        id: 2499,
        imgId: 1989,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-666.jpg",
        cropName: "20151222-666.jpg",
      },
      {
        id: 2500,
        imgId: 1992,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-674.jpg",
        cropName: "20151222-674.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:10:08.947+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 806.5,
    cashPrice: 923.935,
    taxAll: 73.57,
    taxInclude: 960.72003,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 657,
    p_adminid: 2,
    productCategory: { id: 658, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 658,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4935,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4936,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4937,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4938,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4939,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4940,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 658, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 658,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 658,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 658,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2501,
        imgId: 2007,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1135.jpg",
        cropName: "20151222-1135.jpg",
      },
      {
        id: 2502,
        imgId: 2008,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1140.jpg",
        cropName: "20151222-1140.jpg",
      },
      {
        id: 2503,
        imgId: 2005,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1126.jpg",
        cropName: "20151222-1126.jpg",
      },
      {
        id: 2504,
        imgId: 1999,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-811.jpg",
        cropName: "20151222-811.jpg",
      },
      {
        id: 2505,
        imgId: 1998,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-810.jpg",
        cropName: "20151222-810.jpg",
      },
      {
        id: 2506,
        imgId: 1994,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-697.jpg",
        cropName: "20151222-697.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:12:57.531+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 372.5,
    cashPrice: 435.685,
    taxAll: 51.87,
    taxInclude: 461.62,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 658,
    p_adminid: 2,
    productCategory: { id: 659, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 659,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4941,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4942,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4943,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4944,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4945,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4946,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 659, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 659,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 659,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 659,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2507,
        imgId: 1996,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-804.jpg",
        cropName: "20151222-804.jpg",
      },
      {
        id: 2508,
        imgId: 1997,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-807.jpg",
        cropName: "20151222-807.jpg",
      },
      {
        id: 2509,
        imgId: 2003,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1115.jpg",
        cropName: "20151222-1115.jpg",
      },
      {
        id: 2510,
        imgId: 2004,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1125.jpg",
        cropName: "20151222-1125.jpg",
      },
      {
        id: 2511,
        imgId: 1995,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-803.jpg",
        cropName: "20151222-803.jpg",
      },
      {
        id: 2512,
        imgId: 2006,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1127.jpg",
        cropName: "20151222-1127.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:14:29.703+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 372.5,
    cashPrice: 435.685,
    taxAll: 51.87,
    taxInclude: 461.62,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 659,
    p_adminid: 2,
    productCategory: { id: 660, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 660,
      adminId: 2,
      parentId: 123,
      categoryId: 11,
      title: "8MM",
    },
    productChild: [
      {
        id: 4947,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 4948,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 4949,
        lbrId: 1575,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Dell",
      },
      {
        id: 4950,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4951,
        lbrId: 1598,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Plating Gold Plated",
      },
      {
        id: 4952,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 660, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 660,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 660,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 660,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2513,
        imgId: 2000,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1102.jpg",
        cropName: "20151222-1102.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:15:11.053+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 372.5,
    cashPrice: 435.685,
    taxAll: 51.87,
    taxInclude: 461.62,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 660,
    p_adminid: 2,
    productCategory: { id: 661, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 661,
      adminId: 2,
      parentId: 127,
      categoryId: 11,
      title: "9.2MM",
    },
    productChild: [
      {
        id: 4953,
        lbrId: 1759,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Die Cutting",
      },
      {
        id: 4954,
        lbrId: 1761,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 4955,
        lbrId: 1769,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 4956,
        lbrId: 1768,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 4957,
        lbrId: 1781,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4958,
        lbrId: 1798,
        price: 12.5,
        taxPercentage: 5.0,
        tax: 0.625,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4959,
        lbrId: 1801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 661, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 661,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 661,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 661,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2514,
        imgId: 1753,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1120.jpg",
        cropName: "20220430_1120.jpg",
      },
      {
        id: 2515,
        imgId: 1756,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1121.jpg",
        cropName: "20220430_1121.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:17:31.556+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "8MM",
    prodManfDetails: "8",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 460.5,
    cashPrice: 526.365,
    taxAll: 39.63,
    taxInclude: 546.18,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 661,
    p_adminid: 2,
    productCategory: { id: 662, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 662,
      adminId: 2,
      parentId: 127,
      categoryId: 11,
      title: "9.2MM",
    },
    productChild: [
      {
        id: 8709,
        lbrId: 1759,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Die Cutting",
      },
      {
        id: 8710,
        lbrId: 1761,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 8711,
        lbrId: 1767,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8712,
        lbrId: 1781,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8713,
        lbrId: 1801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8714,
        lbrId: 1770,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Dell",
      },
      {
        id: 8715,
        lbrId: 1791,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 662, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 662,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 662,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 662,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4118,
        imgId: 1792,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/designer-gold-bangle-set-500x500.jpg",
        cropName: "designer-gold-bangle-set-500x500.jpg",
      },
      {
        id: 4119,
        imgId: 1833,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/989b820d9fe171c06c4f48bb46f180e1.jpg",
        cropName: "989b820d9fe171c06c4f48bb46f180e1.jpg",
      },
    ],
    modifyDate: "2023-09-16T08:54:35.345+00:00",
    createDate: "2023-09-04T14:18:10.813+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9.2MM",
    prodManfDetails: "9",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 422.5,
    cashPrice: 491.935,
    taxAll: 54.37,
    taxInclude: 519.12,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 662,
    p_adminid: 2,
    productCategory: { id: 663, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 663,
      adminId: 2,
      parentId: 127,
      categoryId: 11,
      title: "9.2MM",
    },
    productChild: [
      {
        id: 4967,
        lbrId: 1761,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Die Solder",
      },
      {
        id: 4968,
        lbrId: 1759,
        price: 1.25,
        taxPercentage: 5.0,
        tax: 0.0625,
        title: "Die Cutting",
      },
      {
        id: 4969,
        lbrId: 1769,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 4970,
        lbrId: 1767,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 4971,
        lbrId: 1781,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 4972,
        lbrId: 1798,
        price: 12.5,
        taxPercentage: 5.0,
        tax: 0.625,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4973,
        lbrId: 1801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 663, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 663,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 663,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 663,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2518,
        imgId: 1755,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1185.jpg",
        cropName: "20220430_1185.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:19:22.064+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "9.2MM",
    prodManfDetails: "9",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1572.5,
    cashPrice: 1798.49,
    taxAll: 137.48001,
    taxInclude: 1867.23,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 663,
    p_adminid: 2,
    productCategory: { id: 664, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 664,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 4981,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 4982,
        lbrId: 1861,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 4983,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4984,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 4985,
        lbrId: 1859,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 4986,
        lbrId: 1878,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 4987,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4988,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 664, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 664,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 664,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 664,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2521,
        imgId: 1757,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1122.jpg",
        cropName: "20220430_1122.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:22:22.211+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 260.0,
    cashPrice: 297.13,
    taxAll: 22.259998,
    taxInclude: 308.26,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 664,
    p_adminid: 2,
    productCategory: { id: 665, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 665,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 4989,
        lbrId: 1859,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 4990,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 4991,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4992,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 4993,
        lbrId: 1878,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 4994,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 4995,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 665, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 665,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 665,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 665,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2522,
        imgId: 1758,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1123.jpg",
        cropName: "20220430_1123.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:23:29.476+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 473.5,
    cashPrice: 540.99,
    taxAll: 40.28,
    taxInclude: 561.13,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 665,
    p_adminid: 2,
    productCategory: { id: 666, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 666,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 4996,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 4997,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 4998,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 4999,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5000,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5001,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 666, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 666,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 666,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 666,
      adminId: 2,
      packingmaterialId: 107,
      type: "Polythene PP",
      pkgdesc: "1 Pcs 8MM  2.5x5 - 2.75x5 - 13MM 3.5x6 - 4x6",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2523,
        imgId: 1759,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1124.jpg",
        cropName: "20220430_1124.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:24:21.760+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 253.0,
    cashPrice: 289.25497,
    taxAll: 21.91,
    taxInclude: 300.21,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 1000.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 666,
    p_adminid: 2,
    productCategory: { id: 667, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 667,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5002,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5003,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 5004,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 5005,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5006,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5007,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 667, setsId: 32, setDesc: "7 Pcs in 1 Set", pcs: 7 },
    productBox: {
      id: 667,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 667,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 667,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2524,
        imgId: 1760,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1183.jpg",
        cropName: "20220430_1183.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:25:22.392+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 1600.5,
    cashPrice: 1829.99,
    taxAll: 138.88,
    taxInclude: 1899.43,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 143.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 667,
    p_adminid: 2,
    productCategory: { id: 668, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 668,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5008,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5009,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5010,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5011,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5012,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5013,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 668, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 668,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 668,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 668,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2525,
        imgId: 1767,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/0f927b0cd8f84028825af04ff72b10d8.jpg",
        cropName: "0f927b0cd8f84028825af04ff72b10d8.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:28:24.605+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 215.5,
    cashPrice: 250.74,
    taxAll: 27.380001,
    taxInclude: 264.43,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 668,
    p_adminid: 2,
    productCategory: { id: 669, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 669,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5014,
        lbrId: 1566,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Solder",
      },
      {
        id: 5015,
        lbrId: 1574,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Micro Dell",
      },
      {
        id: 5016,
        lbrId: 1572,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Acid Double & Putas",
      },
      {
        id: 5017,
        lbrId: 1586,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5018,
        lbrId: 1605,
        price: 11.0,
        taxPercentage: 5.0,
        tax: 0.55,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5019,
        lbrId: 1608,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5020,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5021,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5022,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 5023,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5024,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5025,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 669, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 669,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 669,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 669,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2526,
        imgId: 1778,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20160417-WA0036.jpg",
        cropName: "IMG-20160417-WA0036.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:31:18.619+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 729.5,
    cashPrice: 828.99,
    taxAll: 53.08,
    taxInclude: 855.53,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 669,
    p_adminid: 2,
    productCategory: { id: 670, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 670,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5026,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5027,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 5028,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 5029,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5030,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5031,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 670, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 670,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 670,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 670,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2527,
        imgId: 1779,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20171109-WA0013.jpg",
        cropName: "IMG-20171109-WA0013.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:32:19.815+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 470.5,
    cashPrice: 537.615,
    taxAll: 40.13,
    taxInclude: 557.68,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 670,
    p_adminid: 2,
    productCategory: { id: 671, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 671,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5032,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5033,
        lbrId: 1861,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 5034,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 5035,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 5036,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5037,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5038,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 671, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 671,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 671,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 671,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2528,
        imgId: 1780,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20171109-WA0022.jpg",
        cropName: "IMG-20171109-WA0022.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:33:52.705+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 481.5,
    cashPrice: 549.99005,
    taxAll: 40.68,
    taxInclude: 570.33,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 671,
    p_adminid: 2,
    productCategory: { id: 672, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 672,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5039,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5040,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5041,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5042,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5043,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5044,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 672, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 672,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 672,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 672,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2529,
        imgId: 1787,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_223005_Richtone(HDR).jpg",
        cropName: "20190821_223005_Richtone(HDR).jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:35:26.759+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 247.65,
    cashPrice: 289.4485,
    taxAll: 34.066998,
    taxInclude: 306.48196,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 672,
    p_adminid: 2,
    productCategory: { id: 673, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 673,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5045,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5046,
        lbrId: 1861,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 5047,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 5048,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 5049,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5050,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5051,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 673, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 673,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 673,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 673,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2530,
        imgId: 1798,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/8077991976700487511133.jpg",
        cropName: "8077991976700487511133.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:37:15.681+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 160.25,
    cashPrice: 186.95949,
    taxAll: 21.369,
    taxInclude: 197.644,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 673,
    p_adminid: 2,
    productCategory: { id: 674, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 674,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5052,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5053,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5054,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5055,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5056,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5057,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 674, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 674,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 674,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 674,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2531,
        imgId: 1807,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/23927181285601012.jpg",
        cropName: "23927181285601012.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:38:22.207+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 151.25,
    cashPrice: 176.8345,
    taxAll: 20.919,
    taxInclude: 187.294,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 674,
    p_adminid: 2,
    productCategory: { id: 675, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 675,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5058,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5059,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5060,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5061,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5062,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5063,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 675, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 675,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 675,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 675,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2532,
        imgId: 1810,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/892419990338150264424.jpg",
        cropName: "892419990338150264424.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:39:35.256+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 151.25,
    cashPrice: 176.8345,
    taxAll: 20.919,
    taxInclude: 187.294,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 675,
    p_adminid: 2,
    productCategory: { id: 676, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 676,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5064,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5065,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5066,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5067,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5068,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5069,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 676, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 676,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 676,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 676,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2533,
        imgId: 1822,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/646451837552717257.jpg",
        cropName: "646451837552717257.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:40:41.230+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 151.25,
    cashPrice: 176.8345,
    taxAll: 20.919,
    taxInclude: 187.294,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 676,
    p_adminid: 2,
    productCategory: { id: 677, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 677,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5070,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5071,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 5072,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 5073,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5074,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5075,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
    ],
    productSets: { id: 677, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 677,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 677,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 677,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2534,
        imgId: 1829,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/66c2ddfeef83fc87b5fc280588811ebe.jpg",
        cropName: "66c2ddfeef83fc87b5fc280588811ebe.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:41:52.027+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 470.5,
    cashPrice: 537.615,
    taxAll: 40.13,
    taxInclude: 557.68,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 677,
    p_adminid: 2,
    productCategory: { id: 678, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 678,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5076,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5077,
        lbrId: 1864,
        price: 0.35,
        taxPercentage: 5.0,
        tax: 0.0175,
        title: "Buffing",
      },
      {
        id: 5078,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 5079,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5080,
        lbrId: 1892,
        price: 13.0,
        taxPercentage: 5.0,
        tax: 0.65,
        title: "Plating Micro Gold\r\n",
      },
      {
        id: 5081,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 678, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 678,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 678,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 678,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2535,
        imgId: 2009,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-090.jpg",
        cropName: "20151222-090.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:47:09.800+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 460.5,
    cashPrice: 526.365,
    taxAll: 39.63,
    taxInclude: 546.18,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 678,
    p_adminid: 2,
    productCategory: { id: 679, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 679,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5082,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5083,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5084,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5085,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5086,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5087,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 679, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 679,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 679,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 679,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2536,
        imgId: 2010,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-510.jpg",
        cropName: "20151222-510.jpg",
      },
      {
        id: 2537,
        imgId: 2014,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-777.jpg",
        cropName: "20151222-777.jpg",
      },
      {
        id: 2538,
        imgId: 2012,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-578.jpg",
        cropName: "20151222-578.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:48:11.196+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 215.5,
    cashPrice: 250.74,
    taxAll: 27.380001,
    taxInclude: 264.43,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 679,
    p_adminid: 2,
    productCategory: { id: 680, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 680,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5088,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5089,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5090,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5091,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5092,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5093,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 680, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 680,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 680,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 680,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2539,
        imgId: 2011,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/201512222-550.jpg",
        cropName: "201512222-550.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:49:14.062+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 215.5,
    cashPrice: 250.74,
    taxAll: 27.380001,
    taxInclude: 264.43,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 680,
    p_adminid: 2,
    productCategory: { id: 681, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 681,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5094,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5095,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5096,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5097,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5098,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5099,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 681, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 681,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 681,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 681,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2540,
        imgId: 2020,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1138.jpg",
        cropName: "20151222-1138.jpg",
      },
      {
        id: 2541,
        imgId: 2019,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1130.jpg",
        cropName: "20151222-1130.jpg",
      },
      {
        id: 2542,
        imgId: 2018,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1122.jpg",
        cropName: "20151222-1122.jpg",
      },
      {
        id: 2543,
        imgId: 2017,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1108.jpg",
        cropName: "20151222-1108.jpg",
      },
      {
        id: 2544,
        imgId: 2016,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1103.jpg",
        cropName: "20151222-1103.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:50:21.224+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 410.5,
    cashPrice: 478.435,
    taxAll: 53.77,
    taxInclude: 505.31998,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 681,
    p_adminid: 2,
    productCategory: { id: 682, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 682,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5100,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5101,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5102,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5103,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5104,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5105,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 682, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 682,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 682,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 682,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2545,
        imgId: 2015,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-1101.jpg",
        cropName: "20151222-1101.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:51:33.417+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 410.5,
    cashPrice: 478.435,
    taxAll: 53.77,
    taxInclude: 505.31998,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 682,
    p_adminid: 2,
    productCategory: { id: 683, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 683,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 5106,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5107,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 5108,
        lbrId: 1866,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5109,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5110,
        lbrId: 1887,
        price: 0.95,
        taxPercentage: 5.0,
        tax: 0.0475,
        title: "Plating Gold Plated",
      },
      {
        id: 5111,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 683, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 683,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 683,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 683,
      adminId: 2,
      packingmaterialId: 110,
      type: "Polythene PP",
      pkgdesc: "4 Pcs",
      hsncode: "39202020",
      price: 1.25,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2546,
        imgId: 2013,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20151222-623.jpg",
        cropName: "20151222-623.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:52:35.309+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: "10",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 410.5,
    cashPrice: 478.435,
    taxAll: 53.77,
    taxInclude: 505.31998,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 683,
    p_adminid: 2,
    productCategory: { id: 684, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 684,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 5112,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5113,
        lbrId: 2129,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "CNC/Laser Marking",
      },
      {
        id: 5114,
        lbrId: 2135,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Dell",
      },
      {
        id: 5115,
        lbrId: 2132,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Acid Double & Putas",
      },
      {
        id: 5116,
        lbrId: 2157,
        price: 3.5,
        taxPercentage: 5.0,
        tax: 0.175,
        title: "Plating Gold Plated & Chrome",
      },
      {
        id: 5117,
        lbrId: 2145,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5118,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 684, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 684,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 684,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 684,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2547,
        imgId: 1761,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1129.jpg",
        cropName: "20220430_1129.jpg",
      },
      {
        id: 2548,
        imgId: 1762,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1130.jpg",
        cropName: "20220430_1130.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:54:53.934+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "12",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 209.65001,
    cashPrice: 242.85951,
    taxAll: 24.489,
    taxInclude: 255.104,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 684,
    p_adminid: 2,
    productCategory: { id: 685, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 685,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 5119,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5120,
        lbrId: 2133,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "Buffing",
      },
      {
        id: 5121,
        lbrId: 2134,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Micro Dell",
      },
      {
        id: 5122,
        lbrId: 2145,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5123,
        lbrId: 2162,
        price: 14.5,
        taxPercentage: 5.0,
        tax: 0.725,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5124,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 685, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 685,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 685,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 685,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2549,
        imgId: 1763,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1131.jpg",
        cropName: "20220430_1131.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:55:50.582+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "12",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 529.5,
    cashPrice: 603.99,
    taxAll: 43.08,
    taxInclude: 625.53,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 685,
    p_adminid: 2,
    productCategory: { id: 686, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 686,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 5125,
        lbrId: 2128,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 5126,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5127,
        lbrId: 2134,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Micro Dell",
      },
      {
        id: 5128,
        lbrId: 2133,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "Buffing",
      },
      {
        id: 5129,
        lbrId: 2145,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5130,
        lbrId: 2162,
        price: 14.5,
        taxPercentage: 5.0,
        tax: 0.725,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5131,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 5132,
        lbrId: 2130,
        price: 1.0,
        taxPercentage: 5.0,
        tax: 0.05,
        title: "Half Round &V",
      },
    ],
    productSets: { id: 686, setsId: 28, setDesc: "3 Pcs in 1 Set", pcs: 3 },
    productBox: {
      id: 686,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 686,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 686,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2550,
        imgId: 1764,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1132.jpg",
        cropName: "20220430_1132.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:57:20.377+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "12",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 811.5,
    cashPrice: 925.23755,
    taxAll: 65.175,
    taxInclude: 957.825,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 333.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 686,
    p_adminid: 2,
    productCategory: { id: 687, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 687,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 5133,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5134,
        lbrId: 2133,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "Buffing",
      },
      {
        id: 5135,
        lbrId: 2134,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Micro Dell",
      },
      {
        id: 5136,
        lbrId: 2145,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5137,
        lbrId: 2162,
        price: 14.5,
        taxPercentage: 5.0,
        tax: 0.725,
        title: "Plating 3tone\r\n",
      },
      {
        id: 5138,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 687, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 687,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 687,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 687,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2551,
        imgId: 1781,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20171109-WA0044.jpg",
        cropName: "IMG-20171109-WA0044.jpg",
      },
      {
        id: 2552,
        imgId: 1782,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20171109-WA0055.jpg",
        cropName: "IMG-20171109-WA0055.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:58:16.745+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12mm",
    prodManfDetails: "12",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 529.5,
    cashPrice: 603.99,
    taxAll: 43.08,
    taxInclude: 625.53,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 687,
    p_adminid: 2,
    productCategory: { id: 688, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 688,
      adminId: 2,
      parentId: 135,
      categoryId: 11,
      title: "12MM",
    },
    productChild: [
      {
        id: 5139,
        lbrId: 2127,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 5140,
        lbrId: 2133,
        price: 2.5,
        taxPercentage: 5.0,
        tax: 0.125,
        title: "Buffing",
      },
      {
        id: 5141,
        lbrId: 2134,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Micro Dell",
      },
      {
        id: 5142,
        lbrId: 2145,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 5143,
        lbrId: 2155,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Plating Gold Plated",
      },
      {
        id: 5144,
        lbrId: 2165,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 688, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 688,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 688,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 688,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2553,
        imgId: 1832,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/889afd076a8b97a1e6354361d24d32ba.jpg",
        cropName: "889afd076a8b97a1e6354361d24d32ba.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-04T14:59:16.696+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "12MM",
    prodManfDetails: "12",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 180.25,
    cashPrice: 209.7845,
    taxAll: 23.019,
    taxInclude: 221.29399,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 688,
    p_adminid: 2,
    productCategory: { id: 689, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 689,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 5468,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 5469,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 5470,
        lbrId: 789,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 5471,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 5472,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 5473,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 689, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 689,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 689,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 689,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 2658,
        imgId: 217,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133515.jpg",
        cropName: "20170614_133515.jpg",
      },
      {
        id: 2659,
        imgId: 219,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133656.jpg",
        cropName: "20170614_133656.jpg",
      },
      {
        id: 2660,
        imgId: 221,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133833.jpg",
        cropName: "20170614_133833.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-08T08:44:29.402+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "3",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 392.75,
    cashPrice: 458.4025,
    taxAll: 52.755005,
    taxInclude: 484.78,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 294.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 689,
    p_adminid: 2,
    productCategory: { id: 690, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 690,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 7066,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 7067,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7068,
        lbrId: 789,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7069,
        lbrId: 801,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 7070,
        lbrId: 813,
        price: 1.8,
        taxPercentage: 5.0,
        tax: 0.09,
        title: "Pola 1 Colour\r\n",
      },
      {
        id: 7071,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 690, setsId: 33, setDesc: "8 Pcs in 1 Set", pcs: 8 },
    productBox: {
      id: 690,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 690,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 690,
      adminId: 2,
      packingmaterialId: 87,
      type: "Crystal Dibbi",
      pkgdesc: "H5",
      hsncode: "39231000",
      price: 4.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3546,
        imgId: 220,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133736.jpg",
        cropName: "20170614_133736.jpg",
      },
      {
        id: 3547,
        imgId: 222,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133924.jpg",
        cropName: "20170614_133924.jpg",
      },
      {
        id: 3548,
        imgId: 218,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170614_133551.jpg",
        cropName: "20170614_133551.jpg",
      },
    ],
    modifyDate: "2023-09-12T06:16:17.872+00:00",
    createDate: "2023-09-08T08:46:30.511+00:00",
    metalGram: 0.0085,
    extraGram: 5.0e-4,
    readyGram: 0.006,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "3MM",
    prodManfDetails: "RED",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 446.77002,
    cashPrice: 521.4323,
    taxAll: 59.9706,
    taxInclude: 551.4176,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 245.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 690,
    p_adminid: 2,
    productCategory: { id: 691, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 691,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 8976,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 8977,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
    ],
    productSets: { id: 691, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 691,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 691,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 691,
      adminId: 2,
      packingmaterialId: 109,
      type: "Polythene PP",
      pkgdesc: "3 Pcs",
      hsncode: "39202020",
      price: 1.0,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4218,
        imgId: 41,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/925450_571281839719336_1926478214.jpg",
        cropName: "925450_571281839719336_1926478214.jpg",
      },
    ],
    modifyDate: "2023-09-29T13:18:08.023+00:00",
    createDate: "2023-09-09T06:12:22.603+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "HHKJ786",
    prodManfDetails: "test",
    prodDescription: "test",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 181.01,
    cashPrice: 214.23788,
    taxAll: 30.253798,
    taxInclude: 229.36479,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 691,
    p_adminid: 2,
    productCategory: { id: 692, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 692,
      adminId: 2,
      parentId: 109,
      categoryId: 11,
      title: "4MM",
    },
    productChild: [
      {
        id: 6549,
        lbrId: 867,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 6550,
        lbrId: 872,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 6551,
        lbrId: 875,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 6552,
        lbrId: 874,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 6553,
        lbrId: 871,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 6554,
        lbrId: 880,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Chakri Single",
      },
      {
        id: 6555,
        lbrId: 879,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Kanas Diamond",
      },
      {
        id: 6556,
        lbrId: 899,
        price: 0.2,
        taxPercentage: 5.0,
        tax: 0.01,
        title: "Plating Gold Plated",
      },
      {
        id: 6557,
        lbrId: 910,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 692, setsId: 31, setDesc: "6 Pcs in 1 Set", pcs: 6 },
    productBox: {
      id: 692,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 692,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 692,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3240,
        imgId: 539,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190821_153148.jpg",
        cropName: "20190821_153148.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-09T12:02:06.844+00:00",
    metalGram: 0.0095,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "4MM",
    prodManfDetails: "4",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 332.18,
    cashPrice: 389.2552,
    taxAll: 47.714397,
    taxInclude: 413.11237,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 292.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 692,
    p_adminid: 2,
    productCategory: { id: 693, adminId: 2, categoryId: 12, title: "EARRINGS" },
    productParent: {
      id: 693,
      adminId: 2,
      parentId: 167,
      categoryId: 12,
      title: "3MM",
    },
    productChild: [
      {
        id: 7729,
        lbrId: 3545,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 7730,
        lbrId: 3550,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 7731,
        lbrId: 3552,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 7732,
        lbrId: 3563,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 7733,
        lbrId: 3574,
        price: 2.75,
        taxPercentage: 5.0,
        tax: 0.1375,
        title: "Earring Die Cutting",
      },
      {
        id: 7734,
        lbrId: 3575,
        price: 2.0,
        taxPercentage: 5.0,
        tax: 0.1,
        title: "Earring Solder",
      },
      {
        id: 7735,
        lbrId: 3583,
        price: 5.0,
        taxPercentage: 5.0,
        tax: 0.25,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7736,
        lbrId: 3586,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 693, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 693,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 693,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 693,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3807,
        imgId: 1727,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20220430_1002.jpg",
        cropName: "20220430_1002.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T10:30:18.632+00:00",
    metalGram: 0.0115,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 386.95,
    cashPrice: 444.82053,
    taxAll: 38.351,
    taxInclude: 463.99603,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 435.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 693,
    p_adminid: 2,
    productCategory: { id: 694, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 694,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7751,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7752,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7753,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7754,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7755,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7756,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7757,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7758,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7759,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7760,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7761,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7762,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7763,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7764,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 694, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 694,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 694,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 694,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3814,
        imgId: 2162,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_174245.jpg",
        cropName: "20170304_174245.jpg",
      },
      {
        id: 3815,
        imgId: 2161,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_173856.jpg",
        cropName: "20170304_173856.jpg",
      },
      {
        id: 3816,
        imgId: 2160,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_173733.jpg",
        cropName: "20170304_173733.jpg",
      },
      {
        id: 3817,
        imgId: 2163,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_174819.jpg",
        cropName: "20170304_174819.jpg",
      },
      {
        id: 3818,
        imgId: 2164,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_175027.jpg",
        cropName: "20170304_175027.jpg",
      },
      {
        id: 3819,
        imgId: 2165,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_175252.jpg",
        cropName: "20170304_175252.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T11:24:36.658+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 694,
    p_adminid: 2,
    productCategory: { id: 695, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 695,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7765,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7766,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7767,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7768,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7769,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7770,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7771,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7772,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7773,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7774,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7775,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7776,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7777,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7778,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 695, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 695,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 695,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 695,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3820,
        imgId: 2167,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_175505.jpg",
        cropName: "20170304_175505.jpg",
      },
      {
        id: 3821,
        imgId: 2166,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_175404.jpg",
        cropName: "20170304_175404.jpg",
      },
      {
        id: 3822,
        imgId: 2168,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_175703.jpg",
        cropName: "20170304_175703.jpg",
      },
      {
        id: 3823,
        imgId: 2169,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_180135.jpg",
        cropName: "20170304_180135.jpg",
      },
      {
        id: 3824,
        imgId: 2170,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_180348.jpg",
        cropName: "20170304_180348.jpg",
      },
      {
        id: 3825,
        imgId: 2171,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_180459.jpg",
        cropName: "20170304_180459.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T11:28:37.663+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 695,
    p_adminid: 2,
    productCategory: { id: 696, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 696,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7779,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7780,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7781,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7782,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7783,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7784,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7785,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7786,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7787,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7788,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7789,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7790,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7791,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7792,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 696, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 696,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 696,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 696,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3826,
        imgId: 2345,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163245.jpg",
        cropName: "20170306_163245.jpg",
      },
      {
        id: 3827,
        imgId: 2344,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163148.jpg",
        cropName: "20170306_163148.jpg",
      },
      {
        id: 3828,
        imgId: 2342,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162954.jpg",
        cropName: "20170306_162954.jpg",
      },
      {
        id: 3829,
        imgId: 2341,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162900.jpg",
        cropName: "20170306_162900.jpg",
      },
      {
        id: 3830,
        imgId: 2347,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163417.jpg",
        cropName: "20170306_163417.jpg",
      },
      {
        id: 3831,
        imgId: 2348,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163516.jpg",
        cropName: "20170306_163516.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T11:46:41.406+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 696,
    p_adminid: 2,
    productCategory: { id: 697, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 697,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7793,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7794,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7795,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7796,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7797,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7798,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7799,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7800,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7801,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7802,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7803,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7804,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7805,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7806,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 697, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 697,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 697,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 697,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3832,
        imgId: 2346,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163327.jpg",
        cropName: "20170306_163327.jpg",
      },
      {
        id: 3833,
        imgId: 2343,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163104.jpg",
        cropName: "20170306_163104.jpg",
      },
      {
        id: 3834,
        imgId: 2352,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163911.jpg",
        cropName: "20170306_163911.jpg",
      },
      {
        id: 3835,
        imgId: 2351,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163815.jpg",
        cropName: "20170306_163815.jpg",
      },
      {
        id: 3836,
        imgId: 2350,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163728.jpg",
        cropName: "20170306_163728.jpg",
      },
      {
        id: 3837,
        imgId: 2349,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_163631.jpg",
        cropName: "20170306_163631.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T11:50:54.215+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 697,
    p_adminid: 2,
    productCategory: { id: 698, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 698,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7849,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7850,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7851,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7852,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7853,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7854,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7855,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7856,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7857,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7858,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7859,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7860,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7861,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7862,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 698, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 698,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 698,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 698,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3852,
        imgId: 2338,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162434.jpg",
        cropName: "20170306_162434.jpg",
      },
      {
        id: 3853,
        imgId: 2339,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162525.jpg",
        cropName: "20170306_162525.jpg",
      },
      {
        id: 3854,
        imgId: 2340,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162618.jpg",
        cropName: "20170306_162618.jpg",
      },
      {
        id: 3855,
        imgId: 2337,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162338.jpg",
        cropName: "20170306_162338.jpg",
      },
      {
        id: 3856,
        imgId: 2335,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162137.jpg",
        cropName: "20170306_162137.jpg",
      },
    ],
    modifyDate: "2023-09-14T12:06:40.482+00:00",
    createDate: "2023-09-14T12:00:59.292+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 698,
    p_adminid: 2,
    productCategory: { id: 699, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 699,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7821,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7822,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7823,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7824,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7825,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7826,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7827,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7828,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7829,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7830,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7831,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7832,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7833,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7834,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 699, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 699,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 699,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 699,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3844,
        imgId: 2333,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_161657.jpg",
        cropName: "20170306_161657.jpg",
      },
      {
        id: 3845,
        imgId: 2334,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162018.jpg",
        cropName: "20170306_162018.jpg",
      },
      {
        id: 3846,
        imgId: 2332,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_161610.jpg",
        cropName: "20170306_161610.jpg",
      },
      {
        id: 3847,
        imgId: 2331,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_161522.jpg",
        cropName: "20170306_161522.jpg",
      },
      {
        id: 3848,
        imgId: 2329,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_161402.jpg",
        cropName: "20170306_161402.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:03:00.764+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 699,
    p_adminid: 2,
    productCategory: { id: 700, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 700,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7835,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7836,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7837,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7838,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7839,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7840,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7841,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7842,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7843,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7844,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7845,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7846,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7847,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7848,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 700, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 700,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 700,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 700,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3849,
        imgId: 2330,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_161438.jpg",
        cropName: "20170306_161438.jpg",
      },
      {
        id: 3850,
        imgId: 2328,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_161321.jpg",
        cropName: "20170306_161321.jpg",
      },
      {
        id: 3851,
        imgId: 2336,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_162247.jpg",
        cropName: "20170306_162247.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:06:05.382+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 700,
    p_adminid: 2,
    productCategory: { id: 701, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 701,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7905,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7906,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7907,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7908,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7909,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7910,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7911,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7912,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7913,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7914,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7915,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7916,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7917,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7918,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 701, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 701,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 701,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 701,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3871,
        imgId: 2327,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_161108.jpg",
        cropName: "20170306_161108.jpg",
      },
      {
        id: 3872,
        imgId: 2326,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_161013.jpg",
        cropName: "20170306_161013.jpg",
      },
      {
        id: 3873,
        imgId: 2324,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160838.jpg",
        cropName: "20170306_160838.jpg",
      },
      {
        id: 3874,
        imgId: 2323,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160730.jpg",
        cropName: "20170306_160730.jpg",
      },
      {
        id: 3875,
        imgId: 2322,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160625.jpg",
        cropName: "20170306_160625.jpg",
      },
    ],
    modifyDate: "2023-09-14T12:18:14.250+00:00",
    createDate: "2023-09-14T12:12:15.291+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 701,
    p_adminid: 2,
    productCategory: { id: 702, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 702,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7877,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7878,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7879,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7880,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7881,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7882,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7883,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7884,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7885,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7886,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7887,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7888,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7889,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 7890,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
    ],
    productSets: { id: 702, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 702,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 702,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 702,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3863,
        imgId: 2320,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160427.jpg",
        cropName: "20170306_160427.jpg",
      },
      {
        id: 3864,
        imgId: 2321,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160534.jpg",
        cropName: "20170306_160534.jpg",
      },
      {
        id: 3865,
        imgId: 2318,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160249.jpg",
        cropName: "20170306_160249.jpg",
      },
      {
        id: 3866,
        imgId: 2316,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_155015.jpg",
        cropName: "20170306_155015.jpg",
      },
      {
        id: 3867,
        imgId: 2315,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154905.jpg",
        cropName: "20170306_154905.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:14:56.287+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.23,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 702,
    p_adminid: 2,
    productCategory: { id: 703, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 703,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7891,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7892,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7893,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7894,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7895,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7896,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7897,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7898,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7899,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7900,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7901,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7902,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7903,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7904,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 703, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 703,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 703,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 703,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3868,
        imgId: 2317,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160125.jpg",
        cropName: "20170306_160125.jpg",
      },
      {
        id: 3869,
        imgId: 2319,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160339.jpg",
        cropName: "20170306_160339.jpg",
      },
      {
        id: 3870,
        imgId: 2325,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_160921.jpg",
        cropName: "20170306_160921.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:17:31.766+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 703,
    p_adminid: 2,
    productCategory: { id: 704, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 704,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7919,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7920,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7921,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7922,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7923,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7924,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7925,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7926,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7927,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7928,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7929,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7930,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7931,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7932,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 704, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 704,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 704,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 704,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3876,
        imgId: 2310,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154301.jpg",
        cropName: "20170306_154301.jpg",
      },
      {
        id: 3877,
        imgId: 2311,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154352.jpg",
        cropName: "20170306_154352.jpg",
      },
      {
        id: 3878,
        imgId: 2312,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154508.jpg",
        cropName: "20170306_154508.jpg",
      },
      {
        id: 3879,
        imgId: 2313,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154600.jpg",
        cropName: "20170306_154600.jpg",
      },
      {
        id: 3880,
        imgId: 2314,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154651.jpg",
        cropName: "20170306_154651.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:20:49.606+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 704,
    p_adminid: 2,
    productCategory: { id: 705, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 705,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7933,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7934,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7935,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7936,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7937,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7938,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7939,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7940,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7941,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7942,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7943,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7944,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7945,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7946,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 705, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 705,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 705,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 705,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3881,
        imgId: 2305,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_153742.jpg",
        cropName: "20170306_153742.jpg",
      },
      {
        id: 3882,
        imgId: 2306,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_153827.jpg",
        cropName: "20170306_153827.jpg",
      },
      {
        id: 3883,
        imgId: 2307,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154020.jpg",
        cropName: "20170306_154020.jpg",
      },
      {
        id: 3884,
        imgId: 2308,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154113.jpg",
        cropName: "20170306_154113.jpg",
      },
      {
        id: 3885,
        imgId: 2309,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_154201.jpg",
        cropName: "20170306_154201.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:23:58.157+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 705,
    p_adminid: 2,
    productCategory: { id: 706, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 706,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7947,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7948,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7949,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7950,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7951,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7952,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7953,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7954,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7955,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7956,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7957,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7958,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7959,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7960,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 706, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 706,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 706,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 706,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3886,
        imgId: 2302,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_153409.jpg",
        cropName: "20170306_153409.jpg",
      },
      {
        id: 3887,
        imgId: 2303,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_153529.jpg",
        cropName: "20170306_153529.jpg",
      },
      {
        id: 3888,
        imgId: 2304,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_153641.jpg",
        cropName: "20170306_153641.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:27:14.910+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 706,
    p_adminid: 2,
    productCategory: { id: 707, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 707,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7961,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7962,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7963,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7964,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7965,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7966,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7967,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7968,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7969,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7970,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7971,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7972,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7973,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7974,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 707, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 707,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 707,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 707,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3889,
        imgId: 2299,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152748.jpg",
        cropName: "20170306_152748.jpg",
      },
      {
        id: 3890,
        imgId: 2300,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152841.jpg",
        cropName: "20170306_152841.jpg",
      },
      {
        id: 3891,
        imgId: 2301,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152941.jpg",
        cropName: "20170306_152941.jpg",
      },
      {
        id: 3892,
        imgId: 2298,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152658.jpg",
        cropName: "20170306_152658.jpg",
      },
      {
        id: 3893,
        imgId: 2297,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152545.jpg",
        cropName: "20170306_152545.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:32:37.966+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 707,
    p_adminid: 2,
    productCategory: { id: 708, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 708,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7975,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7976,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7977,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7978,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7979,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 7980,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7981,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7982,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7983,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7984,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7985,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7986,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 7987,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 7988,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 708, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 708,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 708,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 708,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3894,
        imgId: 2295,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152337.jpg",
        cropName: "20170306_152337.jpg",
      },
      {
        id: 3895,
        imgId: 2296,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152430.jpg",
        cropName: "20170306_152430.jpg",
      },
      {
        id: 3896,
        imgId: 2292,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_151955.jpg",
        cropName: "20170306_151955.jpg",
      },
      {
        id: 3897,
        imgId: 2291,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_151806.jpg",
        cropName: "20170306_151806.jpg",
      },
      {
        id: 3898,
        imgId: 2290,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_145836.jpg",
        cropName: "20170306_145836.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:35:30.396+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 708,
    p_adminid: 2,
    productCategory: { id: 709, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 709,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 7989,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 7990,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 7991,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 7992,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 7993,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 7994,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 7995,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 7996,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 7997,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 7998,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 7999,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8000,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8001,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8002,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
    ],
    productSets: { id: 709, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 709,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 709,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 709,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3899,
        imgId: 2289,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_145730.jpg",
        cropName: "20170306_145730.jpg",
      },
      {
        id: 3900,
        imgId: 2293,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152123.jpg",
        cropName: "20170306_152123.jpg",
      },
      {
        id: 3901,
        imgId: 2294,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_152228.jpg",
        cropName: "20170306_152228.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:37:25.383+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 709,
    p_adminid: 2,
    productCategory: { id: 710, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 710,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8003,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8004,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8005,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8006,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8007,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8008,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8009,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8010,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8011,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8012,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8013,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8014,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8015,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8016,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 710, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 710,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 710,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 710,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3902,
        imgId: 2287,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_145445.jpg",
        cropName: "20170306_145445.jpg",
      },
      {
        id: 3903,
        imgId: 2288,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_145545.jpg",
        cropName: "20170306_145545.jpg",
      },
      {
        id: 3904,
        imgId: 2286,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_145230.jpg",
        cropName: "20170306_145230.jpg",
      },
      {
        id: 3905,
        imgId: 2285,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_145115.jpg",
        cropName: "20170306_145115.jpg",
      },
      {
        id: 3906,
        imgId: 2284,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_144905.jpg",
        cropName: "20170306_144905.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:42:01.949+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 710,
    p_adminid: 2,
    productCategory: { id: 711, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 711,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8017,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8018,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8019,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8020,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8021,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8022,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8023,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8024,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8025,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8026,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8027,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8028,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8029,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8030,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 711, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 711,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 711,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 711,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3907,
        imgId: 2283,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_144751.jpg",
        cropName: "20170306_144751.jpg",
      },
      {
        id: 3908,
        imgId: 2282,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_144246.jpg",
        cropName: "20170306_144246.jpg",
      },
      {
        id: 3909,
        imgId: 2281,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_144120.jpg",
        cropName: "20170306_144120.jpg",
      },
      {
        id: 3910,
        imgId: 2280,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_143813.jpg",
        cropName: "20170306_143813.jpg",
      },
      {
        id: 3911,
        imgId: 2279,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_134852.jpg",
        cropName: "20170306_134852.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:44:39.461+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 711,
    p_adminid: 2,
    productCategory: { id: 712, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 712,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8031,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8032,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8033,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8034,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8035,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8036,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8037,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8038,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8039,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8040,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8041,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8042,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8043,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8044,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 712, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 712,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 712,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 712,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3912,
        imgId: 2276,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_134519.jpg",
        cropName: "20170306_134519.jpg",
      },
      {
        id: 3913,
        imgId: 2277,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_134628.jpg",
        cropName: "20170306_134628.jpg",
      },
      {
        id: 3914,
        imgId: 2278,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_134742.jpg",
        cropName: "20170306_134742.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:47:01.002+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 712,
    p_adminid: 2,
    productCategory: { id: 713, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 713,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8045,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8046,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8047,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8048,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8049,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8050,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8051,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8052,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8053,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8054,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8055,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8056,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8057,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8058,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 713, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 713,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 713,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 713,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3915,
        imgId: 2272,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_133221.jpg",
        cropName: "20170306_133221.jpg",
      },
      {
        id: 3916,
        imgId: 2274,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_133827.jpg",
        cropName: "20170306_133827.jpg",
      },
      {
        id: 3917,
        imgId: 2275,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_134217.jpg",
        cropName: "20170306_134217.jpg",
      },
      {
        id: 3918,
        imgId: 2271,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_132810.jpg",
        cropName: "20170306_132810.jpg",
      },
      {
        id: 3919,
        imgId: 2270,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_132659.jpg",
        cropName: "20170306_132659.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:49:03.516+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 713,
    p_adminid: 2,
    productCategory: { id: 714, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 714,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8059,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8060,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8061,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8062,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8063,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8064,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8065,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8066,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8067,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8068,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8069,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8070,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8071,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8072,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 714, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 714,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 714,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 714,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3920,
        imgId: 2273,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_133628.jpg",
        cropName: "20170306_133628.jpg",
      },
      {
        id: 3921,
        imgId: 2269,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_132525.jpg",
        cropName: "20170306_132525.jpg",
      },
      {
        id: 3922,
        imgId: 2268,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_132338.jpg",
        cropName: "20170306_132338.jpg",
      },
      {
        id: 3923,
        imgId: 2267,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_132141.jpg",
        cropName: "20170306_132141.jpg",
      },
      {
        id: 3924,
        imgId: 2266,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_131457.jpg",
        cropName: "20170306_131457.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:51:31.653+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 714,
    p_adminid: 2,
    productCategory: { id: 715, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 715,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8073,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8074,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8075,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8076,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8077,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8078,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8079,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8080,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8081,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8082,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8083,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8084,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8085,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8086,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 715, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 715,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 715,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 715,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3925,
        imgId: 2265,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_131121.jpg",
        cropName: "20170306_131121.jpg",
      },
      {
        id: 3926,
        imgId: 2264,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_130939.jpg",
        cropName: "20170306_130939.jpg",
      },
      {
        id: 3927,
        imgId: 2263,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_130809.jpg",
        cropName: "20170306_130809.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:53:57.162+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 715,
    p_adminid: 2,
    productCategory: { id: 716, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 716,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8129,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8130,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8131,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8132,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8133,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8134,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8135,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8136,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8137,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8138,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8139,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8140,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8141,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8142,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 716, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 716,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 716,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 716,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3941,
        imgId: 2262,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_130334.jpg",
        cropName: "20170306_130334.jpg",
      },
      {
        id: 3942,
        imgId: 2261,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_130232.jpg",
        cropName: "20170306_130232.jpg",
      },
      {
        id: 3943,
        imgId: 2260,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_130105.jpg",
        cropName: "20170306_130105.jpg",
      },
      {
        id: 3944,
        imgId: 2259,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_125934.jpg",
        cropName: "20170306_125934.jpg",
      },
      {
        id: 3945,
        imgId: 2258,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_125723.jpg",
        cropName: "20170306_125723.jpg",
      },
    ],
    modifyDate: "2023-09-14T13:03:06.351+00:00",
    createDate: "2023-09-14T12:56:13.021+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 716,
    p_adminid: 2,
    productCategory: { id: 717, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 717,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8101,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8102,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8103,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8104,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8105,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8106,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8107,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8108,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8109,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8110,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8111,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8112,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8113,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8114,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 717, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 717,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 717,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 717,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3933,
        imgId: 2254,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_124804.jpg",
        cropName: "20170306_124804.jpg",
      },
      {
        id: 3934,
        imgId: 2256,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_125416.jpg",
        cropName: "20170306_125416.jpg",
      },
      {
        id: 3935,
        imgId: 2255,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_125136.jpg",
        cropName: "20170306_125136.jpg",
      },
      {
        id: 3936,
        imgId: 2257,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_125540.jpg",
        cropName: "20170306_125540.jpg",
      },
      {
        id: 3937,
        imgId: 2253,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_124713.jpg",
        cropName: "20170306_124713.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T12:58:50.647+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 717,
    p_adminid: 2,
    productCategory: { id: 718, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 718,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8115,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8116,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8117,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8118,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8119,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8120,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8121,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8122,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8123,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8124,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8125,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8126,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8127,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8128,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 718, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 718,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 718,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 718,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3938,
        imgId: 2250,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_124056.jpg",
        cropName: "20170306_124056.jpg",
      },
      {
        id: 3939,
        imgId: 2251,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_124233.jpg",
        cropName: "20170306_124233.jpg",
      },
      {
        id: 3940,
        imgId: 2252,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_124615.jpg",
        cropName: "20170306_124615.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T13:01:59.730+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 718,
    p_adminid: 2,
    productCategory: { id: 719, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 719,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8143,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8144,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8145,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8146,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8147,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8148,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8149,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8150,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8151,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8152,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8153,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8154,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8155,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8156,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 719, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 719,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 719,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 719,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3946,
        imgId: 2247,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_201334.jpg",
        cropName: "20170304_201334.jpg",
      },
      {
        id: 3947,
        imgId: 2248,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_201426.jpg",
        cropName: "20170304_201426.jpg",
      },
      {
        id: 3948,
        imgId: 2249,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_201504.jpg",
        cropName: "20170304_201504.jpg",
      },
      {
        id: 3949,
        imgId: 2245,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_201202.jpg",
        cropName: "20170304_201202.jpg",
      },
      {
        id: 3950,
        imgId: 2246,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_201251.jpg",
        cropName: "20170304_201251.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T13:07:28.408+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 719,
    p_adminid: 2,
    productCategory: { id: 720, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 720,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8157,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8158,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8159,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8160,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8161,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8162,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8163,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8164,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8165,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8166,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8167,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8168,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8169,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8170,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 720, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 720,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 720,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 720,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3951,
        imgId: 2241,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200844.jpg",
        cropName: "20170304_200844.jpg",
      },
      {
        id: 3952,
        imgId: 2242,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200918.jpg",
        cropName: "20170304_200918.jpg",
      },
      {
        id: 3953,
        imgId: 2243,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_201009.jpg",
        cropName: "20170304_201009.jpg",
      },
      {
        id: 3954,
        imgId: 2244,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_201120.jpg",
        cropName: "20170304_201120.jpg",
      },
      {
        id: 3955,
        imgId: 2239,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200704.jpg",
        cropName: "20170304_200704.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T13:10:05.803+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 720,
    p_adminid: 2,
    productCategory: { id: 721, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 721,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8171,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8172,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8173,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8174,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8175,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8176,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8177,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8178,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8179,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8180,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8181,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8182,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8183,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8184,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 721, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 721,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 721,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 721,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3956,
        imgId: 2237,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200533.jpg",
        cropName: "20170304_200533.jpg",
      },
      {
        id: 3957,
        imgId: 2238,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200621.jpg",
        cropName: "20170304_200621.jpg",
      },
      {
        id: 3958,
        imgId: 2240,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200755.jpg",
        cropName: "20170304_200755.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T13:12:49.430+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 721,
    p_adminid: 2,
    productCategory: { id: 722, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 722,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8185,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8186,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8187,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8188,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8189,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8190,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8191,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8192,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8193,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8194,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8195,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8196,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8197,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8198,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 722, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 722,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 722,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 722,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3959,
        imgId: 2235,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200305.jpg",
        cropName: "20170304_200305.jpg",
      },
      {
        id: 3960,
        imgId: 2236,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200425.jpg",
        cropName: "20170304_200425.jpg",
      },
      {
        id: 3961,
        imgId: 2232,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_195037.jpg",
        cropName: "20170304_195037.jpg",
      },
      {
        id: 3962,
        imgId: 2233,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200112.jpg",
        cropName: "20170304_200112.jpg",
      },
      {
        id: 3963,
        imgId: 2234,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200221.jpg",
        cropName: "20170304_200221.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T13:14:39.738+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 722,
    p_adminid: 2,
    productCategory: { id: 723, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 723,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8199,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8200,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8201,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8202,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8203,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8204,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8205,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8206,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8207,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8208,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8209,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8210,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8211,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8212,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 723, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 723,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 723,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 723,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3964,
        imgId: 2236,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200425.jpg",
        cropName: "20170304_200425.jpg",
      },
      {
        id: 3965,
        imgId: 2235,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200305.jpg",
        cropName: "20170304_200305.jpg",
      },
      {
        id: 3966,
        imgId: 2234,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200221.jpg",
        cropName: "20170304_200221.jpg",
      },
      {
        id: 3967,
        imgId: 2233,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200112.jpg",
        cropName: "20170304_200112.jpg",
      },
      {
        id: 3968,
        imgId: 2232,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_195037.jpg",
        cropName: "20170304_195037.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T13:18:41.744+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 723,
    p_adminid: 2,
    productCategory: { id: 724, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 724,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8213,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8214,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8215,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8216,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8217,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8218,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8219,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8220,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8221,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8222,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8223,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8224,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8225,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8226,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 724, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 724,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 724,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 724,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3969,
        imgId: 2229,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_194814.jpg",
        cropName: "20170304_194814.jpg",
      },
      {
        id: 3970,
        imgId: 2230,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_194938.jpg",
        cropName: "20170304_194938.jpg",
      },
      {
        id: 3971,
        imgId: 2231,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_200018.jpg",
        cropName: "20170304_200018.jpg",
      },
      {
        id: 3972,
        imgId: 2225,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_194010.jpg",
        cropName: "20170304_194010.jpg",
      },
      {
        id: 3973,
        imgId: 2224,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_193917.jpg",
        cropName: "20170304_193917.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T13:21:47.518+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 724,
    p_adminid: 2,
    productCategory: { id: 725, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 725,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8227,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8228,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8229,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8230,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8231,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8232,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8233,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8234,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8235,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8236,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8237,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8238,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8239,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8240,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 725, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 725,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 725,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 725,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 3974,
        imgId: 2226,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_194055.jpg",
        cropName: "20170304_194055.jpg",
      },
      {
        id: 3975,
        imgId: 2227,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_194151.jpg",
        cropName: "20170304_194151.jpg",
      },
      {
        id: 3976,
        imgId: 2228,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_194252.jpg",
        cropName: "20170304_194252.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-14T13:29:46.863+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 725,
    p_adminid: 2,
    productCategory: { id: 726, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 726,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8357,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8358,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8359,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8360,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8361,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8362,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8363,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8364,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8365,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8366,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8367,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8368,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8369,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8370,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 726, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 726,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 726,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 726,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4013,
        imgId: 2151,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_192355.jpg",
        cropName: "20170306_192355.jpg",
      },
      {
        id: 4014,
        imgId: 2152,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_192313.jpg",
        cropName: "20170306_192313.jpg",
      },
      {
        id: 4015,
        imgId: 2153,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_192221.jpg",
        cropName: "20170306_192221.jpg",
      },
      {
        id: 4016,
        imgId: 2154,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_192137.jpg",
        cropName: "20170306_192137.jpg",
      },
      {
        id: 4017,
        imgId: 2157,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_191811.jpg",
        cropName: "20170306_191811.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T05:33:03.515+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 726,
    p_adminid: 2,
    productCategory: { id: 727, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 727,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8371,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8372,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8373,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8374,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8375,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8376,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8377,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8378,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8379,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8380,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8381,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8382,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8383,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8384,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 727, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 727,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 727,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 727,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4018,
        imgId: 2155,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_192057.jpg",
        cropName: "20170306_192057.jpg",
      },
      {
        id: 4019,
        imgId: 2156,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_192007.jpg",
        cropName: "20170306_192007.jpg",
      },
      {
        id: 4020,
        imgId: 2158,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_191653.jpg",
        cropName: "20170306_191653.jpg",
      },
      {
        id: 4021,
        imgId: 2159,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170306_191551.jpg",
        cropName: "20170306_191551.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T05:34:26.731+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 727,
    p_adminid: 2,
    productCategory: { id: 728, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 728,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8385,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8386,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8387,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8388,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8389,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8390,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8391,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8392,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8393,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8394,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8395,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8396,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8397,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8398,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
    ],
    productSets: { id: 728, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 728,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 728,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 728,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4022,
        imgId: 2172,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_180859.jpg",
        cropName: "20170304_180859.jpg",
      },
      {
        id: 4023,
        imgId: 2173,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_180935.jpg",
        cropName: "20170304_180935.jpg",
      },
      {
        id: 4024,
        imgId: 2174,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181004.jpg",
        cropName: "20170304_181004.jpg",
      },
      {
        id: 4025,
        imgId: 2175,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181033.jpg",
        cropName: "20170304_181033.jpg",
      },
      {
        id: 4026,
        imgId: 2176,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181105.jpg",
        cropName: "20170304_181105.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T05:39:39.116+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 728,
    p_adminid: 2,
    productCategory: { id: 729, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 729,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8399,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8400,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8401,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8402,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8403,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8404,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8405,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8406,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8407,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8408,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8409,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8410,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8411,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8412,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 729, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 729,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 729,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 729,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4027,
        imgId: 2177,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181143.jpg",
        cropName: "20170304_181143.jpg",
      },
      {
        id: 4028,
        imgId: 2178,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181227.jpg",
        cropName: "20170304_181227.jpg",
      },
      {
        id: 4029,
        imgId: 2179,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181256.jpg",
        cropName: "20170304_181256.jpg",
      },
      {
        id: 4030,
        imgId: 2180,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181410.jpg",
        cropName: "20170304_181410.jpg",
      },
      {
        id: 4031,
        imgId: 2181,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181447.jpg",
        cropName: "20170304_181447.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:01:04.740+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 729,
    p_adminid: 2,
    productCategory: { id: 730, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 730,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8413,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8414,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8415,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8416,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8417,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8418,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8419,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8420,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8421,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8422,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8423,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8424,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8425,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8426,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 730, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 730,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 730,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 730,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4032,
        imgId: 2183,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181547.jpg",
        cropName: "20170304_181547.jpg",
      },
      {
        id: 4033,
        imgId: 2182,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181514.jpg",
        cropName: "20170304_181514.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:03:20.569+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 730,
    p_adminid: 2,
    productCategory: { id: 731, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 731,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8427,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8428,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8429,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8430,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8431,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8432,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8433,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8434,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8435,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8436,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8437,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8438,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8439,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8440,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 731, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 731,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 731,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 731,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4034,
        imgId: 2186,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182001.jpg",
        cropName: "20170304_182001.jpg",
      },
      {
        id: 4035,
        imgId: 2185,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181911.jpg",
        cropName: "20170304_181911.jpg",
      },
      {
        id: 4036,
        imgId: 2184,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181839.jpg",
        cropName: "20170304_181839.jpg",
      },
      {
        id: 4037,
        imgId: 2183,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_181547.jpg",
        cropName: "20170304_181547.jpg",
      },
      {
        id: 4038,
        imgId: 2190,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182307.jpg",
        cropName: "20170304_182307.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:06:02.676+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 731,
    p_adminid: 2,
    productCategory: { id: 732, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 732,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8441,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8442,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8443,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8444,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8445,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8446,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8447,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8448,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8449,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8450,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8451,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8452,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8453,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8454,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 732, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 732,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 732,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 732,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4039,
        imgId: 2189,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182236.jpg",
        cropName: "20170304_182236.jpg",
      },
      {
        id: 4040,
        imgId: 2188,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182158.jpg",
        cropName: "20170304_182158.jpg",
      },
      {
        id: 4041,
        imgId: 2187,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182110.jpg",
        cropName: "20170304_182110.jpg",
      },
      {
        id: 4042,
        imgId: 2191,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182449.jpg",
        cropName: "20170304_182449.jpg",
      },
      {
        id: 4043,
        imgId: 2194,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182708.jpg",
        cropName: "20170304_182708.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:07:43.239+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 732,
    p_adminid: 2,
    productCategory: { id: 733, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 733,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8455,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8456,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8457,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8458,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8459,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8460,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8461,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8462,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8463,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8464,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8465,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8466,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8467,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8468,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 733, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 733,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 733,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 733,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4044,
        imgId: 2193,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182627.jpg",
        cropName: "20170304_182627.jpg",
      },
      {
        id: 4045,
        imgId: 2192,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_182525.jpg",
        cropName: "20170304_182525.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:10:42.253+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 733,
    p_adminid: 2,
    productCategory: { id: 734, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 734,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8469,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8470,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8471,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8472,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8473,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8474,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8475,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8476,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8477,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8478,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8479,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8480,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8481,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8482,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 734, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 734,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 734,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 734,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4046,
        imgId: 2195,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_184712.jpg",
        cropName: "20170304_184712.jpg",
      },
      {
        id: 4047,
        imgId: 2196,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_184808.jpg",
        cropName: "20170304_184808.jpg",
      },
      {
        id: 4048,
        imgId: 2197,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_184908.jpg",
        cropName: "20170304_184908.jpg",
      },
      {
        id: 4049,
        imgId: 2198,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185016.jpg",
        cropName: "20170304_185016.jpg",
      },
      {
        id: 4050,
        imgId: 2199,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185133.jpg",
        cropName: "20170304_185133.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:12:36.076+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 734,
    p_adminid: 2,
    productCategory: { id: 735, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 735,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8483,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8484,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8485,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8486,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8487,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8488,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8489,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8490,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8491,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8492,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8493,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8494,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8495,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8496,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 735, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 735,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 735,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 735,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4051,
        imgId: 2200,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185434.jpg",
        cropName: "20170304_185434.jpg",
      },
      {
        id: 4052,
        imgId: 2201,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185558.jpg",
        cropName: "20170304_185558.jpg",
      },
      {
        id: 4053,
        imgId: 2202,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185645.jpg",
        cropName: "20170304_185645.jpg",
      },
      {
        id: 4054,
        imgId: 2203,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185734.jpg",
        cropName: "20170304_185734.jpg",
      },
      {
        id: 4055,
        imgId: 2205,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185851.jpg",
        cropName: "20170304_185851.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:15:04.214+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 735,
    p_adminid: 2,
    productCategory: { id: 736, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 736,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8497,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8498,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8499,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8500,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8501,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8502,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8503,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8504,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8505,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8506,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8507,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8508,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8509,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8510,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 736, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 736,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 736,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 736,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4056,
        imgId: 2206,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185958.jpg",
        cropName: "20170304_185958.jpg",
      },
      {
        id: 4057,
        imgId: 2204,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_185807.jpg",
        cropName: "20170304_185807.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:17:09.526+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 736,
    p_adminid: 2,
    productCategory: { id: 737, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 737,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8511,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8512,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8513,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8514,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8515,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8516,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8517,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8518,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8519,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8520,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8521,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8522,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8523,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8524,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
    ],
    productSets: { id: 737, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 737,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 737,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 737,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4058,
        imgId: 2210,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191001.jpg",
        cropName: "20170304_191001.jpg",
      },
      {
        id: 4059,
        imgId: 2209,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_190808.jpg",
        cropName: "20170304_190808.jpg",
      },
      {
        id: 4060,
        imgId: 2208,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_190708.jpg",
        cropName: "20170304_190708.jpg",
      },
      {
        id: 4061,
        imgId: 2207,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_190620.jpg",
        cropName: "20170304_190620.jpg",
      },
      {
        id: 4062,
        imgId: 2211,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_190839.jpg",
        cropName: "20170304_190839.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:19:23.630+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 737,
    p_adminid: 2,
    productCategory: { id: 738, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 738,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8525,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8526,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8527,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8528,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8529,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8530,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8531,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8532,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8533,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8534,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8535,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8536,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8537,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8538,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 738, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 738,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 738,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 738,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4063,
        imgId: 2212,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191058.jpg",
        cropName: "20170304_191058.jpg",
      },
      {
        id: 4064,
        imgId: 2213,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191150.jpg",
        cropName: "20170304_191150.jpg",
      },
      {
        id: 4065,
        imgId: 2214,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191235.jpg",
        cropName: "20170304_191235.jpg",
      },
      {
        id: 4066,
        imgId: 2215,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191315.jpg",
        cropName: "20170304_191315.jpg",
      },
      {
        id: 4067,
        imgId: 2216,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191357.jpg",
        cropName: "20170304_191357.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:21:30.994+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 738,
    p_adminid: 2,
    productCategory: { id: 739, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 739,
      adminId: 2,
      parentId: 152,
      categoryId: 11,
      title: "20/3MM",
    },
    productChild: [
      {
        id: 8539,
        lbrId: 2879,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Pipe Cutting",
      },
      {
        id: 8540,
        lbrId: 2885,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Buffing",
      },
      {
        id: 8541,
        lbrId: 2882,
        price: 0.85,
        taxPercentage: 12.0,
        tax: 0.102000006,
        title: "Half Round",
      },
      {
        id: 8542,
        lbrId: 2886,
        price: 0.8,
        taxPercentage: 5.0,
        tax: 0.04,
        title: "Micro Dell",
      },
      {
        id: 8543,
        lbrId: 2884,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8544,
        lbrId: 2895,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8545,
        lbrId: 2896,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 8546,
        lbrId: 2898,
        price: 0.75,
        taxPercentage: 5.0,
        tax: 0.0375,
        title: "Cutting 5 Cut",
      },
      {
        id: 8547,
        lbrId: 2900,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8548,
        lbrId: 2903,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8549,
        lbrId: 2901,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8550,
        lbrId: 2902,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8551,
        lbrId: 2916,
        price: 22.5,
        taxPercentage: 5.0,
        tax: 1.125,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8552,
        lbrId: 2919,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 739, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 739,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 739,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 739,
      adminId: 2,
      packingmaterialId: 113,
      type: "Polythene PP",
      pkgdesc: "7 Pcs 6MM 6x3.5 - 6x4 - 6x5 - 7x5 Inc",
      hsncode: "39202020",
      price: 2.5,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4068,
        imgId: 2218,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191520.jpg",
        cropName: "20170304_191520.jpg",
      },
      {
        id: 4069,
        imgId: 2217,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_191438.jpg",
        cropName: "20170304_191438.jpg",
      },
      {
        id: 4070,
        imgId: 2223,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20170304_193410.jpg",
        cropName: "20170304_193410.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T06:23:09.236+00:00",
    metalGram: 0.031,
    extraGram: 5.0e-4,
    readyGram: 0.023,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 594.6,
    cashPrice: 684.974,
    taxAll: 61.828003,
    taxInclude: 715.888,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 323.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 739,
    p_adminid: 2,
    productCategory: { id: 740, adminId: 2, categoryId: 14, title: "WATCH" },
    productParent: {
      id: 740,
      adminId: 2,
      parentId: 223,
      categoryId: 14,
      title: "10MM",
    },
    productChild: [
      {
        id: 8562,
        lbrId: 6027,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 8563,
        lbrId: 6033,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 8564,
        lbrId: 6034,
        price: 0.4,
        taxPercentage: 5.0,
        tax: 0.02,
        title: "Buffing",
      },
      {
        id: 8565,
        lbrId: 6031,
        price: 0.55,
        taxPercentage: 5.0,
        tax: 0.0275,
        title: "Half Round",
      },
      {
        id: 8566,
        lbrId: 6035,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 8567,
        lbrId: 6052,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Cutting 10 Cut",
      },
      {
        id: 8568,
        lbrId: 6065,
        price: 15.5,
        taxPercentage: 5.0,
        tax: 0.775,
        title: "Plating 3tone\r\n",
      },
      {
        id: 8569,
        lbrId: 6068,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8570,
        lbrId: 6038,
        price: 3.0,
        taxPercentage: 5.0,
        tax: 0.15,
        title: "Diamond Dell/CNC",
      },
      {
        id: 8571,
        lbrId: 6044,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Cutting 2 Cut",
      },
      {
        id: 8572,
        lbrId: 6051,
        price: 1.35,
        taxPercentage: 5.0,
        tax: 0.0675,
        title: "Cutting 9 Cut",
      },
      {
        id: 8573,
        lbrId: 6050,
        price: 1.2,
        taxPercentage: 5.0,
        tax: 0.06,
        title: "Cutting 8 Cut",
      },
      {
        id: 8574,
        lbrId: 6056,
        price: 20.0,
        taxPercentage: 5.0,
        tax: 1.0,
        title: "Earring Die Cutting (Lock)",
      },
      {
        id: 8575,
        lbrId: 6067,
        price: 185.0,
        taxPercentage: 18.0,
        tax: 33.3,
        title: "Al 35E Complete Moment ",
      },
    ],
    productSets: { id: 740, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 740,
      adminId: 2,
      boxId: 83,
      brandName: "H5 - My Bangles 24",
      boxDesc: "24 Sets In 1 Box",
      pkgCharge: 24.0,
      box: "24",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 740,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 740,
      adminId: 2,
      packingmaterialId: 86,
      type: "Crystal Dibbi",
      pkgdesc: "H4",
      hsncode: "39231000",
      price: 3.6,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4072,
        imgId: 1766,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/IMG-20170808-WA0020.jpg",
        cropName: "IMG-20170808-WA0020.jpg",
      },
    ],
    modifyDate: "2023-09-15T11:03:06.025+00:00",
    createDate: "2023-09-15T10:56:59.131+00:00",
    metalGram: 0.015,
    extraGram: 5.0e-4,
    readyGram: 0.014,
    golwireThaw: "Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 5924.05,
    cashPrice: 6975.5435,
    taxAll: 918.177,
    taxInclude: 7434.632,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 278.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 4,
        adminId: 2,
        productId: 739,
        stockCategoryId: 14,
        stockParentId: 223,
        cutOrFullSize: "1",
        createDate: "2023-09-15T11:08:52.228+00:00",
        s1x8: "0",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "10",
        s2x3: "0",
        s2x4: "37",
        s2x5: "0",
        s2x6: "32",
        s2x7: "0",
        s2x8: "0",
        s2x9: "0",
        s2x10: "0",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 79.0,
      },
    ],
  },
  {
    id: 741,
    p_adminid: 2,
    productCategory: { id: 742, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 742,
      adminId: 2,
      parentId: 107,
      categoryId: 11,
      title: "3MM",
    },
    productChild: [
      {
        id: 8599,
        lbrId: 770,
        price: 0.25,
        taxPercentage: 10.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 8600,
        lbrId: 776,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Buffing",
      },
      {
        id: 8601,
        lbrId: 773,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Half Round",
      },
      {
        id: 8602,
        lbrId: 775,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8603,
        lbrId: 777,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 8604,
        lbrId: 791,
        price: 1.05,
        taxPercentage: 5.0,
        tax: 0.0525,
        title: "Cutting 7 Cut",
      },
      {
        id: 8605,
        lbrId: 803,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Plated & Chrome",
      },
      {
        id: 8606,
        lbrId: 811,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 742, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 742,
      adminId: 2,
      boxId: 75,
      brandName: "H5 - My Bangles 24",
      boxDesc: "10 Sets In 1 Box",
      pkgCharge: 10.0,
      box: "10",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 742,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 742,
      adminId: 2,
      packingmaterialId: 85,
      type: "Crystal Dibbi",
      pkgdesc: "H3",
      hsncode: "39231000",
      price: 3.4,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4081,
        imgId: 204,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20161117_101217.jpg",
        cropName: "20161117_101217.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-09-15T13:28:24.491+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.007,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "",
    prodManfDetails: "",
    prodDescription: "",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 429.65,
    cashPrice: 502.31348,
    taxAll: 59.397,
    taxInclude: 532.01196,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 250.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 752,
    p_adminid: 2,
    productCategory: { id: 776, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 776,
      adminId: 2,
      parentId: 101,
      categoryId: 11,
      title: "Golwire small",
    },
    productChild: [
      {
        id: 8856,
        lbrId: 501,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Solder",
      },
      {
        id: 8857,
        lbrId: 503,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8858,
        lbrId: 513,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8859,
        lbrId: 534,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
      {
        id: 8860,
        lbrId: 524,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 8861,
        lbrId: 505,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 8862,
        lbrId: 502,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
    ],
    productSets: { id: 776, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 776,
      adminId: 2,
      boxId: 72,
      brandName: "H5 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 11.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 776,
      adminId: 2,
      rawpriceId: 186,
      title: "Golwire Less 99 Thaw",
      price: 610.0,
      different: 8.0,
      taxPercentage: 18.0,
      tax: 111.24,
    },
    productPacking: {
      id: 776,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4164,
        imgId: 2431,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/22023092251324.jpeg",
        cropName: "22023092251324.jpeg",
      },
      {
        id: 4165,
        imgId: 2430,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230922513241.jpeg",
        cropName: "20230922513241.jpeg",
      },
      {
        id: 4166,
        imgId: 2426,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230922513422.jpeg",
        cropName: "20230922513422.jpeg",
      },
      {
        id: 4167,
        imgId: 2427,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230922513223.jpeg",
        cropName: "20230922513223.jpeg",
      },
      {
        id: 4168,
        imgId: 2428,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/320230922513231.jpeg",
        cropName: "320230922513231.jpeg",
      },
      {
        id: 4169,
        imgId: 2429,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/22023092251323.jpeg",
        cropName: "22023092251323.jpeg",
      },
    ],
    modifyDate: "2023-09-22T11:56:32.053+00:00",
    createDate: "2023-09-22T11:55:46.511+00:00",
    metalGram: 0.0045,
    extraGram: 5.0e-4,
    readyGram: 0.003,
    golwireThaw: "80 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: null,
    prodManfDetails: null,
    prodDescription: "k",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 355.882,
    cashPrice: 415.47156,
    taxAll: 48.00276,
    taxInclude: 439.47296,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 309.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 7,
        adminId: 2,
        productId: 752,
        stockCategoryId: 11,
        stockParentId: 101,
        cutOrFullSize: "1",
        createDate: "2023-09-22T12:00:21.292+00:00",
        s1x8: "0",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "17",
        s2x3: "0",
        s2x4: "8",
        s2x5: "0",
        s2x6: "32",
        s2x7: "0",
        s2x8: "32",
        s2x9: "0",
        s2x10: "12",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 101.0,
      },
    ],
  },
  {
    id: 753,
    p_adminid: 2,
    productCategory: { id: 777, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 777,
      adminId: 2,
      parentId: 111,
      categoryId: 11,
      title: "5MM",
    },
    productChild: [
      {
        id: 8928,
        lbrId: 969,
        price: 0.5,
        taxPercentage: 5.0,
        tax: 0.025,
        title: "Solder",
      },
      {
        id: 8929,
        lbrId: 975,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 8930,
        lbrId: 977,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Micro Dell",
      },
      {
        id: 8931,
        lbrId: 989,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 8932,
        lbrId: 1001,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Plating Gold Plated",
      },
      {
        id: 8933,
        lbrId: 1011,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 777, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 777,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 777,
      adminId: 2,
      rawpriceId: 189,
      title: "Rolling Patti/Lasiya",
      price: 610.0,
      different: 5.0,
      taxPercentage: 18.0,
      tax: 110.7,
    },
    productPacking: {
      id: 777,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4199,
        imgId: 2435,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/2023092260635.jpeg",
        cropName: "2023092260635.jpeg",
      },
      {
        id: 4200,
        imgId: 2436,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230922606361.jpeg",
        cropName: "20230922606361.jpeg",
      },
      {
        id: 4201,
        imgId: 2437,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230922606363.jpeg",
        cropName: "20230922606363.jpeg",
      },
      {
        id: 4202,
        imgId: 2438,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230922606374.jpeg",
        cropName: "20230922606374.jpeg",
      },
      {
        id: 4203,
        imgId: 2439,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/32023092260635.jpeg",
        cropName: "32023092260635.jpeg",
      },
      {
        id: 4204,
        imgId: 2434,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20230922 60637.jpeg",
        cropName: "20230922 60637.jpeg",
      },
    ],
    modifyDate: "2023-09-25T09:58:02.312+00:00",
    createDate: "2023-09-22T13:36:05.250+00:00",
    metalGram: 0.0105,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "000 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "1025",
    prodManfDetails: "test",
    prodDescription: "testtt",
    cartDescription: "60 boxes of in 1 Carton",
    cartQuantity: 4,
    cartPackCharge: 500.0,
    makingCharge: 239.03,
    cashPrice: 280.94168,
    taxAll: 36.017395,
    taxInclude: 298.95038,
    active: true,
    gstCash: true,
    moqkg: 60.48,
    moq: 240.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 9,
        adminId: 2,
        productId: 753,
        stockCategoryId: 11,
        stockParentId: 111,
        cutOrFullSize: "1",
        createDate: "2023-09-22T13:37:14.011+00:00",
        s1x8: "0",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "0",
        s2x3: "0",
        s2x4: "30",
        s2x5: "0",
        s2x6: "30",
        s2x7: "0",
        s2x8: "0",
        s2x9: "0",
        s2x10: "0",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 60.0,
      },
    ],
  },
  {
    id: 761,
    p_adminid: 2,
    productCategory: { id: 785, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 785,
      adminId: 2,
      parentId: 129,
      categoryId: 11,
      title: "10MM",
    },
    productChild: [
      {
        id: 9085,
        lbrId: 1857,
        price: 0.85,
        taxPercentage: 5.0,
        tax: 0.0425,
        title: "Pipe Cutting",
      },
      {
        id: 9086,
        lbrId: 1863,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Acid Double & Putas",
      },
      {
        id: 9087,
        lbrId: 1868,
        price: 1.5,
        taxPercentage: 5.0,
        tax: 0.075,
        title: "Diamond Dell/CNC",
      },
      {
        id: 9088,
        lbrId: 1865,
        price: 0.65,
        taxPercentage: 5.0,
        tax: 0.0325,
        title: "Micro Dell",
      },
      {
        id: 9089,
        lbrId: 1876,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
      {
        id: 9090,
        lbrId: 1877,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9091,
        lbrId: 1894,
        price: 13.5,
        taxPercentage: 5.0,
        tax: 0.675,
        title: "Plating 3tone\r\n",
      },
      {
        id: 9092,
        lbrId: 1897,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "MFG. Transport\r\n",
      },
    ],
    productSets: { id: 785, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 785,
      adminId: 2,
      boxId: 64,
      brandName: "Polythene bag",
      boxDesc: "10 set in 1 polythene bag",
      pkgCharge: 15.0,
      box: "10",
      hsnCode: "39202020",
      price: 3.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 785,
      adminId: 2,
      rawpriceId: 207,
      title: "20 Gauge Brass Tube 0.9mm Price in Mumbai",
      price: 620.0,
      different: 90.0,
      taxPercentage: 18.0,
      tax: 127.8,
    },
    productPacking: {
      id: 785,
      adminId: 2,
      packingmaterialId: 108,
      type: "Polythene PP",
      pkgdesc: "2 Pcs 7/8MM 2.5x4 - 3x4 - 3x4.5 - 3.25x5 - 3.5x5",
      hsncode: "39202020",
      price: 0.75,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4236,
        imgId: 2468,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/Watch_10MM_Gold Plated_20160204_145440.jpg",
        cropName: "Watch_10MM_Gold Plated_20160204_145440.jpg",
      },
      {
        id: 4237,
        imgId: 2467,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/Watch_10MM_Gold Plated_20160204_145319.jpg",
        cropName: "Watch_10MM_Gold Plated_20160204_145319.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-11-17T07:00:00.747+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: null,
    itemCodeNumber: "10MM",
    prodManfDetails: null,
    prodDescription: null,
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 527.5,
    cashPrice: 602.975,
    taxAll: 45.45,
    taxInclude: 625.7,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 500.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [
      {
        id: 16,
        adminId: 2,
        productId: 761,
        stockCategoryId: 11,
        stockParentId: 129,
        cutOrFullSize: "3",
        createDate: "2023-11-17T07:02:19.799+00:00",
        s1x8: "0",
        s1x9: "0",
        s1x10: "0",
        s1x11: "0",
        s1x12: "0",
        s1x13: "0",
        s1x14: "0",
        s1x15: "0",
        s2x0: "0",
        s2x1: "0",
        s2x2: "0",
        s2x3: "0",
        s2x4: "0",
        s2x5: "0",
        s2x6: "0",
        s2x7: "0",
        s2x8: "10",
        s2x9: "10",
        s2x10: "0",
        s2x11: "0",
        s2x12: "0",
        s2x13: "0",
        s2x14: "0",
        s2x15: "0",
        s3x0: "0",
        s3x1: "0",
        grandTotal: 20.0,
      },
    ],
  },
  {
    id: 763,
    p_adminid: 2,
    productCategory: { id: 787, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 787,
      adminId: 2,
      parentId: 121,
      categoryId: 11,
      title: "7/3MM",
    },
    productChild: [
      {
        id: 9118,
        lbrId: 1465,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Die Cutting",
      },
      {
        id: 9119,
        lbrId: 1471,
        price: 0.0,
        taxPercentage: 5.0,
        tax: 0.0,
        title: "Eaching",
      },
      {
        id: 9120,
        lbrId: 1468,
        price: 0.7,
        taxPercentage: 5.0,
        tax: 0.035,
        title: "Solder",
      },
      {
        id: 9121,
        lbrId: 1477,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Micro Dell",
      },
      {
        id: 9122,
        lbrId: 1474,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
    ],
    productSets: { id: 787, setsId: 26, setDesc: "1 Pcs", pcs: 1 },
    productBox: {
      id: 787,
      adminId: 2,
      boxId: 61,
      brandName: "Sets in Party Box",
      boxDesc: "6 Sets in Party Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 0.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 787,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 787,
      adminId: 2,
      packingmaterialId: 95,
      type: "Plastic Dibbi",
      pkgdesc: "R0",
      hsncode: "39231000",
      price: 2.1,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4254,
        imgId: 996,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20190510_132547.jpg",
        cropName: "20190510_132547.jpg",
      },
    ],
    modifyDate: "2023-11-27T14:00:26.683+00:00",
    createDate: "2023-11-27T13:49:24.849+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: 7112,
    itemCodeNumber: null,
    prodManfDetails: "test",
    prodDescription: null,
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 63.239998,
    cashPrice: 74.2476,
    taxAll: 9.367199,
    taxInclude: 78.9312,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 1667.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 764,
    p_adminid: 2,
    productCategory: { id: 788, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 788,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 9234,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 9235,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9236,
        lbrId: 542,
        price: 0.25,
        taxPercentage: 5.0,
        tax: 0.0125,
        title: "Zigzag",
      },
      {
        id: 9237,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
      {
        id: 9238,
        lbrId: 554,
        price: 0.45,
        taxPercentage: 5.0,
        tax: 0.0225,
        title: "Cutting 3 Cut",
      },
    ],
    productSets: { id: 788, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 788,
      adminId: 2,
      boxId: 69,
      brandName: "Plastic Box No.6",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "39231000",
      price: 0.0,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 788,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 788,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4326,
        imgId: 41,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/925450_571281839719336_1926478214.jpg",
        cropName: "925450_571281839719336_1926478214.jpg",
      },
    ],
    modifyDate: "2023-12-01T06:11:39.391+00:00",
    createDate: "2023-11-28T09:20:17.135+00:00",
    metalGram: 0.004,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: 7117,
    itemCodeNumber: "55",
    prodManfDetails: "jjjjj",
    prodDescription: "New Stylish 2 Tone Brass Bangles.",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 298.51202,
    cashPrice: 348.8393,
    taxAll: 40.95216,
    taxInclude: 369.31537,
    active: true,
    gstCash: false,
    moqkg: 100.0,
    moq: 347.0,
    taxApply: false,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 765,
    p_adminid: 2,
    productCategory: { id: 789, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 789,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 9161,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 9162,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9163,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9164,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 9165,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
    ],
    productSets: { id: 789, setsId: 27, setDesc: "2 Pcs in 1 Set", pcs: 2 },
    productBox: {
      id: 789,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 789,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 789,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4284,
        imgId: 41,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/925450_571281839719336_1926478214.jpg",
        cropName: "925450_571281839719336_1926478214.jpg",
      },
      {
        id: 4285,
        imgId: 42,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232145.jpg",
        cropName: "20160203_232145.jpg",
      },
      {
        id: 4286,
        imgId: 43,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232338.jpg",
        cropName: "20160203_232338.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-11-29T08:33:42.400+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: 1234,
    itemCodeNumber: "YTY65",
    prodManfDetails: "Test",
    prodDescription: "Test",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 128.33,
    cashPrice: 151.1977,
    taxAll: 20.069399,
    taxInclude: 161.23239,
    active: false,
    gstCash: true,
    moqkg: 100.0,
    moq: 833.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 766,
    p_adminid: 2,
    productCategory: { id: 790, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 790,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 9187,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 9188,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9189,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9190,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 9191,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 9192,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 790, setsId: 29, setDesc: "4 Pcs in 1 Set", pcs: 4 },
    productBox: {
      id: 790,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 790,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 790,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4305,
        imgId: 42,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232145.jpg",
        cropName: "20160203_232145.jpg",
      },
      {
        id: 4306,
        imgId: 43,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232338.jpg",
        cropName: "20160203_232338.jpg",
      },
      {
        id: 4307,
        imgId: 44,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232449.jpg",
        cropName: "20160203_232449.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-11-30T08:21:57.957+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: 1234,
    itemCodeNumber: "HYT45",
    prodManfDetails: "TEST",
    prodDescription: "TEST",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 221.81,
    cashPrice: 261.2299,
    taxAll: 34.4778,
    taxInclude: 278.4688,
    active: true,
    gstCash: false,
    moqkg: 100.0,
    moq: 417.0,
    taxApply: false,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
  {
    id: 767,
    p_adminid: 2,
    productCategory: { id: 791, adminId: 2, categoryId: 11, title: "BANGLES" },
    productParent: {
      id: 791,
      adminId: 2,
      parentId: 102,
      categoryId: 11,
      title: "Golwire Medium",
    },
    productChild: [
      {
        id: 9193,
        lbrId: 540,
        price: 0.3,
        taxPercentage: 5.0,
        tax: 0.015,
        title: "Solder",
      },
      {
        id: 9194,
        lbrId: 543,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Acid Double & Putas",
      },
      {
        id: 9195,
        lbrId: 545,
        price: 0.1,
        taxPercentage: 5.0,
        tax: 0.005,
        title: "Dell",
      },
      {
        id: 9196,
        lbrId: 555,
        price: 0.6,
        taxPercentage: 5.0,
        tax: 0.03,
        title: "Cutting 4 Cut",
      },
      {
        id: 9197,
        lbrId: 566,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Plating Gold Plated",
      },
      {
        id: 9198,
        lbrId: 544,
        price: 0.15,
        taxPercentage: 5.0,
        tax: 0.0075,
        title: "Micro Dell",
      },
    ],
    productSets: { id: 791, setsId: 34, setDesc: "12 Pcs in 1 Set", pcs: 12 },
    productBox: {
      id: 791,
      adminId: 2,
      boxId: 78,
      brandName: "H4 - My Bangles 24",
      boxDesc: "6 Sets In 1 Box",
      pkgCharge: 6.0,
      box: "6",
      hsnCode: "4819",
      price: 9.65,
      tax_percentage: 18.0,
    },
    productRawPrice: {
      id: 791,
      adminId: 2,
      rawpriceId: 185,
      title: "Golwire Less 60 Thaw",
      price: 610.0,
      different: 14.0,
      taxPercentage: 18.0,
      tax: 112.32,
    },
    productPacking: {
      id: 791,
      adminId: 2,
      packingmaterialId: 77,
      type: "Crystal Dibbi",
      pkgdesc: "M3",
      hsncode: "39231000",
      price: 3.8,
      ligst: 18.0,
    },
    productImage: [
      {
        id: 4308,
        imgId: 44,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232449.jpg",
        cropName: "20160203_232449.jpg",
      },
      {
        id: 4309,
        imgId: 43,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232338.jpg",
        cropName: "20160203_232338.jpg",
      },
      {
        id: 4310,
        imgId: 45,
        cropImageUrl:
          "http://localhost:8085/api/image/cropImageDownload/20160203_232704.jpg",
        cropName: "20160203_232704.jpg",
      },
    ],
    modifyDate: null,
    createDate: "2023-11-30T08:23:22.231+00:00",
    metalGram: 0.01,
    extraGram: 5.0e-4,
    readyGram: 0.0075,
    golwireThaw: "105 Thaw Golwire",
    hsnSacCode: 2345,
    itemCodeNumber: "JK89",
    prodManfDetails: "TEST",
    prodDescription: "TEST",
    cartDescription: "96 boxes of in 1 Carton",
    cartQuantity: 1,
    cartPackCharge: 500.0,
    makingCharge: 588.52997,
    cashPrice: 693.2586,
    taxAll: 91.751396,
    taxInclude: 739.13434,
    active: true,
    gstCash: true,
    moqkg: 100.0,
    moq: 139.0,
    taxApply: true,
    cgst: 1.5,
    sgst: 1.5,
    igst: 3.0,
    stockSize: [],
  },
];

export default productApi;
