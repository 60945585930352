/* eslint-disable */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";
import productApi from "offlineApi/productApi";

import { localApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import logo from "assets/img/examples/apple-insha-bangles-icon.png";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(aboutUsStyle);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function InshaSizeMMList() {
  const [prodList, setProdList] = React.useState([]);
  const [page, setpage] = React.useState(1);
  const [loginModal, setLoginModal] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classPop = useStylPop();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { search } = useLocation();
  const catdesc = new URLSearchParams(search).get("catdesc");
  const cat1desc = new URLSearchParams(search).get("cat1desc");

  // const fetchData = async () => {
  //   const catdesc = new URLSearchParams(search).get("catdesc");
  //   const cat1desc = new URLSearchParams(search).get("cat1desc");
  //   const itemsPerPage = 4;
  //   setpage(page + 1);

  //   const startIndex = (page - 1) * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   const pageItems = productApi
  //     .filter(
  //       (p) =>
  //         p.p_adminid == "2" &&
  //         p.category.title == catdesc &&
  //         p.parent.title == cat1desc
  //     )
  //     .slice(startIndex, endIndex);

  //   console.log([...pageItems]);
  //   setProdList([...prodList, ...pageItems]);
  // };

  useEffect(() => {
    // fetchData();

    const catdesc = new URLSearchParams(search).get("catdesc");
    const cat1desc = new URLSearchParams(search).get("cat1desc");

    const pageItems = productApi
      .filter(
        (p) =>
          p.p_adminid == "2" &&
          p.productCategory.title == catdesc &&
          p.productParent.title == cat1desc
      );

    setProdList(pageItems);

    setTimeout(hideAlertPop, 1000);
    setLoginModal(true);
  }, [search]);

  const hideAlertPop = () => {
    setLoginModal(false);
  };


  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Bangles Size MM</title>
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
      />
      <Parallax
        image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.pageHeader}
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classe.textCenter}>
              <h1 className={classe.title}>{catdesc}</h1>
              <h4 style={{ color: "white" }}>
                {catdesc === "BANGLES"
                  ? "Sparkles and glitters all the way. The only method to make your day. Take a little scoop of bangles, we say."
                  : catdesc === "EARRINGS"
                  ? "Trends come and go, seasons change yet, certain things stay - your grace, that million dollar smile and timeless jewelry."
                  : catdesc === "FINGER RINGS"
                  ? "A promise is forever. A promise to celebrate womanhood goes beyond forever, a challenge we have happily welcomed"
                  : catdesc === "WATCH"
                  ? "Take a journey to divineness with each collection, explore watches made with layers of passion and detailing."
                  : catdesc === "PUNJABI KADA"
                  ? "Unrevealing divine secrets with each piece of jewelry, the voyage is getting richer and richer."
                  : ""}
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <Dialog
            style={{ marginTop: "10%" }}
            classes={{
              root: classPop.modalRoot,
              paper: classPop.modal + " " + classPop.modalLogin,
            }}
            open={loginModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setLoginModal(false)}
            aria-labelledby="login-modal-slide-title"
            aria-describedby="login-modal-slide-description"
          >
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <h5>Loading...</h5>
              <DialogContent
                id="login-modal-slide-description"
                className={classPop.modalBody}
              >
                <GridContainer justify="center">
                  <Spinner
                    onChange={() => hideAlertPop()}
                    style={{ color: "black" }}
                  />
                </GridContainer>
                <br />
              </DialogContent>
            </DialogTitle>
          </Dialog>
          <div className={classes.relatedProducts}>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              You may also be interested in:
            </h3>
            {/* <InfiniteScroll
              dataLength={prodList.length}
              next={fetchData}
              hasMore={true}
            > */}
              <div className={classes.container}>
                <GridContainer>
                  {prodList &&
                    prodList.map((prod) => (
                      <GridItem sm={6} md={3} key={prod.id}>
                        <Card product>
                          <CardHeader image>
                            {/* <CornerRibbon
                          position={prod.total > 0 ? "top-left" : "top-right"} // position="top-right" // OPTIONAL, default as "top-right"
                          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                          backgroundColor="black" // OPTIONAL, default as "#2c7"
                          containerStyle={{}} // OPTIONAL, style of the ribbon
                          style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                          className="" // OPTIONAL, css class of ribbon
                        > {prod.total > 0 ? "In Stock" : "Make To Order"}
                        </CornerRibbon> */}
                            <a
                              href={`/insha-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                              // &Plating=${prod.plating.description.slice(8)}
                            >
                              <img
                                src={
                                  prod.productImage[0].cropImageUrl +
                                  "?id=" +
                                  prod.productImage[0].imgId
                                }
                                alt="..."
                              />
                              <p
                                style={{
                                  position: "absolute",
                                  top: "65%",
                                  left: "50%",
                                  width: "65px",
                                  height: "65px",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                <img src={logo} alt="logo1" />
                              </p>
                            </a>
                          </CardHeader>
                          <h6
                            className={classNames(
                              classes.cardCategory,
                              classes.textRose
                            )}
                          >
                            {prod.productCategory.title + " " + prod.productParent.title}{" "}
                            <br /> {prod.itemCodeNumber}-{prod.id}
                          </h6>
                        </Card>
                        <Card>
                          <CardBody>
                            <div className={classes.cardDescription}>
                              {(prod.readyGram * prod.productSets.pcs).toFixed(4)} Avg
                              Gram Of {prod.productSets.setDesc} {"and "}
                              {prod.productBox.boxDesc}
                              {","} {prod.cartDescription}
                              {"."}
                            </div>
                          </CardBody>
                          <CardFooter className={classes.justifyContentBetween}>
                            <div className={classes.price}>
                              <h5>
                                <span style={{ color: "red" }}>
                                  GST Price<br /> ₹{" "}
                                  {prod.taxInclude.toFixed(2) + " / "}₹{(prod.taxInclude / prod.productBox.box).toFixed(2) + " Inc."}
                                </span>
                                <br />
                                <span style={{ color: "black" }}>
                                  CASH Price<br /> ₹ {prod.cashPrice.toFixed(2) + " / "}₹{(prod.cashPrice / prod.productBox.box).toFixed(2)}
                                </span>
                              </h5>
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))}
                </GridContainer>
              </div>
            {/* </InfiniteScroll> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
